import { type Item } from '../types'
import { type ComplexType } from './complex_type'

export type InitFunction = new (init?: any) => ComplexType
export type GetFormFunction = () => Item

export interface ComplexTypeRegistrationInfo {
  name: string
  Constructor: InitFunction
  form: GetFormFunction
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ComplexFactory {
  private static readonly registeredClasses = new Map<string, ComplexTypeRegistrationInfo>()

  public static registerClass (info: ComplexTypeRegistrationInfo): void {
    ComplexFactory.registeredClasses.set(info.name, info)
  }

  public static isClassRegistered (name: string): boolean {
    return ComplexFactory.registeredClasses.has(name)
  }

  public static getForm (name: string): Item {
    const registrationInfo = ComplexFactory.registeredClasses.get(name)
    if (!registrationInfo) {
      throw new Error('Unknown Class ' + name)
    }
    return registrationInfo.form()
  }

  public static new<T extends ComplexType>(name: string, init?: any): T {
    if (!ComplexFactory.registeredClasses.has(name)) {
      throw new Error('Unknown Class ' + name)
    }
    const registrationInfo = ComplexFactory.registeredClasses.get(name) as ComplexTypeRegistrationInfo
    if (!registrationInfo) {
      throw new Error('Unknown Class ' + name)
    }
    const result = new registrationInfo.Constructor(init) as T
    return result
  }
}
