import { Action, Section, Translation } from "anketa-core"
import { KeycloakInstance } from "keycloak-js"
export const generateActions = (savePath: string, changeListener: () => void, authCtx: KeycloakInstance) => {
    const rActionSection = new Section('action_row', [
        new Translation('en', ''),
        new Translation('de', '')
    ])



    const aStore = new Action(`save_${savePath}`, [
        new Translation('en', 'Save'),
        new Translation('de', 'Speichern')
    ])
    aStore.addChangeListener(changeListener)
    rActionSection.addChild(aStore)


    return rActionSection
}