export const template = `

![Opsanio Logo](https://nextcloud.autonubil.net/s/JNgb2Mi2RY9PDAM)
### Opsanio Onboarding 
#### This document is a full documentation for the opsanio-{{autonubil.commons.project.value}}-{{autonubil.commons.stage.value}} that is located by {{basic.header.name.value}}.

## Configuration 

### Hardware

![opsanio setup street](https://nextcloud.autonubil.net/s/PNgmG54C7DT88AA)

- **1**: Setup Interface
  - This interface is used only for server setup. Once the appliance is with the customer, the interface has no further use.
- **2** && **3**: Bonded NIC's
  - These interfaces connect the appliance to the customer network and the CPE network. To ensure high redundancy, the two NIC's create a bond (mode 0/round-robin) and are connected to two different switches, so that only one NIC is active at a time.
- **4**: Management
  - This interface provides access to the server's management interface.
- **5** && **6**: Power
  - Similar to the NIC's, the two power supplies are connected to different power circuits to achieve high redundancy.


| Name                | Serial number | Device Type               | Storage | Storage Counter | RAM    | RAM Counter | CPU                              | CPU Speed | CPU Cores | CPU threats | Firmware Updates | High Performance Compute | Latest Ubuntu ISO |
| ------------------- | ------------ | ------------------------- | ------- | --------------- | ------ | ----------- | -------------------------------- | --------- | --------- | ----------- | ---------------- | ------------------------ | ----------------- |
{{#agents}}
| {{agent.agentname.value}} | {{agent.sn.value}} | ProLiant DL325 Gen10 Plus | 4800 GB | 5 x 960 GB      | 384 GB | 12 x 32 GB  | AMD EPYC 7302P 16-Core Processor | 3000 MHz  | 16/16     | 32          | ✅               | ✅                       | ✅                |
{{/agents}}

Each node has a redundant set of network ports that form a bond.

### {{autonubil.exanio_net.nn_exanio.value}} network details

The {{autonubil.exanio_net.nn_exanio.value}} network facilitates communication between nodes. Agent nodes in the appliance communicate with witness nodes in the Opsanio datacenter via a secure WireGuard tunnel. Agents must access the WireGuard endpoint. If there is an overlapping network in the customer's network, it cannot be scanned directly by the nodes.

- VLAN: {{autonubil.exanio_net.vlan_exanio.value}}
- CIDR: {{autonubil.exanio_net.network_exanio.value}}

The physical site is located in our data center. There are two essential components there:

- **Kula**: This component is needed both for managing the stack and as a transfer interface.
- **K3s-Master**: These provide all the necessary services, including Squid and Traefik. They provide updates and interfaces for the agents.

A native flannel WireGuard network is set up in the Kubernetes cluster, which enables communication between all K3S masters and agents. Each master has the following network interfaces:

- In the Exanio network
- Flannel transfer address
- Kubernetes network

### {{autonubil.cpe_net.nn_cpe.value}} network details

The {{autonubil.cpe_net.nn_cpe.value}} network is response for the communication between the two opsanio servers.

These are also part of the {{autonubil.exanio_net.nn_exanio.value}} network and can therefore communicate with the remote stations in the data center via the WireGuard.

There is the possibility of a two-room setup. If it is a two-room setup, the /26 network will be split into two /27 networks. To facilitate the transition to a two-room setup, the agents are distributed within the /26 network into the sub-segments from the beginning, but are still part of the /26 network.

The CPE site is the physical appliance at the customer. It must communicate with both the Exanio network and the customer network. The interfaces are:

- In the transfer network
- In the customer network
- Kubernetes network
- Flannel transfer address
- Exanio address

Communication with the Exanio and Kubernetes networks takes place via the transfer network.

### {{autonubil.cpe_net.nn_cpe.value}} network details

- VLAN: {{autonubil.cpe_net.vlan_cpe.value}}
- CIDR: {{autonubil.cpe_net.network_cpe.value}}
- Shared IP: {{autonubil.cpe_net.cpe_shared.value}}
- Agents: 
{{#agents}}
  - {{agent.agentname.value}}: {{agent.cip.value}}
{{/agents}}

#### {{autonubil.cpe_net.cpe_a_net.nn_cpe_a.value}}

- VLAN: {{autonubil.cpe_net.cpe_a_net.vlan_cpe_a.value}}
- CIDR: {{autonubil.cpe_net.cpe_a_net.network_cpe_a.value}}

#### {{autonubil.cpe_net.cpe_b_net.nn_cpe_b.value}}

- VLAN: {{autonubil.cpe_net.cpe_b_net.vlan_cpe_b.value}}
- CIDR: {{autonubil.cpe_net.cpe_b_net.network_cpe_b.value}}

### {{autonubil.transfer_net.nn_transfer.value}} network details

The {{autonubil.transfer_net.nn_transfer.value}} is the part of the {{autonubil.exanio_net.nn_exanio.value}} VPC for {{basic.header.name.value}} it connects the CPE, located by {{basic.header.name.value}}, with the opsanio Backend side using a WireGuard connection.

- VLAN: {{autonubil.transfer_net.vlan_transfer.value}}
- CIDR: {{autonubil.transfer_net.network_transfer.value}}
- WireGuard DNS endpoint: {{autonubil.wg.wireguard_dns.value}}:{{autonubil.wg.wireguard_port.value}}
- WireGuard IP endpoint: {{autonubil.wg.wireguard_ip.value}}:{{autonubil.wg.wireguard_port.value}}
- Agents: 
{{#agents}}
  - {{agent.agentname.value}}: {{agent.tip.value}}
{{/agents}}

### {{autonubil.cpe_net.nn_cpe.value}}

### Kubernetes

This network is hidden from the customer. It should not be routed and requires no firewall permissions. For the customer, the key point is that if they use the same network, it cannot be scanned by the appliance.

##### Kubernetes network details

- CIDR: 10.42.0.0/16

### Management 

Each appliance node has a management board. The boards can be part of an existing customer management zone or can be associated with the same VLAN as the Internal Network.

##### ILO network details

- VLAN: {{management_network_vlan}}
- CIDR: {{management_network_cidr}}
- Agents: 
{{#agents}}
  - {{agent.agentname.value}}: {{agent.redfish.value}}
{{/agents}}

### {{basic.header.name.value}} networks

#### {{basic.header.name.value}} and opsanio Appliance Transfer Network

This network is visible to the customer. It is used to access the dashboards, ingest data, and serve as the origin for scanner requests. Each physical node has its own IP address, which is used for outgoing requests from these nodes. All nodes share a single virtual IP address, which acts as the entry point and is load balanced to the physical nodes.

##### {{basic.header.name.value}} network details

- VLAN: {{c_network_env.c_gp_setup.vlan_setup.value}}
- CIDR: {{c_network_env.c_gp_setup.network_setup.value}}
- Shared IP: {{c_network_env.c_gp_setup.shared_ip_setup.value}}
- Gateway: {{c_network_env.c_gp_setup.gateway_setup.value}}
- DNS: {{c_network_env.c_gp_setup.dns_setup.value}}
- Agents: 
{{#agents}}
  - {{agent.agentname.value}}: {{agent.cuip.value}}
{{/agents}}

##### DNS Names, Services, and Ports

- Dashboards: https://dashboards.opsanio-{{autonubil.commons.project.value}}-{{autonubil.commons.stage.value}}.exanio.net on port 443
- Syslog: syslog.opsanio-{{autonubil.commons.project.value}}-{{autonubil.commons.stage.value}}.exanio.net UDP/TCP 514
- SNMP Traps: snmp.opsanio-{{autonubil.commons.project.value}}-{{autonubil.commons.stage.value}}.exanio.net UDP/TCP 162

#### Other networks from {{basic.header.name.value}}

| Name | CIDR | Gateway | 
| ---- | ---- | ------- |
{{#customer_networks}}
| {{cne.nn.value}} | {{cne.network.value}} | {{cne.gateway.value}} |  
{{/customer_networks}}

#### Opsanio Network overview

| Network    | Example Subnet | VLAN | Color in Diagrams                                                                      | Connection Type to Switches | VLAN Mode on Switches |
| ---------- | -------------- | ---- | -------------------------------------------------------------------------------------- | --------------------------- | --------------------- |
| Setup      | 10.207.0.0/24  | 70   | <span style="color:#009900; background:#009900;">&#9632;</span> #009900 (Green)        | RJ45                        | Untagged              |
| iLO        |  {{management_network_cidr}} | {{management_network_vlan}}   | <span style="color:#66B2FF; background:#66B2FF;">&#9632;</span> #66B2FF (Light Blue)   | RJ45                        | Untagged              |
| Exanio     | {{autonubil.exanio_net.network_exanio.value}}   | {{autonubil.exanio_net.vlan_exanio.value}} | <span style="color:#99004D; background:#99004D;">&#9632;</span> #99004D (Dark Violet) | -                           | -                     |
| CPE        | {{autonubil.cpe_net.network_cpe.value}} | {{autonubil.cpe_net.vlan_cpe.value}} | <span style="color:#FF00FF; background:#FF00FF;">&#9632;</span> #FF00FF (Pink)         | Fiber (LWL)                 | Tagged                |
| Transfer   | {{autonubil.transfer_net.network_transfer.value}}  | {{autonubil.transfer_net.vlan_transfer.value}} | <span style="color:#3333FF; background:#3333FF;">&#9632;</span> #3333FF (Dark Blue)    | -                           | -                     |
| Customer   | {{c_network_env.c_gp_setup.network_setup.value}} | {{c_network_env.c_gp_setup.vlan_setup.value}} | <span style="color:#FF8000; background:#FF8000;">&#9632;</span> #FF8000 (Orange)       | Fiber (LWL)                 | Tagged                |
| Kubernetes |    10.42.0.0/16  |      | <span style="color:#CCE5FF; background:#CCE5FF;">&#9632;</span> #CCE5FF (Navy)         | Fiber (LWL)                 | Tagged                |

<pre><code>
// {images.networkIo}
</code></pre>

## Setup

### Customer Prerequisites

Before installing the appliance, ensure the following requirements are met:

- Sufficient rack space (2U)
- 4 available PDU power sockets
- RJ45 connections for ILO access
- VLAN's and ports configured:
  - {{node_count\*2}} 10GB ports ({{node_count}} bonds)
  - VLAN's: 
    - {{basic.header.name.value}}: {{c_network_env.c_gp_setup.vlan_setup.value}}
    - opsanio: {{autonubil.cpe_net.vlan_cpe.value}}

If only the basic requirements are met, the appliance will not be available to users or able to gather information. Please follow the instructions below for further setup.

#### Rules

| Name     | Protocol     | Port     | Source                                                                                              | Destination                            | 
| -------- | ------------ | -------- | --------------------------------------------------------------------------------------------------- | -------------------------------------- |
| WireGuard-outgoing | UDP | {{autonubil.wg.wireguard_port.value}}    | {{#agents}} {{agent.cuip.value}}, {{/agents}}                                                       | {{autonubil.wg.wireguard_ip.value}}, {{autonubil.wg.wireguard_dns.value}} |
| WireGuard-incoming | UDP | any    | {{autonubil.wg.wireguard_ip.value}}, {{autonubil.wg.wireguard_dns.value}} | {{#agents}} {{agent.cuip.value}}, {{/agents}}                                                       | 
| IPFIX    | UDP          | 4739     | {{autonubil.cpe_net.cpe_shared.value}}                                                              |                                        |
| NetFlow  | UDP          | 4739     | {{autonubil.cpe_net.cpe_shared.value}}                                                              |                                        |
| SysLog   | UDP          | 514      | {{autonubil.cpe_net.cpe_shared.value}}                                                              |                                        |
| SNMP     | UDP          | 162      | {{autonubil.cpe_net.cpe_shared.value}}                                                              |                                        |
| Wazuh    | TCP          | 1514     | {{autonubil.cpe_net.cpe_shared.value}}                                                              |                                        |
| WebHooks | TCP          | 443/9999 | {{autonubil.cpe_net.cpe_shared.value}}                                                              |                                        |
| Opsnaio Dashboard | TCP | 443      |  | dashboards.opsanio-{{autonubil.commons.project.value}}-{{autonubil.commons.stage.value}}.exanio.net |
| vmWare-shared   | TCP          | 443      | vSphere Address from {{basic.header.name.value}}                                                    | {{autonubil.cpe_net.cpe_shared.value}} |
{{#agents}}
| vmWare-{{agent.agentname.value}}   | TCP          | 443      | vSphere Address from {{basic.header.name.value}}                                                    | {{agent.cip.value}} |
{{/agents}}

For each network you want to scan, we recommend deactivating disable intrusion detection:

| Network Name | Protocol | Port | Source | Destination |
| ------------ | -------- | ---- | ------ | ----------- |
{{#customer_networks}}
| scan-{{cne.nn.value}}    | any          | any      | {{autonubil.cpe_net.cpe_shared.value}} | {{cne.network.value}} |
{{/customer_networks}}

### Wazuh Agents

You can find the manuall on your [Opsanio Dashboard](https://dashboard.opsanio-{{autonubil.commons.project.value}}-{{autonubil.commons.stage.value}}.exanio.net/app/endpoints-summary#/agents-preview/) if you click on the button \`Deploy new agent\`.

`;
