import axios, { type AxiosError } from 'axios'
import { SERVER_URL } from '../common/constant'
import { ApiMethods, IAxios, IReturn } from '../../_types';
export const url = `${SERVER_URL}api/`


export const useApi = () => {
  const makeRequest = async (
    method: ApiMethods,
    { path, token, value, responseType }: IAxios
  ): Promise<IReturn> => {
    if (token !== undefined) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    try {
      const response = await axios[method](url + path, value, {
        responseType: responseType ?? 'json',
      });

      return {
        status: response.status,
        data: response.data,
      };
    } catch (error: unknown) {
      const axiosError = error as AxiosError
      return {
        status: axiosError.response?.status ?? 500,
        data: axiosError.message ?? 'An error occurred',
      };
    }
  };

  const Get = async (params: IAxios): Promise<IReturn> => {
    return await makeRequest('get', params);
  };

  const Post = async (params: IAxios): Promise<IReturn> => {
    return await makeRequest('post', params);
  };

  const Put = async (params: IAxios): Promise<IReturn> => {
    return await makeRequest('put', params);
  };

  const Delete = async (params: IAxios): Promise<IReturn> => {
    return await makeRequest('delete', params);
  };

  return { Get, Post, Put, Delete };
};