import { JsonObject } from "../../_types"

export const removePrefixFromKeys = (obj: JsonObject): JsonObject => {
    const newObj: JsonObject = {}

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const [firstPart, secondPart, ...rest] = key.split('.')
            const newKey = [firstPart, secondPart, ...rest].join('.')
            newObj[newKey] = obj[key]
        }
    }

    return newObj
}
export const createNestedObject = (obj: JsonObject): any => {
    const result: any = {}

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const keys = key.split('.')
            let currentLevel = result

            for (let i = 0; i < keys.length; i++) {
                const subKey = keys[i]

                if (i === 0) {
                    continue
                }

                currentLevel[subKey] = currentLevel[subKey] || {}

                if (i === keys.length - 1) {
                    const leafKey = keys[keys.length - 1]
                    const value = obj[key]?.value !== undefined ? obj[key]?.value : null
                    currentLevel[subKey][leafKey] = value
                }

                currentLevel = currentLevel[subKey]
            }
        }
    }

    return result
}
export const removeNumberSuffix = (obj: { [key: string]: any }): { [key: string]: any } => {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const newKey = key.replace(/_(\d+)$/, '');
            newObj[newKey] = typeof obj[key] === 'object' ? removeNumberSuffix(obj[key]) : obj[key];
        }
    }
    return newObj;
};
export const removeNumberFromKey = (obj: JsonObject): JsonObject => {
    const newObj: JsonObject = {}
    const regex = /\_\d+$/
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const newKey = key.replace(regex, '')
            newObj[newKey] = obj[key]
        }
    }
    return newObj
}
export const removeParentKey = (obj: JsonObject): JsonObject => {
    const newObj: JsonObject = {}
    const regex = /^([a-z])_ne_\d+_/
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const newKey = key.replace(regex, '')
            newObj[newKey] = obj[key]
        }
    }
    return newObj
}