import { DateQuestion, Group, NumericQuestion, OpenQuestion, OptionsQuestion, Row, Section, Translation } from "anketa-core"
import { MedoOptionMap } from "../helpers/medoOptionsMap"

export const generateBasicUse = () => {

    const sBasic = new Section('basic', [new Translation('en', 'Basic use'), new Translation('de', 'Grundlegende Nutzung')])

    const rVerison = new Row('versions', [new Translation('en', 'Versions'), new Translation('de', 'Versionen')])
    const vVmware = new OpenQuestion('vvmarer', [new Translation('en', 'VMWare'), new Translation('de', 'VMWare')])
    vVmware.help = [
        new Translation('en', 'Which version of VMware are you currently using?'),
        new Translation('de', 'Welche Version von VMware verwenden Sie aktuell?')
    ]
    rVerison.addChild(vVmware)

    const vVsphere = new OpenQuestion('vsphere', [new Translation('en', 'vSphere'), new Translation('de', 'vSphere')])
    vVsphere.help = [
        new Translation('en', 'Which version of vSphere are you currently using?'),
        new Translation('de', 'Welche Version von vSphere verwenden Sie aktuell?')
    ]
    rVerison.addChild(vVsphere)

    const vEsxi = new OpenQuestion('vesxi', [new Translation('en', 'ESXI'), new Translation('de', 'ESXI')])
    vEsxi.help = [
        new Translation('en', 'Which version of ESXI are you currently using?'),
        new Translation('de', 'Welche Version von ESXI verwenden Sie aktuell?')
    ]
    rVerison.addChild(vEsxi)

    sBasic.addChild(rVerison)


    const vLizenz = new OptionsQuestion(`vlizenz`, [new Translation('en', ''), new Translation('de', 'Lizenzedition')], MedoOptionMap.vmware_lizenz)
    vLizenz.help = [
        new Translation('en', 'Which license edition do you use?'),
        new Translation('de', 'Welche Lizenzedition verwenden Sie?')
    ]
    sBasic.addChild(vLizenz)

    const vLizenzModel = new OptionsQuestion(`vlizenzmodel`, [new Translation('en', ''), new Translation('de', 'Lizenzmodell')], MedoOptionMap.vmware_lizenz_modell)
    vLizenzModel.help = [
        new Translation('en', 'Which license model do you use?'),
        new Translation('de', 'Welches Lizenzmodell nutzen Sie?')
    ]
    sBasic.addChild(vLizenzModel)


    const endSupport = new DateQuestion('end_support', [new Translation('en', 'Support'), new Translation('de', 'Support')])
    endSupport.help = [
        new Translation('en', 'How long do you currently still have support/subscription?'),
        new Translation('de', 'Wie lange haben Sie aktuell noch Support/Subscription?')
    ]
    sBasic.addChild(endSupport)

    const vms = new NumericQuestion('vms', [new Translation('en', 'VMs'), new Translation('de', 'VMs')])
    vms.help = [
        new Translation('en', 'How many hosts and virtual machines (VMs) do you manage in your environment?'),
        new Translation('de', 'Wie viele Hosts und virtuelle Maschinen (VMs) verwalten Sie in Ihrer Umgebung?')
    ]

    const optimization = new Group('optimization_lizenz', [new Translation('en', 'Optimierung der Lizenzierung '), new Translation('de', 'Optimization of licensing ')])
    sBasic.addChild(vms)

    const licenseVerification = new OptionsQuestion(`license_verification`, [new Translation('en', 'License verification'), new Translation('de', 'Lizenzüberprüfung')], MedoOptionMap.yes_no);
    licenseVerification.help = [
        new Translation('en', 'Have you recently performed a review of your existing licenses to ensure that you are only using the licenses you need?'),
        new Translation('de', 'Haben Sie kürzlich eine Überprüfung Ihrer vorhandenen Lizenzen durchgeführt, um sicherzustellen, dass Sie nur die benötigten Lizenzen verwenden?')
    ];
    optimization.addChild(licenseVerification)

    const essentialsKits = new OptionsQuestion(`essentials_kits`, [new Translation('en', 'Essentials Kits'), new Translation('de', 'Essentials Kits')], MedoOptionMap.yes_no);
    essentialsKits.help = [
        new Translation('en', 'Do you use the vSphere Essentials or Essentials Plus kits for smaller environments?'),
        new Translation('de', 'Nutzen Sie die vSphere Essentials oder Essentials Plus Kits für kleinere Umgebungen?')
    ];
    optimization.addChild(essentialsKits)

    const lizenzeditionen = new OptionsQuestion(`lizenzeditionen`, [new Translation('en', 'Lizenzeditionen'), new Translation('de', 'Lizenzeditionen')], MedoOptionMap.yes_no);
    lizenzeditionen.help = [
        new Translation('en', 'Have you checked whether all the functions of the vSphere editions you are using are actually required, or whether a less expensive edition would be sufficient?'),
        new Translation('de', 'Haben Sie geprüft, ob alle Funktionen der von Ihnen verwendeten vSphere-Editionen tatsächlich benötigt werden, oder ob eine kostengünstigere Edition ausreichend wäre?')
    ];
    optimization.addChild(lizenzeditionen)

    sBasic.addChild(optimization)
    return sBasic
}
