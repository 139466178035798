import React from 'react';
import {
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModalBody,
} from '@elastic/eui';
import { UserValues } from '../types';

export const UserEditingModalUser = ({
  editing,
  oldUserData,
  newGivenName,
  setNewGivenName,
  newSurename,
  setSurename,
  domain,
}: {
  editing: boolean;
  oldUserData: UserValues;
  newGivenName: string;
  setNewGivenName: (name: string) => void;
  newSurename: string;
  setSurename: (name: string) => void;
  domain: string;
}) => {
  return (
    <EuiModalBody>
      <EuiForm>
        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Vorname">
              <EuiFieldText disabled value={oldUserData.givenName} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Vorname">
                <EuiFieldText
                  value={newGivenName}
                  placeholder={oldUserData.givenName}
                  onChange={(e) => {
                    setNewGivenName(e.target.value);
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Nachname">
              <EuiFieldText disabled value={oldUserData.surname} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Nachname">
                <EuiFieldText
                  value={newSurename}
                  placeholder={oldUserData.surname}
                  onChange={(e) => {
                    setSurename(e.target.value);
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Display Name">
              <EuiFieldText disabled value={oldUserData.displayName} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Display Name">
                <EuiFieldText disabled value={`${newGivenName} ${newSurename}`} />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Mail Nickname">
              <EuiFieldText disabled value={oldUserData.mailNickname} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Mail Nickname">
                <EuiFieldText
                  disabled
                  value={`${newGivenName.toLowerCase()}.${newSurename.toLowerCase()}`}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="E-Mail">
              <EuiFieldText disabled value={oldUserData.mail} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue E-Mail">
                <EuiFieldText
                  disabled
                  value={`${newGivenName.toLowerCase()}.${newSurename.toLowerCase()}@${domain}`}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>
      </EuiForm>
    </EuiModalBody>
  );
};
