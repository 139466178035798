import { QuestionType } from './base'
import { type Options, type TextMessage } from './i18n'
import { OptionsQuestion } from './options_question'

export class SuggestionsQuestion extends OptionsQuestion {
  constructor (id: string, title: TextMessage, options: Options) {
    super(id, title, options, QuestionType.Suggestions)
    this.setSuggestOnly(true)
  }

  public read (o: any): this {
    super.read(o)
    this.setSuggestOnly(true)
    return this
  }

  public save (): any {
    return super.save()
  }
}
