import { Group, OptionsQuestion, Row, Section, Translation } from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';
import { generateTool } from './tool';

export const generateResourceManagement = () => {
  const sBasic = new Section('ressourcemgmt', [
    new Translation('en', 'Resource management'),
    new Translation('de', 'Ressourcenverwaltung'),
  ]);

  const drs = new OptionsQuestion(
    `drs`,
    [new Translation('en', 'DRS'), new Translation('de', 'DRS')],
    MedoOptionMap.yes_no
  );
  drs.help = [
    new Translation(
      'en',
      'Do you use vSphere DRS (Distributed Resource Scheduler) for load balancing?'
    ),
    new Translation(
      'de',
      'Nutzen Sie vSphere DRS (Distributed Resource Scheduler) für die Lastverteilung?'
    ),
  ];
  sBasic.addChild(drs);

  const dpm = new OptionsQuestion(
    `dpm`,
    [new Translation('en', 'DPM'), new Translation('de', 'DPM')],
    MedoOptionMap.yes_no
  );
  dpm.help = [
    new Translation('en', 'Do you use vSphere DPM (Distributed Power Management)?'),
    new Translation('de', 'Nutzen Sie vSphere DPM (Distributed Power Management)?'),
  ];
  sBasic.addChild(dpm);

  const pools = new OptionsQuestion(
    `pools`,
    [new Translation('en', 'Ressource Pools'), new Translation('de', 'Ressource Pools')],
    MedoOptionMap.yes_no
  );
  pools.help = [
    new Translation('en', 'Do you use ResourcePools?'),
    new Translation('de', 'Nutzen Sie RessourcePools?'),
  ];
  sBasic.addChild(pools);

  const limits = new Row('limits', 'Limits');
  const cpuLimits = new OptionsQuestion(
    `cpulimits`,
    [new Translation('en', 'CPU Limits'), new Translation('de', 'CPU Limits')],
    MedoOptionMap.yes_no
  );
  cpuLimits.help = [
    new Translation('en', 'Do you use CPU Limits?'),
    new Translation('de', 'Nutzen Sie CPU Limits?'),
  ];
  limits.addChild(cpuLimits);

  const memoryLimits = new OptionsQuestion(
    `memorylimits`,
    [new Translation('en', 'Memory Limits'), new Translation('de', 'Memory Limits')],
    MedoOptionMap.yes_no
  );
  memoryLimits.help = [
    new Translation('en', 'Do you use Memory Limits?'),
    new Translation('de', 'Nutzen Sie Memory Limits?'),
  ];
  limits.addChild(memoryLimits);
  sBasic.addChild(limits);

  const shares = new Row('shares', 'Shares');
  const cpushares = new OptionsQuestion(
    `cpushares`,
    [new Translation('en', 'CPU shares'), new Translation('de', 'CPU shares')],
    MedoOptionMap.yes_no
  );
  cpushares.help = [
    new Translation('en', 'Do you use CPU shares?'),
    new Translation('de', 'Nutzen Sie CPU shares?'),
  ];
  shares.addChild(cpushares);

  const memoryshares = new OptionsQuestion(
    `memoryshares`,
    [new Translation('en', 'Memory shares'), new Translation('de', 'Memory shares')],
    MedoOptionMap.yes_no
  );
  memoryshares.help = [
    new Translation('en', 'Do you use Memory shares?'),
    new Translation('de', 'Nutzen Sie Memory shares?'),
  ];
  shares.addChild(memoryshares);
  sBasic.addChild(shares);

  const optimization = new Group('optimization_energy', [
    new Translation('en', ''),
    new Translation('de', ''),
  ]);
  optimization.addChild(generateTool('ressources'));
  sBasic.addChild(optimization);

  return sBasic;
};
