import React, { useState } from "react";
import { EuiButton, EuiButtonEmpty, EuiComboBox, EuiComboBoxOptionOption, EuiFieldText, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiTextArea } from "@elastic/eui";
import { Newsletter, UserMail } from "../../../_types";
import { useNewsletter } from "../../../_functions";

export const CreateNewsletterModal = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
    const { mailUsers, scannedMailbox, createNewsletter } = useNewsletter()

    const [sendNewsletterAs, setSendNewsletterAs] = useState<UserMail | undefined>()
    const [newsletterName, setNewsletterName] = useState<string | undefined>();
    const [newsletterText, setNewsletterText] = useState<string | undefined>();
    const [from, setFrom] = useState<string | undefined>();
    const [interval, setInterval] = useState<number | undefined>();

    const handleChangeSendNewsletterAs = (selectedOptions: EuiComboBoxOptionOption<UserMail>[]) => {
        if (selectedOptions && selectedOptions.length > 0) {
            const selectedOption = selectedOptions[0];
            setSendNewsletterAs(selectedOption.value as UserMail);
        } else {
            setSendNewsletterAs(undefined);
        }
    };

    const closeModal = () => {
        handleClose()
    }
    const finish = () => {
        console.warn(from && interval && newsletterName && sendNewsletterAs && newsletterText)
        if (from && interval && newsletterName && sendNewsletterAs && newsletterText) {
            const newsletter: Newsletter = {
                from_email: from,
                id: "",
                interval: interval,
                name: newsletterName,
                sender: sendNewsletterAs.id,
                text: newsletterText
            }
            console.warn(newsletter)
            createNewsletter(newsletter)
            closeModal()
        }
    }


    return (
        <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Neuen Newsletter erstellen</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiForm component="form">
                    <EuiFormRow>
                        <p>Die E-Mail die Weitergeleitet werden soll muss an <strong>{scannedMailbox}</strong> gesendet werde!</p>
                    </EuiFormRow>
                    <EuiFormRow
                        label="Senden im Namen von"
                        helpText="Wählen Sie eine E-Mail-Adresse aus, unter der Sie senden möchten."
                    >
                        <EuiComboBox
                            placeholder="Wählen Sie eine Option"
                            singleSelection={true}
                            options={mailUsers.map((user: UserMail) => ({
                                label: `${user.displayName} (${user.email})`,
                                value: user,
                            }))}
                            selectedOptions={sendNewsletterAs !== undefined ? [{ label: `${sendNewsletterAs.displayName} (${sendNewsletterAs.email})`, value: sendNewsletterAs }] : []}
                            onChange={(selectedOptions) => handleChangeSendNewsletterAs(selectedOptions)}
                        />
                    </EuiFormRow>
                    <EuiFieldText
                        placeholder="Emails Weiterleiten von"
                        aria-label="Emails Weiterleiten von"
                        name="from"
                        onChange={(e) => setFrom(e.target.value)}
                    />
                    <EuiFieldText
                        placeholder="Name"
                        aria-label="Name des Newsletters"
                        name="newsletterName"
                        onChange={(e) => setNewsletterName(e.target.value)}
                    />
                    <EuiTextArea
                        placeholder="Text"
                        aria-label="Text des Newsletters"
                        name="newsletterText"
                        onChange={(e) => setNewsletterText(e.target.value)}
                    />
                    <EuiFieldText
                        placeholder="Interval"
                        aria-label="Scan alle x minuten"
                        name="interval"
                        type="number"
                        onChange={(e) => setInterval(parseInt(e.target.value))}
                    />
                </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
                <EuiButton onClick={() => finish()}>Erstellen</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}