import React, { useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
} from '@elastic/eui';
import { CreateUserStepper } from './steper_user';
import { DepartmentOption, OnboardingTemplate } from './types';
import { SelectTemplate } from './selectTemplate';
import { SelectUserValues } from './userVlaues';
import { createOfficeUser } from './functions/tennant';
import { useSupport } from '../../../_functions/support/support';
import { UpdateUserTemplateValues } from './updateTemplate';
import { SummaryOnboardUser } from './summary';
import { useKeycloak } from '../../../_functions';

export const CreateUserOnBoarding = (): JSX.Element => {
  const { requestCreateUser, templates } = useSupport();
  const { profile } = useKeycloak();
  const [selectedTemplate, _setSelectedTemplate] = useState<OnboardingTemplate | undefined>(
    undefined
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedStep, _setSelectedStep] = useState<string>('template');
  const [approver, setApprover] = useState<string>('');

  const [fname, setFname] = useState<string>('');
  const [lname, setLname] = useState<string>('');
  const [gender, setGender] = useState<string>('men');
  const [jobT, setJobT] = useState<DepartmentOption[]>([]);
  const openModal = () => setIsModalVisible(true);
  const closeModal = () => {
    setIsModalVisible(false);
    _setSelectedStep('template');
    _setSelectedTemplate(undefined);
  };

  const setSelectedStep = (step: string) => {
    _setSelectedStep(step);
  };
  const setSelectedTemplate = (id: string, approver: string) => {
    const wantedTemplate = templates.filter((t) => t.id === id)[0];
    console.log('Debug', wantedTemplate, id);
    _setSelectedTemplate(wantedTemplate);
    setApprover(approver);
  };
  const updateTemplate = (update: OnboardingTemplate) => {
    _setSelectedTemplate(update);
  };
  const changelastname = (name: string) => {
    setLname(name);
  };
  const changeFirstName = (name: string) => {
    setFname(name);
  };
  const changeGender = (gender: string) => {
    setGender(gender);
  };
  const changeJonT = (jobT: DepartmentOption[]) => {
    setJobT(jobT);
  };

  const createNewUser = async () => {
    if (selectedTemplate !== undefined) {
      try {
        if (profile && profile.email) {
          const user = await createOfficeUser(
            {
              templateValues: selectedTemplate,
              user: { firstname: fname, lastname: lname, gender, jobT: jobT },
            },
            profile.email,
            approver
          );
          requestCreateUser(user);
        }
        closeModal();
      } catch (error) {
        console.error('Error fetching tenant details:', error);
        closeModal();
      }
    }
  };

  const nextStep = () => {
    switch (selectedStep) {
      case 'template':
        setSelectedStep('user');
        break;
      case 'user':
        setSelectedStep('update-template');
        break;
      case 'update-template':
        setSelectedStep('summary');
        break;
      case 'summary':
        createNewUser();
        break;
    }
  };

  return (
    <EuiFlexItem>
      <EuiButton onClick={openModal} iconType="user">
        Erstelle einen neuen Nutzer mithilfe eines Templates
      </EuiButton>
      {isModalVisible && (
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <CreateUserStepper
              selectedStep={selectedStep}
              setSelectedStep={setSelectedStep}
              selectedTemplate={selectedTemplate}
              userValue={{ firstname: fname, lastname: lname, gender }}
            />
          </EuiModalHeader>
          <EuiModalBody>
            {selectedStep === 'template' && <SelectTemplate changeTemplate={setSelectedTemplate} />}
            {selectedStep === 'user' && (
              <SelectUserValues
                changelastname={changelastname}
                changeFirstName={changeFirstName}
                changeGender={changeGender}
                changeJonT={changeJonT}
              />
            )}
            {selectedStep === 'update-template' && selectedTemplate !== undefined && (
              <UpdateUserTemplateValues
                selectedTemplate={selectedTemplate}
                updateTemplate={updateTemplate}
              />
            )}
            {selectedStep === 'summary' && selectedTemplate !== undefined && (
              <SummaryOnboardUser
                user={{
                  templateValues: selectedTemplate,
                  user: { firstname: fname, lastname: lname, gender, jobT },
                }}
              />
            )}
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal}>Cancle</EuiButtonEmpty>
            <EuiButton onClick={nextStep}>Next</EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </EuiFlexItem>
  );
};
