import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import React, { useState } from 'react';
import { DepartmentOption } from './types';
import { useToast } from '../../../_functions/toast';
import { useApi, useKeycloak } from '../../../_functions';
import { useSupport } from '../../../_functions/support/support';

export const CreateNewOnBoarding = (): JSX.Element => {
  const { addToast } = useToast();
  const { keycloak } = useKeycloak();
  const { Post } = useApi();
  const {
    groups,
    domains,
    departments,
    citys,
    companies,
    setCompanies,
    setCitys,
    setDepartments,
    licenses,
    verteilerlisten,
  } = useSupport();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');
  const [selectedGroups, setSelectedGroups] = useState<Array<DepartmentOption>>([]);
  const [selectedVerteilerlisten, setSelectedVerteilerlisten] = useState<Array<DepartmentOption>>(
    []
  );
  const [selectedDomain, setSelectedDomains] = useState<Array<DepartmentOption>>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Array<DepartmentOption>>([]);
  const [selectedCitys, setSelectedCitys] = useState<Array<DepartmentOption>>([]);
  const [selectedLicenses, setSelectedLicenses] = useState<Array<DepartmentOption>>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<Array<DepartmentOption>>([]);

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedDepartments([]);
    setSelectedCitys([]);
    setSelectedCompanies([]);
    setSelectedDomains([]);
    setSelectedGroups([]);
    setSelectedLicenses([]);
    setSelectedVerteilerlisten([]);
    setTemplateName('');
  };
  const openModal = () => setIsModalVisible(true);

  const onDepartmentsChange = (options: Array<DepartmentOption>) => {
    setSelectedDepartments(options);
  };
  const onDepartmentCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setDepartments(newOption);
    }

    setSelectedDepartments([newOption]);
  };

  const onCitysChange = (options: Array<DepartmentOption>) => {
    setSelectedCitys(options);
  };
  const onCityCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setCitys(newOption);
    }

    setSelectedCitys([newOption]);
  };

  const onCompaniesChange = (options: Array<DepartmentOption>) => {
    setSelectedCompanies(options);
  };
  const onCompanieCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setCompanies(newOption);
    }

    setSelectedCompanies([newOption]);
  };

  const onDomainChange = (options: Array<DepartmentOption>) => {
    setSelectedDomains(options);
  };

  const onGroupChange = (options: Array<DepartmentOption>) => {
    setSelectedGroups(options);
  };

  const onSelectedVerteilerlistenChange = (options: Array<DepartmentOption>) => {
    setSelectedVerteilerlisten(options);
  };

  const onLicensesChange = (options: Array<DepartmentOption>) => {
    setSelectedLicenses(options);
  };

  const submit = () => {
    const templateValues = {
      name: templateName,
      department: selectedDepartments,
      city: selectedCitys,
      companies: selectedCompanies,
      domain: selectedDomain,
      groups: selectedGroups,
      licenses: selectedLicenses,
    };
    if (
      !templateName.trim() ||
      selectedGroups.length === 0 ||
      selectedDomain.length === 0 ||
      selectedCompanies.length === 0 ||
      selectedCitys.length === 0 ||
      selectedDepartments.length === 0 ||
      selectedLicenses.length === 0
    ) {
      addToast({
        title: 'Fehler',
        text: <p>Alle Felder müssen ausgefüllt sein!</p>,
        color: 'danger',
        iconType: 'cross',
      });
    } else {
      Post({ path: `onboarding/template/create`, value: templateValues, token: keycloak?.token })
        .then((response) => {
          closeModal();
          if (response.status === 500) {
            addToast({
              title: 'Fehler beim erstellen des neuen On-Boarding Templates!',
              text: <p>{response.data}</p>,
              color: 'danger',
              iconType: 'cross',
            });
          } else {
            addToast({
              title: 'On-Boarding Templates erstellt!',
              text: (
                <p>Das On-Boarding Template {templateValues.name} wurde erfolgreich erstellt!</p>
              ),
              color: 'success',
              iconType: 'check',
            });
          }
        })
        .catch((err) => {
          closeModal();
          addToast({
            title: 'Fehler beim erstellen des neuen On-Boarding Templates!',
            text: <p>{err}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        });
    }
  };

  return (
    <EuiFlexItem>
      <EuiButton onClick={openModal} iconType="heart">
        Erstelle einen neuen On-Boarding Catalog
      </EuiButton>
      {isModalVisible && (
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Neues Template</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiForm>
              <EuiFormRow label="Template Name">
                <EuiFieldText
                  placeholder="Placeholder text"
                  value={templateName}
                  onChange={(e) => {
                    setTemplateName(e.target.value);
                  }}
                />
              </EuiFormRow>
              <EuiFormRow label="Department">
                <EuiComboBox
                  placeholder="Select or create a Department"
                  options={departments}
                  selectedOptions={selectedDepartments}
                  onChange={onDepartmentsChange}
                  onCreateOption={onDepartmentCreateOption}
                  isClearable={true}
                  singleSelection={{ asPlainText: true }}
                  data-test-subj="departmentComboBox"
                />
              </EuiFormRow>
              <EuiFormRow label="City">
                <EuiComboBox
                  placeholder="Select or create a City"
                  options={citys}
                  selectedOptions={selectedCitys}
                  onChange={onCitysChange}
                  onCreateOption={onCityCreateOption}
                  singleSelection={{ asPlainText: true }}
                  isClearable={true}
                  data-test-subj="cityComboBox"
                />
              </EuiFormRow>
              <EuiFormRow label="Companie">
                <EuiComboBox
                  placeholder="Select or create a Companie"
                  options={companies}
                  selectedOptions={selectedCompanies}
                  onChange={onCompaniesChange}
                  onCreateOption={onCompanieCreateOption}
                  singleSelection={{ asPlainText: true }}
                  isClearable={true}
                  data-test-subj="compenieComboBox"
                />
              </EuiFormRow>
              <EuiFormRow label="Domain">
                <EuiComboBox
                  placeholder="Select a Domain"
                  options={domains}
                  selectedOptions={selectedDomain}
                  onChange={onDomainChange}
                  singleSelection={{ asPlainText: true }}
                  isClearable={true}
                  data-test-subj="compenieComboBox"
                />
              </EuiFormRow>
              <EuiFormRow label="Groups">
                <EuiComboBox
                  placeholder="Select a Group"
                  options={groups}
                  selectedOptions={selectedGroups}
                  onChange={onGroupChange}
                  isClearable={true}
                  data-test-subj="groupComboBox"
                />
              </EuiFormRow>
              <EuiFormRow label="Verteilerlisten">
                <EuiComboBox
                  placeholder="Select a Verteilerlisten"
                  options={verteilerlisten}
                  selectedOptions={selectedVerteilerlisten}
                  onChange={onSelectedVerteilerlistenChange}
                  isClearable={true}
                  data-test-subj="groupComboBox"
                />
              </EuiFormRow>
              <EuiFormRow label="Licenses">
                <EuiComboBox
                  placeholder="Select the wanted licenses"
                  options={licenses}
                  selectedOptions={selectedLicenses}
                  onChange={onLicensesChange}
                  isClearable={true}
                  data-test-subj="licensesComboBox"
                />
              </EuiFormRow>
            </EuiForm>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
            <EuiButton type="submit" onClick={submit} fill>
              Save
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </EuiFlexItem>
  );
};
