import React, { useEffect, useState } from 'react';
import { useSupport } from '../../../_functions/support/support';
import { EuiComboBox, EuiComboBoxOptionOption, EuiForm, EuiFormRow } from '@elastic/eui';
import { DepartmentOption, OnboardingTemplate } from './types';

export const UpdateUserTemplateValues = ({
  updateTemplate,
  selectedTemplate,
}: {
  selectedTemplate: OnboardingTemplate;
  updateTemplate: (update: OnboardingTemplate) => void;
}): JSX.Element => {
  const {
    departments,
    setDepartments,
    citys,
    setCitys,
    companies,
    setCompanies,
    domains,
    groups,
    licenses,
    verteilerlisten,
  } = useSupport();
  const [selectedDepartments, setSelectedDepartments] = useState<Array<DepartmentOption>>([]);
  const [selectedCitys, setSelectedCitys] = useState<Array<DepartmentOption>>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Array<DepartmentOption>>([]);
  const [selectedDomain, setSelectedDomains] = useState<Array<DepartmentOption>>([]);
  const [selectedGroups, setSelectedGroups] = useState<Array<DepartmentOption>>([]);
  const [selectedLicenses, setSelectedLicenses] = useState<Array<DepartmentOption>>([]);
  const [selectedVerteilerlisten, setSelectedVerteilerlisten] = useState<Array<DepartmentOption>>(
    []
  );

  useEffect(() => {
    setSelectedDepartments(selectedTemplate.department);
    setSelectedCitys(selectedTemplate.city);
    setSelectedCompanies(selectedTemplate.companies);
    setSelectedDomains(selectedTemplate.domain);
    setSelectedGroups(selectedTemplate.groups);
    setSelectedLicenses(selectedTemplate.licenses);
    setSelectedVerteilerlisten(selectedTemplate.verteilerlisten);
  }, []);

  useEffect(() => {
    const updatedTemplate: OnboardingTemplate = {
      id: selectedTemplate.id,
      last_updated: '',
      name: selectedTemplate.name,
      department: selectedDepartments,
      city: selectedCitys,
      companies: selectedCompanies,
      domain: selectedDomain,
      groups: selectedGroups,
      licenses: selectedLicenses,
      verteilerlisten: selectedVerteilerlisten,
    };
    updateTemplate(updatedTemplate);
  }, [
    selectedDepartments,
    selectedCitys,
    selectedCompanies,
    selectedDomain,
    selectedGroups,
    selectedLicenses,
    selectedVerteilerlisten,
  ]);

  const onDepartmentsChange = (options: Array<DepartmentOption>) => {
    setSelectedDepartments(options);
  };
  const onDepartmentCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setDepartments(newOption);
    }

    setSelectedDepartments([newOption]);
  };

  const onCitysChange = (options: Array<DepartmentOption>) => {
    setSelectedCitys(options);
  };
  const onCityCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setCitys(newOption);
    }

    setSelectedCitys([newOption]);
  };

  const onCompaniesChange = (options: Array<DepartmentOption>) => {
    setSelectedCompanies(options);
  };
  const onCompanieCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setCompanies(newOption);
    }

    setSelectedCompanies([newOption]);
  };

  const onDomainChange = (options: Array<DepartmentOption>) => {
    setSelectedDomains(options);
  };

  const onGroupChange = (options: Array<DepartmentOption>) => {
    setSelectedGroups(options);
  };

  const onLicensesChange = (options: Array<DepartmentOption>) => {
    setSelectedLicenses(options);
  };

  const onSelectedVerteilerlistenChange = (options: Array<DepartmentOption>) => {
    setSelectedVerteilerlisten(options);
  };

  return (
    <EuiForm>
      <EuiFormRow label="Department">
        <EuiComboBox
          placeholder="Select or create a Department"
          options={departments}
          selectedOptions={selectedDepartments}
          onChange={onDepartmentsChange}
          onCreateOption={onDepartmentCreateOption}
          isClearable={true}
          data-test-subj="departmentComboBox"
        />
      </EuiFormRow>
      <EuiFormRow label="City">
        <EuiComboBox
          placeholder="Select or create a City"
          options={citys}
          selectedOptions={selectedCitys}
          onChange={onCitysChange}
          onCreateOption={onCityCreateOption}
          singleSelection={{ asPlainText: true }}
          isClearable={true}
          data-test-subj="cityComboBox"
        />
      </EuiFormRow>
      <EuiFormRow label="Companie">
        <EuiComboBox
          placeholder="Select or create a Companie"
          options={companies}
          selectedOptions={selectedCompanies}
          onChange={onCompaniesChange}
          onCreateOption={onCompanieCreateOption}
          singleSelection={{ asPlainText: true }}
          isClearable={true}
          data-test-subj="compenieComboBox"
        />
      </EuiFormRow>
      <EuiFormRow label="Domain">
        <EuiComboBox
          placeholder="Select a Domain"
          options={domains}
          selectedOptions={selectedDomain}
          onChange={onDomainChange}
          singleSelection={{ asPlainText: true }}
          isClearable={true}
          data-test-subj="compenieComboBox"
        />
      </EuiFormRow>
      <EuiFormRow label="Groups">
        <EuiComboBox
          placeholder="Select a Group"
          options={groups}
          selectedOptions={selectedGroups}
          onChange={onGroupChange}
          isClearable={true}
          data-test-subj="groupComboBox"
        />
      </EuiFormRow>
      <EuiFormRow label="Verteilerlisten">
        <EuiComboBox
          placeholder="Select a Verteilerlisten"
          options={verteilerlisten}
          selectedOptions={selectedVerteilerlisten}
          onChange={onSelectedVerteilerlistenChange}
          isClearable={true}
          data-test-subj="groupComboBox"
        />
      </EuiFormRow>
      <EuiFormRow label="Licenses">
        <EuiComboBox
          placeholder="Select Licenses"
          options={licenses}
          selectedOptions={selectedLicenses}
          onChange={onLicensesChange}
          isClearable={true}
          data-test-subj="licensesComboBox"
        />
      </EuiFormRow>
    </EuiForm>
  );
};
