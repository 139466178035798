import React, { useEffect, useState } from 'react';
import { EuiBadge, EuiText, EuiToolTip } from '@elastic/eui';
import { OnboardingRequest, OnboardingRequestStatus } from '../create/types';
import { useApi, useKeycloak } from '../../../_functions';
import moment from 'moment';
import { getStatusColor } from './table';

export const TooltipGrid = ({
  title,
  content,
}: {
  title: string;
  content: string;
}): React.ReactElement => {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
      <span>{title}</span>
      <span>{content}</span>
    </div>
  );
};

export const UserChange = ({ user }: { user: OnboardingRequest }) => {
  const { keycloak } = useKeycloak();
  const { Get } = useApi();

  const [changes, setChanges] = useState<Array<OnboardingRequestStatus>>([]);
  useEffect(() => {
    Get({ path: `onboarding/user/${user.user.id}/status`, token: keycloak?.token })
      .then((response) => {
        console.log('Fetched On-Boarding Users:', response.data.data);
        if (response.status === 403 || response.status === 500) {
          setChanges([]);
        } else {
          console.log('response', response);
          setChanges(response.data.status);
        }
      })
      .catch((err) => {
        console.error('Error Fetching On-Boarding Templates', err);
      });
  }, [user]);

  return (
    <>
      {changes?.map((c) => {
        return (
          <EuiToolTip
            content={
              <>
                <TooltipGrid title="State" content={c.state} />
                <TooltipGrid title="Type" content={c.type} />
                <TooltipGrid title="Timestamp" content={moment(c.timestamp).format('lll')} />
                <TooltipGrid title="Action by" content={c.userEmail} />
                <TooltipGrid title="Action by role" content={c.userRole} />
              </>
            }
          >
            <EuiBadge color={getStatusColor(c.state)}>
              <EuiText>
                <p>{c.type}</p>
              </EuiText>
            </EuiBadge>
          </EuiToolTip>
        );
      })}
    </>
  );
};
