import { MultiSelectOptionsQuestion, Section, Translation } from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';

export const generateSecurityFunctions = () => {
  const sSecurity = new Section('s_sec', [
    new Translation('en', 'Security functions'),
    new Translation('de', 'Sicherheitsfunktionen'),
  ]);
  const rSecurity = new Section('r_sec', [
    new Translation('en', 'Security functions'),
    new Translation('de', 'Sicherheitsfunktionen'),
  ]);

  const secFeatures = new MultiSelectOptionsQuestion(
    `sec_features`,
    [new Translation('en', 'VDS'), new Translation('de', 'VDS')],
    MedoOptionMap.vsphere_security_features
  );
  secFeatures.help = [
    new Translation('en', 'Which security functions do you use in vSphere?'),
    new Translation('de', 'Welche Sicherheitsfunktionen nutzen Sie in vSphere?'),
  ];
  rSecurity.addChild(secFeatures);

  const access_roles = new MultiSelectOptionsQuestion(
    `auth`,
    [new Translation('en', 'Auth'), new Translation('de', 'Auth')],
    MedoOptionMap.vsphere_access_roles
  );
  access_roles.help = [
    new Translation(
      'en',
      'How to manage access rights and user roles in your vSphere environment?'
    ),
    new Translation(
      'de',
      'Wie verwalten Sie Zugriffsrechte und Benutzerrollen in Ihrer vSphere-Umgebung?'
    ),
  ];
  rSecurity.addChild(access_roles);

  sSecurity.addChild(rSecurity);
  return sSecurity;
};
