import { QuestionType } from './base'
import { type Options, type TextMessage } from './i18n'
import { OptionsQuestion } from './options_question'

// see: https://www.extension.iastate.edu/documents/anr/likertscaleexamplesforsurveys.pdf

export class LikertQuestion extends OptionsQuestion {
  constructor (id: string, title: TextMessage, options: Options) {
    super(id, title, options, QuestionType.Likert)
  }
}
