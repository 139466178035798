import React, { useEffect, useState } from "react";
import { EuiModal, EuiModalBody, EuiModalHeader, EuiTitle } from "@elastic/eui";
import { NewsletterDetails } from "../../../_types";
import { useNewsletter } from "../../../_functions";

export const NewsletterPreviewModal = ({ selectedNewsletter, handleClose }: { selectedNewsletter: NewsletterDetails, handleClose: () => void }): JSX.Element => {
    const { getPreview } = useNewsletter()

    const [preview, setPreview] = useState<string>("")

    const generate = async () => {
        try {
            const pre = await getPreview(selectedNewsletter.id)
            setPreview(pre)
            console.log()
        } catch (error) {
            setPreview(`<p>${error}</p>`)
        }
    }
    useEffect(() => {
        generate()
    }, [])

    return (
        <EuiModal onClose={handleClose}>
            <EuiModalHeader style={{ flexDirection: "column" }}>
                <EuiTitle>
                    <h4>So würde die E-Mail beispielhaft ausschauen wenn du sie erhälst!</h4>
                </EuiTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <div dangerouslySetInnerHTML={{ __html: preview }} />
            </EuiModalBody>
        </EuiModal>
    )
}