import { OptionsQuestion, Row, Section, Translation } from "anketa-core";
import { MedoOptionMap } from "../helpers/medoOptionsMap";

export const generateHaFault = () => {
    const sHaFault = new Section('s_ha_fault', [new Translation('en', 'High availability and fault tolerance'), new Translation('de', 'Hochverfügbarkeit und Fehlertoleranz')]);
    const rHaFault = new Row('r_ha_fault', [new Translation('en', 'High availability and fault tolerance'), new Translation('de', 'Hochverfügbarkeit und Fehlertoleranz')]);

    const ha = new OptionsQuestion(`vds`, [new Translation('en', 'vSphere HA'), new Translation('de', 'vSphere HA')], MedoOptionMap.yes_no);
    ha.help = [
        new Translation('en', 'Do you use vSphere HA (High Availability) to ensure the availability of your VMs?'),
        new Translation('de', 'Nutzen Sie vSphere HA (High Availability) zur Sicherstellung der Verfügbarkeit Ihrer VMs?')
    ];
    rHaFault.addChild(ha)

    const fault = new OptionsQuestion(`fault`, [new Translation('en', 'vSphere Fault Tolerance'), new Translation('de', 'vSphere Fault Tolerance')], MedoOptionMap.yes_no);
    fault.help = [
        new Translation('en', 'Do Do you use vSphere Fault Tolerance to ensure continuous availability for critical VMs?'),
        new Translation('de', 'Setzen Sie vSphere Fault Tolerance ein, um eine kontinuierliche Verfügbarkeit für kritische VMs zu gewährleisten?')
    ];
    rHaFault.addChild(fault)

    sHaFault.addChild(rHaFault)
    return sHaFault;
};
