import React, { useContext } from 'react';
import { EuiIconTip, EuiSpacer, EuiText } from '@elastic/eui';
import { AnketaContext } from '../context_provider';
import { MarkdownText } from '../markdown_text';
import { TextCardProps } from '../../_types';

export const TextCard = ({ text }: TextCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext);

  if (!text.visible) {
    return <div></div>;
  }

  return (
    <EuiText>
      {text.help ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <EuiIconTip
            aria-label={text.id}
            size="m"
            type="questionInCircle"
            color="primary"
            content={ctx.i18nContext.render(text.help)}
          />
        </div>
      ) : (
        <div>
          <EuiIconTip aria-label={text.id} size="m" type="" color="primary" content="" />
        </div>
      )}
      <MarkdownText text={text.title} />
      <EuiSpacer />
    </EuiText>
  );
};
