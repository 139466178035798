import React from "react";
import { EuiConfirmModal } from "@elastic/eui";
import { NewsletterDetails } from "../../../_types";
import { useNewsletter } from "../../../_functions";

export const DeleteNewsletterModal = ({ selectedNewsletter, handleClose }: { selectedNewsletter: NewsletterDetails, handleClose: () => void }): JSX.Element => {
    const { deleteNewsletter } = useNewsletter()

    const finish = () => {
        deleteNewsletter(selectedNewsletter.id)
        closeModal()
    }

    const closeModal = () => {
        handleClose()
    }
    return (
        <EuiConfirmModal
            title={`Newsletter ${selectedNewsletter.name} Löschen`}
            onCancel={closeModal}
            onConfirm={finish}
            cancelButtonText="Abrechen"
            confirmButtonText={`${selectedNewsletter.name} löschen`}
            buttonColor="danger"
            defaultFocusedButton="confirm">
            <p>Sind Sie sich sicher, dass Sie den Newsletter {selectedNewsletter.name} löschen wollen?</p>
            <p>{selectedNewsletter.users?.length} Nutzer erhalten dann keine E-Mails mehr!</p>
        </EuiConfirmModal>
    )
}