import { Severity, type DiagnosticInfo } from './diagnostic'

export class Diagnostics extends Array<DiagnosticInfo> {
  // HasError returns true is Diagnostics contains an instance of
  // Severity == Error.
  public get hasError (): boolean {
    for (const diag of this) {
      if (diag.severity === Severity.Error) {
        return true
      }
    }
    return false
  }

  // hasProblems returns true is Diagnostics contains an instance of
  // Severity > Info.
  public get hasProblems (): boolean {
    for (const diag of this) {
      if (diag.severity > Severity.Info) {
        return true
      }
    }
    return false
  }
}
