import { type TextMessage } from '../i18n'

export enum Severity {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export interface DiagnosticInfo {
  // Severity indicates the level of the Diagnostic. Currently can be set to
  // either Error or Warning
  readonly severity: Severity

  // Severity indicates the level of the Diagnostic. Currently can be set to
  // either Error or Warning
  readonly summary: TextMessage
  // Summary is a short description of the problem, rendered above location
  // information
  readonly detail?: TextMessage
  // Source of the information
  readonly source?: any
}

export class Diagnostic implements DiagnosticInfo {
  // Severity indicates the level of the Diagnostic. Currently can be set to
  // either Error or Warning
  readonly severity: Severity

  // Severity indicates the level of the Diagnostic. Currently can be set to
  // either Error or Warning
  readonly summary: TextMessage
  // Summary is a short description of the problem, rendered above location
  // information
  readonly detail?: TextMessage

  // Source of the information
  readonly source?: any

  constructor (severity: Severity, summary: TextMessage, detail?: TextMessage, source?: any) {
    this.severity = severity
    this.summary = summary
    this.detail = detail
    this.source = source
  }
}
