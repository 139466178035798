import { QuestionType } from './base'
import { OptionMap, type Options, type TextMessage } from './i18n'
import { QuestionObject } from './question_object'
import { type ExecutionContext } from './runtime'
import { OptionsValidator } from './validation'

export class OptionsQuestion extends QuestionObject<number> {
  private _options: Options

  constructor (id: string, title: TextMessage, options: Options, type?: QuestionType) {
    super(id, type ?? QuestionType.Options, title)
    this._options = options
    this._validator = new OptionsValidator(this._options)
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'options')) {
      this._options = o.options
    }
    if (this._options !== undefined) {
      this._validator = new OptionsValidator(this._options)
    }
    return this
  }

  public save (): any {
    let result = super.save()
    result = {
      ...result,
      ...{
        options: this._options
      }
    }
    return result
  }

  public get optionsValidator (): OptionsValidator {
    if (this.validator instanceof OptionsValidator) {
      return this.validator
    }
    return new OptionsValidator(OptionMap.empty)
  }

  public getOptions (ctx: ExecutionContext): Map<string, TextMessage> {
    if (this.validator instanceof OptionsValidator) {
      return ctx.getOptions(this.validator.options)
    }
    return new Map<string, TextMessage>()
  }

  public setSuggestOnly (val: boolean): void {
    if (this.validator instanceof OptionsValidator) {
      (this._validator as OptionsValidator).suggestOnly = true
    }
  }
}
