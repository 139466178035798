import React, { useState } from 'react';
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiFormRow,
  EuiRadioGroup,
} from '@elastic/eui';
import { useSupport } from '../../../_functions/support/support';
import { DepartmentOption } from './types';

export const SelectUserValues = ({
  changelastname,
  changeFirstName,
  changeGender,
  changeJonT,
}: {
  changelastname: (name: string) => void;
  changeFirstName: (name: string) => void;
  changeGender: (gender: string) => void;
  changeJonT: (jobT: DepartmentOption[]) => void;
}): JSX.Element => {
  const { jobTitlet, setJobTitlet } = useSupport();
  const [firstname, _setFirstname] = useState<string>('');
  const [lastname, _setLastname] = useState<string>('');
  const [gender, _setGender] = useState<string>('men');
  const [jobT, _setJobT] = useState<DepartmentOption[]>([]);

  const _changelastname = (name: string) => {
    _setLastname(name);
    changelastname(name);
  };
  const _changeFirstName = (name: string) => {
    _setFirstname(name);
    changeFirstName(name);
  };
  const _changeGender = (gender: string) => {
    _setGender(gender);
    changeGender(gender);
  };
  const _changeJobTitle = (options: EuiComboBoxOptionOption<string>[]) => {
    _setJobT(options);
    changeJonT(options);
  };
  const onJobTitleCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setJobTitlet(newOption);
    }

    _changeJobTitle([newOption]);
  };
  return (
    <>
      <EuiFormRow label="Vorname">
        <EuiFieldText
          placeholder="Vorname"
          value={firstname}
          onChange={(e) => {
            _changeFirstName(e.target.value);
          }}
        />
      </EuiFormRow>
      <EuiFormRow label="Nachname">
        <EuiFieldText
          placeholder="Nachname"
          value={lastname}
          onChange={(e) => {
            _changelastname(e.target.value);
          }}
        />
      </EuiFormRow>
      <EuiFormRow label="Geschlecht">
        <EuiRadioGroup
          options={[
            {
              id: 'women',
              label: 'Frau',
            },
            {
              id: 'men',
              label: 'Herr',
            },
          ]}
          idSelected={gender}
          onChange={(id) => {
            _changeGender(id);
          }}
          name="radio group"
        />
      </EuiFormRow>
      <EuiFormRow label="Job Title">
        <EuiComboBox
          placeholder="Select a Job Title"
          options={jobTitlet}
          selectedOptions={jobT}
          onChange={_changeJobTitle}
          onCreateOption={onJobTitleCreateOption}
          isClearable={true}
          singleSelection={{ asPlainText: true }}
          data-test-subj="jobTitleComboBox"
        />
      </EuiFormRow>
    </>
  );
};
