import React, { useState } from "react";
import { EuiButton, EuiButtonEmpty, EuiComboBox, EuiComboBoxOptionOption, EuiFieldText, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiTextArea } from "@elastic/eui";
import { Newsletter, NewsletterDetails, UserMail } from "../../../_types";
import { useNewsletter } from "../../../_functions";

export const EditNewsletterModal = ({ selectedNewsletter, handleClose }: { selectedNewsletter: NewsletterDetails, handleClose: () => void }): JSX.Element => {
    const { mailUsers, updateNewsletter } = useNewsletter()

    const [sendNewsletterAs, setSendNewsletterAs] = useState<UserMail>(mailUsers.filter(usr => usr.email === selectedNewsletter.sender)[0])
    const [newsletterName, setNewsletterName] = useState<string>(selectedNewsletter.name);
    const [newsletterText, setNewsletterText] = useState<string>(selectedNewsletter.text);
    const [from, setFrom] = useState<string>(selectedNewsletter.from_email);
    const [interval, setInterval] = useState<number>(selectedNewsletter.interval);

    const handleChangeSendNewsletterAs = (selectedOptions: EuiComboBoxOptionOption<UserMail>[]) => {
        if (selectedOptions && selectedOptions.length > 0) {
            const selectedOption = selectedOptions[0];
            setSendNewsletterAs(selectedOption.value as UserMail);
        } else {
            setSendNewsletterAs(mailUsers.filter(usr => usr.id === selectedNewsletter.sender)[0]);
        }
    };

    const finish = () => {
        const newsletter: Newsletter = {
            from_email: from,
            id: selectedNewsletter.id,
            interval: interval,
            name: newsletterName,
            sender: sendNewsletterAs.email,
            text: newsletterText
        }
        updateNewsletter(newsletter)
        closeModal()
    }

    const closeModal = () => {
        handleClose()
    }
    return (
        <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>{selectedNewsletter.name} bearbeiten</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiForm component="form">
                    <EuiFormRow
                        label="Senden im Namen von"
                        helpText="Wählen Sie eine E-Mail-Adresse aus, unter der Sie senden möchten."
                    >
                        <EuiComboBox
                            placeholder="Wählen Sie eine Option"
                            singleSelection={true}
                            options={mailUsers.map((user: UserMail) => ({
                                label: `${user.displayName} (${user.email})`,
                                value: user,
                            }))}
                            selectedOptions={sendNewsletterAs ? [{ label: `${sendNewsletterAs.displayName} (${sendNewsletterAs.email})`, value: sendNewsletterAs }] : []}
                            onChange={(selectedOptions) => handleChangeSendNewsletterAs(selectedOptions)}
                        />
                    </EuiFormRow>
                    <EuiFieldText
                        placeholder="Emails Weiterleiten von"
                        aria-label="Emails Weiterleiten von"
                        name="from"
                        onChange={(e) => setFrom(e.target.value)}
                        defaultValue={selectedNewsletter.from_email}
                    />
                    <EuiFieldText
                        placeholder="Name"
                        aria-label="Name des Newsletters"
                        name="newsletterName"
                        onChange={(e) => setNewsletterName(e.target.value)}
                        defaultValue={selectedNewsletter.name}
                    />
                    <EuiTextArea
                        placeholder="Text"
                        aria-label="Text des Newsletters"
                        name="newsletterText"
                        onChange={(e) => setNewsletterText(e.target.value)}
                        defaultValue={selectedNewsletter.text}
                    />
                    <EuiFieldText
                        placeholder="Interval"
                        aria-label="Scan alle x minuten"
                        name="interval"
                        type="number"
                        onChange={(e) => setInterval(parseInt(e.target.value))}
                        defaultValue={selectedNewsletter.interval}
                    />
                </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
                <EuiButton onClick={finish}>Anpassen</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}