import { type Hierarchical, type Identifiable, type Item, type Serializable } from './base'
import { PropertyEvaluator, type Evaluator, type EvaluatorValue } from './base/evaluator'
import { ItemObject } from './base/item_object'
import { ItemType, QuestionType, SectionType } from './base/types'
import { readTextMessage, saveTextMessage, type TextMessage } from './i18n'
import { type QuestionObject } from './question_object'

export class Group extends ItemObject {
  /** longer description additonally to the title */
  description?: TextMessage
  help?: TextMessage

  visibleEvaluator?: Evaluator<boolean>
  visible: boolean = true

  constructor (id: string, title: TextMessage, type?: ItemType) {
    super(id, type ?? ItemType.Group, title)
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'description') && (o.description !== undefined)) {
      this.description = readTextMessage(o.description, this.path)
    }
    if (Object.prototype.hasOwnProperty.call(o, 'visible') && (o.visible !== undefined)) {
      if (typeof o.visible === 'boolean') {
        this.visible = o.visible
      } else {
        if (this.visibleEvaluator === undefined) {
          this.visibleEvaluator = new PropertyEvaluator<boolean>('true', this, 'visible')
        }
        this.visibleEvaluator = this.visibleEvaluator.read(o.visible)
      }
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.description !== undefined) {
      result.description = saveTextMessage(this.description)
    }
    if (this.visibleEvaluator) {
      result.visible = this.visibleEvaluator.save()
    } else {
      if (!this.visible) {
        result.visible = this.visible
      }
    }
    return result
  }
}

export class Row extends Group {
  constructor (id: string, title: TextMessage) {
    super(id, title, ItemType.Row)
  }
}

export class Section extends Group {
  sectionType!: SectionType
  expandedEvaluator?: Evaluator<boolean>
  expanded: boolean = true
  navVisibleEvaluator?: Evaluator<boolean>
  navVisible: boolean | undefined = undefined

  constructor (id: string, title: TextMessage, sectionType?: SectionType) {
    super(id, title, ItemType.Section)
    if (sectionType) {
      this.sectionType = sectionType
    }
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'sectionType') && (o.sectionType !== undefined)) {
      this.sectionType = o.sectionType as SectionType
    } else {
      this.sectionType = SectionType.Generic
    }
    if (Object.prototype.hasOwnProperty.call(o, 'expanded') && (o.expanded !== undefined)) {
      if (typeof o.expanded === 'boolean') {
        this.expanded = o.expanded
      } else {
        if (this.expandedEvaluator === undefined) {
          this.expandedEvaluator = new PropertyEvaluator<boolean>('true', this, 'expanded')
        }
        this.expandedEvaluator = this.expandedEvaluator.read(o.expanded)
      }
    }
    if (Object.prototype.hasOwnProperty.call(o, 'navVisible') && (o.navVisible !== undefined)) {
      if (typeof o.navVisible === 'boolean') {
        this.navVisible = o.navVisible
      } else {
        if (this.navVisibleEvaluator === undefined) {
          this.navVisibleEvaluator = new PropertyEvaluator<boolean>('true', this, 'navVisible')
        }
        this.navVisibleEvaluator = this.navVisibleEvaluator.read(o.navVisible)
      }
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.sectionType !== SectionType.Generic) {
      result.sectionType = SectionType[this.sectionType]
    }
    if (this.description !== undefined) {
      result.description = saveTextMessage(this.description)
    }
    if (this.expandedEvaluator) {
      result.expanded = this.expandedEvaluator.save()
    } else {
      if (!this.expanded) {
        result.expanded = this.expanded
      }
    }
    if (this.navVisibleEvaluator) {
      result.navVisible = this.navVisibleEvaluator.save()
    } else {
      if (this.navVisible !== undefined) {
        result.navVisible = this.navVisible
      }
    }
    return result
  }
}

export class Matrix extends Group implements Hierarchical, Item, Identifiable, Serializable {
  constructor (id: string, title: TextMessage) {
    super(id, title, ItemType.Matrix)
  }

  public addChild (item: Hierarchical): void {
    if ((item instanceof ItemObject) && (item.type !== ItemType.Question) && ((item as QuestionObject<EvaluatorValue>).questionType !== QuestionType.Likert)) {
      throw new Error('Only LikertQuestions can be added to a matrix')
    }
    super.addChild(item)
  }
}

export class Page extends Group {
  constructor (id: string, title: TextMessage) {
    super(id, title, ItemType.Page)
  }
}
