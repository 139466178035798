import React, { ReactNode, createContext, useContext, useState } from 'react';
import { EuiGlobalToastList } from '@elastic/eui';
import { ToastContextType } from '../../_types';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

let toastId = 0;

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = (toast: Omit<Toast, 'id'>) => {
        setToasts([...toasts, { ...toast, id: `toast${toastId++}` }]);
    };

    const removeToast = (removedToast: Toast) => {
        setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
    };

    return (
        <ToastContext.Provider value={{ addToast }}>
            {children}
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={6000}
            />
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    const context = useContext(ToastContext);
    if (context === undefined) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};
