import React, { useState } from 'react';
import { EuiComboBox, EuiFieldText, EuiForm, EuiFormRow } from '@elastic/eui';
import { DepartmentOption } from './types';
import { useSupport } from '../../../_functions/support/support';

export const SelectTemplate = ({
  changeTemplate,
}: {
  changeTemplate: (id: string, approver: string) => void;
}): JSX.Element => {
  const { templates } = useSupport();
  const [selectedTemplate, setSelectedTemplate] = useState<Array<DepartmentOption>>([]);
  const [approver, setApprover] = useState<string>('');

  const _changeTemplate = (options: Array<DepartmentOption>) => {
    setSelectedTemplate(options);
    changeTemplate(options[0].value || '', approver);
  };

  return (
    <EuiForm>
      <EuiFormRow label="Template">
        <EuiComboBox
          placeholder="Select a Template"
          options={templates.map((t) => ({
            label: t.name,
            value: t.id,
          }))}
          selectedOptions={selectedTemplate}
          onChange={_changeTemplate}
          singleSelection={{ asPlainText: true }}
          isClearable={true}
          data-test-subj="templateComboBox"
        />
      </EuiFormRow>
      <EuiFormRow label="Approver">
        <EuiFieldText
          value={approver}
          placeholder="Wer soll die Anfrage genehmigen"
          onChange={(e) => {
            setApprover(e.target.value);
            changeTemplate(selectedTemplate[0].value || '', e.target.value);
          }}
        />
      </EuiFormRow>
    </EuiForm>
  );
};
