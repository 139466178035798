import React, { useContext } from 'react';
import { EuiButton } from '@elastic/eui';
import { AnketaContext } from '../context_provider';
import { ActionButtonProps } from '../../_types';

export const ActionButton = ({ action }: ActionButtonProps): JSX.Element => {
  const ctx = useContext(AnketaContext);

  const onClick = (): void => {
    ctx.onAction(action);
  };

  return (
    <EuiButton
      style={{ margin: '2rem' }}
      disabled={!action.enabled}
      onClick={onClick}
      title={ctx.i18nContext.render(action.help)}
      fill
    >
      {ctx.i18nContext.render(action.title)}
    </EuiButton>
  );
};
