import { Group, OptionsQuestion, Section, Translation } from "anketa-core"
import { MedoOptionMap } from "../helpers/medoOptionsMap"

export const generateOptimazation = () => {

    const sBasic = new Section('basic', [new Translation('en', 'Basic use'), new Translation('de', 'Grundlegende Nutzung')])

    const optimization = new Group('optimization_hw', [new Translation('en', 'Resource optimization'), new Translation('de', 'Ressourcenoptimierung')])

    const VMKonsolidierung = new OptionsQuestion(`vm_konsolidierung`, [new Translation('en', 'VM consolidation'), new Translation('de', 'VM-Konsolidierung')], MedoOptionMap.yes_no);
    VMKonsolidierung.help = [
        new Translation('en', 'Have you looked at ways to consolidate VMs to reduce the number of VMs and use resources more efficiently?'),
        new Translation('de', 'Haben Sie Möglichkeiten zur Konsolidierung von VMs geprüft, um die Anzahl der VMs zu reduzieren und Ressourcen effizienter zu nutzen?')
    ];
    optimization.addChild(VMKonsolidierung)

    const VMKonfigurationen = new OptionsQuestion(`vm_konfigurationen`, [new Translation('en', 'VM Configurations'), new Translation('de', 'VM-Konfigurationen')], MedoOptionMap.yes_no);
    VMKonfigurationen.help = [
        new Translation('en', 'Are you making sure that your VMs are not overprovisioned and are only allocated as much CPU and memory as they actually need?'),
        new Translation('de', 'Stellen Sie sicher, dass Ihre VMs nicht überprovisioniert sind und nur so viel CPU und Speicher zugewiesen bekommen, wie tatsächlich benötigt wird?')
    ];
    optimization.addChild(VMKonfigurationen)

    sBasic.addChild(optimization)
    return sBasic
}
