import React, { useState } from "react";
import { EuiFlexGroup, EuiPage, EuiFlexItem, EuiPageBody, EuiPageHeader, EuiPageContent, EuiPageContentBody, EuiSpacer, EuiButton, EuiCard, EuiIcon } from "@elastic/eui";
import { Avatar } from "../components/avatar";
import { AuthorizeItem, useKeycloak } from "../_functions";
import { AssignNewsletterToUserModal, CreateNewsletterModal, CreateUserModal } from "./modal";
import { NewsletterTable, UserTable } from "./tables";

export const NewsletterComponent = (): JSX.Element => {
    const authCtx = useKeycloak()

    const [view, setView] = useState<"user" | "newsletter">("user")
    const [modal, setModal] = useState<"createUser" | "createNewsletter" | "assign" | undefined>(undefined)

    const handleClose = () => {
        setModal(undefined)
    }

    return (
        <EuiPage>
            <EuiPageBody>
                <EuiPageHeader
                    pageTitle="Newsletter Admin Center"
                    rightSideItems={[
                        <EuiFlexGroup gutterSize="s" alignItems="center">
                            <EuiFlexItem>
                                <Avatar />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    ]}
                />
                <EuiPageContent style={{ overflowY: "auto", height: "90vh", background: "none", border: "none", boxShadow: "none" }}>
                    <EuiFlexGroup>
                        <AuthorizeItem key={`newsletter:user:auth`} ctx={authCtx.keycloak} roleName={`anketa-newsletter-admin`}>
                            <EuiFlexItem key="newsletter:user">
                                <EuiCard
                                    icon={<EuiIcon type={"user"} />}
                                    title="User"
                                    description="Zeige alle Nutzer und deren Abos an."
                                    onClick={() => {
                                        setView("user")
                                    }}
                                />
                            </EuiFlexItem>
                        </AuthorizeItem >
                        <AuthorizeItem key={`newsletter:newsletter:auth`} ctx={authCtx.keycloak} roleName={`anketa-newsletter-admin`}>
                            <EuiFlexItem key="newsletter:newsletter">
                                <EuiCard
                                    icon={<EuiIcon type={"email"} />}
                                    title="Newsletter"
                                    description="Zeige dir alle Newsletter an."
                                    onClick={() => {
                                        setView("newsletter")
                                    }}
                                />
                            </EuiFlexItem>
                        </AuthorizeItem >
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiButton style={{ width: "200px" }} iconType="plus" fill onClick={() => setModal("createUser")}>Nutzer anlegen</EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton style={{ width: "200px" }} iconType="plus" fill onClick={() => setModal("createNewsletter")}>Newsletter erstellen</EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton style={{ width: "200px" }} iconType="user" fill onClick={() => setModal("assign")}>Newsletter abonnieren</EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer />
                    <EuiPageContentBody>
                        {modal === "createUser" && <CreateUserModal handleClose={handleClose} />}
                        {modal === "createNewsletter" && <CreateNewsletterModal handleClose={handleClose} />}
                        {modal === "assign" && <AssignNewsletterToUserModal handleClose={handleClose} />}
                        {view === "user" && <UserTable />}
                        {view === "newsletter" && <NewsletterTable />}
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </EuiPage >
    )
}