import React, { useState } from "react"
import { EuiButton, EuiButtonEmpty, EuiComboBox, EuiComboBoxOptionOption, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle } from "@elastic/eui"
import { useNewsletter } from "../../../_functions"
import { NewsletterDetails, User, UserDetail } from "../../../_types"
import { useToast } from "../../../_functions/toast"

export const AssignNewsletterToUserModal = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
    const { customers, newsletters, addUserToNewsletter } = useNewsletter()
    const { addToast } = useToast();

    const [user, setUser] = useState<User | undefined>(undefined)
    const [newsletter, setNewsletter] = useState<NewsletterDetails | undefined>(undefined)

    const closeModal = () => {
        setUser(undefined)
        setNewsletter(undefined)
        handleClose()
    }

    const finish = () => {
        if (user && newsletter) {
            const userAlreadyAssign = customers.filter((customer) => customer.id === user.id)[0].newsletters?.some((news) => news.id === newsletter.id)
            console.warn(userAlreadyAssign)
            if (userAlreadyAssign) {
                addToast({
                    title: 'Nutzer ist bereits im Newsltter!',
                    text: <p>Ein Nutzer kann nicht mehrfach in einem Newsletter sein!</p>,
                    color: "warning",
                    iconType: "cross"
                });
            } else {
                addUserToNewsletter(user.id, newsletter.id)
            }
        }
        closeModal()
    }
    const handleChangeUser = (selectedOptions: EuiComboBoxOptionOption<User>[]) => {
        if (selectedOptions.length > 0) {
            const selectedOption = selectedOptions[0];
            setUser(selectedOption.value);
        } else {
            setUser(undefined);
        }
    };

    const handleChangeNewsletter = (selectedOptions: EuiComboBoxOptionOption<NewsletterDetails>[]) => {
        if (selectedOptions.length > 0) {
            const selectedOption = selectedOptions[0];
            setNewsletter(selectedOption.value);
        } else {
            setNewsletter(undefined);
        }
    };

    return (
        <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Nutzer einen Newsletter zuweisen</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiForm component="form">
                    <EuiFormRow
                        label="Nutzer"
                        helpText="Wählen Sie einen Nutzer aus, der Teil des Newsletters werden soll."
                    >
                        <EuiComboBox
                            placeholder="Wählen Sie einen Nutzer"
                            singleSelection={true}
                            options={customers.map((user: UserDetail) => ({
                                label: user.email,
                                value: user,
                            }))}
                            selectedOptions={user ? [{ label: user.email, value: user }] : []}
                            onChange={(selectedOptions) => handleChangeUser(selectedOptions)}
                        />
                    </EuiFormRow>
                    <EuiFormRow
                        label="Newsletter"
                        helpText="Wählen Sie den Newsletter aus."
                    >
                        <EuiComboBox
                            placeholder="Wählen Sie einen Newsletter"
                            singleSelection={true}
                            options={newsletters.map((news: NewsletterDetails) => ({
                                label: news.name,
                                value: news,
                            }))}
                            selectedOptions={newsletter ? [{ label: newsletter.name, value: newsletter }] : []}
                            onChange={(selectedOptions) => handleChangeNewsletter(selectedOptions)}
                        />
                    </EuiFormRow>

                </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
                <EuiButton disabled={newsletter === undefined || user === undefined} onClick={finish}>Erstellen</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}