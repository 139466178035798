import { PATH_SEPARATOR } from '../base/const'
import { type EvaluatorValue } from '../base/evaluator'
import { type Fact } from '../base/fact'
import { ValidatorType } from '../base/types'
import { type Options, type TextMessage } from '../i18n'
import { MessageCollection } from '../i18n/message_context'
import { Translation } from '../i18n/translation'
import { type ExecutionContext } from '../runtime/execution_context'
import { ValidationContext } from './context'
import { ValidationError } from './errors'
import { Validator } from './validator'

export class OptionsValidator extends Validator {
  private _options: Options
  private multiple_choice: boolean
  suggestOnly: boolean = false

  static readonly errors_not_an_option = 'default' + PATH_SEPARATOR + 'validators' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'errors' + PATH_SEPARATOR + 'not_an_option'

  static readonly options_choose = 'options' + PATH_SEPARATOR + 'choose'
  static readonly options_yes_no = 'options' + PATH_SEPARATOR + 'yes_no'
  static readonly options_true_false = 'options' + PATH_SEPARATOR + 'true_false'

  static {
    MessageCollection.Global.setTranslations(OptionsValidator.errors_not_an_option, [new Translation('en', 'Not a valid option'), new Translation('de', 'Keine gültige Auswahl')])

    MessageCollection.Global.setTranslations(OptionsValidator.options_choose, [new Translation('en', 'Please chose'), new Translation('de', 'Treffen Sie eine Auswahl')])

    MessageCollection.Global.setTranslations(`${OptionsValidator.options_yes_no}${PATH_SEPARATOR}yes`, [new Translation('en', 'yes'), new Translation('de', 'Ja')])
    MessageCollection.Global.setTranslations(`${OptionsValidator.options_yes_no}${PATH_SEPARATOR}no`, [new Translation('en', 'no'), new Translation('de', 'Nein')])

    MessageCollection.Global.setTranslations(`${OptionsValidator.options_true_false}${PATH_SEPARATOR}true`, [new Translation('en', 'true'), new Translation('de', 'wahr')])
    MessageCollection.Global.setTranslations(`${OptionsValidator.options_true_false}${PATH_SEPARATOR}/false`, [new Translation('en', 'false'), new Translation('de', 'falsch')])
  }

  constructor (options: Options) {
    super(ValidatorType.Options)
    this._options = options
    this.multiple_choice = false
  }

  public validate (ctx: ExecutionContext, fact: Fact<EvaluatorValue>): ValidationContext {
    const result = new ValidationContext(fact)
    const val = fact.getStrValue(ctx.i18nContext)
    const options = ctx.getOptions(this._options)
    if (!this.suggestOnly && !options.has(val)) {
      result.diagnostics.push(new ValidationError(OptionsValidator.errors_not_an_option, undefined, this))
    }
    return result
  }

  public read (o: any): this {
    super.read(o)

    if (Object.prototype.hasOwnProperty.call(o, 'options')) {
      this._options = o.options
    } else {
      this._options = new Map<string, TextMessage>()
    }
    if (Object.prototype.hasOwnProperty.call(o, 'multiple_choice')) {
      this.multiple_choice = o.multiple_choice
    }

    return this
  }

  public save (): any {
    const result = super.save()
    if (typeof (this._options) === 'string') {
      result.options = this._options
    } else if (this._options.size > 0) {
      result.options = Object.fromEntries(this._options)
    }
    if (this.multiple_choice) {
      result.multiple_choice = this.multiple_choice
    }
    return result
  }

  public get options (): Options {
    return this._options
  }

  public set options (options: Options) {
    this._options = options
  }
}
