import React from 'react';
import { EuiStepsHorizontal } from '@elastic/eui';

export type UserEditingModalSteps = 'internal' | 'groups' | 'user' | 'user-details';

export const UserEditingModalHeader = ({
  selectedStep,
  setSelectedStep,
}: {
  selectedStep: UserEditingModalSteps;
  setSelectedStep: (step: UserEditingModalSteps) => void;
}): JSX.Element => {
  const horizontalSteps: any = [
    {
      title: 'Internal',
      isSelected: selectedStep === 'internal',
      isComplete: false,
      id: 'template',
      onClick: () => {
        setSelectedStep('internal' as UserEditingModalSteps);
      },
    },
    {
      title: 'User',
      isSelected: selectedStep === 'user',
      isComplete: false,
      onClick: () => {
        setSelectedStep('user');
      },
    },
    {
      title: 'Details',
      isSelected: selectedStep === 'user-details',
      isComplete: false,
      onClick: () => {
        setSelectedStep('user-details');
      },
    },
    {
      title: 'Groups',
      isSelected: selectedStep === 'groups',
      isComplete: false,
      onClick: () => {
        setSelectedStep('groups');
      },
    },
  ];
  return <EuiStepsHorizontal steps={horizontalSteps} />;
};
