import { type EvaluatorValue } from '../base/evaluator'
import { type Fact } from '../base/fact'
import { ValidatorType } from '../base/types'
import { MessageCollection } from '../i18n/message_context'
import { Translation } from '../i18n/translation'
import { type ExecutionContext } from '../runtime'
import { type ValidationContext } from './context'
import { ValidationError, ValidationWarning } from './errors'
import { NumericValidator } from './numeric_validator'

export class ScaleValidator extends NumericValidator {
  step: number = 1

  constructor (min?: number, max?: number, decimals?: number) {
    super(ValidatorType.Scale)
    if (min !== undefined) {
      this.min = min
    }
    if (max !== undefined) {
      this.max = max
    }
    if (decimals !== undefined) {
      this.decimals = decimals
    }
  }

  static readonly range_errors_out_of_scale = 'default.validators.scale.errors.out_of_scale'
  static {
    MessageCollection.Global.setTranslations(ScaleValidator.range_errors_out_of_scale, [new Translation('en', 'Value not on scale'), new Translation('de', 'Eingabewert nicht auf der Skala')])
  }

  static readonly warning_float_input = 'default.validators.scale.warnings.float_input'
  static {
    MessageCollection.Global.setTranslations(ScaleValidator.warning_float_input, [new Translation('en', 'Input not an integer'), new Translation('de', 'Eingabewert ist keine Integer')])
  }

  public validate (ctx: ExecutionContext, fact: Fact<EvaluatorValue>): ValidationContext {
    const result = super.validate(ctx, fact)
    let nVal: number
    if (this.decimals > 0) {
      nVal = fact.getFloatValue(ctx.i18nContext)
    } else {
      if (Math.floor(fact.getFloatValue(ctx.i18nContext)) !== fact.getFloatValue(ctx.i18nContext)) {
        result.diagnostics.push(new ValidationWarning(ScaleValidator.warning_float_input, undefined, this, undefined))
      }
      nVal = fact.getIntValue(ctx.i18nContext)
    }

    if (nVal % this.step !== 0) {
      result.diagnostics.push(new ValidationError(ScaleValidator.range_errors_out_of_scale, undefined, this, undefined))
    }
    return result
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'step')) {
      this.step = o.step
    } else {
      this.step = 1
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.step !== 1) {
      result.step = this.step
    }
    return result
  }

  public get decimals (): number {
    return this._decimals
  }

  public set decimals (val: number) {
    if (val < 0) {
      throw new Error('decimals must be 0 or a positive number')
    }
    this._decimals = val
  }
}
