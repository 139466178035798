import {
    ComplexFactory,
    ComplexQuestion,
    Section,
    Translation,
} from 'anketa-core';
import { OpsanioStatus } from '../helpers/medocino_user';

export const generateStatus = (opsanioAdmin: boolean) => {
    ComplexFactory.registerClass({
        name: 'OpsanioStatus',
        Constructor: OpsanioStatus as new (init?: any) => OpsanioStatus,
        form: OpsanioStatus.getForm,
    });

    const gHeader = new Section('ops', [
        new Translation('en', 'Status'),
        new Translation('de', 'Status'),
    ]);

    const status = new ComplexQuestion(`status`, [
        new Translation('en', ''),
        new Translation('de', ''),
    ], "OpsanioStatus");
    status.readonly = !opsanioAdmin
    gHeader.addChild(status)
    return gHeader;
};
