import { MarkdownValidator, OpenQuestion, OptionsQuestion, Row, Section, Translation } from "anketa-core";
import { MedoOptionMap } from "../helpers/medoOptionsMap";

export const generateUpgrades = () => {
    const sUpgrades = new Section('upgrades', [new Translation('en', 'Upgrade and maintenance'), new Translation('de', 'Upgrade und Wartung')]);

    const how = new OpenQuestion('upgrades_how', [new Translation('en', 'How:'), new Translation('de', 'Wie:')])
    how.validator = new MarkdownValidator()
    how.help = [
        new Translation('en', 'How do you go about upgrading and maintaining your vSphere environment?'),
        new Translation('de', 'Wie gehen Sie bei Upgrades und Wartungsarbeiten Ihrer vSphere-Umgebung vor?')
    ]
    sUpgrades.addChild(how)

    const lifecycle = new OptionsQuestion(`lifecycle`, [new Translation('en', ' vSphere Lifecycle Manager'), new Translation('de', ' vSphere Lifecycle Manager')], MedoOptionMap.yes_no);
    lifecycle.help = [
        new Translation('en', 'Do you use vSphere Lifecycle Manager to manage patches and upgrades?'),
        new Translation('de', 'Nutzen Sie vSphere Lifecycle Manager für die Verwaltung von Patches und Upgrades?')
    ];
    sUpgrades.addChild(lifecycle)

    const flashen = new OptionsQuestion(`flashen`, [new Translation('en', ' Flashen'), new Translation('de', ' Flashen')], MedoOptionMap.yes_no);
    flashen.help = [
        new Translation('en', 'Do you flash the server firmware with vSphere on-board resources?'),
        new Translation('de', 'Flashen Sie die Serverfirmware mit vSphere-Bordmitteln?')
    ];
    sUpgrades.addChild(flashen)

    return sUpgrades;
};
