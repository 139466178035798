import { isQuestion } from './base'
import { ItemObject } from './base/item_object'
import { ItemType, type ChangeAction, type Hierarchical } from './base/types'
import { type TextMessage } from './i18n'
import { type Storage } from './storage'
import { type Catalog } from './types'

export class CatalogObject extends ItemObject implements Catalog {
  protected _store?: Storage

  constructor (id: string, title: TextMessage) {
    super(id, ItemType.Catalog, title)
  }

  get store (): Storage | undefined {
    return this._store
  }

  set store (store: Storage | undefined) {
    this._store = store
  }

  public onChildrenChanged (item: Hierarchical, action: ChangeAction): void {
    if (this.store) {
      if (isQuestion(item)) {
        this.store.set(item.path, item.fact).then(() => {
          console.debug('store', item.path)
        }).catch((reason) => {
          console.error(reason)
        })
      }
    }
    super.onChildrenChanged(item, action)
  }

  public clone (): Catalog {
    const ser = this.save()
    const result = new CatalogObject(this.id, this.title)
    return result.read(ser)
  }
}
