import { PATH_SEPARATOR } from '../base/const'
import { MessageCollection, type TextMessage } from './message_context'
import { Translation, type Translations } from './translation'

export class LikertScale extends Map<string, TextMessage> {
  static readonly agreement_5 = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'agreement_5'

  static readonly protection_level_4 = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'protection_level_4'

  static readonly degree_of_fulfillment_6 = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'degree_of_fulfillment_6'

  static readonly yes_no = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'yes_no'

  static addScaleOption (baseId: string, translations: Translations): void {
    let id = 0
    while (MessageCollection.Global.has(baseId + PATH_SEPARATOR + `${id}`)) {
      id++
    }
    MessageCollection.Global.setTranslations(baseId + PATH_SEPARATOR + `${id}`, translations)
  }

  static {
    LikertScale.addScaleOption(LikertScale.agreement_5, [new Translation('en', 'Strongly Agree'), new Translation('de', 'Stimme sehr zu')])
    LikertScale.addScaleOption(LikertScale.agreement_5, [new Translation('en', 'Agree'), new Translation('de', 'Stimme zu')])
    LikertScale.addScaleOption(LikertScale.agreement_5, [new Translation('en', 'Undecided'), new Translation('de', 'Unentschlossen')])
    LikertScale.addScaleOption(LikertScale.agreement_5, [new Translation('en', 'Disagree'), new Translation('de', 'Stimme nicht zu')])
    LikertScale.addScaleOption(LikertScale.agreement_5, [new Translation('en', 'Strongly Disagree'), new Translation('de', 'Stimme überhaupt nicht zu')])

    LikertScale.addScaleOption(LikertScale.protection_level_4, [new Translation('en', 'N/A'), new Translation('de', 'N/A')])
    LikertScale.addScaleOption(LikertScale.protection_level_4, [new Translation('en', 'Basic'), new Translation('de', 'Basis')])
    LikertScale.addScaleOption(LikertScale.protection_level_4, [new Translation('en', 'Standard'), new Translation('de', 'Standard')])
    LikertScale.addScaleOption(LikertScale.protection_level_4, [new Translation('en', 'Elevated'), new Translation('de', 'Erhöht')])

    LikertScale.addScaleOption(LikertScale.degree_of_fulfillment_6, [new Translation('en', '<todo>'), new Translation('de', '<offen>')])
    LikertScale.addScaleOption(LikertScale.degree_of_fulfillment_6, [new Translation('en', 'N/A'), new Translation('de', 'N/A')])
    LikertScale.addScaleOption(LikertScale.degree_of_fulfillment_6, [new Translation('en', 'major nonconformity'), new Translation('de', 'Hauptabweichung')])
    LikertScale.addScaleOption(LikertScale.degree_of_fulfillment_6, [new Translation('en', 'minor nonconformity'), new Translation('de', 'Nebenabweichung')])
    LikertScale.addScaleOption(LikertScale.degree_of_fulfillment_6, [new Translation('en', 'room for improvement'), new Translation('de', 'Verbesserungspotential')])
    LikertScale.addScaleOption(LikertScale.degree_of_fulfillment_6, [new Translation('en', 'fuly applied'), new Translation('de', 'voll erfüllt')])

    LikertScale.addScaleOption(LikertScale.yes_no, [new Translation('en', 'Yes'), new Translation('de', 'Ja')])
    LikertScale.addScaleOption(LikertScale.yes_no, [new Translation('en', 'No'), new Translation('de', 'Nein')])
  }
}
