import React, { useState } from 'react';
import { EuiStepsHorizontal } from '@elastic/eui';
import { OnboardingTemplate } from './types';

interface CreateUserStepperProps {
  selectedStep: string;
  selectedTemplate: OnboardingTemplate | undefined;
  userValue: {
    firstname: string;
    lastname: string;
    gender: string;
  };
  setSelectedStep: (step: string) => void;
}

export const CreateUserStepper = ({
  selectedStep,
  selectedTemplate,
  userValue,
  setSelectedStep,
}: CreateUserStepperProps): JSX.Element => {
  const horizontalSteps: any = [
    {
      title: 'Template',
      isSelected: selectedStep === 'template',
      isComplete: selectedTemplate !== undefined,
      id: 'template',
      onClick: () => {
        setSelectedStep('template');
      },
    },
    {
      title: 'User Values',
      isSelected: selectedStep === 'user',
      isComplete:
        userValue.firstname.trim() !== '' &&
        userValue.lastname.trim() !== '' &&
        userValue.gender.trim() !== '',
      onClick: () => {
        setSelectedStep('user');
      },
    },
    {
      title: 'Update Template',
      isDisabled: selectedTemplate === undefined,
      isSelected: selectedStep === 'update-template',
      onClick: () => {
        setSelectedStep('update-template');
      },
    },
    {
      title: 'Summary',
      isSelected: selectedStep === 'summary',
      onClick: () => {
        setSelectedStep('summary');
      },
    },
  ];
  return <EuiStepsHorizontal steps={horizontalSteps} />;
};
