import { MultiSelectOptionsQuestion, OptionsQuestion, Section, Translation } from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';

export const generateStorageManagement = () => {
  const sStorage = new Section('storage', [
    new Translation('en', 'Storage management'),
    new Translation('de', 'Speicherverwaltung'),
  ]);

  const storageSolutions = new MultiSelectOptionsQuestion(
    `storagesolutions`,
    [
      new Translation('en', 'Storage solutions'),
      new Translation('de', 'Arten von Speicherlösungen'),
    ],
    MedoOptionMap.storage_solutions
  );
  storageSolutions.help = [
    new Translation(
      'en',
      'What types of storage solutions do you use (e.g., SAN, NAS, local datastores, HCI)?'
    ),
    new Translation(
      'de',
      'Welche Arten von Speicherlösungen nutzen Sie (z.B. SAN, NAS, lokale Datenspeicher, HCI)?'
    ),
  ];
  sStorage.addChild(storageSolutions);

  const storageProtocols = new MultiSelectOptionsQuestion(
    `storageprotocols`,
    [new Translation('en', 'Storage protocols'), new Translation('de', 'Speicherprotokolle')],
    MedoOptionMap.storage_protocols
  );
  storageProtocols.help = [
    new Translation(
      'en',
      'Which storage protocols does your primary storage system support (e.g., NFS, SMB, FC, iSCSI)?'
    ),
    new Translation(
      'de',
      'Welche Speicherprotokolle beherrscht ihr primäres Speichersystem (z.B. NFS, SMB, FC, iSCSI)?'
    ),
  ];
  sStorage.addChild(storageProtocols);

  const storageDrs = new OptionsQuestion(
    `storagedrs`,
    [new Translation('en', 'Storage DRS'), new Translation('de', 'vSphere Storage DRS')],
    MedoOptionMap.yes_no
  );
  storageDrs.help = [
    new Translation('en', 'Do you use vSphere Storage DRS for storage resource management?'),
    new Translation('de', 'Nutzen Sie vSphere Storage DRS zur Verwaltung von Speicherressourcen?'),
  ];
  sStorage.addChild(storageDrs);

  const nvme = new OptionsQuestion(
    `nvme`,
    [new Translation('en', 'NVMe usage'), new Translation('de', 'NVMe Nutzung')],
    MedoOptionMap.yes_no
  );
  nvme.help = [
    new Translation('en', 'Do you use NVMe (NVMe-over-Fabric, NVMe-over-TCP, etc.)?'),
    new Translation('de', 'Setzen Sie NVMe ein (NVMe-over-Fabric (RoCEv2), NVMe-over-TCP, etc.)?'),
  ];
  sStorage.addChild(nvme);

  const vcenterPlugins = new OptionsQuestion(
    `vcenterplugins`,
    [new Translation('en', 'vCenter plugins'), new Translation('de', 'vCenter-Plugins')],
    MedoOptionMap.yes_no
  );
  vcenterPlugins.help = [
    new Translation('en', 'Do you use vCenter plugins of your storage system?'),
    new Translation('de', 'Nutzen Sie vCenter-Plugins Ihres Storagesystems?'),
  ];
  sStorage.addChild(vcenterPlugins);

  return sStorage;
};
