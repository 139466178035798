import { type EvaluatorValue } from '../base/evaluator'
import { type Fact } from '../base/fact'
import { ValidatorType } from '../base/types'
import { MessageCollection, Translation } from '../i18n'
import { type ExecutionContext } from '../runtime/execution_context'
import { ValidationContext } from './context'
import { ValidationError } from './errors'
import { Validator } from './validator'

export class NumericValidator extends Validator {
  min: number = Number.MIN_SAFE_INTEGER
  max: number = Number.MAX_SAFE_INTEGER
  // 0 means integer
  protected _decimals: number = 0

  constructor (type?: ValidatorType) {
    super(type !== undefined ? type : ValidatorType.Numeric)
  }

  static readonly numeric_errors_to_low = 'default/validators/numeric/errors/to_short'
  static readonly numeric_errors_to_high = 'default/validators/numeric/errors/to_long'
  static readonly numeric_errors_nan = 'default/validators/numeric/errors/nan'
  static {
    MessageCollection.Global.setTranslations(NumericValidator.numeric_errors_to_low, [new Translation('en', 'Value is to low'), new Translation('de', 'Eingabewert ist gering')])
    MessageCollection.Global.setTranslations(NumericValidator.numeric_errors_to_high, [new Translation('en', 'Value is to high'), new Translation('de', 'Eingabewert zu hoch')])
    MessageCollection.Global.setTranslations(NumericValidator.numeric_errors_nan, [new Translation('en', 'Value is not a number'), new Translation('de', 'Eingabe ist keien Zahl')])
  }

  public validate (ctx: ExecutionContext, fact: Fact<EvaluatorValue>): ValidationContext {
    const result = new ValidationContext(fact)
    let nVal: number
    if (this.decimals > 0) {
      nVal = fact.getFloatValue(ctx.i18nContext)
    } else {
      nVal = fact.getIntValue(ctx.i18nContext)
    }
    if (Number.isNaN(nVal)) {
      result.diagnostics.push(new ValidationError(NumericValidator.numeric_errors_nan, undefined, this, undefined))
    } else {
      if (nVal < this.min) {
        result.diagnostics.push(new ValidationError(NumericValidator.numeric_errors_to_low, undefined, this))
      }
      if (nVal > this.max) {
        result.diagnostics.push(new ValidationError(NumericValidator.numeric_errors_to_high, undefined, this))
      }
    }
    return result
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'min')) {
      this.min = o.min
    }
    if (Object.prototype.hasOwnProperty.call(o, 'max')) {
      this.max = o.max
    }
    if (Object.prototype.hasOwnProperty.call(o, 'decimals')) {
      this.decimals = o.decimals
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.min !== Number.MIN_SAFE_INTEGER) {
      result.min = this.min
    }
    if (this.max !== Number.MAX_SAFE_INTEGER) {
      result.max = this.max
    }
    if (this.decimals > 0) {
      result.decimals = this.decimals
    }
    return result
  }

  public get decimals (): number {
    return this._decimals
  }

  public set decimals (val: number) {
    if (val < 0) {
      throw new Error('decimals must be 0 or a positive number')
    }
    this._decimals = val
  }
}
