import {
  MarkdownValidator,
  OpenQuestion,
  OptionsQuestion,
  Section,
  Translation,
} from 'anketa-core';

export const generateChecklist = (): Section => {
  const sChecklistPr = new Section('checklist', [
    new Translation('en', 'Packing list'),
    new Translation('de', 'Packliste'),
  ]);

  const rails = new OptionsQuestion(
    `rails`,
    [new Translation('en', 'Rails'), new Translation('de', 'Schienen')],
    'default.options.yes_no'
  );
  rails.help = [
    new Translation('eng', 'Are both pairs of server rails packed?'),
    new Translation('de', 'Sind beide Serverschienenpaare eingepackt?'),
  ];
  sChecklistPr.addChild(rails);

  const powerSupplyUnits = new OptionsQuestion(
    `power`,
    [new Translation('en', 'Power supply units'), new Translation('de', 'Netzteile')],
    'default.options.yes_no'
  );
  powerSupplyUnits.help = [
    new Translation('eng', 'Are all four C13 to C14 power supply units packed?'),
    new Translation('de', 'Sind alle vier C13 auf C14 Netzteile eingepackt?'),
  ];
  sChecklistPr.addChild(powerSupplyUnits);

  const iloCable = new OptionsQuestion(
    `ilo`,
    [new Translation('en', 'ILO cable'), new Translation('de', 'ILO Kabel')],
    'default.options.yes_no'
  );
  iloCable.help = [
    new Translation('eng', 'Are two RJ45 cables for the ILO included?'),
    new Translation('de', 'Sind zwei RJ45 Kabel für die ILO eingepackt?'),
  ];
  sChecklistPr.addChild(iloCable);

  const server = new OptionsQuestion(
    `appliance`,
    [new Translation('en', 'opsanio Appliance'), new Translation('de', 'opsanio Appliance')],
    'default.options.yes_no'
  );
  server.help = [
    new Translation('eng', 'Have all opsanio appliances been packed?'),
    new Translation('de', 'Wurden alle opsanio Appliance eingepackt?'),
  ];
  sChecklistPr.addChild(server);

  const network_cabel = new OptionsQuestion(
    `network_cabel`,
    [new Translation('en', 'Network Cabel'), new Translation('de', 'Netzwerk Kabel')],
    'default.options.yes_no'
  );
  network_cabel.help = [
    new Translation('eng', 'Have all four network cables for the CPE interfaces been packed?'),
    new Translation('de', 'Wurden alle vier Netzwerk Kabel für die CPE schnitstellen eingepackt?'),
  ];
  sChecklistPr.addChild(network_cabel);

  const notes = new OpenQuestion('notes', [
    new Translation('en', 'Notes'),
    new Translation('de', 'Notizen'),
  ]);
  notes.validator = new MarkdownValidator();
  sChecklistPr.addChild(notes);

  return sChecklistPr;
};
