import { MarkdownValidator, OpenQuestion, Section, Translation } from "anketa-core";

export const generateSpezial = () => {
    const sSpezials = new Section('spezials', [new Translation('en', 'Special hardware requirements'), new Translation('de', 'Besondere Anforderungen an Hardware')]);

    const how = new OpenQuestion('spezial_hw', [new Translation('en', ''), new Translation('de', '')])
    how.validator = new MarkdownValidator()
    how.help = [
        new Translation('en', 'Do you have special requirements for your vSphere environment in terms of hardware virtualization (vGPU, etc.)?'),
        new Translation('de', 'Haben Sie spezielle Anforderungen an Ihre vSphere-Umgebung bzgl. Hardwarevirtualisierung (vGPU, etc.)?')
    ]
    sSpezials.addChild(how)

    return sSpezials;
};
