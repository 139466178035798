import { type Item } from '../base'
import { ComplexType } from '../base/complex'
import { Form } from '../complex/form'
import { DefaultCatalog } from '../default_catalog'
import { Translation } from '../i18n'
import { cloneItem } from '../item_factory'
import { type QuestionObject } from '../question_object'

export class Link extends ComplexType {
  url!: string
  description?: string
  author?: string
  created?: Date
  modified?: Date

  constructor (init?: any) {
    super('Link', init)
  }

  static getForm (): Item {
    const c = new Form('Link', [new Translation('en', 'Link'), new Translation('de', 'Verknüpfung')])
    c.addChild(cloneItem(DefaultCatalog.instance, 'typed.uri'))
    const desc = cloneItem(DefaultCatalog.instance, 'typed.description') as QuestionObject<any>
    desc.required = false
    c.addChild(desc)
    c.addChild(cloneItem(DefaultCatalog.instance, 'typed.description'))
    return c
  }
}
