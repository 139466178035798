import { Group, OpenQuestion, Row, Translation } from 'anketa-core';

export const generateTool = (name: string): Group => {
  const gTool = new Group('internal_contact', [
    new Translation('en', 'Tool'),
    new Translation('de', 'Tool'),
  ]);
  const rTool = new Row(`tool_${name}_0`, [
    new Translation('en', 'Tool'),
    new Translation('de', 'Tool'),
  ]);

  const generateTool = () => {
    const oldTrigger: OpenQuestion = gTool.children
      .get(`tool_${name}_${gTool.children.size - 1}`)
      ?.children.get(`${name}_tool_${gTool.children.size - 1}`) as OpenQuestion;
    oldTrigger.removeChangeListener(generateTool);

    const item = new Row(`tool_${name}_${gTool.children.size}`, [
      new Translation('en', `${gTool.children.size + 1}. Tool`),
      new Translation('de', `${gTool.children.size + 1}. Tool`),
    ]);

    const toolName = new OpenQuestion(`${name}_tool_${gTool.children.size}`, [
      new Translation('en', 'Tool'),
      new Translation('de', 'Tool'),
    ]);
    toolName.addChangeListener(generateTool);
    item.addChild(toolName);

    gTool.addChild(item);
  };

  gTool.addChild(rTool);

  const internalContactTel = new OpenQuestion(`${name}_tool_0`, [
    new Translation('en', 'Tool'),
    new Translation('de', 'Tool'),
  ]);
  internalContactTel.addChangeListener(generateTool);
  rTool.addChild(internalContactTel);

  return gTool;
};
