import { visit } from 'unist-util-visit';
// eslint-disable-next-line  @typescript-eslint/no-var-requires
const plantumlEncoder = require('plantuml-encoder');

const DEFAULT_OPTIONS = {
  baseUrl: 'https://www.plantuml.com/plantuml/svg',
};

interface Options {
  baseUrl: string;
}

/**
 * Plugin for remark-js
 *
 * See details about plugin API:
 * https://github.com/unifiedjs/unified#plugin
 *
 * You can specify the endpoint of PlantUML with the option 'baseUrl'
 *
 * @param {Object} pluginOptions Remark plugin options.
 */
export function remarkSimplePlantumlPlugin(pluginOptions: any): any {
  const options: Options = { ...DEFAULT_OPTIONS, ...pluginOptions };

  function transformer(syntaxTree: any): any {
    visit(syntaxTree, 'code', (node) => {
      const { lang, value, meta } = node;
      if (!lang || !value || (lang !== 'plantuml' && lang !== 'puml')) {
        return;
      }

      node.type = 'image';
      node.url = `${options.baseUrl.replace(/\/$/, '')}/${plantumlEncoder.encode(value) as string}`;
      node.alt = meta;
      node.meta = undefined;
    });
    return syntaxTree;
  }

  return transformer;
}
