import { type Serializable } from '../base/serializable'

export class Translation implements Serializable {
  language: string
  message: string

  constructor (language: string, message: string) {
    this.language = language.toLowerCase()
    this.message = message
  }

  read (o: any): void {
    /* eslint-disable no-unreachable-loop */
    for (const lang in o) {
      this.language = lang.toLowerCase()
      this.message = o[lang]
      return
    }
    /* eslint-enable no-unreachable-loop */
  }

  save (): any {
    const result = {} as any
    result[this.language] = this.message
    return result
  }

  public toString (): string {
    return this.message
  }
}

export type Translations = Translation[]
