import React from "react";

export const CalendarImage = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={586.479}
            height={659.298}
            {...props}
        >
            <path
                fill="#f2f2f2"
                d="M819.77 378.11c-6.16 6.73-13.8 11.74-22.09 15.63h-.01c-.78.37-1.56.73-2.35 1.07h-.01l-.01.01-.01-.01h-.01a.01.01 0 0 1-.01.01h-.01l-.01-.01c-.01.01-.01.01-.02 0l-.01.01-.01-.01h-.01c-.01.01-.01.01-.02 0h-.01a139.774 139.774 0 0 1-17.06 6.1A236.651 236.651 0 0 1 673 406.4l-2-.36v-81.11c.66-.28 1.33-.56 2-.83q6.555-2.715 13.27-4.99 9.66-3.3 19.59-5.66a212.167 212.167 0 0 1 66.04-5.34q6.045.45 12.06 1.3c8.39 1.17 17.19 3.21 24.93 6.75h.01c1.13.53 2.24 1.08 3.34 1.67 6.88 3.73 12.68 8.86 16.22 15.89a30.573 30.573 0 0 1 2.72 7.99v.02c.2.96.34 1.93.45 2.89 1.26 11.84-3.65 24.51-11.86 33.49Z"
            />
            <path
                fill="#fff"
                d="M831.63 344.62a1.358 1.358 0 0 1-.3.04q-25.695 1.125-51.32 3.34h-.03c-.07.01-.13.01-.2.02q-26.31 2.28-52.51 5.71c-.74.1-1.47.2-2.2.29q-18.12 2.415-36.17 5.36-7.965 1.305-15.9 2.72c-.67.12-1.33.23-2 .36v-3.03c.67-.13 1.34-.24 2-.36q26.43-4.68 53.04-8.17 6.69-.9 13.4-1.7 19.38-2.34 38.82-4.04l2.84-.24q24.885-2.115 49.84-3.21a.939.939 0 0 1 .24.02c1.6.14 1.94 2.54.45 2.89Z"
            />
            <path
                fill="#fff"
                d="M796.056 391.863c-21.34.157-42.363-8.892-57.458-23.87a77.826 77.826 0 0 1-11.184-14.07c-1.017-1.63-3.398.169-2.389 1.786 11.723 18.8 31.25 32.193 52.778 37.118a80.811 80.811 0 0 0 18.637 1.994c1.913-.014 1.517-2.972-.384-2.958ZM705.86 313.45a1.692 1.692 0 0 1-1.25 1.13 65.22 65.22 0 0 0-14.08 5.5A68.406 68.406 0 0 0 673 333.33c-.27.27-.53.54-.78.82-.42.44-.82.89-1.22 1.35v-4.36a41.8 41.8 0 0 1 2-1.99 71.912 71.912 0 0 1 13.27-10.04q9.66-3.3 19.59-5.66ZM810.659 315.372a69.402 69.402 0 0 0-32.422 28.585 1.5 1.5 0 0 0 .793 1.954 1.528 1.528 0 0 0 1.953-.792 66.049 66.049 0 0 1 30.797-26.984c1.765-.754.648-3.519-1.121-2.763Z"
            />
            <path
                fill="#f2f2f2"
                d="M851.58 230.44c-2.32 8.82-6.77 16.81-12.3 24.1l-.01.01c-.51.68-1.04 1.37-1.59 2.03v.01a139.27 139.27 0 0 1-12.41 13.39 233.429 233.429 0 0 1-53.37 38.13c-30.67 16.02-64.95 25.49-98.9 26.03-.26.01-.52.01-.78.01-.41.01-.82.01-1.22.01v-45.45c.65-1.24 1.32-2.48 2-3.71a219.524 219.524 0 0 1 32.03-43.82c.3-.33.61-.66.92-.98q7.02-7.41 14.72-14.11a210.819 210.819 0 0 1 67.29-39.85c7.97-2.86 16.71-5.15 25.21-5.6 1.25-.07 2.49-.1 3.74-.09 7.83.11 15.34 1.96 21.75 6.54a30.999 30.999 0 0 1 6.12 5.81c.62.77 1.19 1.56 1.74 2.37v.01c6.62 9.9 8.16 23.39 5.06 35.16Z"
            />
            <path
                fill="#fff"
                d="M846.52 195.27v.01a1.461 1.461 0 0 1-.24.17q-22.2 12.915-43.84 26.77c-.02.01-.03.02-.05.03a1.74 1.74 0 0 1-.18.11q-22.245 14.25-43.86 29.46c-.6.42-1.21.85-1.82 1.28q-14.925 10.545-29.54 21.54-27.6 20.79-53.99 43.12c-.67.56-1.33 1.13-2 1.7v-3.89c.66-.57 1.33-1.14 2-1.7q10.11-8.535 20.4-16.83c2.05-1.65 4.11-3.3 6.17-4.93q27.45-21.855 56.13-42.08h.01q5.64-3.975 11.3-7.88 16.08-11.07 32.52-21.61c.79-.51 1.59-1.02 2.39-1.53q21.045-13.44 42.64-26a1.489 1.489 0 0 1 .22-.11c1.5-.6 2.91 1.38 1.74 2.37Z"
            />
            <path
                fill="#fff"
                d="M836.968 253.633c-18.825 10.052-41.646 11.805-61.97 5.553a77.827 77.827 0 0 1-16.44-7.264c-1.66-.973-2.931 1.726-1.286 2.69 19.114 11.204 42.627 13.993 63.98 8.353a80.812 80.812 0 0 0 17.43-6.89c1.688-.902-.037-3.337-1.714-2.442ZM696.121 290.222a68.553 68.553 0 0 1-.952-15.87 72.319 72.319 0 0 1 10.78-34.15q7.029-7.408 14.724-14.112a1.705 1.705 0 0 1-.586 1.585 65.451 65.451 0 0 0-9.912 11.407 69.122 69.122 0 0 0-11.082 50.892 1.212 1.212 0 0 1-.314 1.143 1.625 1.625 0 0 1-2.658-.895ZM814.368 179.112a69.402 69.402 0 0 0-15.433 40.375 1.5 1.5 0 0 0 1.61 1.362 1.528 1.528 0 0 0 1.361-1.61 66.048 66.048 0 0 1 14.738-38.2c1.213-1.488-1.061-3.417-2.276-1.927Z"
            />
            <path
                fill="#f2f2f2"
                d="M710.81 211.97v.01c-.07.86-.15 1.71-.24 2.56v.02a141.3 141.3 0 0 1-3.25 17.96c-.7 2.9-1.46 5.78-2.29 8.66-.1.37-.21.74-.32 1.1v.01a229.523 229.523 0 0 1-8.74 24.65A238.918 238.918 0 0 1 673 309.16c-.65.97-1.32 1.94-2 2.9v-156.6c.67-.04 1.33-.04 2-.02q.48 0 .96.03a30.322 30.322 0 0 1 8.28 1.61c.94.31 1.85.66 2.75 1.05 10.91 4.78 19.48 15.32 23.2 26.91 2.79 8.68 3.35 17.81 2.62 26.93Z"
            />
            <path
                fill="#fff"
                d="M684.99 158.13a1.076 1.076 0 0 1-.11.28q-6.075 11.805-11.88 23.73c-.67 1.37-1.34 2.75-2 4.12v-6.8q.99-2.04 2-4.08 4.485-9.09 9.12-18.1a1.304 1.304 0 0 1 .12-.2c.92-1.32 3.2-.42 2.75 1.05ZM711.13 213.58c-.18.32-.36.64-.56.96a79.816 79.816 0 0 1-10.42 14.24A85.916 85.916 0 0 1 673 248.55c-.66.32-1.33.62-2 .9v-3.25c.67-.3 1.34-.61 2-.93a80.866 80.866 0 0 0 35.37-32.82 1.645 1.645 0 0 1 2.44-.47 1.153 1.153 0 0 1 .32 1.6Z"
            />
            <path
                fill="#6c63ff"
                d="M673 33v76h-2v-.81H2v.81H0V33A33.032 33.032 0 0 1 33 0h607a33.032 33.032 0 0 1 33 33Z"
            />
            <path
                fill="#3f3d56"
                d="M640 0H33A33.032 33.032 0 0 0 0 33v434a33.032 33.032 0 0 0 33 33h607a33.032 33.032 0 0 0 33-33V33a33.032 33.032 0 0 0-33-33Zm31 467a31.04 31.04 0 0 1-31 31H33a31.04 31.04 0 0 1-31-31V33A31.04 31.04 0 0 1 33 2h607a31.04 31.04 0 0 1 31 31Z"
            />
            <circle cx={136} cy={54.5} r={20} fill="#fff" />
            <circle cx={537} cy={54.5} r={20} fill="#fff" />
            <path
                fill="#6c63ff"
                d="M193.785 277.502H74.808a19.033 19.033 0 0 1-19.011-19.012v-56.232a19.033 19.033 0 0 1 19.011-19.012h118.977a19.033 19.033 0 0 1 19.011 19.012v56.232a19.033 19.033 0 0 1-19.011 19.012ZM396.183 277.502H277.207a19.033 19.033 0 0 1-19.011-19.012v-56.232a19.033 19.033 0 0 1 19.011-19.012h118.976a19.033 19.033 0 0 1 19.012 19.012v56.232a19.033 19.033 0 0 1-19.012 19.012Z"
            />
            <path
                fill="#f2f2f2"
                d="M396.183 422.793H277.207a19.61 19.61 0 0 1-19.588-19.587v-56.233a19.61 19.61 0 0 1 19.588-19.588h118.976a19.61 19.61 0 0 1 19.588 19.588v56.233a19.61 19.61 0 0 1-19.588 19.587ZM193.394 422.793H74.418a19.61 19.61 0 0 1-19.587-19.587v-56.233a19.61 19.61 0 0 1 19.587-19.588h118.976a19.61 19.61 0 0 1 19.588 19.588v56.233a19.61 19.61 0 0 1-19.588 19.587ZM598.582 422.793H479.606a19.61 19.61 0 0 1-19.588-19.587v-56.233a19.61 19.61 0 0 1 19.588-19.588h118.976a19.61 19.61 0 0 1 19.587 19.588v56.233a19.61 19.61 0 0 1-19.587 19.587ZM598.582 277.615H479.606a19.61 19.61 0 0 1-19.588-19.588v-56.233a19.61 19.61 0 0 1 19.588-19.587h118.976a19.61 19.61 0 0 1 19.587 19.587v56.233a19.61 19.61 0 0 1-19.587 19.588Z"
            />
            <circle cx={134.297} cy={230.374} r={32.262} fill="#fff" />
            <path
                fill="#6c63ff"
                d="M131.099 242.681a3.346 3.346 0 0 1-2.014-.668l-.036-.027-7.581-5.805a3.368 3.368 0 1 1 4.097-5.348l4.91 3.766 11.606-15.135a3.368 3.368 0 0 1 4.723-.624l-.072.098.074-.096a3.372 3.372 0 0 1 .623 4.723l-13.65 17.802a3.37 3.37 0 0 1-2.68 1.314Z"
            />
            <circle cx={337.271} cy={229.911} r={32.262} fill="#fff" />
            <path
                fill="#6c63ff"
                d="M334.073 242.218a3.346 3.346 0 0 1-2.013-.669l-.036-.027-7.582-5.805a3.368 3.368 0 1 1 4.098-5.347l4.91 3.765 11.606-15.134a3.368 3.368 0 0 1 4.722-.624l-.072.098.074-.096a3.372 3.372 0 0 1 .624 4.722l-13.65 17.803a3.37 3.37 0 0 1-2.68 1.314Z"
            />
            <path
                fill="#a0616a"
                d="M555.312 382.15a10.743 10.743 0 0 0-2.183-16.328l-18.01-96.172-21.905 8.122 24.592 91.98a10.8 10.8 0 0 0 17.506 12.398ZM380.527 235.4a11.406 11.406 0 0 1 .022 1.796l42.59 32.78 12.078-4.96 9.867 17.25-20.528 10.857a8.67 8.67 0 0 1-10.276-1.627l-42.571-43.89a11.376 11.376 0 1 1 8.818-12.206ZM480.028 552.849h-12.259l-5.833-37.288 18.095.001-.003 37.287z"
            />
            <path
                fill="#2f2e41"
                d="m483.155 564.733-39.53-.001v-.5a15.386 15.386 0 0 1 15.386-15.386h24.145Z"
            />
            <path
                fill="#a0616a"
                d="M526.028 552.849h-12.259l-5.833-37.288 18.095.001-.003 37.287z"
            />
            <path
                fill="#2f2e41"
                d="m529.155 564.733-39.53-.001v-.5a15.386 15.386 0 0 1 15.386-15.386h24.145ZM465.223 545.48a4.49 4.49 0 0 1-4.475-4.073l-10.683-143.69.504-.04 73.521-6.043.02.522 5.845 148.85a4.5 4.5 0 0 1-4.497 4.669h-14.416a4.475 4.475 0 0 1-4.447-3.816l-19.577-120.245a.5.5 0 0 0-.994.072l-.888 118.724a4.505 4.505 0 0 1-4.263 4.462l-15.409.6c-.08.005-.161.007-.241.007Z"
            />
            <circle cx={477.772} cy={191.564} r={24.561} fill="#a0616a" />
            <path
                fill="#3f3d56"
                d="M482.527 406.882a121.037 121.037 0 0 1-31.769-4.342 4.51 4.51 0 0 1-3.236-4.687c3.307-49.693 4.084-88.257-2.869-114.939-2.963-11.372-1.618-23.349 3.691-32.86 7.99-14.313 22.676-23.024 38.34-22.723q1.124.021 2.269.08c23.773 1.224 42.297 22.73 41.294 47.942l-4.782 120.168a4.44 4.44 0 0 1-2.815 4.044 114.245 114.245 0 0 1-40.123 7.317Z"
            />
            <path
                fill="#3f3d56"
                d="m448.695 292.14-18.397-22.544a5.761 5.761 0 0 1 1.514-8.591l24.921-14.85a16 16 0 0 1 20.16 24.852l-19.477 21.373a5.761 5.761 0 0 1-8.72-.24ZM506.57 289.312a5.755 5.755 0 0 1-3.164-3.606L495.124 258a16 16 0 0 1 28.943-13.652l16.017 24.188a5.761 5.761 0 0 1-2.362 8.399l-26.354 12.336a5.755 5.755 0 0 1-4.798.04Z"
            />
            <path
                fill="#2f2e41"
                d="m489.909 211.717-18.206-4.167c-1.878-.43-4.134-1.25-4.395-3.159-.35-2.565 3.342-4.352 3.001-6.918-.33-2.486-3.692-2.806-6.09-3.54a9.11 9.11 0 0 1-5.672-11.34c-2.595 3.656-8.522 3.969-11.887 1.007s-4.01-8.336-1.99-12.338a14.287 14.287 0 0 1 10.724-7.24 22.617 22.617 0 0 1 13.023 2.428c.267-2.834 3.805-3.983 6.65-4.008a28.43 28.43 0 0 1 26.644 19.446c3.492 11.253 1.156 23.587-8.615 30.17Z"
            />
            <path
                fill="#3f3d56"
                d="M673.287 565h-381a1 1 0 1 1 0-2h381a1 1 0 1 1 0 2Z"
            />
        </svg>
    );
}
