import React, { useCallback, useContext, useState, useEffect } from 'react';
import { AnketaContext, AnketaContextProvider } from '../context_provider';
import {
  MessageCollection,
  type Hierarchical,
  isQuestion,
  type Link,
  type Item,
  ComplexFactory,
} from 'anketa-core';
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiDescriptionList,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
} from '@elastic/eui';
import { CatalogCard } from '../catalog_card';
import { CatalogSubmitButton } from '../catalog_submit_button';
import { ComplexQuestionEditorProps } from '../../_types';

export const generateGermanTitle = (key: string): string => {
  switch (key) {
    case 'title':
      return 'Title';
    case 'job_title':
      return 'Position';
    case 'surname':
      return 'Nachname';
    case 'firstname':
      return 'Vorname';
    case 'sex':
      return 'Geschlecht';
    case 'email':
      return 'E-Mail';
    case 'phone':
      return 'Telefonnummer';
    case 'name':
      return 'Name';
    case 'street_name':
      return 'Straßennamen';
    case 'street_number':
      return 'Straßen Nr.';
    case 'zip':
      return 'PLZ';
    case 'city':
      return 'Stadt';
    case 'country':
      return 'Land';
    case 'employees_location':
      return 'Mitarbeiter am Standort';
    case 'hardware':
      return 'Hardware';
    case 'security':
      return 'Cyber Security';
    case 'services':
      return 'Services';
    case 'network':
      return 'Netzwerk';
    case 'server':
      return 'Server';
    case 'database':
      return 'Database';
    case 'cloud_computing':
      return 'Cloud-Computing';
    case 'programming_language':
      return 'Programmiersprache';
    case 'framework':
      return 'Framework';
    case 'storage':
      return 'Storage';
    case 'ap':
      return 'Access Point';
    case 'fw':
      return 'Firewall';
    case 'backup':
      return 'Backup';
    case 'archiv':
      return 'Archiv';
    default:
      return key;
  }
};
export const ComplexQuestionEditor = ({
  question,
  setValue,
  invalid,
}: ComplexQuestionEditorProps): JSX.Element => {
  const [subCatalog, setSubCatalog] = useState<Item>();
  const ctx = useContext(AnketaContext);
  //   let locale = ctx.i18nContext.language
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = useCallback((): void => {
    setIsModalVisible(false);
  }, []);
  const saveModal = useCallback((): void => {
    if (!subCatalog) {
      return;
    }
    if (subCatalog.children.size > 0) {
      const rawObject = {} as any;
      subCatalog.children.forAny((item: Hierarchical) => {
        if (isQuestion(item)) {
          if (item.fact?.raw) {
            rawObject[item.id] = item.fact?.raw;
          }
        }
      });
      const complexVal = ComplexFactory.new(question.complexType, rawObject);
      setValue(complexVal);
    }
    setIsModalVisible(false);
  }, [subCatalog, question.complexType, setValue]);

  useEffect((): void => {
    setSubCatalog(question.getForm());
  }, [question]);

  const getModalEditor = useCallback((): JSX.Element => {
    return (
      <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>{ctx.i18nContext.render(question.title)}</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <CatalogCard showTitle={false} />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal}>
            {ctx.i18nContext.render(ctx.messages.get(MessageCollection.cancel))}
          </EuiButtonEmpty>
          <CatalogSubmitButton onClick={saveModal}></CatalogSubmitButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }, [ctx.i18nContext, ctx.messages, closeModal, question.title, saveModal]);

  let control: JSX.Element;

  if (question.complexType === 'Link') {
    if (question.fact.raw) {
      control = (
        <span>
          <a href={(question.fact.raw as Link).url}>{(question.fact.raw as Link).description}</a>
        </span>
      );
    } else {
      control = <span>{ctx.i18nContext.render(question.placeholder)}</span>;
    }
  } else {
    if (Object.entries(question.fact.raw ?? {}).length !== 0) {
      control = (
        <EuiPanel>
          {Object.entries(question.fact.raw ?? {})
            .filter(([_, value]) => value)
            .map(([key, value]) => {
              if (key.startsWith('_')) {
                return null;
              }
              return (
                <EuiDescriptionList
                  listItems={[
                    {
                      title: ctx.i18nContext.render(generateGermanTitle(key)).replaceAll('_', ' '),
                      description: ctx.i18nContext.render(value),
                    },
                  ]}
                  compressed
                  type="column"
                  key={key}
                  style={{ width: '300px' }}
                />
              );
            })}
        </EuiPanel>
      );
    } else {
      control = <span>{ctx.i18nContext.render(question.placeholder)}</span>;
    }
  }

  let modal = <></>;
  if (isModalVisible) {
    modal = getModalEditor();
  }

  return (
    <AnketaContextProvider locale={ctx.i18nContext.language} catalog={subCatalog}>
      <EuiButtonIcon
        iconType="documentEdit"
        display="base"
        color="text"
        aria-label={ctx.i18nContext.render(ctx.messages.get(MessageCollection.edit))}
        onClick={() => {
          setIsModalVisible(true);
        }}
        style={{ float: 'right' }}
      ></EuiButtonIcon>
      {modal}
      {control}
    </AnketaContextProvider>
  );
};
