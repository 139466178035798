import { type TextMessage } from '../i18n'
import { Severity, type DiagnosticInfo } from './diagnostic'

export class ValidationError extends Error implements DiagnosticInfo {
  summary: TextMessage
  detail?: TextMessage | undefined
  readonly severity: Severity = Severity.Error
  readonly source?: any

  constructor (summary: TextMessage, detail?: TextMessage, source?: any, options?: any /* ErrroOptions */) {
    super(summary.toString()) // , options)
    this.summary = summary
    this.detail = detail
    this.source = source
  }
}

export class ValidationWarning extends Error implements DiagnosticInfo {
  summary: TextMessage
  detail?: TextMessage | undefined
  readonly severity: Severity = Severity.Warning
  readonly source?: any

  constructor (summary: TextMessage, detail?: TextMessage, source?: any, options?: any /* ErrroOptions */) {
    super(summary.toString()) // , options)
    this.summary = summary
    this.detail = detail
    this.source = source
  }
}

export class ValidationInfo extends Error implements DiagnosticInfo {
  summary: TextMessage
  detail?: TextMessage | undefined
  readonly severity: Severity = Severity.Info
  readonly source?: any

  constructor (summary: TextMessage, detail?: TextMessage, source?: any, options?: any /* ErrroOptions */) {
    super(summary.toString()) // , options)
    this.summary = summary
    this.detail = detail
    this.source = source
  }
}
