import { ValidatorType } from '../base/types'
import { DateValidator } from './date_validator'
import { MarkdownValidator } from './markdown_validator'
import { NumericValidator } from './numeric_validator'
import { OptionsValidator } from './options_validator'
import { ScaleValidator } from './scale_validator'
import { TextValidator } from './text_validator'
import { type Validator } from './validator'

export function validatorFromAny (src: any): Validator {
  if (!Object.prototype.hasOwnProperty.call(src, 'type')) {
    throw new Error('no type specified')
  }

  let qt: ValidatorType
  if (typeof (src.type) === 'string') {
    qt = (ValidatorType[src.type] as any) as ValidatorType
  } else {
    qt = src.type as ValidatorType
  }
  switch (qt) {
    case ValidatorType.Numeric as ValidatorType: {
      const q = new NumericValidator()
      q.read(src)
      return q
    }
    case ValidatorType.Text as ValidatorType: {
      const q = new TextValidator()
      q.read(src)
      return q
    }
    case ValidatorType.Scale as ValidatorType: {
      const q = new ScaleValidator()
      q.read(src)
      return q
    }
    case ValidatorType.Options as ValidatorType: {
      const q = new OptionsValidator('')
      q.read(src)
      return q
    }
    case ValidatorType.Date as ValidatorType: {
      const q = new DateValidator()
      q.read(src)
      return q
    }
    case ValidatorType.Markdown as ValidatorType: {
      const q = new MarkdownValidator()
      q.read(src)
      return q
    }
  }
  throw new Error(`unsupported validator type ${ValidatorType[src.type]}`)
}
