import React from 'react';
import {
  EuiComboBox,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModalBody,
  EuiSwitch,
} from '@elastic/eui';
import { DepartmentOption, UserValues } from '../types';
import { useSupport } from '../../../../_functions/support/support';

export const UserEditingModalGroups = ({
  editing,
  oldUserData,
  setGroup,
  setGroupMail,
  setLicenseDetails,
  group,
  groupMail,
  licenseDetails,
  setAccountEnabled,
  accountEnabled,
}: {
  editing: boolean;
  oldUserData: UserValues;
  setGroup: (groups: Array<DepartmentOption>) => void;
  setGroupMail: (groups: Array<DepartmentOption>) => void;
  setLicenseDetails: (licenseDetails: Array<DepartmentOption>) => void;
  group: Array<DepartmentOption>;
  groupMail: Array<DepartmentOption>;
  licenseDetails: Array<DepartmentOption>;
  setAccountEnabled: (enbabled: boolean) => void;
  accountEnabled: boolean;
}) => {
  const { groups, verteilerlisten, licenses } = useSupport();

  const onGroupChange = (options: Array<DepartmentOption>) => {
    setGroup(options);
  };

  const onGroupMailChange = (options: Array<DepartmentOption>) => {
    setGroupMail(options);
  };

  const onLicenseDetailsChange = (options: Array<DepartmentOption>) => {
    setLicenseDetails(options);
  };

  return (
    <EuiModalBody>
      <EuiForm>
        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Gruppen">
              <EuiComboBox isDisabled selectedOptions={oldUserData.groups} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue Gruppen">
                <EuiComboBox
                  options={groups}
                  selectedOptions={group}
                  onChange={onGroupChange}
                  isClearable={true}
                  data-test-subj="groupComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Verteilerlisten">
              <EuiComboBox isDisabled selectedOptions={oldUserData.verteilerlisten} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue Verteilerlisten">
                <EuiComboBox
                  options={verteilerlisten}
                  selectedOptions={groupMail}
                  onChange={onGroupMailChange}
                  isClearable={true}
                  data-test-subj="groupMailComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Lizenzen">
              <EuiComboBox isDisabled selectedOptions={oldUserData.licenseDetails} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue Lizenzen">
                <EuiComboBox
                  options={licenses}
                  selectedOptions={licenseDetails}
                  onChange={onLicenseDetailsChange}
                  isClearable={true}
                  data-test-subj="licenseDetailsComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Enabled">
              <EuiSwitch
                label=""
                onChange={() => {}}
                disabled
                checked={oldUserData.accountEnabled}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue Enabled Status">
                <EuiSwitch
                  label=""
                  onChange={() => {
                    setAccountEnabled(!accountEnabled);
                  }}
                  checked={accountEnabled}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>
      </EuiForm>
    </EuiModalBody>
  );
};
