import React, { useContext, useState } from 'react';
import {
    EuiSteps,
} from '@elastic/eui';
import { Translation } from 'anketa-core';
import { AnketaContext } from '../components';
import { MarkdownText } from '../components/markdown_text';

interface Step {
    title: string;
    content: JSX.Element;
}

export const KeycloakStepGuid = ({ customer, catalog }: { customer?: string, catalog?: string }): JSX.Element => {
    const [currentStep, setCurrentStep] = useState(0);
    const ctx = useContext(AnketaContext)

    const steps: Step[] = [
        {
            title: ctx.i18nContext.render([
                new Translation('en', 'Overview'),
                new Translation('de', 'Grobe Übersicht'),
            ]),
            content: (
                <MarkdownText
                    text={`
\`\`\`mermaid
flowchart TD;
    A[keycloak] -->|Login| B(Groups)
    B --> C(anketa-admin)
    B --> D(customers)
    B --> J(anketa-catalog_name)
    D --> |Roles| I(anketa-catalog_name-customer)
    C --> |Roles| E[anketa-admin]
    E --> F(Create new)
    E --> G(Delete existing)
    E --> H(Edit)
    I --> J
    J --> H
\`\`\`
`}
                />
            ),
        },
        {
            title: ctx.i18nContext.render([
                new Translation('en', `Create the group ${customer} if it doesn't exist.`),
                new Translation('de', `Die Gruppe ${customer} erstellen, falls noch nicht vorhanden.`),
            ]),
            content: (
                <></>
            ),
        },
        {
            title: ctx.i18nContext.render([
                new Translation('en', `Create the role anketa-${catalog}-${customer}`),
                new Translation('de', `Die Rolle anketa-${catalog}-${customer} erstellen.`),
            ]),
            content: (
                <></>
            ),
        },
        {
            title: ctx.i18nContext.render([
                new Translation('en', `Assign users to the ${customer} group.`),
                new Translation('de', `Nutzer der Gruppe ${customer} zuweisen.`),
            ]),
            content: (
                <></>
            ),
        },
        {
            title: ctx.i18nContext.render([
                new Translation('en', `Assign the role anketa-${catalog}-${customer} to the ${customer} group.`),
                new Translation('de', `Die Rolle anketa-${catalog}-${customer} der Gruppe ${customer} zuweisen.`),
            ]),
            content: (
                <></>
            ),
        },
    ];


    return (
        <EuiSteps
            steps={steps.map((step, index) => ({
                title: step.title,
                children: step.content,
                isSelected: index === currentStep,
                onClick: () => setCurrentStep(index),
            }))}
        />
    );
};
