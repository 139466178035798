import { PropertyEvaluator, type Evaluator } from './base/evaluator'
import { ItemObject } from './base/item_object'
import { ItemType } from './base/types'
import { readTextMessage, saveTextMessage, type TextMessage } from './i18n'

export class Action extends ItemObject {
  /** longer description additonally to the title */
  description?: TextMessage
  help?: TextMessage

  visibleEvaluator?: Evaluator<boolean>
  visible: boolean = true

  enabledEvaluator?: Evaluator<boolean>
  enabled: boolean = true

  constructor (id: string, title: TextMessage, type?: ItemType) {
    super(id, type ?? ItemType.Action, title)
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'description') && (o.description !== undefined)) {
      this.description = readTextMessage(o.description, this.path)
    }
    if (Object.prototype.hasOwnProperty.call(o, 'visible') && (o.visible !== undefined)) {
      if (typeof o.visible === 'boolean') {
        this.visible = o.visible
      } else {
        if (this.visibleEvaluator === undefined) {
          this.visibleEvaluator = new PropertyEvaluator<boolean>('true', this, 'visible')
        }
        this.visibleEvaluator = this.visibleEvaluator.read(o.visible)
      }
    } else {
      this.visible = true
    }
    if (Object.prototype.hasOwnProperty.call(o, 'enabled') && (o.enabled !== undefined)) {
      if (typeof o.enabled === 'boolean') {
        this.enabled = o.enabled
      } else {
        if (this.enabledEvaluator === undefined) {
          this.enabledEvaluator = new PropertyEvaluator<boolean>('true', this, 'enabled')
        }
        this.enabledEvaluator = this.enabledEvaluator.read(o.enabled)
      }
    } else {
      this.enabled = true
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.description !== undefined) {
      result.description = saveTextMessage(this.description)
    }
    if (this.visibleEvaluator) {
      result.visible = this.visibleEvaluator.save()
    } else {
      if (!this.visible) {
        result.visible = this.visible
      }
    }
    if (this.enabledEvaluator) {
      result.enabled = this.enabledEvaluator.save()
    } else {
      if (!this.enabled) {
        result.enabled = this.enabled
      }
    }
    return result
  }
}
