import { type Item } from '../base'
import { ComplexType } from '../base/complex'
import { Form } from '../complex/form'
import { Row } from '../group'
import { OptionMap, Translation } from '../i18n'
import { NumericQuestion } from '../numeric_question'
import { OpenQuestion } from '../open_question'
import { OptionsQuestion } from '../options_question'

export class Location extends ComplexType {
  locationid!: string
  firstname?: string
  surname?: string

  constructor (init?: any) {
    super('Location', init)
  }

  static getForm (): Item {
    const c = new Form('User', [new Translation('en', 'Location'), new Translation('de', 'Standort')])

    const locationCompanyName = new OpenQuestion('name', [new Translation('en', 'Company Name'), new Translation('de', 'Firmenname')])
    c.addChild(locationCompanyName)

    const streetRow = new Row('street', [new Translation('en', 'Street name and number'), new Translation('de', 'Straßennamen und Hausnummer')])

    const locationStreet = new OpenQuestion('street_name', [new Translation('en', 'Street name'), new Translation('de', 'Straßennamen')])
    streetRow.addChild(locationStreet)

    const locationStreetNr = new NumericQuestion('street_number', [new Translation('en', 'Street number'), new Translation('de', 'Hausnummer')])
    streetRow.addChild(locationStreetNr)

    c.addChild(streetRow)

    const zipRow = new Row('zip', [new Translation('en', 'ZIP and City'), new Translation('de', 'PLZ und Stadt')])

    const locationZip = new NumericQuestion('zip', [new Translation('en', 'ZIP'), new Translation('de', 'PLZ')])
    zipRow.addChild(locationZip)

    const locationCity = new OpenQuestion('city', [new Translation('en', 'City'), new Translation('de', 'Stadt')])
    zipRow.addChild(locationCity)

    c.addChild(zipRow)

    const location = new OptionsQuestion('country', [new Translation('en', 'Country'), new Translation('de', 'Land')], OptionMap.country)
    c.addChild(location)

    const employeesLocation = new NumericQuestion('employees_location', [new Translation('en', 'Employees in this site'), new Translation('de', 'Mitarbeiter am Standort')])
    c.addChild(employeesLocation)

    return c
  }
}
