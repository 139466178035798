import React from 'react';
import { EuiText } from '@elastic/eui';
import { OnboardingRequest } from '../create/types';

export const UserCell = ({ user }: { user: OnboardingRequest }) => {
  return (
    <EuiText onClick={() => {}}>
      <p>{user.user.displayName}</p>
    </EuiText>
  );
};
