import { PATH_SEPARATOR } from '../base/const'
import { MessageCollection, type TextMessage } from './message_context'
import { Translation } from './translation'

export enum OptionsType {
  // yes/no , radio
  Gender,
}

export class OptionMap extends Map<string, TextMessage> {
  static readonly sex = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender'
  static readonly empty = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'empty'
  static readonly sex_female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender' + PATH_SEPARATOR + 'female'
  static readonly sex_male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender' + PATH_SEPARATOR + 'male'
  static readonly sex_diverse = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender' + PATH_SEPARATOR + 'diverse'
  static readonly gender_identity = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity'
  static readonly gender_identity_agender = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'agender'
  static readonly gender_identity_androgyne = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'androgyne'
  static readonly gender_identity_androgynous = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'androgynous'
  static readonly gender_identity_bigender = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'bigender'
  static readonly gender_identity_cis = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cis'
  static readonly gender_identity_cisgender = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cisgender'
  static readonly gender_identity_cis_female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cis_female'
  static readonly gender_identity_cis_male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cis_male'
  static readonly gender_identity_cis_man = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cis_man'
  static readonly gender_identity_cis_woman = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cis_woman'
  static readonly gender_identity_cisgender_female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cisgender_female'
  static readonly gender_identity_cisgender_male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cisgender_male'
  static readonly gender_identity_cisgender_man = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cisgender_man'
  static readonly gender_identity_cisgender_woman = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'cisgender_woman'
  static readonly gender_identity_female_to_male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'female_to_male'
  static readonly gender_identity_ftm = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'ftm'
  static readonly gender_identity_gender_fluid = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'gender_fluid'
  static readonly gender_identity_gender_nonconforming = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'gender_nonconforming'
  static readonly gender_identity_gender_questioning = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'gender_questioning'
  static readonly gender_identity_gender_variant = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'gender_variant'
  static readonly gender_identity_genderqueer = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'genderqueer'
  static readonly gender_identity_intersex = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'intersex'
  static readonly gender_identity_male_to_female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'male_to_female'
  static readonly gender_identity_mtf = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'mtf'
  static readonly gender_identity_neither = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'neither'
  static readonly gender_identity_neutrois = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'neutrois'
  static readonly gender_identity_non_binary = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'non-binary'
  static readonly gender_identity_other = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'other'
  static readonly gender_identity_pangender = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'pangender'
  static readonly gender_identity_trans = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans'
  static readonly gender_identity_trans_ = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans-'
  static readonly gender_identity_trans_female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans_female'
  static readonly gender_identity_trans__female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans-_female'
  static readonly gender_identity_trans_male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans_male'
  static readonly gender_identity_trans__male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans-_male'
  static readonly gender_identity_trans_man = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans_man'
  static readonly gender_identity_trans__man = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans-_man'
  static readonly gender_identity_trans_person = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans_person'
  static readonly gender_identity_trans__person = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans-_person'
  static readonly gender_identity_trans_woman = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans_woman'
  static readonly gender_identity_trans__woman = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'trans-_woman'
  static readonly gender_identity_transfeminine = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transfeminine'
  static readonly gender_identity_transgender = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transgender'
  static readonly gender_identity_transgender_female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transgender_female'
  static readonly gender_identity_transgender_male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transgender_male'
  static readonly gender_identity_transgender_man = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transgender_man'
  static readonly gender_identity_transgender_person = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transgender_person'
  static readonly gender_identity_transgender_woman = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transgender_woman'
  static readonly gender_identity_transmasculine = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transmasculine'
  static readonly gender_identity_transsexual = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transsexual'
  static readonly gender_identity_transsexual_female = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transsexual_female'
  static readonly gender_identity_transsexual_male = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transsexual_male'
  static readonly gender_identity_transsexual_man = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transsexual_man'
  static readonly gender_identity_transsexual_person = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transsexual_person'
  static readonly gender_identity_transsexual_woman = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'transsexual_woman'
  static readonly gender_identity_two_spirit = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'gender_identity' + PATH_SEPARATOR + 'two-spirit'

  static readonly country = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'country'

  // https://gist.github.com/zspine/2365808
  // https://deutsch.lingolia.com/de/wortschatz/laender-nationalitaeten
  static readonly nationality = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'nationality'
  static readonly titles = 'default' + PATH_SEPARATOR + 'titles'
  static readonly job_titles = 'default' + PATH_SEPARATOR + 'job_titles'

  static readonly pronoun = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'pronoun'
  static readonly pronoun_she = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'pronoun' + PATH_SEPARATOR + 'she'
  static readonly pronoun_he = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'pronoun' + PATH_SEPARATOR + 'he'
  static readonly pronoun_they = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'pronoun' + PATH_SEPARATOR + 'they'
  static readonly pronoun_name = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'pronoun' + PATH_SEPARATOR + 'name'
  static readonly pronoun_ze = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'pronoun' + PATH_SEPARATOR + 'ze'

  static readonly protection_level = 'default' + PATH_SEPARATOR + 'options' + PATH_SEPARATOR + 'protection_level'

  static readonly auto_languages = ['en', 'de', 'fr', 'es', 'pt']

  static readonly country_codes = ['AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AN',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BM',
    'BN',
    'BO',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CS',
    'CU',
    'CV',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'ST',
    'SV',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'UM',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW']

  static {
    MessageCollection.Global.setTranslations(OptionMap.empty + PATH_SEPARATOR + 'undefined', [new Translation('en', 'Undefined'), new Translation('de', 'Undefiniert')])

    MessageCollection.Global.setTranslations(OptionMap.sex_female, [new Translation('en', 'Female'), new Translation('de', 'Weiblich')])
    MessageCollection.Global.setTranslations(OptionMap.sex_male, [new Translation('en', 'Male'), new Translation('de', 'Männlich')])
    MessageCollection.Global.setTranslations(OptionMap.sex_diverse, [new Translation('en', 'Diverse'), new Translation('de', 'Divers')])
    OptionMap.country_codes.forEach((code: string) => {
      const t = new Array<Translation>()
      OptionMap.auto_languages.forEach((lang: string) => {
        const localizedNames = new Intl.DisplayNames([lang], { type: 'region' })
        const localizedName = localizedNames.of(code)
        if (localizedName !== undefined) {
          t.push(new Translation(lang, localizedName))
        }
      })
      if (t.length > 0) {
        MessageCollection.Global.setTranslations(OptionMap.country + PATH_SEPARATOR + code, t)
      }
    })

    MessageCollection.Global.setTranslations(OptionMap.sex_diverse, [new Translation('en', 'Diverse'), new Translation('de', 'Divers')])

    MessageCollection.Global.setTranslations(OptionMap.gender_identity_agender, [new Translation('en', 'Agender'), new Translation('de', 'weder noch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_androgyne, [new Translation('en', 'Androgyne'), new Translation('de', 'androgyn')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_androgynous, [new Translation('en', 'Androgynous'), new Translation('de', 'androgyner Mensch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_bigender, [new Translation('en', 'Bigender'), new Translation('de', 'Bigender')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cis, [new Translation('en', 'Cis'), new Translation('de', 'Cis')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cisgender, [new Translation('en', 'Cisgender'), new Translation('de', 'Cisgender')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cis_female, [new Translation('en', 'Cis Female'), new Translation('de', 'Cis Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cis_male, [new Translation('en', 'Cis Male'), new Translation('de', 'Cis Mann')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cis_man, [new Translation('en', 'Cis Man'), new Translation('de', 'Cis Mensch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cis_woman, [new Translation('en', 'Cis Woman'), new Translation('de', 'Cis Woman')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cisgender_female, [new Translation('en', 'Cisgender Female'), new Translation('de', 'Cisgender Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cisgender_male, [new Translation('en', 'Cisgender Male'), new Translation('de', 'Cisgender Mann')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cisgender_man, [new Translation('en', 'Cisgender Man'), new Translation('de', 'Cisgender Mensch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_cisgender_woman, [new Translation('en', 'Cisgender Woman'), new Translation('de', 'Cisgender Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_female_to_male, [new Translation('en', 'Female to Male'), new Translation('de', 'Frau zu Mann')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_ftm, [new Translation('en', 'Ftm'), new Translation('de', 'FzM')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_gender_fluid, [new Translation('en', 'Gender Fluid'), new Translation('de', 'Gender Fluid')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_gender_nonconforming, [new Translation('en', 'Gender Nonconforming'), new Translation('de', 'Gender Nonconforming')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_gender_questioning, [new Translation('en', 'Gender Questioning'), new Translation('de', 'Gender Questioning')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_gender_variant, [new Translation('en', 'Gender Variant'), new Translation('de', 'Gender Variant')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_genderqueer, [new Translation('en', 'Genderqueer'), new Translation('de', 'Genderqueer')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_intersex, [new Translation('en', 'Intersex'), new Translation('de', 'Intersex')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_male_to_female, [new Translation('en', 'Male to Female'), new Translation('de', 'Mann zu Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_mtf, [new Translation('en', 'Mtf'), new Translation('de', 'MzF')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_neither, [new Translation('en', 'Neither'), new Translation('de', 'Neither')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_neutrois, [new Translation('en', 'Neutrois'), new Translation('de', 'Neutrois')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_non_binary, [new Translation('en', 'Non-binary'), new Translation('de', 'Non-binary')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_other, [new Translation('en', 'Other'), new Translation('de', 'Andere')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_pangender, [new Translation('en', 'Pangender'), new Translation('de', 'Pangender')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans, [new Translation('en', 'Trans'), new Translation('de', 'Trans')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans_, [new Translation('en', 'Trans*'), new Translation('de', 'Trans*')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans_female, [new Translation('en', 'Trans Female'), new Translation('de', 'Trans Female')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans__female, [new Translation('en', 'Trans* Female'), new Translation('de', 'Trans* Female')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans_male, [new Translation('en', 'Trans Male'), new Translation('de', 'Trans Male')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans__male, [new Translation('en', 'Trans* Male'), new Translation('de', 'Trans* Male')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans_man, [new Translation('en', 'Trans Man'), new Translation('de', 'Trans Mensch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans__man, [new Translation('en', 'Trans* Man'), new Translation('de', 'Trans* Mensch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans_person, [new Translation('en', 'Trans Person'), new Translation('de', 'Trans Person')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans__person, [new Translation('en', 'Trans* Person'), new Translation('de', 'Trans* Person')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans_woman, [new Translation('en', 'Trans Woman'), new Translation('de', 'Trans Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_trans__woman, [new Translation('en', 'Trans* Woman'), new Translation('de', 'Trans* Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transfeminine, [new Translation('en', 'Transfeminine'), new Translation('de', 'Transfeminine')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transgender, [new Translation('en', 'Transgender'), new Translation('de', 'Transgender')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transgender_female, [new Translation('en', 'Transgender Female'), new Translation('de', 'Transgender Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transgender_male, [new Translation('en', 'Transgender Male'), new Translation('de', 'Transgender Mann')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transgender_man, [new Translation('en', 'Transgender Man'), new Translation('de', 'Transgender Mensch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transgender_person, [new Translation('en', 'Transgender Person'), new Translation('de', 'Transgender Person')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transgender_woman, [new Translation('en', 'Transgender Woman'), new Translation('de', 'Transgender Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transmasculine, [new Translation('en', 'Transmasculine'), new Translation('de', 'Transmasculine')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transsexual, [new Translation('en', 'Transsexual'), new Translation('de', 'Transsexual')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transsexual_female, [new Translation('en', 'Transsexual Female'), new Translation('de', 'Transsexual Frau')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transsexual_male, [new Translation('en', 'Transsexual Male'), new Translation('de', 'Transsexual Mann')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transsexual_man, [new Translation('en', 'Transsexual Man'), new Translation('de', 'Transsexual Mensch')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transsexual_person, [new Translation('en', 'Transsexual Person'), new Translation('de', 'Transsexual Person')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_transsexual_woman, [new Translation('en', 'Transsexual Woman'), new Translation('de', 'Transsexual Woman')])
    MessageCollection.Global.setTranslations(OptionMap.gender_identity_two_spirit, [new Translation('en', 'Two-Spirit'), new Translation('de', 'Two-Spirit')])

    MessageCollection.Global.setTranslations(OptionMap.pronoun_she, [new Translation('en', 'She/Her'), new Translation('de', 'Sie/Ihr')])
    MessageCollection.Global.setTranslations(OptionMap.pronoun_he, [new Translation('en', 'He/His'), new Translation('de', 'Er/Sein')])
    MessageCollection.Global.setTranslations(OptionMap.pronoun_they, [new Translation('en', 'They/Them'), new Translation('de', 'They/Them')])
    MessageCollection.Global.setTranslations(OptionMap.pronoun_name, [new Translation('en', 'Name'), new Translation('de', 'Vorname')])
    MessageCollection.Global.setTranslations(OptionMap.pronoun_ze, [new Translation('en', 'Ze/Zee'), new Translation('de', 'Ze/Zee')])

    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_a', [new Translation('en', 'B. A.'), new Translation('de', 'B. A.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'bba', [new Translation('en', 'BBA'), new Translation('de', 'BBA')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_ed', [new Translation('en', 'B. Ed.'), new Translation('de', 'B. Ed.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_eng', [new Translation('en', 'B. Eng.'), new Translation('de', 'B. Eng.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_f_a', [new Translation('en', 'B. F. A.'), new Translation('de', 'B. F. A.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'll_b', [new Translation('en', 'LL. B.'), new Translation('de', 'LL. B.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_mus', [new Translation('en', 'B. Mus.'), new Translation('de', 'B. Mus.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_m_a', [new Translation('en', 'B. M. A.'), new Translation('de', 'B. M. A.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_sc', [new Translation('en', 'B. Sc.'), new Translation('de', 'B. Sc.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'b_a', [new Translation('en', 'B. A.'), new Translation('de', 'B. A.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'bac_jur', [new Translation('en', 'Bac. Jur.'), new Translation('de', 'Bac. Jur.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'bac_oec', [new Translation('en', 'Bac. Oec.'), new Translation('de', 'Bac. Oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_a', [new Translation('en', 'M. A.'), new Translation('de', 'M. A.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_sc', [new Translation('en', 'M. Sc.'), new Translation('de', 'M. Sc.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_th', [new Translation('en', 'M. Th.'), new Translation('de', 'M. Th.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_acc', [new Translation('en', 'M. Acc.'), new Translation('de', 'M. Acc.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_a', [new Translation('en', 'M. A.'), new Translation('de', 'M. A.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_ed', [new Translation('en', 'M. Ed.'), new Translation('de', 'M. Ed.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_f_a', [new Translation('en', 'M. F. A.'), new Translation('de', 'M. F. A.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'll_m', [new Translation('en', 'LL. M.'), new Translation('de', 'LL. M.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_mus', [new Translation('en', 'M. Mus.'), new Translation('de', 'M. Mus.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'm_sc', [new Translation('en', 'M. Sc.'), new Translation('de', 'M. Sc.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr', [new Translation('en', 'Dr.'), new Translation('de', 'Dr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'prof', [new Translation('en', 'Prof.'), new Translation('de', 'Prof.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'ph_d_kunst_und_design', [new Translation('en', 'Ph. D. Kunst und Design'), new Translation('de', 'Ph. D. Kunst und Design')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'ph_d_freie_kunst', [new Translation('en', 'Ph. D. Freie Kunst'), new Translation('de', 'Ph. D. Freie Kunst')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'ph_d_medienkunst', [new Translation('en', 'Ph. D. Medienkunst'), new Translation('de', 'Ph. D. Medienkunst')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing', [new Translation('en', 'Dipl.-Ing.'), new Translation('de', 'Dipl.-Ing.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_b_a_d_b_a_business_administration', [new Translation('en', 'Dr. B.A./D.B.A. (Business Administration)'), new Translation('de', 'Dr. B.A./D.B.A. (Business Administration)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_iur_utr_iuris_utriusque', [new Translation('en', 'Dr. iur. utr. (iuris utriusque)'), new Translation('de', 'Dr. iur. utr. (iuris utriusque)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_agr_agriculturae', [new Translation('en', 'Dr. agr. (agriculturae)'), new Translation('de', 'Dr. agr. (agriculturae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_agr_scientiarum_agrarium', [new Translation('en', 'Dr. sc. agr. (scientiarum agrarium)'), new Translation('de', 'Dr. sc. agr. (scientiarum agrarium)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_mont_rerum_montanarum', [new Translation('en', 'Dr. rer. mont. (rerum montanarum)'), new Translation('de', 'Dr. rer. mont. (rerum montanarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_nat_techn_naturalium_technicarum', [new Translation('en', 'Dr. nat. techn. (naturalium technicarum)'), new Translation('de', 'Dr. nat. techn. (naturalium technicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_ed_scientiarum_educationis', [new Translation('en', 'Dr. sc. ed. (scientiarum educationis)'), new Translation('de', 'Dr. sc. ed. (scientiarum educationis)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_troph_trophologiae', [new Translation('en', 'Dr. troph. (trophologiae)'), new Translation('de', 'Dr. troph. (trophologiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_oec_troph_oecotrophologiae', [new Translation('en', 'Dr. oec. troph. (oecotrophologiae)'), new Translation('de', 'Dr. oec. troph. (oecotrophologiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_paed_paedagogiae', [new Translation('en', 'Dr. paed. (paedagogiae)'), new Translation('de', 'Dr. paed. (paedagogiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_silv_rerum_silvestrium_bzw_rerum_silvaticarum', [new Translation('en', 'Dr. rer. silv. (rerum silvestrium bzw. rerum silvaticarum)'), new Translation('de', 'Dr. rer. silv. (rerum silvestrium bzw. rerum silvaticarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_forest_rerum_forestalium', [new Translation('en', 'Dr. rer. forest. (rerum forestalium)'), new Translation('de', 'Dr. rer. forest. (rerum forestalium)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_hort_rerum_horticulturarum', [new Translation('en', 'Dr. rer. hort. (rerum horticulturarum)'), new Translation('de', 'Dr. rer. hort. (rerum horticulturarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_merc_rerum_mercantilium', [new Translation('en', 'Dr. rer. merc. (rerum mercantilium)'), new Translation('de', 'Dr. rer. merc. (rerum mercantilium)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_biol_hom_biologiae_hominis', [new Translation('en', 'Dr. biol. hom. (biologiae hominis)'), new Translation('de', 'Dr. biol. hom. (biologiae hominis)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_biol_hum_rerum_biologiae_humanae', [new Translation('en', 'Dr. rer. biol. hum. (rerum biologiae humanae)'), new Translation('de', 'Dr. rer. biol. hum. (rerum biologiae humanae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_physiol_rerum_physiologicarum', [new Translation('en', 'Dr. rer. physiol. (rerum physiologicarum)'), new Translation('de', 'Dr. rer. physiol. (rerum physiologicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_hum_scientiarum_humanarum', [new Translation('en', 'Dr. sc. hum. (scientiarum humanarum)'), new Translation('de', 'Dr. sc. hum. (scientiarum humanarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_ing_doktoringenieur', [new Translation('en', 'Dr.-Ing. (Doktoringenieur)'), new Translation('de', 'Dr.-Ing. (Doktoringenieur)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_iur_can_iuris_canonici', [new Translation('en', 'Dr. iur. can. (iuris canonici)'), new Translation('de', 'Dr. iur. can. (iuris canonici)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_cult_culturae', [new Translation('en', 'Dr. cult. (culturae)'), new Translation('de', 'Dr. cult. (culturae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_cult_rerum_culturarum', [new Translation('en', 'Dr. rer. cult. (rerum culturarum)'), new Translation('de', 'Dr. rer. cult. (rerum culturarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_agr_rerum_agriculturarum', [new Translation('en', 'Dr. rer. agr. (rerum agriculturarum)'), new Translation('de', 'Dr. rer. agr. (rerum agriculturarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_math_mathematicae', [new Translation('en', 'Dr. math. (mathematicae)'), new Translation('de', 'Dr. math. (mathematicae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_med_medicinae', [new Translation('en', 'Dr. med. (medicinae)'), new Translation('de', 'Dr. med. (medicinae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_med_sci_medicinae_scientiae', [new Translation('en', 'Dr. med. sci. (medicinae scientiae)'), new Translation('de', 'Dr. med. sci. (medicinae scientiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_hum_rerum_humanarum', [new Translation('en', 'Dr. rer. hum. (rerum humanarum)'), new Translation('de', 'Dr. rer. hum. (rerum humanarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_med_rerum_medicarum', [new Translation('en', 'Dr. rer. med. (rerum medicarum)'), new Translation('de', 'Dr. rer. med. (rerum medicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_mus_scientiae_musicae', [new Translation('en', 'Dr. sc. mus. (scientiae musicae)'), new Translation('de', 'Dr. sc. mus. (scientiae musicae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_sust_rerum_sustineri', [new Translation('en', 'Dr. rer. sust. (rerum sustineri)'), new Translation('de', 'Dr. rer. sust. (rerum sustineri)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_phil_nat_philosophiae_naturalis', [new Translation('en', 'Dr. phil. nat. (philosophiae naturalis)'), new Translation('de', 'Dr. phil. nat. (philosophiae naturalis)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_nat_rerum_naturalium', [new Translation('en', 'Dr. rer. nat. (rerum naturalium)'), new Translation('de', 'Dr. rer. nat. (rerum naturalium)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_nat_med_naturalium_medicinae', [new Translation('en', 'Dr. nat. med. (naturalium medicinae)'), new Translation('de', 'Dr. nat. med. (naturalium medicinae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_cur_rerum_curae', [new Translation('en', 'Dr. rer. cur. (rerum curae)'), new Translation('de', 'Dr. rer. cur. (rerum curae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_pharm_pharmaciae', [new Translation('en', 'Dr. pharm. (pharmaciae)'), new Translation('de', 'Dr. pharm. (pharmaciae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_phil_in_art_philosophiae_in_artibus', [new Translation('en', 'Dr. phil. in art. (philosophiae in artibus)'), new Translation('de', 'Dr. phil. in art. (philosophiae in artibus)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_phil_philosophiae', [new Translation('en', 'Dr. phil. (philosophiae)'), new Translation('de', 'Dr. phil. (philosophiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_phil_scientiarum_philosophiae', [new Translation('en', 'Dr. sc. phil. (scientiarum philosophiae)'), new Translation('de', 'Dr. sc. phil. (scientiarum philosophiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_ph_public_health', [new Translation('en', 'Dr. PH (public health)'), new Translation('de', 'Dr. PH (public health)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_iur_iuris', [new Translation('en', 'Dr. iur. (iuris)'), new Translation('de', 'Dr. iur. (iuris)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_iur_et_rer_pol_iuris_et_rerum_politicarum', [new Translation('en', 'Dr. iur. et rer. pol. (iuris et rerum politicarum)'), new Translation('de', 'Dr. iur. et rer. pol. (iuris et rerum politicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_sec_rerum_securitatis', [new Translation('en', 'Dr. rer. sec. (rerum securitatis)'), new Translation('de', 'Dr. rer. sec. (rerum securitatis)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_soc_rerum_socialium', [new Translation('en', 'Dr. rer. soc. (rerum socialium)'), new Translation('de', 'Dr. rer. soc. (rerum socialium)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_soc_scientiae_socialis', [new Translation('en', 'Dr. sc. soc. (scientiae socialis)'), new Translation('de', 'Dr. sc. soc. (scientiae socialis)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_disc_pol_disciplinarum_politicarum', [new Translation('en', 'Dr. disc. pol. (disciplinarum politicarum)'), new Translation('de', 'Dr. disc. pol. (disciplinarum politicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sportwiss_sportwissenschaften', [new Translation('en', 'Dr. Sportwiss. (Sportwissenschaften)'), new Translation('de', 'Dr. Sportwiss. (Sportwissenschaften)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_oec_publ_oeconomiae_publicae', [new Translation('en', 'Dr. oec. publ. (oeconomiae publicae)'), new Translation('de', 'Dr. oec. publ. (oeconomiae publicae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_tech_rerum_technicarium', [new Translation('en', 'Dr. rer. tech. (rerum technicarium)'), new Translation('de', 'Dr. rer. tech. (rerum technicarium)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_techn_scientiarum_technicarum', [new Translation('en', 'Dr. sc. techn. (scientiarum technicarum)'), new Translation('de', 'Dr. sc. techn. (scientiarum technicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_theol_theologiae', [new Translation('en', 'Dr. theol. (theologiae)'), new Translation('de', 'Dr. theol. (theologiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_medic_rerum_medicinalium', [new Translation('en', 'Dr. rer. medic. (rerum medicinalium)'), new Translation('de', 'Dr. rer. medic. (rerum medicinalium)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_med_vet_medicinae_veterinariae', [new Translation('en', 'Dr. med. vet. (medicinae veterinariae)'), new Translation('de', 'Dr. med. vet. (medicinae veterinariae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_biol_anim_biologiae_animalis', [new Translation('en', 'Dr. biol. anim. (biologiae animalis)'), new Translation('de', 'Dr. biol. anim. (biologiae animalis)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_publ_rerum_publicarum', [new Translation('en', 'Dr. rer. publ. (rerum publicarum)'), new Translation('de', 'Dr. rer. publ. (rerum publicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_biol_vet_rerum_biologiae_veterinariae', [new Translation('en', 'Dr. rer. biol. vet. (rerum biologiae veterinariae)'), new Translation('de', 'Dr. rer. biol. vet. (rerum biologiae veterinariae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_pol_scientiarum_politicarum', [new Translation('en', 'Dr. sc. pol. (scientiarum politicarum)'), new Translation('de', 'Dr. sc. pol. (scientiarum politicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_oec_oeconomiae', [new Translation('en', 'Dr. oec. (oeconomiae)'), new Translation('de', 'Dr. oec. (oeconomiae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_rer_oec_rerum_oeconomicarum', [new Translation('en', 'Dr. rer. oec. (rerum oeconomicarum)'), new Translation('de', 'Dr. rer. oec. (rerum oeconomicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_sc_oec_scientiarum_oeconomicarum', [new Translation('en', 'Dr. sc. oec. (scientiarum oeconomicarum)'), new Translation('de', 'Dr. sc. oec. (scientiarum oeconomicarum)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dr_med_dent_medicinae_dentariae', [new Translation('en', 'Dr. med. dent. (medicinae dentariae)'), new Translation('de', 'Dr. med. dent. (medicinae dentariae)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'prof_dr', [new Translation('en', 'Prof. Dr.'), new Translation('de', 'Prof. Dr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'prof_dr_dr', [new Translation('en', 'Prof. Dr. Dr.'), new Translation('de', 'Prof. Dr. Dr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inf_wirt_fh', [new Translation('en', 'Dipl.-Inf.-Wirt (FH)'), new Translation('de', 'Dipl.-Inf.-Wirt (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kunstpaedagogik', [new Translation('en', 'Dipl. Kunstpädagogik'), new Translation('de', 'Dipl. Kunstpädagogik')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_freie_kunst', [new Translation('en', 'Dipl. Freie Kunst'), new Translation('de', 'Dipl. Freie Kunst')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_gdfs', [new Translation('en', 'Dipl. GDFS'), new Translation('de', 'Dipl. GDFS')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_vis_komm', [new Translation('en', 'Dipl. Vis. Komm.'), new Translation('de', 'Dipl. Vis. Komm.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_audiovisuelle_medien', [new Translation('en', 'Dipl. Audiovisuelle Medien'), new Translation('de', 'Dipl. Audiovisuelle Medien')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kunstpaedagogik_kunstvermittlung', [new Translation('en', 'Dipl. Kunstpädagogik/Kunstvermittlung'), new Translation('de', 'Dipl. Kunstpädagogik/Kunstvermittlung')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mus', [new Translation('en', 'Dipl. Mus.'), new Translation('de', 'Dipl. Mus.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_agr_biol', [new Translation('en', 'Dipl.agr.biol.'), new Translation('de', 'Dipl.agr.biol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_agr', [new Translation('en', 'Dipl.-Ing. agr.'), new Translation('de', 'Dipl.-Ing. agr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inf_fh', [new Translation('en', 'Dipl.-Inf. (FH)'), new Translation('de', 'Dipl.-Inf. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_agrar_ing_fh', [new Translation('en', 'Dipl.-Agrar Ing. (FH)'), new Translation('de', 'Dipl.-Agrar Ing. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_agr_oec', [new Translation('en', 'Dipl.agr.oec.'), new Translation('de', 'Dipl.agr.oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_angl', [new Translation('en', 'Dipl.-Angl.'), new Translation('de', 'Dipl.-Angl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_anim', [new Translation('en', 'Dipl.-Anim.'), new Translation('de', 'Dipl.-Anim.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_arch', [new Translation('en', 'Dipl.-Arch.'), new Translation('de', 'Dipl.-Arch.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_arch', [new Translation('en', 'Dipl.-Arch.'), new Translation('de', 'Dipl.-Arch.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_archivar_fh', [new Translation('en', 'Dipl.-Archivar (FH)'), new Translation('de', 'Dipl.-Archivar (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sc_pol_univ', [new Translation('en', 'Dipl. sc. pol. Univ.'), new Translation('de', 'Dipl. sc. pol. Univ.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rer_pol', [new Translation('en', 'Dipl. rer. pol.'), new Translation('de', 'Dipl. rer. pol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_berufspaed', [new Translation('en', 'Dipl.-Berufspäd.'), new Translation('de', 'Dipl.-Berufspäd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_betriebsw', [new Translation('en', 'Dipl.-Betriebsw.'), new Translation('de', 'Dipl.-Betriebsw.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_betriebsw_dh', [new Translation('en', 'Dipl.-Betriebsw. (DH)'), new Translation('de', 'Dipl.-Betriebsw. (DH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_betriebsw_fh', [new Translation('en', 'Dipl.-Betriebsw. (FH)'), new Translation('de', 'Dipl.-Betriebsw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_bibl', [new Translation('en', 'Dipl.-Bibl.'), new Translation('de', 'Dipl.-Bibl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_bibl_fh', [new Translation('en', 'Dipl.-Bibl. (FH)'), new Translation('de', 'Dipl.-Bibl. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_biochem', [new Translation('en', 'Dipl.-Biochem.'), new Translation('de', 'Dipl.-Biochem.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_biogeogr', [new Translation('en', 'Dipl.-Biogeogr.'), new Translation('de', 'Dipl.-Biogeogr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_bioinf', [new Translation('en', 'Dipl.-Bioinf.'), new Translation('de', 'Dipl.-Bioinf.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_bioinf_fh', [new Translation('en', 'Dipl.-Bioinf. (FH)'), new Translation('de', 'Dipl.-Bioinf. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_bioing_fh', [new Translation('en', 'Dipl.-Bioing. (FH)'), new Translation('de', 'Dipl.-Bioing. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_biol', [new Translation('en', 'Dipl.-Biol.'), new Translation('de', 'Dipl.-Biol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_biol_t_o_', [new Translation('en', 'Dipl.-Biol. (t.o.)'), new Translation('de', 'Dipl.-Biol. (t.o.)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_biomath_fh', [new Translation('en', 'Dipl.-Biomath. (FH)'), new Translation('de', 'Dipl.-Biomath. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_biophys', [new Translation('en', 'Dipl.-Biophys.'), new Translation('de', 'Dipl.-Biophys.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_biotechnol', [new Translation('en', 'Dipl.-Biotechnol.'), new Translation('de', 'Dipl.-Biotechnol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_braumeister', [new Translation('en', 'Dipl.-Braumeister'), new Translation('de', 'Dipl.-Braumeister')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_chem_ing', [new Translation('en', 'Dipl.-Chem.-Ing.'), new Translation('de', 'Dipl.-Chem.-Ing.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_chem', [new Translation('en', 'Dipl.-Chem.'), new Translation('de', 'Dipl.-Chem.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_chem_fh', [new Translation('en', 'Dipl.-Chem. (FH)'), new Translation('de', 'Dipl.-Chem. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_comp_math', [new Translation('en', 'Dipl.-Comp.-Math.'), new Translation('de', 'Dipl.-Comp.-Math.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_demogr', [new Translation('en', 'Dipl.-Demogr.'), new Translation('de', 'Dipl.-Demogr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_des', [new Translation('en', 'Dipl.-Des.'), new Translation('de', 'Dipl.-Des.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_des', [new Translation('en', 'Dipl.-Des.'), new Translation('de', 'Dipl.-Des.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_des_fh', [new Translation('en', 'Dipl.-Des. (FH)'), new Translation('de', 'Dipl.-Des. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_dok_fh', [new Translation('en', 'Dipl.-Dok. (FH)'), new Translation('de', 'Dipl.-Dok. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_dolm', [new Translation('en', 'Dipl.-Dolm.'), new Translation('de', 'Dipl.-Dolm.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_dolm_fh', [new Translation('en', 'Dipl.-Dolm. (FH)'), new Translation('de', 'Dipl.-Dolm. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_dram', [new Translation('en', 'Dipl.-Dram.'), new Translation('de', 'Dipl.-Dram.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_energiewirt_fh', [new Translation('en', 'Dipl.-Energiewirt (FH)'), new Translation('de', 'Dipl.-Energiewirt (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ergotherapeut_fh', [new Translation('en', 'Dipl.-Ergotherapeut (FH)'), new Translation('de', 'Dipl.-Ergotherapeut (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_fachuebersetzer_fh', [new Translation('en', 'Dipl.-Fachübersetzer (FH)'), new Translation('de', 'Dipl.-Fachübersetzer (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_film_u_fernsehdram', [new Translation('en', 'Dipl.-Film- u. Fernsehdram.'), new Translation('de', 'Dipl.-Film- u. Fernsehdram.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_film_u_fernsehwirt', [new Translation('en', 'Dipl.-Film- u. Fernsehwirt.'), new Translation('de', 'Dipl.-Film- u. Fernsehwirt.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_filmkomp', [new Translation('en', 'Dipl.-Filmkomp.'), new Translation('de', 'Dipl.-Filmkomp.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_finw_fh', [new Translation('en', 'Dipl.-Finw. (FH)'), new Translation('de', 'Dipl.-Finw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_foe_fh', [new Translation('en', 'Dipl. FÖ (FH)'), new Translation('de', 'Dipl. FÖ (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_silv', [new Translation('en', 'Dipl.-Ing. silv.'), new Translation('de', 'Dipl.-Ing. silv.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_forsting_fh', [new Translation('en', 'Dipl.-Forsting. (FH)'), new Translation('de', 'Dipl.-Forsting. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_forstw', [new Translation('en', 'Dipl.-Forstw.'), new Translation('de', 'Dipl.-Forstw.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_freizeitwiss_fh', [new Translation('en', 'Dipl.-Freizeitwiss. (FH)'), new Translation('de', 'Dipl.-Freizeitwiss. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_gartenbau_fh', [new Translation('en', 'Dipl.-Ing. Gartenbau (FH)'), new Translation('de', 'Dipl.-Ing. Gartenbau (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_geogr', [new Translation('en', 'Dipl.-Geogr.'), new Translation('de', 'Dipl.-Geogr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_geoinf', [new Translation('en', 'Dipl.-Geoinf.'), new Translation('de', 'Dipl.-Geoinf.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_geol', [new Translation('en', 'Dipl.-Geol.'), new Translation('de', 'Dipl.-Geol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_geooekol', [new Translation('en', 'Dipl.-Geoökol.'), new Translation('de', 'Dipl.-Geoökol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_geophys', [new Translation('en', 'Dipl.-Geophys.'), new Translation('de', 'Dipl.-Geophys.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_germ', [new Translation('en', 'Dipl.-Germ.'), new Translation('de', 'Dipl.-Germ.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_geront', [new Translation('en', 'Dipl.-Geront.'), new Translation('de', 'Dipl.-Geront.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ghl', [new Translation('en', 'Dipl.-Ghl.'), new Translation('de', 'Dipl.-Ghl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ges_oec', [new Translation('en', 'Dipl.-Ges.oec.'), new Translation('de', 'Dipl.-Ges.oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ges_oec_fh', [new Translation('en', 'Dipl.-Ges.oec. (FH)'), new Translation('de', 'Dipl.-Ges.oec. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_gwl', [new Translation('en', 'Dipl.-Gwl.'), new Translation('de', 'Dipl.-Gwl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_gyml', [new Translation('en', 'Dipl.-Gyml.'), new Translation('de', 'Dipl.-Gyml.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_hdl', [new Translation('en', 'Dipl.-Hdl.'), new Translation('de', 'Dipl.-Hdl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_heilpaed', [new Translation('en', 'Dipl.-Heilpäd.'), new Translation('de', 'Dipl.-Heilpäd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_hist', [new Translation('en', 'Dipl.-Hist.'), new Translation('de', 'Dipl.-Hist.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_holzwirt', [new Translation('en', 'Dipl.-Holzwirt'), new Translation('de', 'Dipl.-Holzwirt')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_human_biol', [new Translation('en', 'Dipl. human. biol.'), new Translation('de', 'Dipl. human. biol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_humanbiologe', [new Translation('en', 'Dipl.-Humanbiologe'), new Translation('de', 'Dipl.-Humanbiologe')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_hydrol', [new Translation('en', 'Dipl.-Hydrol.'), new Translation('de', 'Dipl.-Hydrol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_immobilienw_fh', [new Translation('en', 'Dipl.-Immobilienw. (FH)'), new Translation('de', 'Dipl.-Immobilienw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ind_arch', [new Translation('en', 'Dipl.-Ind. Arch.'), new Translation('de', 'Dipl.-Ind. Arch.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inf_oder_dipl_inform', [new Translation('en', 'Dipl.-Inf. oder Dipl.-Inform.'), new Translation('de', 'Dipl.-Inf. oder Dipl.-Inform.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inf_dh', [new Translation('en', 'Dipl.-Inf. (DH)'), new Translation('de', 'Dipl.-Inf. (DH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inform_fh', [new Translation('en', 'Dipl.-Inform. (FH)'), new Translation('de', 'Dipl.-Inform. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inform_med', [new Translation('en', 'Dipl.-Inform. Med.'), new Translation('de', 'Dipl.-Inform. Med.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inf_wirt_inform_wirt', [new Translation('en', 'Dipl.-Inf.Wirt/Inform.Wirt'), new Translation('de', 'Dipl.-Inf.Wirt/Inform.Wirt')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_infw_fh', [new Translation('en', 'Dipl.-Infw. (FH)'), new Translation('de', 'Dipl.-Infw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_inf_wiss', [new Translation('en', 'Dipl.-Inf.wiss'), new Translation('de', 'Dipl.-Inf.wiss')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_dh', [new Translation('en', 'Dipl.-Ing. (DH)'), new Translation('de', 'Dipl.-Ing. (DH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_fh', [new Translation('en', 'Dipl.-Ing. (FH)'), new Translation('de', 'Dipl.-Ing. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_inf', [new Translation('en', 'Dipl.-Ing.-Inf.'), new Translation('de', 'Dipl.-Ing.-Inf.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_oec', [new Translation('en', 'Dipl.-Ing. oec.'), new Translation('de', 'Dipl.-Ing. oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ing_paed', [new Translation('en', 'Dipl.-Ing.-Päd.'), new Translation('de', 'Dipl.-Ing.-Päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_journ', [new Translation('en', 'Dipl.-Journ.'), new Translation('de', 'Dipl.-Journ.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_journ_fh', [new Translation('en', 'Dipl.-Journ. (FH)'), new Translation('de', 'Dipl.-Journ. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_jur_oder_dipl_jur', [new Translation('en', 'Dipl.-jur. oder Dipl.-Jur.'), new Translation('de', 'Dipl.-jur. oder Dipl.-Jur.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kam', [new Translation('en', 'Dipl.-Kam.'), new Translation('de', 'Dipl.-Kam.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kffr_fh', [new Translation('en', 'Dipl.-Kffr. (FH)'), new Translation('de', 'Dipl.-Kffr. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kff_oder_dipl_kffr', [new Translation('en', 'Dipl.-Kff. oder Dipl.-Kffr.'), new Translation('de', 'Dipl.-Kff. oder Dipl.-Kffr.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kfm', [new Translation('en', 'Dipl.-Kfm.'), new Translation('de', 'Dipl.-Kfm.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kfm_fh', [new Translation('en', 'Dipl.-Kfm. (FH)'), new Translation('de', 'Dipl.-Kfm. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kfm_t_o', [new Translation('en', 'Dipl.-Kfm. t.o.'), new Translation('de', 'Dipl.-Kfm. t.o.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_komm_psych_fh', [new Translation('en', 'Dipl.-Komm.Psych. (FH)'), new Translation('de', 'Dipl.-Komm.Psych. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_komm_wirt', [new Translation('en', 'Dipl.-Komm.-Wirt'), new Translation('de', 'Dipl.-Komm.-Wirt')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rer_com', [new Translation('en', 'Dipl. rer. com.'), new Translation('de', 'Dipl. rer. com.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_krim', [new Translation('en', 'Dipl.-Krim.'), new Translation('de', 'Dipl.-Krim.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_krim', [new Translation('en', 'Dipl.-Krim.'), new Translation('de', 'Dipl.-Krim.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kult_arb_fh', [new Translation('en', 'Dipl.-Kult.arb. (FH)'), new Translation('de', 'Dipl.-Kult.arb. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kult_man', [new Translation('en', 'Dipl.-Kult.Man.'), new Translation('de', 'Dipl.-Kult.Man.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kult_paed', [new Translation('en', 'Dipl.-Kult.Päd.'), new Translation('de', 'Dipl.-Kult.Päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kulturwirt', [new Translation('en', 'Dipl.-Kulturwirt'), new Translation('de', 'Dipl.-Kulturwirt')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kult', [new Translation('en', 'Dipl.-Kult.'), new Translation('de', 'Dipl.-Kult.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kuenstler', [new Translation('en', 'Dipl.-Künstler'), new Translation('de', 'Dipl.-Künstler')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kuenstler_fh', [new Translation('en', 'Dipl.-Künstler (FH)'), new Translation('de', 'Dipl.-Künstler (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kunst_paed', [new Translation('en', 'Dipl.-Kunst.-Päd.'), new Translation('de', 'Dipl.-Kunst.-Päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kunstpaedagoge', [new Translation('en', 'Dipl.-Kunstpädagoge'), new Translation('de', 'Dipl.-Kunstpädagoge')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_kunsttherap_fh', [new Translation('en', 'Dipl.-Kunsttherap. (FH)'), new Translation('de', 'Dipl.-Kunsttherap. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_landsch_oekol', [new Translation('en', 'Dipl.-Landsch.-ökol.'), new Translation('de', 'Dipl.-Landsch.-ökol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_lebensmittelchem', [new Translation('en', 'Dipl.-Lebensmittelchem.'), new Translation('de', 'Dipl.-Lebensmittelchem.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_lmchem', [new Translation('en', 'Dipl.-LMChem.'), new Translation('de', 'Dipl.-LMChem.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_lm_ing', [new Translation('en', 'Dipl.-LM-Ing.'), new Translation('de', 'Dipl.-LM-Ing.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_lehrer', [new Translation('en', 'Dipl.-Lehrer'), new Translation('de', 'Dipl.-Lehrer')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_ling', [new Translation('en', 'Dipl.-Ling.'), new Translation('de', 'Dipl.-Ling.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_logist', [new Translation('en', 'Dipl.-Logist.'), new Translation('de', 'Dipl.-Logist.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_logist_fh', [new Translation('en', 'Dipl.-Logist. (FH)'), new Translation('de', 'Dipl.-Logist. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_math', [new Translation('en', 'Dipl.-Math.'), new Translation('de', 'Dipl.-Math.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_math_fh', [new Translation('en', 'Dipl.-Math. (FH)'), new Translation('de', 'Dipl.-Math. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mediator_fh', [new Translation('en', 'Dipl.-Mediator (FH)'), new Translation('de', 'Dipl.-Mediator (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medienberater', [new Translation('en', 'Dipl.-Medienberater'), new Translation('de', 'Dipl.-Medienberater')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mediengestalter', [new Translation('en', 'Dipl.-Mediengestalter'), new Translation('de', 'Dipl.-Mediengestalter')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medieninf', [new Translation('en', 'Dipl.-Medieninf.'), new Translation('de', 'Dipl.-Medieninf.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medieninform_fh', [new Translation('en', 'Dipl.-Medieninform. (FH)'), new Translation('de', 'Dipl.-Medieninform. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medienoek_fh', [new Translation('en', 'Dipl.-Medienök. (FH)'), new Translation('de', 'Dipl.-Medienök. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medienprakt', [new Translation('en', 'Dipl.-Medienprakt.'), new Translation('de', 'Dipl.-Medienprakt.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medienw_fh', [new Translation('en', 'Dipl.-Medienw. (FH)'), new Translation('de', 'Dipl.-Medienw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medienwirt', [new Translation('en', 'Dipl.-Medienwirt'), new Translation('de', 'Dipl.-Medienwirt')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_medienwiss', [new Translation('en', 'Dipl.-Medienwiss.'), new Translation('de', 'Dipl.-Medienwiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_med', [new Translation('en', 'Dipl.-Med.'), new Translation('de', 'Dipl.-Med.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_med_paed', [new Translation('en', 'Dipl.-Med. Päd.'), new Translation('de', 'Dipl.-Med. Päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_phys_med_', [new Translation('en', 'Dipl.-Phys. (Med.)'), new Translation('de', 'Dipl.-Phys. (Med.)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_met', [new Translation('en', 'Dipl.-Met.'), new Translation('de', 'Dipl.-Met.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mikrobiol', [new Translation('en', 'Dipl.-Mikrobiol.'), new Translation('de', 'Dipl.-Mikrobiol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mil', [new Translation('en', 'Dipl.-Mil.'), new Translation('de', 'Dipl.-Mil.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_min', [new Translation('en', 'Dipl.-Min.'), new Translation('de', 'Dipl.-Min.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mol_med', [new Translation('en', 'Dipl.-Mol.Med.'), new Translation('de', 'Dipl.-Mol.Med.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mot', [new Translation('en', 'Dipl.-Mot.'), new Translation('de', 'Dipl.-Mot.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_museol_fh', [new Translation('en', 'Dipl.-Museol. (FH)'), new Translation('de', 'Dipl.-Museol. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_musikl', [new Translation('en', 'Dipl.-Musikl.'), new Translation('de', 'Dipl.-Musikl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_mus_paed', [new Translation('en', 'Dipl.-Mus.-Päd.'), new Translation('de', 'Dipl.-Mus.-Päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_musiktherap_fh', [new Translation('en', 'Dipl.-Musiktherap. (FH)'), new Translation('de', 'Dipl.-Musiktherap. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_nanosc', [new Translation('en', 'Dipl.-NanoSc.'), new Translation('de', 'Dipl.-NanoSc.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_nat', [new Translation('en', 'Dipl.-Nat.'), new Translation('de', 'Dipl.-Nat.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_hist_sc', [new Translation('en', 'Dipl.-Hist.Sc'), new Translation('de', 'Dipl.-Hist.Sc')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_naut_fh', [new Translation('en', 'Dipl.-Naut. (FH)'), new Translation('de', 'Dipl.-Naut. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_neurowiss', [new Translation('en', 'Dipl.-Neurowiss.'), new Translation('de', 'Dipl.-Neurowiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_oec_troph_fh', [new Translation('en', 'Dipl.-oec.-troph. (FH)'), new Translation('de', 'Dipl.-oec.-troph. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_oen', [new Translation('en', 'Dipl.-oen.'), new Translation('de', 'Dipl.-oen.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_oec', [new Translation('en', 'Dipl.-Oec.'), new Translation('de', 'Dipl.-Oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_oek', [new Translation('en', 'Dipl.-Ök.'), new Translation('de', 'Dipl.-Ök.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_oec_troph', [new Translation('en', 'Dipl.oec.troph.'), new Translation('de', 'Dipl.oec.troph.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_oec_troph_fh', [new Translation('en', 'Dipl.oec.troph (FH)'), new Translation('de', 'Dipl.oec.troph (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_online_journalist_fh', [new Translation('en', 'Dipl.-Online-Journalist (FH)'), new Translation('de', 'Dipl.-Online-Journalist (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_orient', [new Translation('en', 'Dipl.-Orient.'), new Translation('de', 'Dipl.-Orient.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_orientarchaeologe', [new Translation('en', 'Dipl.-Orientarchäologe'), new Translation('de', 'Dipl.-Orientarchäologe')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_oz', [new Translation('en', 'Dipl.-Oz.'), new Translation('de', 'Dipl.-Oz.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_paed', [new Translation('en', 'Dipl.-Päd.'), new Translation('de', 'Dipl.-Päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pgw', [new Translation('en', 'Dipl.-PGW'), new Translation('de', 'Dipl.-PGW')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pfl', [new Translation('en', 'Dipl.-Pfl.'), new Translation('de', 'Dipl.-Pfl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pflegepaed_fh', [new Translation('en', 'Dipl.-Pflegepäd. (FH)'), new Translation('de', 'Dipl.-Pflegepäd. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pfl_fh', [new Translation('en', 'Dipl.-Pfl. (FH)'), new Translation('de', 'Dipl.-Pfl. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pflegew_fh', [new Translation('en', 'Dipl.-Pflegew. (FH)'), new Translation('de', 'Dipl.-Pflegew. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pharm', [new Translation('en', 'Dipl.-Pharm.'), new Translation('de', 'Dipl.-Pharm.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_phil', [new Translation('en', 'Dipl.-Phil.'), new Translation('de', 'Dipl.-Phil.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_phys', [new Translation('en', 'Dipl.-Phys.'), new Translation('de', 'Dipl.-Phys.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_phys_ing', [new Translation('en', 'Dipl.-Phys.Ing.'), new Translation('de', 'Dipl.-Phys.Ing.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_physiotherapeut_fh', [new Translation('en', 'Dipl.-Physiotherapeut (FH)'), new Translation('de', 'Dipl.-Physiotherapeut (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pol', [new Translation('en', 'Dipl.-Pol.'), new Translation('de', 'Dipl.-Pol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_pol_fh', [new Translation('en', 'Dipl.-Pol. (FH)'), new Translation('de', 'Dipl.-Pol. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_praehist', [new Translation('en', 'Dipl.-Prähist.'), new Translation('de', 'Dipl.-Prähist.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_psych', [new Translation('en', 'Dipl.-Psych.'), new Translation('de', 'Dipl.-Psych.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rpfl_fh', [new Translation('en', 'Dipl.-Rpfl. (FH)'), new Translation('de', 'Dipl.-Rpfl. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_red_fh', [new Translation('en', 'Dipl.-Red. (FH)'), new Translation('de', 'Dipl.-Red. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_reg_wiss', [new Translation('en', 'Dipl.-Reg.-Wiss.'), new Translation('de', 'Dipl.-Reg.-Wiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_reg', [new Translation('en', 'Dipl.-Reg.'), new Translation('de', 'Dipl.-Reg.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_reh_paed', [new Translation('en', 'Dipl.-Reh.-Päd.'), new Translation('de', 'Dipl.-Reh.-Päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_reha_psych_fh', [new Translation('en', 'Dipl.-Reha-psych. (FH)'), new Translation('de', 'Dipl.-Reha-psych. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rel_paed_fh', [new Translation('en', 'Dipl.-Rel.-päd. (FH)'), new Translation('de', 'Dipl.-Rel.-päd. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rest', [new Translation('en', 'Dipl.-Rest.'), new Translation('de', 'Dipl.-Rest.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rest_fh', [new Translation('en', 'Dipl.-Rest. (FH)'), new Translation('de', 'Dipl.-Rest. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rom', [new Translation('en', 'Dipl.-Rom.'), new Translation('de', 'Dipl.-Rom.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_schau', [new Translation('en', 'Dipl.-Schau.'), new Translation('de', 'Dipl.-Schau.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_schnittm', [new Translation('en', 'Dipl.-Schnittm.'), new Translation('de', 'Dipl.-Schnittm.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sicherheits_ing', [new Translation('en', 'Dipl.-Sicherheits.-Ing.'), new Translation('de', 'Dipl.-Sicherheits.-Ing.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sicherheitsing_fh', [new Translation('en', 'Dipl.-Sicherheitsing. (FH)'), new Translation('de', 'Dipl.-Sicherheitsing. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_arb_fh', [new Translation('en', 'Dipl.-Soz. Arb. (FH)'), new Translation('de', 'Dipl.-Soz. Arb. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_jur_fh', [new Translation('en', 'Dipl.-Soz.jur. (FH)'), new Translation('de', 'Dipl.-Soz.jur. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_oek', [new Translation('en', 'Dipl.-Soz.Ök.'), new Translation('de', 'Dipl.-Soz.Ök.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_paed', [new Translation('en', 'Dipl.-Soz.päd.'), new Translation('de', 'Dipl.-Soz.päd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_paed_dh', [new Translation('en', 'Dipl.-Soz.päd. (DH)'), new Translation('de', 'Dipl.-Soz.päd. (DH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_paed_fh', [new Translation('en', 'Dipl.-Soz.päd. (FH)'), new Translation('de', 'Dipl.-Soz.päd. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sozw', [new Translation('en', 'Dipl.-Sozw.'), new Translation('de', 'Dipl.-Sozw.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_wirt_fh', [new Translation('en', 'Dipl.-Soz.wirt (FH)'), new Translation('de', 'Dipl.-Soz.wirt (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sozialw_oder_dipl_soz_wiss', [new Translation('en', 'Dipl.-Sozialw. oder Dipl.-Soz.Wiss.'), new Translation('de', 'Dipl.-Sozialw. oder Dipl.-Soz.Wiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz', [new Translation('en', 'Dipl.-Soz.'), new Translation('de', 'Dipl.-Soz.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_soz_tech', [new Translation('en', 'Dipl.-Soz. tech.'), new Translation('de', 'Dipl.-Soz. tech.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sporting', [new Translation('en', 'Dipl.-Sporting.'), new Translation('de', 'Dipl.-Sporting.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sportl', [new Translation('en', 'Dipl.-Sportl.'), new Translation('de', 'Dipl.-Sportl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_spoec', [new Translation('en', 'Dipl.-SpOec.'), new Translation('de', 'Dipl.-SpOec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sportwiss', [new Translation('en', 'Dipl.-Sportwiss.'), new Translation('de', 'Dipl.-Sportwiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sprachm', [new Translation('en', 'Dipl.-Sprachm.'), new Translation('de', 'Dipl.-Sprachm.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_sprechwiss', [new Translation('en', 'Dipl.-Sprechwiss.'), new Translation('de', 'Dipl.-Sprechwiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_staatsw', [new Translation('en', 'Dipl.-Staatsw.'), new Translation('de', 'Dipl.-Staatsw.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_stat', [new Translation('en', 'Dipl.-Stat.'), new Translation('de', 'Dipl.-Stat.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_systemwiss', [new Translation('en', 'Dipl.-Systemwiss.'), new Translation('de', 'Dipl.-Systemwiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_szeneb', [new Translation('en', 'Dipl.-Szeneb.'), new Translation('de', 'Dipl.-Szeneb.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_szenem', [new Translation('en', 'Dipl.-Szenem.'), new Translation('de', 'Dipl.-Szenem.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_tech_red_fh', [new Translation('en', 'Dipl.-Tech.-Red. (FH)'), new Translation('de', 'Dipl.-Tech.-Red. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_technoinform', [new Translation('en', 'Dipl.-Technoinform.'), new Translation('de', 'Dipl.-Technoinform.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_techn_fh', [new Translation('en', 'Dipl.-Techn. (FH)'), new Translation('de', 'Dipl.-Techn. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_math_techn', [new Translation('en', 'Dipl.-Math. techn.'), new Translation('de', 'Dipl.-Math. techn.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_tech_math', [new Translation('en', 'Dipl.-Tech. Math.'), new Translation('de', 'Dipl.-Tech. Math.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_theol', [new Translation('en', 'Dipl.-Theol.'), new Translation('de', 'Dipl.-Theol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_tonm', [new Translation('en', 'Dipl.-Tonm.'), new Translation('de', 'Dipl.-Tonm.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_troph', [new Translation('en', 'Dipl.-Troph.'), new Translation('de', 'Dipl.-Troph.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_uebersetzer', [new Translation('en', 'Dipl.-Übersetzer'), new Translation('de', 'Dipl.-Übersetzer')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_uebers_fh', [new Translation('en', 'Dipl.-Übers. (FH)'), new Translation('de', 'Dipl.-Übers. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_uwt', [new Translation('en', 'Dipl.-UWT'), new Translation('de', 'Dipl.-UWT')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_umweltwiss', [new Translation('en', 'Dipl.-Umweltwiss.'), new Translation('de', 'Dipl.-Umweltwiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_verk_wirtsch', [new Translation('en', 'Dipl.-Verk.wirtsch.'), new Translation('de', 'Dipl.-Verk.wirtsch.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_vers_betriebsw_fh', [new Translation('en', 'Dipl.-Vers.-Betriebsw. (FH)'), new Translation('de', 'Dipl.-Vers.-Betriebsw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_verw_betriebsw_fh', [new Translation('en', 'Dipl.-Verw.-Betriebsw. (FH)'), new Translation('de', 'Dipl.-Verw.-Betriebsw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_verw_manager_fh', [new Translation('en', 'Dipl.-Verw.-Manager (FH)'), new Translation('de', 'Dipl.-Verw.-Manager (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_verww_fh', [new Translation('en', 'Dipl.-Verww. (FH)'), new Translation('de', 'Dipl.-Verww. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_verw_wiss', [new Translation('en', 'Dipl.-Verw. Wiss.'), new Translation('de', 'Dipl.-Verw. Wiss.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_vet_med', [new Translation('en', 'Dipl.-Vet.-Med.'), new Translation('de', 'Dipl.-Vet.-Med.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_volksw', [new Translation('en', 'Dipl.-Volksw.'), new Translation('de', 'Dipl.-Volksw.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_volksw_fh', [new Translation('en', 'Dipl.-Volksw. (FH)'), new Translation('de', 'Dipl.-Volksw. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirt', [new Translation('en', 'Dipl.-Wirt.'), new Translation('de', 'Dipl.-Wirt.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_chem_oec', [new Translation('en', 'Dipl.-Chem.oec.'), new Translation('de', 'Dipl.-Chem.oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirtchem', [new Translation('en', 'Dipl.-Wirtchem.'), new Translation('de', 'Dipl.-Wirtchem.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wigeo', [new Translation('en', 'Dipl.-Wigeo.'), new Translation('de', 'Dipl.-Wigeo.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirt_inf__dipl_wirt_inform__dipl_wirtsch_inf__dipl_wirtsch_inf__dipl_wirtsch_inform', [new Translation('en', 'Dipl.-Wirt.-Inf./ Dipl.-Wirt.-Inform./ Dipl.-Wirtsch.-Inf./ Dipl.-Wirtsch.Inf./ Dipl.-Wirtsch.-Inform.'), new Translation('de', 'Dipl.-Wirt.-Inf./ Dipl.-Wirt.-Inform./ Dipl.-Wirtsch.-Inf./ Dipl.-Wirtsch.Inf./ Dipl.-Wirtsch.-Inform.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirtsch_inf_dh', [new Translation('en', 'Dipl.-Wirtsch.-Inf. (DH)'), new Translation('de', 'Dipl.-Wirtsch.-Inf. (DH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wi_inform_fh', [new Translation('en', 'Dipl.-Wi.-Inform. (FH)'), new Translation('de', 'Dipl.-Wi.-Inform. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirt_inf_fh', [new Translation('en', 'Dipl.-Wirt.-Inf. (FH)'), new Translation('de', 'Dipl.-Wirt.-Inf. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wi_ing__dipl_wirt_ing__dipl_wirtsch_ing', [new Translation('en', 'Dipl.-Wi.-Ing./ Dipl.-Wirt.-Ing./ Dipl.-Wirtsch.-Ing.'), new Translation('de', 'Dipl.-Wi.-Ing./ Dipl.-Wirt.-Ing./ Dipl.-Wirtsch.-Ing.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirt_ing_fh', [new Translation('en', 'Dipl.-Wirt.-Ing. (FH)'), new Translation('de', 'Dipl.-Wirt.-Ing. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirtsch_ing_fh', [new Translation('en', 'Dipl.-Wirtsch.-Ing. (FH)'), new Translation('de', 'Dipl.-Wirtsch.-Ing. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wi_jur_fh', [new Translation('en', 'Dipl.-Wi.Jur. (FH)'), new Translation('de', 'Dipl.-Wi.Jur. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_iur_oec_univ', [new Translation('en', 'Dipl. iur .oec. univ.'), new Translation('de', 'Dipl. iur .oec. univ.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirtl', [new Translation('en', 'Dipl.-Wirtl.'), new Translation('de', 'Dipl.-Wirtl.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_math_oec_oder_dipl_wi_math_oder_dipl_wirtschaftsmath_univ', [new Translation('en', 'Dipl.-Math. oec. oder Dipl.-Wi.-Math. oder Dipl.-Wirtschaftsmath. Univ.'), new Translation('de', 'Dipl.-Math. oec. oder Dipl.-Wi.-Math. oder Dipl.-Wirtschaftsmath. Univ.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wipaed', [new Translation('en', 'Dipl.-Wipäd.'), new Translation('de', 'Dipl.-Wipäd.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_phys_oec', [new Translation('en', 'Dipl.-Phys. oec.'), new Translation('de', 'Dipl.-Phys. oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirtschaftspsych_fh', [new Translation('en', 'Dipl.-Wirtschaftspsych. (FH)'), new Translation('de', 'Dipl.-Wirtschaftspsych. (FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wirt_uebers_fh', [new Translation('en', 'Dipl.-Wirt.-Übers.(FH)'), new Translation('de', 'Dipl.-Wirt.-Übers.(FH)')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_rer_oec', [new Translation('en', 'Dipl. rer. oec.'), new Translation('de', 'Dipl. rer. oec.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wiwi', [new Translation('en', 'Dipl.-WiWi.'), new Translation('de', 'Dipl.-WiWi.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_wiss_org', [new Translation('en', 'Dipl.-Wiss.org.'), new Translation('de', 'Dipl.-Wiss.org.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_stomat', [new Translation('en', 'Dipl.-Stomat.'), new Translation('de', 'Dipl.-Stomat.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'dipl_stom', [new Translation('en', 'Dipl.-Stom.'), new Translation('de', 'Dipl.-Stom.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'll_m', [new Translation('en', 'LL. M.'), new Translation('de', 'LL. M.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'lic_rer_publ', [new Translation('en', 'lic. rer. publ.'), new Translation('de', 'lic. rer. publ.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'lic_rer_reg', [new Translation('en', 'lic. rer. reg.'), new Translation('de', 'lic. rer. reg.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'lic_theol', [new Translation('en', 'lic. theol.'), new Translation('de', 'lic. theol.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'lic_iur_can', [new Translation('en', 'lic. iur. can.'), new Translation('de', 'lic. iur. can.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'mag_iur', [new Translation('en', 'Mag. iur.'), new Translation('de', 'Mag. iur.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'mle', [new Translation('en', 'MLE'), new Translation('de', 'MLE')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'mag_rer_publ', [new Translation('en', 'Mag. rer. publ.'), new Translation('de', 'Mag. rer. publ.')])
    MessageCollection.Global.setTranslations(OptionMap.titles + PATH_SEPARATOR + 'mag_theol_bzw_m_theol', [new Translation('en', 'Mag. theol.'), new Translation('de', 'Mag. theol.')])

    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'computer_scientist', [new Translation('en', 'Computer Scientist'), new Translation('de', 'Informatiker')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'it_professional', [new Translation('en', 'IT Professional'), new Translation('de', 'IT-Fachmann')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'ux_designer_ui_developer', [new Translation('en', 'UX Designer & UI Developer'), new Translation('de', 'UX Designer & UI Entwickler')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sql_developer', [new Translation('en', 'SQL Developer'), new Translation('de', 'SQL-Entwickler')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'web_designer', [new Translation('en', 'Web Designer'), new Translation('de', 'Web-Designer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'web_developer', [new Translation('en', 'Web Developer'), new Translation('de', 'Web-Entwickler')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'help_desk_worker', [new Translation('en', 'Help Desk Worker/Desktop Support'), new Translation('de', 'Helpdesk-Mitarbeiter/Desktop-Support')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'software_engineer', [new Translation('en', 'Software Engineer'), new Translation('de', 'Software-Ingenieur')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'data_entry', [new Translation('en', 'Data Entry'), new Translation('de', 'Dateneingabe')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'devops_engineer', [new Translation('en', 'DevOps Engineer'), new Translation('de', 'DevOps-Ingenieur')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'computer_programmer', [new Translation('en', 'Computer Programmer'), new Translation('de', 'Computer-Programmierer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'network_administrator', [new Translation('en', 'Network Administrator'), new Translation('de', 'Netzwerkadministrator')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'information_security_analyst', [new Translation('en', 'Information Security Analyst'), new Translation('de', 'Analyst für Informationssicherheit')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'artificial_intelligence_engineer', [new Translation('en', 'Artificial Intelligence Engineer'), new Translation('de', 'Ingenieur für künstliche Intelligenz')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cloud_architect', [new Translation('en', 'Cloud Architect'), new Translation('de', 'Cloud-Architekt')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'it_manager', [new Translation('en', 'IT Manager'), new Translation('de', 'IT-Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'technical_specialist', [new Translation('en', 'Technical Specialist'), new Translation('de', 'Technischer Spezialist')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'application_developer', [new Translation('en', 'Application Developer'), new Translation('de', 'Anwendungsentwickler')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'chief_technology_officer_cto', [new Translation('en', 'Chief Technology Officer (CTO)'), new Translation('de', 'Technischer Leiter (CTO)')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'chief_information_officer_cio', [new Translation('en', 'Chief Information Officer (CIO)'), new Translation('de', 'Hauptinformationsbeauftragter (CIO)')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'marketing_specialist', [new Translation('en', 'Marketing Specialist'), new Translation('de', 'Marketing-Spezialist')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'marketing_manager', [new Translation('en', 'Marketing Manager'), new Translation('de', 'Marketingleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'marketing_director', [new Translation('en', 'Marketing Director'), new Translation('de', 'Marketing-Direktor')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'graphic_designer', [new Translation('en', 'Graphic Designer'), new Translation('de', 'Grafikdesigner')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'marketing_research_analyst', [new Translation('en', 'Marketing Research Analyst'), new Translation('de', 'Analyst für Marketingforschung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'marketing_communications_manager', [new Translation('en', 'Marketing Communications Manager'), new Translation('de', 'Manager für Marketingkommunikation')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'marketing_consultant', [new Translation('en', 'Marketing Consultant'), new Translation('de', 'Marketing-Berater')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'product_manager', [new Translation('en', 'Product Manager'), new Translation('de', 'Produktmanager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'public_relations', [new Translation('en', 'Public Relations'), new Translation('de', 'Öffentlichkeitsarbeit')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'social_media_assistant', [new Translation('en', 'Social Media Assistant'), new Translation('de', 'Assistentin für soziale Medien')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'brand_manager', [new Translation('en', 'Brand Manager'), new Translation('de', 'Marken-Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'seo_manager', [new Translation('en', 'SEO Manager'), new Translation('de', 'SEO-Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'content_marketing_manager', [new Translation('en', 'Content Marketing Manager'), new Translation('de', 'Manager für Content Marketing')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'copywriter', [new Translation('en', 'Copywriter'), new Translation('de', 'Werbetexter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'media_buyer', [new Translation('en', 'Media Buyer'), new Translation('de', 'Media-Einkäufer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'digital_marketing_manager', [new Translation('en', 'Digital Marketing Manager'), new Translation('de', 'Manager für digitales Marketing')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'ecommerce_marketing_specialist', [new Translation('en', 'eCommerce Marketing Specialist'), new Translation('de', 'Spezialist für eCommerce-Marketing')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'brand_strategist', [new Translation('en', 'Brand Strategist'), new Translation('de', 'Markenstratege')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'vice_president_of_marketing', [new Translation('en', 'Vice President of Marketing'), new Translation('de', 'Vizepräsident für Marketing')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'media_relations_coordinator', [new Translation('en', 'Media Relations Coordinator'), new Translation('de', 'Koordinator für Medienbeziehungen')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_associate', [new Translation('en', 'Sales Associate'), new Translation('de', 'Vertriebsmitarbeiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_representative', [new Translation('en', 'Sales Representative'), new Translation('de', 'Vertriebsbeauftragter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_manager', [new Translation('en', 'Sales Manager'), new Translation('de', 'Verkaufsleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'retail_worker', [new Translation('en', 'Retail Worker'), new Translation('de', 'Einzelhandelsangestellte')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'store_manager', [new Translation('en', 'Store Manager'), new Translation('de', 'Filialleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_representative', [new Translation('en', 'Sales Representative'), new Translation('de', 'Vertriebsmitarbeiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_manager', [new Translation('en', 'Sales Manager'), new Translation('de', 'Verkaufsleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'real_estate_broker', [new Translation('en', 'Real Estate Broker'), new Translation('de', 'Immobilienmakler')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_associate', [new Translation('en', 'Sales Associate'), new Translation('de', 'Vertriebsmitarbeiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cashier', [new Translation('en', 'Cashier'), new Translation('de', 'Kassierer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'store_manager', [new Translation('en', 'Store Manager'), new Translation('de', 'Filialleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'account_executive', [new Translation('en', 'Account Executive'), new Translation('de', 'Kundenbetreuer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'account_manager', [new Translation('en', 'Account Manager'), new Translation('de', 'Kundenbetreuer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'area_sales_manager', [new Translation('en', 'Area Sales Manager'), new Translation('de', 'Gebietsverkaufsleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'direct_salesperson', [new Translation('en', 'Direct Salesperson'), new Translation('de', 'Direktverkäufer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'director_of_inside_sales', [new Translation('en', 'Director of Inside Sales'), new Translation('de', 'Direktor des Vertriebsinnendienstes')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'outside_sales_manager', [new Translation('en', 'Outside Sales Manager'), new Translation('de', 'Verkaufsleiter im Außendienst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_analyst', [new Translation('en', 'Sales Analyst'), new Translation('de', 'Verkaufsanalyst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'market_development_manager', [new Translation('en', 'Market Development Manager'), new Translation('de', 'Manager für Marktentwicklung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'b2b_sales_specialist', [new Translation('en', 'B2B Sales Specialist'), new Translation('de', 'B2B-Verkaufsspezialist')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'sales_engineer', [new Translation('en', 'Sales Engineer'), new Translation('de', 'Vertriebsingenieur')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'merchandising_associate', [new Translation('en', 'Merchandising Associate'), new Translation('de', 'Merchandising-Mitarbeiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'administrative_assistant', [new Translation('en', 'Administrative Assistant'), new Translation('de', 'Verwaltungsassistentin')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'receptionist', [new Translation('en', 'Receptionist'), new Translation('de', 'Empfangsdame')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'office_manager', [new Translation('en', 'Office Manager'), new Translation('de', 'Büroleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'auditing_clerk', [new Translation('en', 'Auditing Clerk'), new Translation('de', 'Sachbearbeiter Rechnungsprüfung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'bookkeeper', [new Translation('en', 'Bookkeeper'), new Translation('de', 'Buchhalter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'account_executive', [new Translation('en', 'Account Executive'), new Translation('de', 'Kundenbetreuer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'branch_manager', [new Translation('en', 'Branch Manager'), new Translation('de', 'Filialleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'business_manager', [new Translation('en', 'Business Manager'), new Translation('de', 'Geschäftsleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'quality_control_coordinator', [new Translation('en', 'Quality Control Coordinator'), new Translation('de', 'Koordinator für Qualitätskontrolle')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'administrative_manager', [new Translation('en', 'Administrative Manager'), new Translation('de', 'Verwaltungsleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'chief_executive_officer', [new Translation('en', 'Chief Executive Officer'), new Translation('de', 'Geschäftsführender Direktor')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'business_analyst', [new Translation('en', 'Business Analyst'), new Translation('de', 'Wirtschaftsanalytiker')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'risk_manager', [new Translation('en', 'Risk Manager'), new Translation('de', 'Risiko-Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'human_resources', [new Translation('en', 'Human Resources'), new Translation('de', 'Personalwesen')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'office_assistant', [new Translation('en', 'Office Assistant'), new Translation('de', 'Büroassistentin')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'secretary', [new Translation('en', 'Secretary'), new Translation('de', 'Sekretärin')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'office_clerk', [new Translation('en', 'Office Clerk'), new Translation('de', 'Bürokaufmann/-frau')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'file_clerk', [new Translation('en', 'File Clerk'), new Translation('de', 'Bürokaufmann/-frau')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'account_collector', [new Translation('en', 'Account Collector'), new Translation('de', 'Kontensammler')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'administrative_specialist', [new Translation('en', 'Administrative Specialist'), new Translation('de', 'Verwaltungsfachkraft')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'executive_assistant', [new Translation('en', 'Executive Assistant'), new Translation('de', 'Assistentin der Geschäftsleitung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'program_administrator', [new Translation('en', 'Program Administrator'), new Translation('de', 'Programmverwalter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'program_manager', [new Translation('en', 'Program Manager'), new Translation('de', 'Programm-Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'administrative_analyst', [new Translation('en', 'Administrative Analyst'), new Translation('de', 'Verwaltungsanalytiker')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'data_entry', [new Translation('en', 'Data Entry'), new Translation('de', 'Dateneingabe')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'ceo', [new Translation('en', 'CEO'), new Translation('de', 'CEO')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'coo', [new Translation('en', 'COO'), new Translation('de', 'COO')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cfo', [new Translation('en', 'CFO'), new Translation('de', 'CFO')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cio', [new Translation('en', 'CIO'), new Translation('de', 'CIO')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cto', [new Translation('en', 'CTO'), new Translation('de', 'CTO')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cmo', [new Translation('en', 'CMO'), new Translation('de', 'CMO')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'ciso', [new Translation('en', 'CISO'), new Translation('de', 'CISO')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'ceo—chief_executive_officer', [new Translation('en', 'Chief Executive Officer'), new Translation('de', 'Geschäftsführer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'coo—chief_operating_officer', [new Translation('en', 'Chief Operating Officer'), new Translation('de', 'Leiter operative Geschäft')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cfo—chief_financial_officer', [new Translation('en', 'Chief Financial Officer'), new Translation('de', 'Finanzvorstand')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cio—chief_information_officer', [new Translation('en', 'Chief Information Officer'), new Translation('de', 'Informationsbeauftragter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cto—chief_technology_officer', [new Translation('en', 'Chief Technology Officer'), new Translation('de', 'Leiter der Technologieabteilung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cmo—chief_marketing_officer', [new Translation('en', 'Chief Marketing Officer'), new Translation('de', 'Marketingleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'chro—chief_human_resources_officer', [new Translation('en', 'Chief Human Resources Officer'), new Translation('de', 'Leiter der Personalabteilung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cdo—chief_data_officer', [new Translation('en', 'Chief Data Officer'), new Translation('de', 'Datenbeauftragter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cpo—chief_product_officer', [new Translation('en', 'Chief Product Officer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'cco—chief_customer_officer', [new Translation('en', 'Chief Customer Officer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'proprietor', [new Translation('en', 'Proprietor'), new Translation('de', 'Inhaber')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'principal', [new Translation('en', 'Principal'), new Translation('de', 'Hauptverantwortlicher')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'owner', [new Translation('en', 'Owner'), new Translation('de', 'Eigentümer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'president', [new Translation('en', 'President'), new Translation('de', 'Präsident')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'founder', [new Translation('en', 'Founder'), new Translation('de', 'Gründer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'administrator', [new Translation('en', 'Administrator'), new Translation('de', 'Verwalter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'director', [new Translation('en', 'Director'), new Translation('de', 'Direktor')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'managing_partner', [new Translation('en', 'Managing Partner'), new Translation('de', 'Geschäftsführender Gesellschafter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'managing_member', [new Translation('en', 'Managing Member'), new Translation('de', 'Geschäftsführendes Mitglied')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'board_of_directors', [new Translation('en', 'Board of Directors'), new Translation('de', 'Direktorium')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'c-level_or_c-suite.', [new Translation('en', 'C-Level or C-Suite.'), new Translation('de', 'C-Ebene oder C-Suite.')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'manager', [new Translation('en', 'Manager'), new Translation('de', 'Führungskraf')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'supervisors', [new Translation('en', 'Supervisors')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'front-line_employees', [new Translation('en', 'Front-Line Employees'), new Translation('de', 'Mitarbeiter der ersten Reihe')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'quality_control', [new Translation('en', 'Quality Control'), new Translation('de', 'Qualitätskontrolle')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'human_resources', [new Translation('en', 'Human Resources'), new Translation('de', 'Personalwesen')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'accounting_staff', [new Translation('en', 'Accounting Staff'), new Translation('de', 'Mitarbeiter der Buchhaltung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'marketing_staff', [new Translation('en', 'Marketing Staff'), new Translation('de', 'Marketing-Personal')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'purchasing_staff', [new Translation('en', 'Purchasing Staff'), new Translation('de', 'Personal im Einkauf')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'shipping_and_receiving_staff', [new Translation('en', 'Shipping and Receiving Staff'), new Translation('de', 'Personal für Versand und Wareneingang')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'office_manager', [new Translation('en', 'Office Manager'), new Translation('de', 'Büroleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'receptionist', [new Translation('en', 'Receptionist'), new Translation('de', 'Empfangsdame')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'virtual_assistant', [new Translation('en', 'Virtual Assistant'), new Translation('de', 'Virtuelle Assistentin')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'customer_service', [new Translation('en', 'Customer Service'), new Translation('de', 'Kundenbetreuung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'customer_support', [new Translation('en', 'Customer Support'), new Translation('de', 'Kundenbetreuung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'concierge', [new Translation('en', 'Concierge'), new Translation('de', 'Concierge')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'help_desk', [new Translation('en', 'Help Desk'), new Translation('de', 'Helpdesk')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'customer_service_manager', [new Translation('en', 'Customer Service Manager'), new Translation('de', 'Kundenservice-Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'technical_support_specialist', [new Translation('en', 'Technical Support Specialist'), new Translation('de', 'Spezialist für technische Unterstützung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'account_representative', [new Translation('en', 'Account Representative'), new Translation('de', 'Kundenbetreuer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'client_service_specialist', [new Translation('en', 'Client Service Specialist'), new Translation('de', 'Spezialist für Kundenservice')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'customer_care_associate', [new Translation('en', 'Customer Care Associate'), new Translation('de', 'Mitarbeiterin der Kundenbetreuung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'operations_manager', [new Translation('en', 'Operations Manager'), new Translation('de', 'Betriebsleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'operations_assistant', [new Translation('en', 'Operations Assistant'), new Translation('de', 'Betriebsassistent')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'operations_coordinator', [new Translation('en', 'Operations Coordinator'), new Translation('de', 'Betriebskoordinator')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'operations_analyst', [new Translation('en', 'Operations Analyst'), new Translation('de', 'Betriebsanalytiker')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'operations_director', [new Translation('en', 'Operations Director'), new Translation('de', 'Betriebsleiter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'vice_president_of_operations', [new Translation('en', 'Vice President of Operations'), new Translation('de', 'Vizepräsident für Betrieb')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'operations_professional', [new Translation('en', 'Operations Professional'), new Translation('de', 'Betriebsfachmann')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'scrum_master', [new Translation('en', 'Scrum Master'), new Translation('de', 'Scrum-Meister')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'continuous_improvement_lead', [new Translation('en', 'Continuous Improvement Lead'), new Translation('de', 'Leiter für kontinuierliche Verbesserung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'continuous_improvement_consultant', [new Translation('en', 'Continuous Improvement Consultant'), new Translation('de', 'Berater für kontinuierliche Verbesserung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'credit_authorizer', [new Translation('en', 'Credit Authorizer'), new Translation('de', 'Kreditermächtiger')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'benefits_manager', [new Translation('en', 'Benefits Manager'), new Translation('de', 'Benefits Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'credit_counselor', [new Translation('en', 'Credit Counselor'), new Translation('de', 'Kredit-Berater')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'accountant', [new Translation('en', 'Accountant'), new Translation('de', 'Buchhalter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'bookkeeper', [new Translation('en', 'Bookkeeper'), new Translation('de', 'Buchhalter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'accounting_analyst', [new Translation('en', 'Accounting Analyst'), new Translation('de', 'Buchhaltungs-Analyst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'accounting_director', [new Translation('en', 'Accounting Director'), new Translation('de', 'Direktor Rechnungswesen')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'accounts_payable/receivable_clerk', [new Translation('en', 'Accounts Payable/Receivable Clerk'), new Translation('de', 'Kreditoren-/Debitorenbuchhalter')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'auditor', [new Translation('en', 'Auditor'), new Translation('de', 'Rechnungsprüfer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'budget_analyst', [new Translation('en', 'Budget Analyst'), new Translation('de', 'Haushaltsanalytiker')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'controller', [new Translation('en', 'Controller'), new Translation('de', 'Rechnungsprüfer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'financial_analyst', [new Translation('en', 'Financial Analyst'), new Translation('de', 'Finanzanalyst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'finance_manager', [new Translation('en', 'Finance Manager'), new Translation('de', 'Finanz-Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'economist', [new Translation('en', 'Economist'), new Translation('de', 'Wirtschaftswissenschaftler')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'payroll_manager', [new Translation('en', 'Payroll Manager'), new Translation('de', 'Payroll Manager')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'payroll_clerk', [new Translation('en', 'Payroll Clerk'), new Translation('de', 'Sachbearbeiter Lohnbuchhaltung')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'financial_planner', [new Translation('en', 'Financial Planner'), new Translation('de', 'Finanzplaner')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'financial_services_representative', [new Translation('en', 'Financial Services Representative'), new Translation('de', 'Vertreter für Finanzdienstleistungen')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'finance_director', [new Translation('en', 'Finance Director'), new Translation('de', 'Finanzdirektor')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'commercial_loan_officer', [new Translation('en', 'Commercial Loan Officer'), new Translation('de', 'Kommerzieller Darlehensberater')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'researcher', [new Translation('en', 'Researcher'), new Translation('de', 'Rechercheur')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'research_assistant', [new Translation('en', 'Research Assistant'), new Translation('de', 'Forschungsassistent')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'data_analyst', [new Translation('en', 'Data Analyst'), new Translation('de', 'Daten-Analyst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'business_analyst', [new Translation('en', 'Business Analyst'), new Translation('de', 'Wirtschaftsanalyst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'financial_analyst', [new Translation('en', 'Financial Analyst'), new Translation('de', 'Finanzanalyst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'biostatistician', [new Translation('en', 'Biostatistician'), new Translation('de', 'Biostatistiker')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'title_researcher', [new Translation('en', 'Title Researcher'), new Translation('de', 'Titel-Forscher')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'market_researcher', [new Translation('en', 'Market Researcher'), new Translation('de', 'Marktforscher')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'title_analyst', [new Translation('en', 'Title Analyst'), new Translation('de', 'Titel-Analyst')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'medical_researcher', [new Translation('en', 'Medical Researcher'), new Translation('de', 'Medizinische Forscherin')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'mentor', [new Translation('en', 'Mentor'), new Translation('de', 'Mentor')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'tutor', [new Translation('en', 'Tutor/Online Tutor'), new Translation('de', 'Tutor/Online-Tutor')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'teacher', [new Translation('en', 'Teacher'), new Translation('de', 'Lehrer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'teaching_assistant', [new Translation('en', 'Teaching Assistant'), new Translation('de', 'Lehrassistent')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'substitute_teacher', [new Translation('en', 'Substitute Teacher'), new Translation('de', 'Vertretungslehrer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'preschool_teacher', [new Translation('en', 'Preschool Teacher'), new Translation('de', 'Vorschullehrer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'test_scorer', [new Translation('en', 'Test Scorer'), new Translation('de', 'Test-Scorer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'online_esl_instructor', [new Translation('en', 'Online ESL Instructor'), new Translation('de', 'Online ESL-Lehrer')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'professor', [new Translation('en', 'Professor'), new Translation('de', 'Professor')])
    MessageCollection.Global.setTranslations(OptionMap.job_titles + PATH_SEPARATOR + 'assistant_professor', [new Translation('en', 'Assistant Professor'), new Translation('de', 'Assistenzprofessor')])
  }
}
