import React from 'react';
import {
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModalBody,
} from '@elastic/eui';
import { OnboardingRequest } from '../types';

export const UserEditingModalInternal = ({
  editing,
  oldData,
  newAgent,
  newApprover,
  setNewAgent,
  setNewApprover,
}: {
  editing: boolean;
  oldData: OnboardingRequest;
  newAgent: string;
  newApprover: string;
  setNewAgent: (agent: string) => void;
  setNewApprover: (approver: string) => void;
}) => {
  return (
    <EuiModalBody>
      <EuiForm>
        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Agent">
              <EuiFieldText disabled value={oldData.agent} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Agent">
                <EuiFieldText
                  value={newAgent}
                  placeholder={oldData.agent}
                  onChange={(e) => {
                    setNewAgent(e.target.value);
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Approver">
              <EuiFieldText disabled value={oldData.approver} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Approver">
                <EuiFieldText
                  value={newApprover}
                  placeholder={oldData.approver}
                  onChange={(e) => {
                    setNewApprover(e.target.value);
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>
      </EuiForm>
    </EuiModalBody>
  );
};
