import * as React from "react"
const MedoLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg8"
        width={48.99}
        height={38.48}
        viewBox="-0.9 0 12.962 10.181"
        {...props}
    >
        <defs id="defs2">
            <style id="style815">
                {".cls-4{fill:#26388b}.cls-5{fill:#3c3c3b}.cls-1{fill:#36393f}"}
            </style>
        </defs>
        <g id="layer1" transform="translate(365.003 381.638)">
            <g id="g857" transform="matrix(.26458 0 0 .26458 -453.688 -398.57)">
                <g id="g947" transform="translate(315.96 86.913)">
                    <g id="g1052" transform="translate(15.828 -22.917)">
                        <path
                            id="polygon1032"
                            d="M25.18 23.47 29.71 28l8.77-8.76L19.24 0 0 19.24l19.24 19.24 4.53-4.53-4.53-4.53-.55-.55-9.63-9.63L19.24 9.06l10.18 10.18z"
                            style={{
                                fill: "#000",
                                fillOpacity: 1,
                            }}
                        />
                        <path
                            id="polygon1034"
                            d="M29.76 29.42 19.58 19.24 23.81 15l-4.53-4.53-8.76 8.77 19.24 19.24 19.23-19.24L29.76 0l-4.53 4.53 4.53 4.53 1.16 1.16 9.02 9.02z"
                            style={{
                                fill: "#000",
                                fillOpacity: 1,
                            }}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
export default MedoLogo
