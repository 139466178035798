import React from "react";
import { EuiConfirmModal } from "@elastic/eui";
import { UserDetail } from "../../../_types";
import { useNewsletter } from "../../../_functions";

export const DeleteUserModal = ({ selectedUser, handleClose }: { selectedUser: UserDetail, handleClose: () => void }): JSX.Element => {
    const { deleteUser } = useNewsletter()

    const finish = () => {
        deleteUser(selectedUser.id)
        handleClose()
    }
    return (
        <EuiConfirmModal
            title={`Nutzer ${selectedUser.email} Löschen`}
            onCancel={() => { handleClose() }}
            onConfirm={() => finish()}
            cancelButtonText="Abrechen"
            confirmButtonText={`${selectedUser.email} löschen`}
            buttonColor="danger"
            defaultFocusedButton="confirm">
            <p>Sind Sie sich sicher, dass Sie den Nutzer mit der E-Mail {selectedUser.email} löschen wollen?</p>
            <p>Der Nutzer wird dann auch von {selectedUser.newsletters?.length} Newslettern entfertn und bekommt keine E-Mails mehr!</p>
        </EuiConfirmModal>
    )
} 