import {
  MarkdownValidator,
  MultiSelectOptionsQuestion,
  NumericQuestion,
  OpenQuestion,
  OptionsQuestion,
  Section,
  Translation,
} from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';

export const generateNetworkManagement = () => {
  const sNetwork = new Section('network', [
    new Translation('en', 'Network management'),
    new Translation('de', 'Netzwerkverwaltung'),
  ]);

  const config = new OpenQuestion('config', [
    new Translation('en', 'Configure'),
    new Translation('de', 'konfigurieren'),
  ]);
  config.validator = new MarkdownValidator();
  config.help = [
    new Translation('en', 'How do you configure and manage your virtual networks?'),
    new Translation('de', 'Wie konfigurieren und verwalten Sie Ihre virtuellen Netzwerke?'),
  ];

  sNetwork.addChild(config);

  const vds = new OptionsQuestion(
    `vds`,
    [new Translation('en', 'VDS'), new Translation('de', 'VDS')],
    MedoOptionMap.yes_no
  );
  vds.help = [
    new Translation('en', 'Do you use vSphere Distributed Switches (VDS) for network management?'),
    new Translation(
      'de',
      'Nutzen Sie vSphere Distributed Switches (VDS) für die Netzwerkverwaltung?'
    ),
  ];
  sNetwork.addChild(vds);

  const vlan = new NumericQuestion(`vlan_nr`, [
    new Translation('en', 'VLAN'),
    new Translation('de', 'VLAN'),
  ]);
  vlan.help = [
    new Translation('en', 'How many VLANs do you use in the ESXi hosts?'),
    new Translation('de', 'Wie viele VLANs nutzen Sie ca. in den ESXi-Hosts?'),
  ];
  sNetwork.addChild(vlan);

  const lacp = new MultiSelectOptionsQuestion(
    `auth`,
    [new Translation('en', 'Auth methods'), new Translation('de', 'Auth methods')],
    MedoOptionMap.yes_no
  );
  lacp.help = [
    new Translation('en', 'What kind of Auth methods do you use to connect your ESXi hosts?'),
    new Translation('de', 'Welche Authetifizierungsmethoden nutzen Sie bei Ihrer ESXi-Hosts?'),
  ];
  sNetwork.addChild(lacp);

  return sNetwork;
};
