import '../../style/App.css';
import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  useEuiI18n,
  EuiPageContentBody,
  EuiPageContent,
  EuiSpacer,
  EuiButton,
  EuiSplitPanel,
} from '@elastic/eui';

import { AuditCatalog, Translation, type Catalog } from 'anketa-core';
import { ActionConsumer } from '../../components/action_consumer';
import { useKeycloak } from '../../_functions/auth/keycloak';
import { NoCatalogSelected } from '../../components/pages/no_catalog';
import { AuthorizeItem } from '../../_functions/auth/authorize';

import { AnketaContextProvider, EvaluationContext } from '../../components/context_provider';
import { WarningPage } from '../../components/pages/warning';
import moment from 'moment';
import { useApi } from '../../_functions/api/api';
import { CatalogButtonsProps, CatalogTypes, questionarieParams } from '../../_types';
import { Avatar } from '../../components/avatar';
import { OnboardingCatalog } from '../../catalogs/onboarding';
import { AnketaContext, CatalogCard } from '../../components';
import { CreateNewOnBoarding } from './create/new';
import { useLocation } from 'react-router';
import { SelectTemplate } from './table/cards';
import { CreateUserOnBoarding } from './create/user';
import { CreatedUsers } from './table/table';
import { useSupport } from '../../_functions/support/support';
export const catalogButtons: CatalogButtonsProps[] = [
  {
    id: 'onboarding',
    label: [new Translation('en', 'On-Boarding'), new Translation('de', 'On-Boarding')],
    iconType:
      'https://media.licdn.com/dms/image/v2/C4E0BAQHXIcQCJQagoA/company-logo_200_200/company-logo_200_200/0/1671093648870/dtcp_capital_logo?e=1735776000&v=beta&t=93gDfwZpMv9kzlrl_Blcl0ggBquj-rjvgb6Q9qJ62YA',
    description: [new Translation('en', ''), new Translation('de', '')],
  },
];

export default function QuestinarySupport(): JSX.Element {
  const authCtx = useKeycloak();
  const ctx = useContext(AnketaContext);

  const evalCtx = useContext(EvaluationContext);

  const { Get, Post } = useApi();

  const [language, _setLanguage] = useState<string>('de-de');
  const [catalog, _setCatalog] = useState<Catalog>(AuditCatalog.instance.clone());
  const [tableItems, setTableItems] = useState<questionarieParams[]>([]);
  const [toggleIdToSelectedLanguageMap, setToggleIdToSelectedLanguageMap] = useState({
    'de-de': true,
  } as any);
  const [catalogType, setCatalogType] = useState<CatalogTypes>();
  const [sideNav, setSideNav] = useState<boolean>(catalogType ? true : false);
  const [createRoleAccess, setCreateRoleAccess] = useState<string>('');
  const [isCustomerView, setIsCustomerView] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [lastChangeMsg, setLastChangeMsg] = useState<string>('');

  const location = useLocation();
  const currentPath = location.pathname;

  const updateSentryContext = (): void => {
    Sentry.setContext('anketa', {
      locale: language,
      catalog: catalog ? catalog.id : 'N/A',
    });
  };
  const languageButtons = [
    {
      id: 'en-us',
      label: useEuiI18n('euiContext.english', 'English'),
    },
    {
      id: 'de-de',
      label: useEuiI18n('euiContext.german', 'Deutsch'),
    },
  ];
  const setLanguage = (lang: string): void => {
    _setLanguage(lang);
    setToggleIdToSelectedLanguageMap({ [lang]: true });
    updateSentryContext();
  };

  const catalogChangeListener = () => {
    console.log('reevaluate...', evalCtx.evaluationId);
    ctx.reevaluate();
    evalCtx.nextEvaluationId();
  };

  const setCatalog = (catalogId: string): void => {
    Get({
      path: `questionaries/catalog/${catalogId.split(':')[0]}/${catalogId.split(':')[1]}`,
      token: authCtx.keycloak?.token,
    })
      .then((result) => {
        const resultData: questionarieParams = result.data;
        let catalogInstance: any;
        if (authCtx.keycloak) {
          if (resultData.id.split(':')[0].startsWith('onboarding')) {
            catalogInstance = new OnboardingCatalog(
              resultData.id.split(':')[1],
              catalogChangeListener,
              authCtx.keycloak
            );
          }
        }
        if (catalogInstance) {
          setLastChangeMsg(
            `${resultData.updated_by}@${moment(resultData.last_updated).format(
              'YYYY-MM-DD HH:mm:ss'
            )}`
          );
          catalogInstance.factsFromObject(resultData.content);
          _setCatalog(catalogInstance);
          setSideNav(true);
          updateSentryContext();
        }

        console.log(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setCatlogeTypeFunc = (type: CatalogTypes) => {
    if (type === 'audit') {
      fetchCatalogs();
    } else {
      if (type !== catalogType) {
        setTableItems([]);
        setCatalogType(type);
      }
    }
  };
  const createNewCatalog = (catalogId: CatalogTypes, name: string) => {
    let catalogInstance: any;
    if (authCtx.keycloak) {
      switch (catalogId) {
        case 'onboarding':
          setCatalogType('onboarding');
          catalogInstance = new OnboardingCatalog(name, catalogChangeListener, authCtx.keycloak);
          break;
      }
    }
    if (catalogInstance) {
      catalogInstance.clone();
      if (catalogInstance) {
        const params: questionarieParams = {
          id: `${catalogId}:${catalogInstance.id}`,
          name: ctx.i18nContext.render(catalogInstance.title),
          content: ctx.catalog.factsToObject(catalogInstance),
          catalog: catalogId,
          updated_by: authCtx.profile?.username || '',
          last_updated: moment().toISOString(),
        };
        Post({
          path: `questionaries/save/${catalogId}/${catalogInstance.id}`,
          value: params,
          token: authCtx.keycloak?.token,
        })
          .then(() => {
            fetchCatalogs();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      console.warn('Error with', catalogId);
    }
  };

  const addItem = (newItem: questionarieParams) => {
    setTableItems((prevItems) => {
      const itemExists = prevItems.some((item) => item.id === newItem.id);
      if (itemExists) {
        return prevItems;
      } else {
        return [...prevItems, newItem];
      }
    });
  };
  const setCatalogItems = (catalogType: string | undefined, data: any) => {
    switch (catalogType) {
      case 'onboarding':
        data.forEach((child: questionarieParams) => {
          const item = child.content;
          const id = child.name.toLowerCase().replace(/ /g, '_');
          if (authCtx.keycloak) {
            const catalogInstance = OnboardingCatalog.createInstance(
              id,
              catalogChangeListener,
              authCtx.keycloak
            );
            catalogInstance.clone();
            catalogInstance.factsFromObject(item);
          }
          addItem(child);
        });
        break;
    }
  };
  const getRoleForCreate = (role?: string): string => {
    if (role) {
      return role;
    } else {
      switch (catalogType) {
        case 'onboarding':
          return 'anketa-support';
        default:
          return 'anketa-admin';
      }
    }
  };

  const fetchCatalogs = async () => {
    try {
      const promises = catalogButtons.map((catalog) =>
        Get({ path: `questionaries/catalog/${catalog.id}`, token: authCtx.keycloak?.token })
      );

      const results = await Promise.all(promises);

      const allCatalogData = results.flatMap((result) => result.data);

      allCatalogData.forEach((child: questionarieParams) => {
        if (child.id) {
          const item = child.content;
          const id = child.name.toLowerCase().replace(/ /g, '_');
          const catalogType = child.id.split(':')[0];
          let catalogInstance: any;
          switch (catalogType) {
            case 'onboarding':
              if (authCtx.keycloak) {
                catalogInstance = OnboardingCatalog.createInstance(
                  id,
                  catalogChangeListener,
                  authCtx.keycloak
                );
              }
              break;
          }
          if (catalogInstance) {
            catalogInstance.clone();
            catalogInstance.factsFromObject(item);
            addItem(child);
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getCatalogIdRole = (roles: string[]) => {
    const idMap = roles.map((role) => role.replace(/^anketa-/, '').replace(/-admin$/, ''));
    const idSet = new Set<string>();

    const ids = idMap
      .filter((role) => {
        const parts = role.split('-');
        return parts.length >= 2;
      })
      .map((role) => {
        const parts = role.split('-');
        const catalog = parts[0];
        const id = parts.slice(1).join('-');
        const key = `${catalog}-${id}`;

        if (!idSet.has(key)) {
          idSet.add(key);
          return { catalog, id };
        }
        return null;
      })
      .filter((item) => item !== null) as { catalog: string; id: string }[];

    return ids;
  };

  useEffect(() => {
    if (authCtx.keycloak) {
      const resourceAccess = authCtx.keycloak?.resourceAccess;
      const clientAccess =
        resourceAccess && resourceAccess['anketa-demo'] && resourceAccess['anketa-demo'].roles;
      const anketaRoles =
        clientAccess &&
        clientAccess?.filter((item) => item.startsWith('anketa-') && item !== 'anketa-opsanio');

      const single = getCatalogIdRole(anketaRoles ? anketaRoles : []);
      single.forEach((item) => {
        Get({
          path: `questionaries/catalog/${item.catalog}/${item.id}`,
          token: authCtx.keycloak?.token,
        })
          .then((result) => {
            if (result.data.length !== 0) {
              setCatalogItems(item.catalog, result.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
      if (
        clientAccess?.includes('anketa-opsanio') ||
        clientAccess?.includes('anketa-newcustomer') ||
        clientAccess?.includes('anketa-arcticwolf') ||
        clientAccess?.includes('anketa-admin')
      ) {
        if (authCtx.keycloak?.token !== undefined && catalogType) {
          setCreateRoleAccess(getRoleForCreate);
          Get({ path: `questionaries/catalog/${catalogType}`, token: authCtx.keycloak?.token })
            .then((result) => {
              if (result.data.length !== 0) {
                setCatalogItems(catalogType, result.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (authCtx.keycloak?.token && !catalogType) {
          fetchCatalogs();
        } else {
          console.log('Waiting for Keycloak Token....');
        }
      } else if (anketaRoles !== undefined && anketaRoles.length !== 0) {
        anketaRoles?.forEach((role) => {
          const catalog = role.split('-')[1];
          const customer = role.split('-')[2];
          setIsCustomerView(true);
          setCreateRoleAccess(getRoleForCreate(anketaRoles && anketaRoles[0]));
          Get({
            path: `questionaries/catalog/${catalog}/${customer}`,
            token: authCtx.keycloak?.token,
          })
            .then((result) => {
              if (result.data.length !== 0) {
                setCatalog(`${catalog}:${customer}`);
                setCatlogeTypeFunc(catalog as CatalogTypes);
                setCatalogItems(catalog, result.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        });
      } else {
        setError(true);
      }
    }
  }, [catalogType, authCtx, ctx]);

  if (error) {
    return <WarningPage error="403" />;
  } else {
    return (
      <AnketaContextProvider locale={language} catalog={catalog}>
        <EuiPage>
          <EuiPageBody>
            <AuthorizeItem ctx={authCtx.keycloak} roleName={createRoleAccess}>
              <ActionConsumer />
            </AuthorizeItem>
            <EuiPageHeader
              pageTitle="Welcome back to Anketa"
              rightSideItems={[
                <EuiFlexGroup gutterSize="s" alignItems="center">
                  <EuiFlexItem>
                    <EuiButtonGroup
                      options={languageButtons}
                      legend="Language"
                      type="multi"
                      idToSelectedMap={toggleIdToSelectedLanguageMap}
                      onChange={(id: string) => {
                        setLanguage(id);
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <Avatar />
                  </EuiFlexItem>
                </EuiFlexGroup>,
              ]}
            />

            <EuiPageContent
              style={{
                overflowY: 'auto',
                height: '90vh',
                background: 'none',
                border: 'none',
                boxShadow: 'none',
              }}
            >
              <EuiSpacer />
              <EuiPageContentBody>
                <EuiFlexGroup>
                  <CreateNewOnBoarding />
                  <CreateUserOnBoarding />
                </EuiFlexGroup>
                <EuiSpacer />
                {currentPath.startsWith('/support') && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <SelectTemplate />
                    <br />
                    <CreatedUsers />
                  </div>
                )}
                {currentPath.startsWith('/support') &&
                catalogType === 'onboarding' &&
                isCustomerView === false ? (
                  <NoCatalogSelected
                    catalogType={catalogType}
                    setCatalogType={setCatlogeTypeFunc}
                    buttons={catalogButtons}
                    items={tableItems}
                    setCatalog={setCatalog}
                    children={catalog.id !== 'audit' && <CatalogCard />}
                    createNewCatalog={createNewCatalog}
                    lastChangeMsg={lastChangeMsg}
                  />
                ) : (
                  catalog.id !== 'audit' && <CatalogCard />
                )}
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </AnketaContextProvider>
    );
  }
}
