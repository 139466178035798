import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGrid,
  EuiFlexItem,
  EuiModal,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { useSupport } from '../../../../_functions/support/support';
import {
  DepartmentOption,
  OnboardingRequest,
  OnboardingRequestStatus,
  OnboardingRequestStatusType,
  UserValues,
} from '../types';
import { UserEditingModalHeader, UserEditingModalSteps } from './header';
import { UserEditingModalUser } from './user';
import { UserEditingModalUserDetails } from './userDetails';
import { UserEditingModalGroups } from './groups';
import { useKeycloak } from '../../../../_functions';
import { UserEditingModalInternal } from './internal';
import moment from 'moment';

function diff<T extends object>(original: T, updated: T): Partial<T> {
  const changes: Partial<T> = {};

  for (const key in updated) {
    if (updated[key] instanceof Object && !(updated[key] instanceof Array)) {
      // Recursively handle nested objects
      const nestedChanges = diff(original[key] as T, updated[key] as T);
      if (Object.keys(nestedChanges).length > 0) {
        changes[key] = nestedChanges as any;
      }
    } else if (Array.isArray(updated[key])) {
      // Handle arrays by comparing `.value` properties
      const originalArray = original[key] as any[];
      const updatedArray = updated[key] as any[];

      const hasValueDifference =
        !originalArray ||
        !updatedArray ||
        originalArray.length !== updatedArray.length ||
        updatedArray.some(
          (updatedItem, index) => updatedItem?.value !== originalArray[index]?.value
        );

      if (hasValueDifference) {
        changes[key] = updated[key];
      }
    } else if (updated[key] !== original[key]) {
      // Handle primitive values
      changes[key] = updated[key];
    }
  }

  return changes;
}

type SubmitBtn = 'Close' | 'Next' | OnboardingRequestStatusType;
export const UpdateOnboardUser = ({
  user,
  changeModal,
  editing,
  deleting,
  getTypeColor,
}: {
  user: OnboardingRequest;
  editing: boolean;
  deleting: boolean;
  changeModal: (userId: OnboardingRequest | null) => void;
  getTypeColor: (status: OnboardingRequestStatusType) => string;
}): JSX.Element => {
  const { fetchUserById, requestCreateUser, requestDeleteUser, requestUpdateUser } = useSupport();
  const { profile } = useKeycloak();
  const [oldUserData, setOldUserData] = useState<UserValues>();

  const [newGivenName, _setNewGivenName] = useState<string>('');
  const [newSurename, _setSurename] = useState<string>('');
  const [preferredLanguage, _setPreferredLanguage] = useState<string>('');
  const [newDepartment, _setNewDepartment] = useState<Array<DepartmentOption>>([]);
  const [domain, _setDomain] = useState<Array<DepartmentOption>>([]);
  const [companie, _setCompanie] = useState<Array<DepartmentOption>>([]);
  const [city, _setCity] = useState<Array<DepartmentOption>>([]);
  const [jobTitel, _setJobTitel] = useState<Array<DepartmentOption>>([]);
  const [group, _setGroup] = useState<Array<DepartmentOption>>([]);
  const [groupMail, _setGroupMail] = useState<Array<DepartmentOption>>([]);
  const [licenseDetails, _setLicenseDetails] = useState<Array<DepartmentOption>>([]);
  const [accountEnabled, _setAccountEnabled] = useState<boolean>(false);
  const [selectedStep, _setSelectedStep] = useState<UserEditingModalSteps>('internal');
  const [newAgent, _setNewAgent] = useState<string>('');
  const [newApprover, _setNewApprover] = useState<string>('');

  const editingForm = (): {
    fetchOld: boolean;
    editing: boolean;
  } => {
    if (deleting) {
      return {
        fetchOld: false,
        editing: false,
      };
    }
    if (user.status.type === 'create' && user.status.state !== 'approved') {
      return {
        fetchOld: false,
        editing,
      };
    } else {
      return {
        fetchOld: true,
        editing,
      };
    }
  };

  const userDiff = useMemo<{
    diff: Partial<OnboardingRequest>;
    updateValue: OnboardingRequest;
  }>(() => {
    if (oldUserData && profile && profile.email) {
      const newUserValues: UserValues = {
        ...oldUserData,
        accountEnabled: accountEnabled,
        licenseDetails: licenseDetails,
        groups: group,
        verteilerlisten: groupMail,
        jobTitle: jobTitel,
        city: city,
        companyName: companie,
        domain: domain,
        department: newDepartment,
        surname: newSurename,
        givenName: newGivenName,
      };
      const newValues: OnboardingRequest = {
        ...user,
        id: user.id,
        user: newUserValues,
        agent: newAgent,
        approver: newApprover,
      };
      const newStatus: OnboardingRequestStatus = {
        id: uuidv4(),
        state: user.status.state,
        type: user.status.type,
        timestamp: moment(),
        user: user.user.id,
        userRole: user.approver === profile?.email ? 'approver' : 'agent',
        userEmail: profile?.email,
      };
      if (editingForm().fetchOld) {
        const oldValues: OnboardingRequest = {
          ...user,
          id: user.id,
          user: oldUserData,
          agent: newAgent,
          approver: newApprover,
        };
        return {
          diff: diff(oldValues, newValues),
          updateValue: {
            ...newValues,
            status: newStatus,
          },
        };
      } else {
        return {
          diff: diff(user, newValues),
          updateValue: {
            ...newValues,
            status: newStatus,
          },
        };
      }
    } else {
      return {
        diff: diff(user, user),
        updateValue: user,
      };
    }
  }, [
    newGivenName,
    newSurename,
    newDepartment,
    domain,
    companie,
    city,
    jobTitel,
    group,
    groupMail,
    licenseDetails,
    accountEnabled,
    oldUserData,
    newAgent,
    newApprover,
    user,
    profile,
  ]);

  const setNewAgent = (agent: string) => {
    _setNewAgent(agent);
  };

  const setNewApprover = (approver: string) => {
    _setNewApprover(approver);
  };
  const setSelectedStep = (selectedStep: UserEditingModalSteps) => {
    _setSelectedStep(selectedStep);
  };

  const setNewGivenName = (name: string) => {
    _setNewGivenName(name);
  };
  const setSurename = (name: string) => {
    _setSurename(name);
  };

  const setPreferredLanguage = (language: string) => {
    _setPreferredLanguage(language);
  };
  const setNewDepartment = (newDepartment: Array<DepartmentOption>) => {
    _setNewDepartment(newDepartment);
  };
  const setDomain = (domain: Array<DepartmentOption>) => {
    _setDomain(domain);
  };
  const setCompanie = (companie: Array<DepartmentOption>) => {
    _setCompanie(companie);
  };
  const setCity = (city: Array<DepartmentOption>) => {
    _setCity(city);
  };
  const setJobTitel = (jobTitel: Array<DepartmentOption>) => {
    _setJobTitel(jobTitel);
  };
  const setGroup = (groups: Array<DepartmentOption>) => {
    _setGroup(groups);
  };
  const setGroupMail = (groups: Array<DepartmentOption>) => {
    _setGroupMail(groups);
  };
  const setLicenseDetails = (licenseDetails: Array<DepartmentOption>) => {
    _setLicenseDetails(licenseDetails);
  };
  const setAccountEnabled = (enabled: boolean) => {
    _setAccountEnabled(enabled);
  };

  const nextStep = () => {
    switch (selectedStep) {
      case 'internal':
        setSelectedStep('user');
        break;
      case 'user':
        setSelectedStep('user-details');
        break;
      case 'user-details':
        setSelectedStep('groups');
        break;
      case 'groups':
        switch (getSubmitBtn()) {
          case 'Close':
            changeModal(null);
            break;
          case 'create':
            requestCreateUser(userDiff.updateValue);
            break;
          case 'delete':
            requestDeleteUser(user.user.id);
            break;
          case 'update':
            requestUpdateUser(user, userDiff.updateValue, userDiff.diff);
            break;
          default:
            changeModal(null);
            break;
        }
        changeModal(null);
    }
  };
  const getSubmitBtn = (): SubmitBtn => {
    if (selectedStep === 'groups') {
      if (deleting) {
        return 'delete';
      }
      if (editing) {
        console.log(userDiff.diff);
        if (Object.keys(userDiff.diff).length === 0) {
          return 'Close';
        } else {
          return 'update';
        }
      }
      return 'Close';
    } else {
      return 'Next';
    }
  };

  useEffect(() => {
    setNewAgent(user.agent);
    setNewApprover(user.approver);
    _setNewGivenName(user.user.givenName);
    _setSurename(user.user.surname);
    _setNewDepartment(user.user.department);
    _setDomain(user.user.domain);
    _setCompanie(user.user.companyName);
    _setCity(user.user.city);
    _setJobTitel(user.user.jobTitle);
    _setGroup(user.user.groups);
    _setGroupMail(user.user.verteilerlisten);
    _setLicenseDetails(user.user.licenseDetails);
    _setAccountEnabled(user.user.accountEnabled);
    _setPreferredLanguage(user.user.preferredLanguage);
    const fetchData = async () => {
      try {
        if (editingForm().fetchOld) {
          const userValues = await fetchUserById(user.user.id);
          setOldUserData(userValues);
          console.log('Current User Config:', userValues);
        } else {
          setOldUserData(user.user);
        }
      } catch (error) {
        console.error('Current User Config:', error, user);
      }
    };

    fetchData();
  }, [user]);

  if (oldUserData !== undefined) {
    return (
      <EuiModal onClose={() => changeModal(null)}>
        <EuiModalHeader>
          <EuiFlexGrid>
            <EuiFlexItem>
              <EuiModalHeaderTitle>
                <EuiTitle>
                  <h3>Update den Nutzer mit der ID {oldUserData.id}</h3>
                </EuiTitle>
              </EuiModalHeaderTitle>
            </EuiFlexItem>
            <EuiFlexItem grow>
              <UserEditingModalHeader
                selectedStep={selectedStep}
                setSelectedStep={setSelectedStep}
              />
            </EuiFlexItem>
          </EuiFlexGrid>
        </EuiModalHeader>
        {selectedStep === 'internal' && (
          <UserEditingModalInternal
            editing={editing}
            oldData={user}
            newAgent={newAgent}
            newApprover={newApprover}
            setNewAgent={setNewAgent}
            setNewApprover={setNewApprover}
          />
        )}
        {selectedStep === 'user' && (
          <UserEditingModalUser
            editing={editing}
            oldUserData={oldUserData}
            newGivenName={newGivenName}
            setNewGivenName={setNewGivenName}
            newSurename={newSurename}
            setSurename={setSurename}
            domain={domain[0]?.value || ''}
          />
        )}
        {selectedStep === 'user-details' && (
          <UserEditingModalUserDetails
            editing={editing}
            oldUserData={oldUserData}
            city={city}
            companie={companie}
            domain={domain}
            jobTitel={jobTitel}
            newDepartment={newDepartment}
            preferredLanguage={preferredLanguage}
            setCity={setCity}
            setCompanie={setCompanie}
            setDomain={setDomain}
            setJobTitel={setJobTitel}
            setNewDepartment={setNewDepartment}
            setPreferredLanguage={setPreferredLanguage}
          />
        )}
        {selectedStep === 'groups' && (
          <UserEditingModalGroups
            editing={editing}
            oldUserData={oldUserData}
            accountEnabled={accountEnabled}
            group={group}
            groupMail={groupMail}
            licenseDetails={licenseDetails}
            setAccountEnabled={setAccountEnabled}
            setGroup={setGroup}
            setGroupMail={setGroupMail}
            setLicenseDetails={setLicenseDetails}
          />
        )}
        <EuiModalFooter>
          <EuiButtonEmpty
            onClick={() => {
              changeModal(null);
            }}
          >
            Cancle
          </EuiButtonEmpty>
          <EuiButton
            color={getTypeColor(deleting ? 'delete' : user.status.type) as any}
            onClick={nextStep}
          >
            <EuiText>
              <p>
                {getSubmitBtn() === 'Next' || getSubmitBtn() === 'Close'
                  ? getSubmitBtn()
                  : getSubmitBtn() + ' Request'}
              </p>
            </EuiText>
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  } else {
    return <></>;
  }
};
