import { EuiButton, EuiCallOut, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiPageTemplate, EuiPanel, EuiSpacer, EuiText } from "@elastic/eui"
import { useEffect } from "react"
import { useApi } from "../_functions/api/api";
import { useParams } from "react-router";
import { CancelImage } from "../asstes/images/cancle";
import { useToast } from "../_functions/toast";

export const NewsletterLeave = (): JSX.Element => {
    const { addToast } = useToast();
    const { newsletter, userid } = useParams()

    const { Put } = useApi();

    const changeStatus = (user: string, newsletter: string, status: "subscribe" | "unsubscribe") => {
        Put({ path: `newsletter/user/${user}/newsletter/${newsletter}/${status}` })
            .then(() => {
                addToast({
                    title: status === "subscribe" ? `Sie haben sich erfolgreich angemeldet!` : `Sie haben sich erfolgreich abgemeldet!`,
                    text: <p></p>,
                    color: "success",
                    iconType: "check"
                });
            }).catch(() => {
                addToast({
                    title: "Es ist ein unerwarter Fehler aufgetreten!",
                    text: <p></p>,
                    color: "danger",
                    iconType: "cross"
                });
            })

    }

    useEffect(() => {
        if (newsletter && userid) {
            changeStatus(userid, newsletter, "unsubscribe")
        } else {
            addToast({
                title: "Es ist ein unerwarter Fehler aufgetreten!",
                text: <p></p>,
                color: "danger",
                iconType: "cross"
            });
        }
    }, [])

    const SideMsg = (): JSX.Element => {
        return (<div>
            <EuiCallOut
                title="Erfolgreich abgemeldet!"
                color="success"
                iconType="check"
            >
                <EuiText>
                    Sie haben sich erfolgreich von unserem Newsletter abgemeldet.
                </EuiText>
            </EuiCallOut>
            <EuiSpacer size="m" />
            <EuiButton onClick={() => { userid && newsletter && changeStatus(userid, newsletter, "subscribe") }} fill>
                Wieder anmelden
            </EuiButton>
        </div>)
    }
    return (
        <EuiPageTemplate template="empty" style={{
            height: "fit-content",
            margin: "20vh auto"
        }}>
            <EuiFlexGroup
                gutterSize="none"
                direction="column"
                responsive={false}>
                <EuiSpacer size="l" />
                <EuiFlexItem style={{ height: "fit-content" }} >
                    <EuiFlexGroup gutterSize="l">
                        <EuiFlexItem grow={3}>
                            <EuiPanel style={{ display: "flex", alignItems: "center", justifyContent: "center" }} tabIndex={0} hasShadow={true}>
                                <CancelImage />
                            </EuiPanel>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiPanel color="success">
                                <EuiEmptyPrompt
                                    iconType="alert"
                                    iconColor="success"
                                    title={<></>}
                                    body={<SideMsg />}
                                />
                            </EuiPanel>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPageTemplate>
    )
}