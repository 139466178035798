import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Campaign, Companie, Mail, Newsletter, NewsletterContextType, NewsletterDetails, UserDetail, UserMail } from '../../_types';
import { useApi } from '../api';
import { useKeycloak } from '../auth';
import { useToast } from '../toast';
import { deduplicateArray, isValidEmail } from '../validators';
import { useLocation } from 'react-router';

const NewsletterContext = createContext<NewsletterContextType | undefined>(undefined);

export const NewletterProvider = ({ children }: { children: ReactNode }) => {

    const { Get, Delete, Post, Put } = useApi()
    const { keycloak } = useKeycloak()
    const { addToast } = useToast();
    const location = useLocation();

    const [companies, setCompanies] = useState<Companie[]>([])
    const [campaign, setCampaign] = useState<Campaign[]>([])
    const [customers, setCustomers] = useState<UserDetail[]>([])
    const [newsletters, setNewsletters] = useState<NewsletterDetails[]>([])
    const [mailUsers, setMailUsers] = useState<UserMail[]>([])
    const [scannedMailbox, setScannedMailbox] = useState<string>("")

    const [trigger, setTrigger] = useState<number>(0)

    const getCompanies = async () => {
        try {
            const cmpns = await Get({ path: 'companies', token: keycloak?.token })
            setCompanies(deduplicateArray(cmpns.data, 'id'))
        } catch (err) {
            console.error("There was an Error fetching companies!", err)
            setCompanies([])
        }
    }
    const getCampaign = async () => {
        try {
            const cmpgn = await Get({ path: 'campaigns', token: keycloak?.token })
            setCampaign(deduplicateArray(cmpgn.data, 'id'))
        } catch (err) {
            console.error("There was an Error fetching campaigns!", err)
            setCampaign([])
        }
    }
    const getCustomers = async () => {
        try {
            const cstmrs = await Get({ path: 'users', token: keycloak?.token })
            setCustomers(deduplicateArray(cstmrs.data, 'id'))
        } catch (err) {
            console.error("There was an Error fetching customers!", err)
            setCustomers([])
        }
    }
    const getNewsletters = async () => {
        try {
            const nwsltrs = await Get({ path: 'newsletter', token: keycloak?.token })
            if (nwsltrs.status === 500) {
                console.error("There was an Error fetching newsletters!")
                setNewsletters([])
            } else {
                setNewsletters(deduplicateArray(nwsltrs.data, 'id'))
            }
        } catch (err) {
            console.error("There was an Error fetching newsletters!", err)
            setNewsletters([])
        }
    }
    const getMailUsers = async () => {
        try {
            const mlsrs = await Get({ path: 'mail/users', token: keycloak?.token })
            setMailUsers(deduplicateArray(mlsrs.data, 'id'))
        } catch (err) {
            console.error("There was an Error fetching mailUsers!", err)
            setMailUsers([])
        }
    }
    const getScannedMailbox = async () => {
        try {
            const mailbox = await Get({ path: 'mail/forward', token: keycloak?.token })
            setScannedMailbox(mailbox.data)
        } catch (err) {
            console.error("There was an Error fetching mailbox!", err)
            setScannedMailbox("")
        }
    }

    const getPreview = async (newsletter: string) => {
        try {
            const wantedNewsletter = newsletters.filter((news: NewsletterDetails) => {
                return news.id === newsletter
            })[0]
            const senderName = mailUsers.filter((user: UserMail) => {
                return user.email === wantedNewsletter.sender
            })[0].displayName
            const values: { mail: Mail, sendFrom: string } = {
                mail: {
                    userId: `${keycloak?.profile?.id}`,
                    name: `${keycloak?.profile?.firstName} ${keycloak?.profile?.lastName}`,
                    from: wantedNewsletter.from_email,
                    text: wantedNewsletter.text,
                    newsletterId: wantedNewsletter.id,
                    newsletterName: wantedNewsletter.name
                },
                sendFrom: senderName
            }
            const preview = await Post({ path: 'newsletter/preview', token: keycloak?.token, value: values })
            return preview.data
        } catch (err) {
            console.error("There was an Error fetching preview!", err)
            return
        }
    }
    // DELETE FUNCTIONS
    const deleteNewsletter = async (id: string) => {
        await Delete({ path: `newsletter/${id}`, token: keycloak?.token })
            .then((response) => {
                if (response.status === 500) {
                    addToast({
                        title: 'Fehler beim löschen des Newsletters!',
                        text: <p>{response.data}</p>,
                        color: "danger",
                        iconType: "cross"
                    });
                } else {
                    setTrigger((prev) => prev + 1)
                    addToast({
                        title: 'Newsletter gelöscht!',
                        text: <p>Der Newsletter wurde erfolgreich gelöscht!</p>,
                        color: "success",
                        iconType: "check"
                    });
                }
            }).catch((err) => {
                addToast({
                    title: 'Fehler beim löschen des Newsletters!',
                    text: <p>{err}</p>,
                    color: "danger",
                    iconType: "cross"
                });
            })

    }

    const deleteUser = async (id: string) => {
        await Delete({ path: `users/${id}`, token: keycloak?.token })
            .then((response) => {
                if (response.status === 500) {
                    addToast({
                        title: 'Fehler beim erstellen des neuen Nutzers!',
                        text: <p>{response.data}</p>,
                        color: "danger",
                        iconType: "cross"
                    });
                } else {
                    setTrigger((prev) => prev + 1)
                    addToast({
                        title: 'Nutzer erstellt!',
                        text: <p>Der Nutzer wurde erfolgreich gelöscht!</p>,
                        color: "success",
                        iconType: "check"
                    });
                }
            }).catch((err) => {
                addToast({
                    title: 'Fehler beim erstellen des neuen Nutzers!',
                    text: <p>{err}</p>,
                    color: "danger",
                    iconType: "cross"
                });
            })
    }

    // CREATE FUNCTIONS
    const createUser = async (email: string, vorname: string, nachname: string, title: string, unternhmen: string, campaign: string) => {
        console.warn(email, vorname, nachname, title, unternhmen, campaign)

        Post({ path: `users/create`, value: { email: email, firstname: vorname, lastName: nachname, title: title, company: unternhmen, campaign: campaign }, token: keycloak?.token })
            .then((response) => {
                if (response.status === 500) {
                    addToast({
                        title: 'Fehler beim erstellen des neuen Nutzers!',
                        text: <p>{response.data}</p>,
                        color: "danger",
                        iconType: "cross"
                    });
                } else {
                    addToast({
                        title: 'Nutzer erstellt!',
                        text: <p>Der Nutzer mit der {email} wurde erfolgreich erstellt!</p>,
                        color: "success",
                        iconType: "check"
                    });
                    setTrigger((prev) => prev + 1)
                }
            }).catch((err) => {
                addToast({
                    title: 'Fehler beim erstellen des neuen Nutzers!',
                    text: <p>{err}</p>,
                    color: "danger",
                    iconType: "cross"
                });
            })
    }
    const importUsers = async (users: { email: string, vorname: string, nachname: string, title: string, unternhmen: string, campaign: string }[]): Promise<void> => {
        try {
            const response = await Post({ path: `users/import`, value: { users }, token: keycloak?.token });
            if (response.status === 500) {
                addToast({
                    title: 'Fehler beim Erstellen des neuen Nutzers!',
                    text: <p>{response.data}</p>,
                    color: 'danger',
                    iconType: 'cross'
                });
            } else {
                addToast({
                    title: 'Nutzer importiert!',
                    text: <p>Alle Nutzer wurden erfolgreich importiert!</p>,
                    color: 'success',
                    iconType: 'check'
                });
                setTrigger(prev => prev + 1);
            }
        } catch (err) {
            addToast({
                title: 'Fehler beim Importieren der neuen Nutzer!',
                text: <p>{err || 'An error occurred'}</p>,
                color: 'danger',
                iconType: 'cross'
            });
        }
    };

    const createNewsletter = async (newsletter: Newsletter) => {
        if (!newsletter.name.trim() || !newsletter.sender.trim() || !newsletter.text.trim() || !newsletter.from_email.trim() || !newsletter.interval) {
            addToast({
                title: 'Fehler',
                text: <p>Alle Felder müssen ausgefüllt sein!</p>,
                color: "danger",
                iconType: "cross"
            });
        } else {
            Post({ path: `newsletter/create`, value: newsletter, token: keycloak?.token })
                .then((response) => {
                    if (response.status === 500) {
                        addToast({
                            title: 'Fehler beim erstellen des neuen Newsletters!',
                            text: <p>{response.data}</p>,
                            color: "danger",
                            iconType: "cross"
                        });
                    } else {
                        addToast({
                            title: 'Newsletter erstellt!',
                            text: <p>Der Newsletter{newsletter.name} wurde erfolgreich erstellt!</p>,
                            color: "success",
                            iconType: "check"
                        });
                        setTrigger((prev) => prev + 1)
                    }
                }).catch((err) => {
                    addToast({
                        title: 'Fehler beim erstellen des neuen Newsletters!',
                        text: <p>{err}</p>,
                        color: "danger",
                        iconType: "cross"
                    });
                })
        }
    }

    // ASSIGN FUNCTIONS
    const addUserToNewsletter = (user: string, newsletter: string) => {
        if (user === undefined || newsletter === undefined) {
            addToast({
                title: 'Fehler',
                text: <p>Alle Felder müssen ausgefüllt sein!</p>,
                color: "danger",
                iconType: "cross"
            });
            return;
        } else {
            Post({ path: `users/${user}/newsletter/${newsletter}/add`, value: { email: user }, token: keycloak?.token })
                .then((response) => {
                    if (response.status === 500) {
                        addToast({
                            title: 'Fehler beim der zuweisung des Nutzers!',
                            text: <p>{response.data}</p>,
                            color: "danger",
                            iconType: "cross"
                        });
                    } else {
                        setTrigger((prev) => prev + 1)
                        addToast({
                            title: 'Nutzer erstellt!',
                            text: <p>Der Nutzer wurde erfolgreich zum Newsletter hinzugefügt!</p>,
                            color: "success",
                            iconType: "check"
                        });
                    }
                }).catch((err) => {
                    addToast({
                        title: 'Fehler beim der zuweisung des Nutzers!',
                        text: <p>{err}</p>,
                        color: "danger",
                        iconType: "cross"
                    });
                })
        }
    }

    // UPDATE FUNCTIONS
    const updateNewsletter = (params: Newsletter) => {
        Put({ path: `newsletter/${params.id}/update`, value: params, token: keycloak?.token })
            .then((response) => {
                if (response.status === 500) {
                    addToast({
                        title: 'Fehler beim updaten des Newsletters!',
                        text: <p>{response.data}</p>,
                        color: "danger",
                        iconType: "cross"
                    });
                } else {
                    setTrigger((prev) => prev + 1)
                    addToast({
                        title: 'Nutzer erstellt!',
                        text: <p>Der Newsletter wurde erfolgreich angepasst!</p>,
                        color: "success",
                        iconType: "check"
                    });
                }
            }).catch((err) => {
                addToast({
                    title: 'Fehler beim updaten des Newsletters!',
                    text: <p>{err}</p>,
                    color: "danger",
                    iconType: "cross"
                });
            })
    }

    useEffect(() => {
        if (keycloak && keycloak.token && location.pathname.startsWith("/newsletter")) {
            getCompanies()
            getCampaign()
            getCustomers()
            getNewsletters()
            getMailUsers()
            getScannedMailbox()
        }
    }, [keycloak, keycloak?.token, location.pathname, trigger])

    return (
        <NewsletterContext.Provider value={{
            companies,
            campaign,
            customers,
            newsletters,
            mailUsers,
            scannedMailbox,
            deleteNewsletter,
            deleteUser,
            createUser,
            importUsers,
            createNewsletter,
            addUserToNewsletter,
            updateNewsletter,
            getPreview
        }}>
            {children}
        </NewsletterContext.Provider>
    );

};

export const useNewsletter = () => {
    const context = useContext(NewsletterContext);
    if (context === undefined) {
        throw new Error('useNewsletter must be used within a NewletterProvider');
    }
    return context;
};