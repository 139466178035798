import React, { useContext, useEffect, useState } from 'react';
import { EuiEmptyPrompt, EuiTitle } from '@elastic/eui';
import { AnketaContext, EvaluationContext } from '../context_provider';
import { Item, Translation } from 'anketa-core';
import { CatalogTreeSmall } from '../catalog_tree';

export const CatalogCard = (props: any): JSX.Element => {
  const ctx = useContext(AnketaContext);
  const evalCtx = useContext(EvaluationContext);
  const [item, setItem] = useState<JSX.Element>(<></>);

  useEffect(() => {
    const pieces = evalCtx.focusItem.split('.');
    const last = pieces[pieces.length - 1];
    const parent = pieces[0];
    const isValidRender = parent === ctx.catalog.id;
    const renderChild = ctx.catalog.children.get(last);
    if (renderChild !== undefined && isValidRender) {
      setItem(
        <>
          <EuiTitle>
            <h3>{ctx.i18nContext.render(ctx.catalog.title)}</h3>
          </EuiTitle>
          {ctx.childrenForItem(renderChild as Item)}
        </>
      );
    } else {
      const title = [
        new Translation('en', `Please choose an Section.`),
        new Translation('de', `Bitte wählen Sie eine Sektion.`),
      ];
      setItem(
        <EuiEmptyPrompt
          iconType="editorStrike"
          title={<h2>{ctx.i18nContext.render(title)}</h2>}
          body={<CatalogTreeSmall showHidden />}
        />
      );
    }
  }, [ctx, evalCtx]);

  return item;
};
