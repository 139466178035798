import React, { useContext, useState } from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiModal,
    EuiModalHeader,
    EuiModalBody,
    EuiModalFooter,
    EuiTitle,
    EuiStepsHorizontal,
    EuiToast,
    EuiForm,
    EuiFormRow,
    EuiFieldText,
} from '@elastic/eui';
import { Translation } from 'anketa-core';
import { AnketaContext } from '../components';
import { KeycloakStepGuid } from './keycloakStepGuid';
import { CreateCustomerProps } from '../_types';



export default function NewQuestinary({ catalogType, updateCatalog, title }: CreateCustomerProps): JSX.Element {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [error, setError] = useState<boolean>(false)
    const [customerName, setCustomerName] = useState<string>('')
    const ctx = useContext(AnketaContext)

    const steps = [
        {
            title: ctx.i18nContext.render([new Translation('en', "Name"), new Translation('de', "Name")]),
            content: <EuiForm id="modalFormId" component="form">
                <EuiFormRow label="Name">
                    <EuiFieldText
                        value={customerName}
                        onChange={(e) => { setCustomerName(e.target.value) }}
                        name="popfirst"
                    />
                </EuiFormRow>
            </EuiForm>,
        },
        {
            title: 'Keycloak',
            content: <KeycloakStepGuid customer={customerName || ""} catalog={catalogType} />,
        }
    ];

    const handleCancel = (): void => {
        setIsModalVisible(false)
        setCustomerName('')
        setError(false)
        closeModal()
    }

    const handleSubmit = (): void => {
        setCurrentStep(0)
        updateCatalog(catalogType, customerName)
        closeModal()
    }

    const closeModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);

    const nextStep = () => setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    const prevStep = () => setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));

    return (
        <>
            <EuiButton onClick={showModal} fill>{title}</EuiButton>
            {isModalVisible && (
                <EuiModal onClose={closeModal}>
                    {error &&
                        <EuiToast
                            title={`Cant find any Catalog with the id ${catalogType}`}
                            color="danger"
                            iconType="alert"
                        />
                    }
                    <EuiModalHeader style={{ flexDirection: "column" }}>
                        <EuiTitle>
                            <h2>{title}</h2>
                        </EuiTitle>
                        <EuiStepsHorizontal
                            steps={steps.map((step, index) => ({
                                title: step.title,
                                isSelected: index === currentStep,
                                onClick: () => setCurrentStep(index),
                            }))}
                        />
                    </EuiModalHeader>
                    <EuiModalBody style={{ alignItems: "center", width: "100%" }}>
                        <div style={{ marginTop: '20px' }}>{steps[currentStep].content}</div>
                    </EuiModalBody>
                    <EuiModalFooter style={{ justifyContent: "center" }}>
                        <EuiButtonEmpty onClick={handleCancel}>Cancel</EuiButtonEmpty>
                        {currentStep > 0 && <EuiButton onClick={prevStep}>Previous</EuiButton>}
                        {currentStep < steps.length - 1 && <EuiButton onClick={nextStep}>Next</EuiButton>}
                        {currentStep === steps.length - 1 && (
                            <EuiButton onClick={handleSubmit} fill>
                                Finish
                            </EuiButton>
                        )}
                    </EuiModalFooter>
                </EuiModal>
            )}
        </>
    );
};
