import React from 'react'
import { EuiAvatar } from '@elastic/eui'
import gravatar from 'gravatar'
import { useKeycloak } from '../_functions'

export const Avatar = (): JSX.Element => {
  const ctx = useKeycloak()
  const name = ctx.profile?.firstName && ctx.profile.lastName ? `${ctx.profile?.firstName} ${ctx.profile.lastName}` : ctx.profile?.username ? ctx.profile?.username : 'Unknown'
  const img = ctx.profile?.email ? gravatar.url(ctx.profile?.email, { s: '32', format: '404', r: 'pg' }) : ''

  return (
    <EuiAvatar size="m" name={name} imageUrl={img} onClick={() => { ctx.keycloak?.logout() }}>
    </EuiAvatar>
  )
}
