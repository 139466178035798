import { QuestionType } from './base'
import { type TextMessage } from './i18n'
import { QuestionObject } from './question_object'
import { DateValidator } from './validation'

export class DateQuestion extends QuestionObject<Date> {
  constructor (id: string, title: TextMessage) {
    super(id, QuestionType.Date, title)
    this.validator = new DateValidator()
  }
}
