import { QuestionType } from './base'
import { type TextMessage } from './i18n'
import { QuestionObject } from './question_object'

export class ListQuestion extends QuestionObject<string> {
  constructor (id: string, title: TextMessage) {
    super(id, QuestionType.List, title)
  }

  public read (o: any): this {
    super.read(o)
    return this
  }

  public save (): any {
    let result = super.save()
    result = {
      ...result,
      ...{
      }
    }
    return result
  }
}
