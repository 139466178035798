import moment from 'moment'
import { type EvaluatorValue } from '../base/evaluator'
import { type Fact } from '../base/fact'
import { ValidatorType } from '../base/types'
import { MessageCollection, Translation } from '../i18n'
import { type ExecutionContext } from '../runtime/execution_context'
import { ValidationContext } from './context'
import { ValidationError } from './errors'
import { Validator } from './validator'

export class DateValidator extends Validator {
  min?: Date
  max?: Date

  constructor (type?: ValidatorType) {
    super(type !== undefined ? type : ValidatorType.Date)
  }

  static readonly numeric_errors_to_low = 'default/validators/numeric/errors/to_short'
  static readonly numeric_errors_to_high = 'default/validators/numeric/errors/to_long'
  static readonly numeric_errors_nan = 'default/validators/numeric/errors/nan'
  static {
    MessageCollection.Global.setTranslations(DateValidator.numeric_errors_to_low, [new Translation('en', 'Value is to low'), new Translation('de', 'Eingabewert ist gering')])
    MessageCollection.Global.setTranslations(DateValidator.numeric_errors_to_high, [new Translation('en', 'Value is to high'), new Translation('de', 'Eingabewert zu hoch')])
    MessageCollection.Global.setTranslations(DateValidator.numeric_errors_nan, [new Translation('en', 'Value is not a number'), new Translation('de', 'Eingabe ist keien Zahl')])
  }

  public validate (ctx: ExecutionContext, fact: Fact<EvaluatorValue>): ValidationContext {
    const result = new ValidationContext(fact)
    const nVal: Date = fact.getDateValue(ctx.i18nContext)
    if (!nVal) {
      result.diagnostics.push(new ValidationError(DateValidator.numeric_errors_nan, undefined, this, undefined))
    } else {
      if (this.min && (nVal < this.min)) {
        result.diagnostics.push(new ValidationError(DateValidator.numeric_errors_to_low, undefined, this))
      }
      if (this.max && (nVal > this.max)) {
        result.diagnostics.push(new ValidationError(DateValidator.numeric_errors_to_high, undefined, this))
      }
    }
    return result
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'min')) {
      this.min = moment(o.min).toDate()
    }
    if (Object.prototype.hasOwnProperty.call(o, 'max')) {
      this.max = moment(o.max).toDate()
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.min) {
      result.min = this.min.toJSON()
    }
    if (this.max) {
      result.max = this.max.toJSON()
    }
    return result
  }
}
