import { MarkdownValidator, OpenQuestion, OptionsQuestion, Row, Section, Translation } from "anketa-core";
import { MedoOptionMap } from "../helpers/medoOptionsMap";

export const generateMonitoring = () => {
    const sMonitoring = new Section('monitoring', [new Translation('en', 'Monitoring and reporting'), new Translation('de', 'Monitoring und Reporting')]);

    const how = new OpenQuestion('monitoring_how', [new Translation('en', 'How:'), new Translation('de', 'Wie:')])
    how.validator = new MarkdownValidator()
    how.help = [
        new Translation('en', 'How do you monitor the performance and health of your vSphere environment?'),
        new Translation('de', 'Wie überwachen Sie die Leistung und den Zustand Ihrer vSphere-Umgebung?')
    ]
    sMonitoring.addChild(how)

    const tools = new OptionsQuestion(`tools`, [new Translation('en', 'Tools'), new Translation('de', 'Tools')], MedoOptionMap.yes_no);
    tools.help = [
        new Translation('en', 'Use vRealize Operations or other monitoring tools'),
        new Translation('de', 'Nutzen Sie vRealize Operations oder andere Monitoring-Tools')
    ];
    sMonitoring.addChild(tools)

    return sMonitoring;
};
