import React from 'react';
import { EuiComboBox, EuiFieldText, EuiForm, EuiFormRow } from '@elastic/eui';
import { CreateUserProps } from './types';

export const SummaryOnboardUser = ({ user }: { user: CreateUserProps }): JSX.Element => {
  return (
    <EuiForm>
      <EuiFormRow label="Name">
        <EuiFieldText disabled value={`${user.user.firstname} ${user.user.lastname}`} />
      </EuiFormRow>
      <EuiFormRow label="Gender">
        <EuiFieldText disabled value={user.user.gender} />
      </EuiFormRow>
      <EuiFormRow label="Job Title">
        <EuiFieldText disabled value={user.user.jobT[0].value} />
      </EuiFormRow>
      <EuiFormRow label="Department">
        <EuiComboBox isDisabled selectedOptions={user.templateValues.department} />
      </EuiFormRow>
      <EuiFormRow label="City">
        <EuiComboBox isDisabled selectedOptions={user.templateValues.city} />
      </EuiFormRow>
      <EuiFormRow label="Companie">
        <EuiComboBox isDisabled selectedOptions={user.templateValues.companies} />
      </EuiFormRow>
      <EuiFormRow label="Domain">
        <EuiComboBox isDisabled selectedOptions={user.templateValues.domain} />
      </EuiFormRow>
      <EuiFormRow label="Groups">
        <EuiComboBox isDisabled selectedOptions={user.templateValues.groups} />
      </EuiFormRow>
      <EuiFormRow label="Licenses">
        <EuiComboBox isDisabled selectedOptions={user.templateValues.licenses} />
      </EuiFormRow>
      <EuiFormRow label="Verteilerlisten">
        <EuiComboBox
          isDisabled
          placeholder="Select a Verteilerlisten"
          selectedOptions={user.templateValues.verteilerlisten}
        />
      </EuiFormRow>
    </EuiForm>
  );
};
