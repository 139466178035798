import { ComplexType, DateQuestion, Form, Item, OpenQuestion, OptionMap, OptionsQuestion, Row, TextValidator, TextValidatorType, Translation } from "anketa-core"

export class MedoUser extends ComplexType {
    userid!: string

    constructor(init?: any) {
        super('MedoUser', init)
    }


    static getForm(): Item {
        const c = new Form('MedoUser', [new Translation('en', 'Customer Contact'), new Translation('de', 'Kunden Kontakt')])

        const rGender = new Row('gender', [new Translation('en', 'Gender'), new Translation('de', 'Gender')])
        const qSex = new OptionsQuestion('sex', [new Translation('en', 'Gender'), new Translation('de', 'Geschlecht')], OptionMap.sex)
        rGender.addChild(qSex)

        const qvName = new OpenQuestion('firstname', [new Translation('en', 'Firstname'), new Translation('de', 'Vorname')])
        qvName.validator = new TextValidator(TextValidatorType.Name)
        qvName.placeholder = [new Translation('en', 'first name'), new Translation('de', 'Vorname')]
        c.addChild(qvName)

        const qName = new OpenQuestion('surname', [new Translation('en', 'Name'), new Translation('de', 'Nachname')])
        qName.validator = new TextValidator(TextValidatorType.Name)
        qName.placeholder = [new Translation('en', 'last name'), new Translation('de', 'Nachname')]
        c.addChild(qName)

        c.addChild(rGender)

        c.addChild(new OpenQuestion('email', [new Translation('en', 'eMail Address'), new Translation('de', 'E-Mail Adressese')]))

        c.addChild(new OpenQuestion('phone', [new Translation('en', 'Phone number'), new Translation('de', 'Telefonnummer')]))


        return c
    }
}

export class Address extends ComplexType {
    userid!: string

    constructor(init?: any) {
        super('Address', init)
    }


    static getForm(): Item {
        const c = new Form('address', [new Translation('en', 'Address'), new Translation('de', 'Anschrift')])

        const prefix = new OpenQuestion('prefix', [new Translation('en', 'Prefix'), new Translation('de', 'Präfix')])
        const street = new OpenQuestion('street', [new Translation('en', 'Street Name + Nr.'), new Translation('de', 'Straßenname + Hausnummer')])
        const zip = new OpenQuestion('zip', [new Translation('en', 'ZIP/Postal Code'), new Translation('de', 'Postleitzahl')])
        const city = new OpenQuestion('city', [new Translation('en', 'City'), new Translation('de', 'Stadt')])
        const country = new OptionsQuestion('country', [new Translation('en', 'Country'), new Translation('de', 'Land')], OptionMap.country)

        c.addChild(prefix)
        c.addChild(street)
        c.addChild(zip)
        c.addChild(city)
        c.addChild(country)


        return c
    }
}

export class OpsanioStatus extends ComplexType {
    userid!: string

    constructor(init?: any) {
        super('status', init)
    }


    static getForm(): Item {
        const c = new Form('status', [new Translation('en', 'Status'), new Translation('de', 'Status')])

        const questionary = new OptionsQuestion(
            `questionary`,
            [new Translation('en', 'All data recorded?'), new Translation('de', 'Alle Daten erfasst?')],
            'default.options.yes_no'
        );
        questionary.required = false
        c.addChild(questionary)

        const server = new DateQuestion('server', [new Translation('en', 'Server lift'), new Translation('de', 'Server Liferung')])
        server.required = false
        server.visible = false

        const serverValidation = new OptionsQuestion(
            `serverval`,
            [new Translation('en', 'Server arrived?'), new Translation('de', 'Server angekommen')],
            'default.options.yes_no'
        );
        serverValidation.required = false
        serverValidation.visible = false

        const serverOrdered = new OptionsQuestion(
            `serverordered`,
            [new Translation('en', 'Server ordered?'), new Translation('de', 'Server bestellt?')],
            'default.options.yes_no'
        );
        serverOrdered.required = false
        serverOrdered.addChangeListener(() => {
            server.visible = true
            serverValidation.visible = true
        })
        serverOrdered.required = false

        c.addChild(serverOrdered)
        c.addChild(server)
        c.addChild(serverValidation)

        const serverSetup = new OptionsQuestion(
            `serversetup`,
            [new Translation('en', 'Server provisioned?'), new Translation('de', 'Server provisioniert?')],
            'default.options.yes_no'
        );
        serverSetup.required = false

        c.addChild(serverSetup)

        const serverEinbau = new DateQuestion('serverin', [new Translation('en', 'Server installation'), new Translation('de', 'Server Einbau')])
        serverEinbau.required = false

        const serverEinbauValidation = new OptionsQuestion(
            `serverinval`,
            [new Translation('en', 'Server installed?'), new Translation('de', 'Server installiert')],
            'default.options.yes_no'
        );
        serverEinbauValidation.required = false

        c.addChild(serverEinbau)
        c.addChild(serverEinbauValidation)




        return c
    }

}