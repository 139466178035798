import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import * as Sentry from '@sentry/react';
import '@elastic/eui/dist/eui_theme_amsterdam_light.css';

// Make sure all locales are loaded
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/de-ch';
import 'moment/locale/de-at';
import { KeycloakAuth } from './_functions/auth';

import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './app';
import { ToastProvider } from './_functions/toast';
import { NewletterProvider } from './_functions';
import { SupportProvider } from './_functions/support/support';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

// Sentry.init({
//   dsn: 'https://e28f31e97728315c4ba755a2823878e9@sentry.genesis.exanio.cloud/34',
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
//   integrations: [
//     Sentry.replayIntegration({
//       maskAllText: true,
//       blockAllMedia: true,
//     }),
//     Sentry.replayCanvasIntegration({
//       enableManualSnapshot: true,
//     }),
//   ],
// });

const msalConfiguration = {
  auth: {
    clientId: 'e32feb06-de0d-4371-b895-36adca6c718b',
    authority: 'https://login.microsoftonline.com/8495880e-0ea4-433e-936a-8e183898c1af',
  },
};

const pca = new PublicClientApplication(msalConfiguration);

ReactDOM.render(
  <Router>
    <KeycloakAuth>
      <ToastProvider>
        <NewletterProvider>
          <MsalProvider instance={pca}>
            <SupportProvider>
              <App />
            </SupportProvider>
          </MsalProvider>
        </NewletterProvider>
      </ToastProvider>
    </KeycloakAuth>
  </Router>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
