import { type EvaluatorValue } from '../base/evaluator'
import { type Fact } from '../base/fact'
import { TextValidatorType, ValidatorType } from '../base/types'
import { MessageCollection } from '../i18n/message_context'
import { Translation } from '../i18n/translation'
import { type ExecutionContext } from '../runtime/execution_context'
import { ValidationContext } from './context'
import { ValidationError } from './errors'
import { Validator } from './validator'

const hasLineBreaks = /[',\n'\r]+/s
const emailPattern = /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
const namePattern = /^(\p{L}+([ .,-]?\p{L}+)*)+$/ui
const wordPattern = /^(\p{L}+)$/ui
const uriPattern = /^\w+:(\/?\/?)[^\s]+$/
const webUrlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
const ipPattern = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/
const cidrPattern = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\/(3[0-2]|[1-2]?[0-9])$/
const vlanPattern = /^(40[0-9][0-4]|[1-3]?[0-9]{1,3})$/

const DEFAULT_MAX_TEXT_LENGTH = 512
const DEFAULT_MAX_EMAIL_LENGTH = 320
const DEFAULT_MAX_URI_LENGTH = 2048
const DEFAULT_MAX_NAME_LENGTH = 30
const DEFAULT_MAX_WORD_LENGTH = 30

export class TextValidator extends Validator {
  private _subType: TextValidatorType = TextValidatorType.Generic
  private _pattern: RegExp | undefined
  minLength: number = 0
  maxLength: number = DEFAULT_MAX_TEXT_LENGTH
  multiLine: boolean = false

  static readonly text_errors_to_short = 'default/validators/text/errors/to_short'
  static readonly text_errors_to_long = 'default/validators/text/errors/to_long'
  static readonly text_errors_pattern = 'default/validators/text/errors/pattern'
  static readonly text_errors_multiline = 'default/validators/text/errors/multiline'
  static {
    MessageCollection.Global.setTranslations(TextValidator.text_errors_to_short, [new Translation('en', 'Value is to short'), new Translation('de', 'Eingabe zu kurz')])
    MessageCollection.Global.setTranslations(TextValidator.text_errors_to_long, [new Translation('en', 'Value is to long'), new Translation('de', 'Eingabe zu lang')])
    MessageCollection.Global.setTranslations(TextValidator.text_errors_pattern, [new Translation('en', 'Value does not match required pattern'), new Translation('de', 'Eingabe entspricht nicht dem geforderten Muster')])
    MessageCollection.Global.setTranslations(TextValidator.text_errors_multiline, [new Translation('en', 'Value must be single line'), new Translation('de', 'Eingabe muss einzeilig sein')])
  }

  constructor (subType?: TextValidatorType) {
    super(ValidatorType.Text)
    if (subType !== undefined) {
      this.subType = subType
    }
  }

  public validate (ctx: ExecutionContext, fact: Fact<EvaluatorValue>): ValidationContext {
    const result = new ValidationContext(fact)
    const val = fact.getStrValue(ctx.i18nContext)
    if (val.length < this.minLength) {
      result.diagnostics.push(new ValidationError(TextValidator.text_errors_to_short, `${this.minLength}`, this))
    }

    if (val.length > this.maxLength) {
      result.diagnostics.push(new ValidationError(TextValidator.text_errors_to_long, `${this.maxLength}`, this))
    }

    if ((this.pattern != null) && !this.pattern.test(val)) {
      result.diagnostics.push(new ValidationError(TextValidator.text_errors_pattern, this.pattern.source, this))
    }

    if (!this.multiLine && hasLineBreaks.test(val)) {
      result.diagnostics.push(new ValidationError(TextValidator.text_errors_multiline, undefined, this))
    }

    return result
  }

  public read (o: any): this {
    super.read(o)

    if (Object.prototype.hasOwnProperty.call(o, 'subType')) {
      if (typeof (o.subType) === 'string') {
        this.subType = (TextValidatorType[o.subType] as any) as TextValidatorType
      } else {
        this.subType = o.subType as TextValidatorType
      }
    } else {
      this._subType = TextValidatorType.Generic
    }

    if (Object.prototype.hasOwnProperty.call(o, 'minLength')) {
      this.minLength = o.minLength
    }
    if (Object.prototype.hasOwnProperty.call(o, 'maxLength')) {
      this.maxLength = o.maxLength
    }
    if (Object.prototype.hasOwnProperty.call(o, 'pattern')) {
      this.pattern = o.pattern
    }
    if (Object.prototype.hasOwnProperty.call(o, 'multiLine')) {
      this.multiLine = o.multiLine
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this._subType !== TextValidatorType.Generic) {
      result.subType = TextValidatorType[this._subType]
    } else {
      if (this._pattern != null) {
        result.pattern = this._pattern.source
      }
      if (this.minLength !== 0) {
        result.minLength = this.minLength
      }
      if (this.maxLength !== DEFAULT_MAX_TEXT_LENGTH) {
        result.maxLength = this.maxLength
      }
      if (this.multiLine) {
        result.multiLine = this.multiLine
      }
    }
    return result
  }

  public get subType (): TextValidatorType {
    return this._subType
  }

  public set subType (subType: TextValidatorType) {
    this._subType = subType
    switch (subType) {
      case TextValidatorType.EMail: {
        this._pattern = emailPattern
        this.minLength = 4
        this.maxLength = DEFAULT_MAX_EMAIL_LENGTH
        this.multiLine = false
        break
      }
      case TextValidatorType.Name: {
        this._pattern = namePattern
        this.minLength = 2
        this.maxLength = DEFAULT_MAX_NAME_LENGTH
        this.multiLine = false
        break
      }
      case TextValidatorType.Word: {
        this._pattern = wordPattern
        this.minLength = 1
        this.maxLength = DEFAULT_MAX_WORD_LENGTH
        this.multiLine = false
        break
      }
      case TextValidatorType.Uri: {
        this._pattern = uriPattern
        this.minLength = 4
        this.maxLength = DEFAULT_MAX_URI_LENGTH
        this.multiLine = false
        break
      }
      case TextValidatorType.WebUrl: {
        this._pattern = webUrlPattern
        this.minLength = 7
        this.maxLength = DEFAULT_MAX_URI_LENGTH
        this.multiLine = false
        break
      }
      case TextValidatorType.IP: {
        this._pattern = ipPattern
        this.minLength = 7
        this.maxLength = 15
        this.multiLine = false
        break
      }
      case TextValidatorType.CIDR: {
        this._pattern = cidrPattern
        this.minLength = 9
        this.maxLength = 18
        this.multiLine = false
        break
      }
      case TextValidatorType.VLAN: {
        this._pattern = vlanPattern
        this.minLength = 1
        this.maxLength = 4
        this.multiLine = false
        break
      }
    }
  }

  public get pattern (): RegExp | undefined {
    return this._pattern
  }

  public set pattern (val: string | RegExp | undefined) {
    if (val === undefined || val === '') {
      this._pattern = undefined
      return
    }
    if (typeof (val) === 'string') {
      val = new RegExp(val, 's')
    }
    this._pattern = val
  }
}
