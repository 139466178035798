import { QuestionType } from './base'
import { type TextMessage } from './i18n'
import { NumericQuestion } from './numeric_question'
import { NumericValidator } from './validation'

export class ScaleQuestion extends NumericQuestion {
  step: number = 1

  constructor (id: string, title: TextMessage, validator?: NumericValidator) {
    super(id, title)
    this.questionType = QuestionType.Scale
    if (validator === undefined) {
      validator = new NumericValidator()
      validator.min = 0
      validator.max = 100
      validator.decimals = 0
    }
    this._validator = validator
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'step')) {
      this.step = o.step
    } else {
      this.step = 1
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.step !== 1) {
      result.step = this.step
    }
    return result
  }

  public get validator (): NumericValidator {
    return this._validator as NumericValidator
  }

  public set validator (validator: NumericValidator) {
    this._validator = validator
  }

  public get min (): number {
    return this.validator.min
  }

  public get max (): number {
    return this.validator.max
  }

  public get decimals (): number {
    return this.validator.decimals
  }
}
