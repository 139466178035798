import { Hierarchical, ItemObject } from "anketa-core"
import { createNestedObject, removePrefixFromKeys } from "./json"
import { Profile } from "../../_types"

export const sendMail = async (item: Hierarchical | undefined, profile: Profile): Promise<void> => {

    try {
        if (item instanceof ItemObject) {
            const jsonItem = item.factsToObject()
            const modifiedObj = removePrefixFromKeys(jsonItem)
            const docArray = createNestedObject(modifiedObj)
            const docValues = {
                surename: docArray.name.surname.surname ?? '',
                firstname: docArray.name.firstname.firstname ?? '',
                email: docArray.name.email.email ?? '',
                company: docArray.company.company ?? '',
                user_name: `${profile?.lastName ?? ''}, ${profile?.firstName ?? ''}`,
                user_email: `${profile?.email ?? ''}`,
                ad: docArray.ad.ad ?? '0',
                ma: docArray.ma.ma ?? '0',
                srv: docArray.srv.srv ?? '0',
                insurance: docArray.insurance.insurance ?? 'Nein',
                budget: docArray.costs?.costs_value?.costs_value ?? 'n/a',
                soc: docArray.soc.soc ?? 'Nein'
            }
        }
    } catch (error) {
        console.error('Error sending mail:', error)
    }
}