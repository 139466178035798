import { Action, Section, Translation } from "anketa-core"
import { AuthorizeComponent } from "../../_functions/auth/authorize"
import { KeycloakInstance } from "keycloak-js"

export const generateActions = (savePath: string, changeListener: () => void, authCtx: KeycloakInstance) => {
    const rActionSection = new Section('action_row', [
        new Translation('en', ''),
        new Translation('de', '')
    ])

    const aConvertPpf = new Action(`pdf_${savePath}`, [
        new Translation('en', 'Convert to Word Document'),
        new Translation('de', 'Als Word Document exportieren')
    ])

    const aStore = new Action(`save_${savePath}`, [
        new Translation('en', 'Save'),
        new Translation('de', 'Speichern')
    ])
    aStore.addChangeListener(changeListener)
    rActionSection.addChild(aStore)

    const aOpsanioOnBoardingDoku = new Action(`onboarding_${savePath}`, [
        new Translation('en', 'Doku'),
        new Translation('de', 'Doku')
    ])
    aOpsanioOnBoardingDoku.addChangeListener(changeListener)


    const bootstrap = new Action(`bootstrap`, [
        new Translation('en', 'Bootstrap'),
        new Translation('de', 'Bootstrap')
    ])
    bootstrap.addChangeListener(changeListener)

    if (savePath.includes("opsanio")) {
        rActionSection.addChild(aOpsanioOnBoardingDoku)
    }
    if (AuthorizeComponent(authCtx, "anketa-development")) {
        rActionSection.addChild(aConvertPpf)
    }
    if (AuthorizeComponent(authCtx, "anketa-admin")) {
        rActionSection.addChild(bootstrap)
    }
    return rActionSection
}