import { type EvaluatorValue } from '../base/evaluator'
import { type Fact } from '../base/fact'
import { ValidatorType } from '../base/types'
import { MessageCollection } from '../i18n/message_context'
import { Translation } from '../i18n/translation'
import { type ExecutionContext } from '../runtime/execution_context'
import { ValidationContext } from './context'
import { ValidationError } from './errors'
import { Validator } from './validator'

/**
 * import { Message } from '../'
 * import { ValidationWarning } from './'
JEST WORKAROUND

import { remark } from 'remark'
import remarkPresetLintConsistent from 'remark-preset-lint-consistent'
import remarkPresetLintRecommended from 'remark-preset-lint-recommended'
import remarkLintListItemIndent from 'remark-lint-list-item-indent'
import { type VFileMessage } from 'vfile-message'
*/

export class MarkdownValidator extends Validator {
  minLength: number = 0
  maxLength: number = 512

  static readonly text_errors_to_short = 'default/validators/text/errors/to_short'
  static readonly text_errors_to_long = 'default/validators/text/errors/to_long'
  static readonly text_errors_pattern = 'default/validators/text/errors/pattern'
  static readonly text_errors_multiline = 'default/validators/text/errors/multiline'
  static {
    MessageCollection.Global.setTranslations(MarkdownValidator.text_errors_to_short, [new Translation('en', 'Value is to short'), new Translation('de', 'Eingabe zu kurz')])
    MessageCollection.Global.setTranslations(MarkdownValidator.text_errors_to_long, [new Translation('en', 'Value is to long'), new Translation('de', 'Eingabe zu lang')])
    MessageCollection.Global.setTranslations(MarkdownValidator.text_errors_pattern, [new Translation('en', 'Value does not match required pattern'), new Translation('de', 'Eingabe entspricht nicht dem erforderlichen Muster')])
    MessageCollection.Global.setTranslations(MarkdownValidator.text_errors_multiline, [new Translation('en', 'Value must be single line'), new Translation('de', 'Eingabe muss einzeilig sein')])
  }

  constructor () {
    super(ValidatorType.Markdown)
  }

  public validate (ctx: ExecutionContext, fact: Fact<EvaluatorValue>): ValidationContext {
    const result = new ValidationContext(fact)
    const val = fact.getStrValue(ctx.i18nContext)
    if (val.length < this.minLength) {
      result.diagnostics.push(new ValidationError(MarkdownValidator.text_errors_to_short, undefined, this))
    }

    if (val.length > this.maxLength) {
      result.diagnostics.push(new ValidationError(MarkdownValidator.text_errors_to_long, undefined, this))
    }

    /** JEST
    const mdValidation = remark()
      // Check that markdown is consistent.
      .use(remarkPresetLintConsistent)
      // Few recommended rules.
      .use(remarkPresetLintRecommended)
      .use(remarkLintListItemIndent)
      .processSync(val)

    mdValidation.messages.forEach((vfMmessage: VFileMessage) => {
      if (vfMmessage.fatal) {
        result.diagnostics.push(new ValidationError(new Message(vfMmessage.name, vfMmessage.message), new Message(vfMmessage.name, vfMmessage.message + '\nRule: ' + (vfMmessage?.ruleId ?? 'NA') + '\nURL: ' + (vfMmessage?.url ?? 'N/A')), this))
      } else {
        result.diagnostics.push(new ValidationWarning(new Message(vfMmessage.name, vfMmessage.message), new Message(vfMmessage.name, vfMmessage.message + '\nRule: ' + (vfMmessage?.ruleId ?? 'NA') + '\nURL: ' + (vfMmessage?.url ?? 'N/A')), this))
      }
    })
    */
    return result
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'minLength')) {
      this.minLength = o.minLength
    }
    if (Object.prototype.hasOwnProperty.call(o, 'maxLength')) {
      this.maxLength = o.maxLength
    }
    return this
  }

  public save (): any {
    const result = super.save()
    if (this.minLength !== Number.MIN_SAFE_INTEGER) {
      result.minLength = this.minLength
    }
    if (this.maxLength !== Number.MAX_SAFE_INTEGER) {
      result.maxLength = this.maxLength
    }
    return result
  }
}
