import { type Group, type Row, type Section } from '../group'
import { QuestionObject } from '../question_object'
import { ItemObject } from './item_object'
import { ItemType, type Item, type Question } from './types'
export { type Serializable } from './serializable'

export function isItem (object: unknown): object is Item {
  return object instanceof ItemObject || Object.prototype.hasOwnProperty.call(object, 'itemType')
}

export function isQuestion (object: unknown): object is Question<any> {
  return object instanceof QuestionObject || Object.prototype.hasOwnProperty.call(object, 'questionType')
}

export function isSection (object: unknown): object is Section {
  return (Object.prototype.hasOwnProperty.call(object, 'itemType') && ((object as any).itemType === ItemType.Section))
}

export function isGroup (object: unknown): object is Group {
  return (Object.prototype.hasOwnProperty.call(object, 'itemType') && ((object as any).itemType === ItemType.Group))
}

export function isRow (object: unknown): object is Row {
  return (Object.prototype.hasOwnProperty.call(object, 'itemType') && ((object as any).itemType === ItemType.Row))
}
