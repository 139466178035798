import React, { useState } from 'react';
import {
    EuiBasicTable,
    EuiButton,
    EuiComboBox,
    EuiFilePicker,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
} from '@elastic/eui';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

interface CsvImportModalProps<T> {
    handleUplod: (item: any) => void;
    handleClose: () => void
    typeDefinition: T;
}

export const UserImportModal = <T extends object>({ handleClose, handleUplod, typeDefinition }: CsvImportModalProps<T>): JSX.Element => {
    const [csvData, setCsvData] = useState<any[]>([]);
    const [fileName, setFileName] = useState<string>('');
    const [mapping, setMapping] = useState<{ [key: string]: string }>({});
    const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
    const [step, setStep] = useState<number>(1);

    const handleFileChange = (files: FileList | null) => {
        const file = files?.[0];
        if (file) {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileType = file.name.split('.').pop()?.toLowerCase();
                const arrayBuffer = e.target?.result;
                if (arrayBuffer) {
                    if (fileType === 'csv') {
                        Papa.parse(arrayBuffer as string, {
                            header: true,
                            complete: (results) => {
                                setCsvData(results.data);
                                setCsvHeaders(results.meta.fields || []);
                                setStep(2);
                            },
                        });
                    } else {
                        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];
                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        const headers = jsonData[0] as string[];
                        const data = jsonData.slice(1);
                        setCsvData(data);
                        setCsvHeaders(headers);
                        setStep(2);
                    }
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleMappingChange = (key: string, selectedOptions: any) => {
        setMapping({ ...mapping, [key]: selectedOptions[0]?.label || '' });
    };

    const handleProceedToImport = () => {
        setStep(3);
    };

    const handleImport = () => {
        const mappedData: T[] = csvData.map((row: any) => {
            const typedRow: Partial<T> = {};
            for (const key in mapping) {
                typedRow[key as keyof T] = row[csvHeaders.indexOf(mapping[key])];
            }
            return typedRow as T;
        });
        setCsvData([]); Array<T>
        setFileName('');
        setMapping({});
        setStep(1);
        handleUplod(mappedData);
        handleClose()
    };


    const columns = Object.keys(typeDefinition).map((key) => ({
        field: key,
        name: key,
    }));

    const renderStep1 = (): JSX.Element => (
        <>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Aus welcher Datei willst du Nutzer importieren</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiFormRow label="Select CSV or Excel file">
                    <EuiFilePicker
                        initialPromptText="Select or drag and drop a CSV or Excel file"
                        onChange={(files) => handleFileChange(files)}
                        accept=".csv, .xls, .xlsx"
                    />
                </EuiFormRow>
            </EuiModalBody>
        </>
    );

    const renderStep2 = (): JSX.Element => (
        <>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Weise die Spalten den richtigen Items zu</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                {Object.keys(typeDefinition).map((key) => (
                    <EuiFormRow key={key} label={`CSV/Excel column for ${key}`}>
                        <EuiComboBox
                            placeholder="Select a CSV/Excel column"
                            singleSelection={{ asPlainText: true }}
                            options={csvHeaders.map(header => ({ label: header }))}
                            selectedOptions={mapping[key] ? [{ label: mapping[key] }] : []}
                            onChange={(selectedOptions) => handleMappingChange(key, selectedOptions)}
                        />
                    </EuiFormRow>
                ))}
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton onClick={handleProceedToImport}>Proceed to Import</EuiButton>
            </EuiModalFooter>
        </>
    );

    const renderStep3 = (): JSX.Element => (
        <>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Folgende Nutzer werden importiert</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiBasicTable
                    items={csvData.map(row => {
                        const mappedRow: { [key: string]: any } = {};
                        Object.keys(mapping).forEach(key => {
                            mappedRow[key] = row[csvHeaders.indexOf(mapping[key])];
                        });
                        return mappedRow;
                    })}
                    columns={columns}
                    tableLayout="auto"
                    responsive
                />
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton onClick={handleImport}>Import Data</EuiButton>
            </EuiModalFooter>
        </>
    );

    return (
        <EuiModal onClose={handleClose}>
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep3()}
        </EuiModal>
    );
};
