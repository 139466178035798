import { Group, OpenQuestion, OptionsQuestion, Row, Translation } from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';

export const genearteBasicEnv = (name: string) => {
  const gBasicEnvironment = new Group(`be_${name}`, [
    new Translation('en', 'Current Environment'),
    new Translation('de', 'Aktuelle Umgebung'),
  ]);
  const basicEnvironmentRow = new Row(`be_${name}_0`, [
    new Translation('en', 'Current Environment'),
    new Translation('de', 'Aktuelle Umgebung'),
  ]);

  const generateBasicEvent = () => {
    const oldTrigger: OptionsQuestion = gBasicEnvironment.children
      .get(`be_${name}_${gBasicEnvironment.children.size - 1}`)
      ?.children.get(`our_buisness_${gBasicEnvironment.children.size - 1}`) as OptionsQuestion;
    oldTrigger.removeChangeListener(generateBasicEvent);
    const item = new Row(`be_${name}_${gBasicEnvironment.children.size}`, [
      new Translation('en', `Basic Environment ${gBasicEnvironment.children.size}`),
      new Translation('de', `Grundeinstellung ${gBasicEnvironment.children.size}`),
    ]);
    item.addChild(
      new OptionsQuestion(
        `components_${gBasicEnvironment.children.size}`,
        [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
        MedoOptionMap.tech_components
      )
    );
    item.addChild(
      new OpenQuestion(`manufacturer_${gBasicEnvironment.children.size}`, [
        new Translation('en', 'Manufacturer'),
        new Translation('de', 'Hersteller'),
      ])
    );

    const itemDate = new OpenQuestion(`comment_be_${gBasicEnvironment.children.size}`, [
      new Translation('en', 'Comment'),
      new Translation('de', 'Kommentar'),
    ]);
    item.addChild(itemDate);

    const itemBuisnes = new OptionsQuestion(
      `our_buisness_${gBasicEnvironment.children.size}`,
      [new Translation('en', 'Our Buisness'), new Translation('de', 'Durch uns bedient')],
      MedoOptionMap.yes_no
    );
    itemBuisnes.addChangeListener(generateBasicEvent);
    item.addChild(itemBuisnes);

    gBasicEnvironment.addChild(item);
  };

  const components = new OptionsQuestion(
    'components',
    [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
    MedoOptionMap.tech_components
  );
  basicEnvironmentRow.addChild(components);

  const manufacturer = new OptionsQuestion(
    'manufacturer',
    [new Translation('en', 'Manufacturer'), new Translation('de', 'Hersteller')],
    MedoOptionMap.manufactores
  );
  basicEnvironmentRow.addChild(manufacturer);

  const comment = new OpenQuestion('comment_be_0', [
    new Translation('en', 'Comment'),
    new Translation('de', 'Kommentar'),
  ]);
  basicEnvironmentRow.addChild(comment);

  const ourBusines = new OptionsQuestion(
    'our_buisness_0',
    [new Translation('en', 'Our Buisness'), new Translation('de', 'Durch uns bedient')],
    MedoOptionMap.yes_no
  );
  ourBusines.addChangeListener(generateBasicEvent);
  basicEnvironmentRow.addChild(ourBusines);

  gBasicEnvironment.addChild(basicEnvironmentRow);
  return gBasicEnvironment;
};
