export const bootstrapCustomerSettingsTemplate = `
locals {
  two_room_setup = false

  customer_cidr = "{{c_network_env.c_gp_setup.network_setup.value}}"

  customer_network = {
    customer_vlan      = {{c_network_env.c_gp_setup.vlan_setup.value}}
    customer_cidr      = local.customer_cidr
    customer_gateway   = "{{c_network_env.c_gp_setup.gateway_setup.value}}"
    customer_dns       = "{{c_network_env.c_gp_setup.dns_setup.value}}"
    customer_shared_ip = "{{c_network_env.c_gp_setup.shared_ip_setup.value}}"
    customer_interface = "vlan_customer"
  }

  cpe_cidr = local.two_room_setup ? local.zones.management.availability_zones.cpe_a.cidr : local.zones.management.availability_zones.cpe.cidr

  cpe_network = {
    cpe_cidr               = local.cpe_cidr
    cpe_shared_internal_ip = cidrhost(local.cpe_cidr, 2)
    cpe_interface          = "vlan_exanio"
    exanio_cidr            = local.zones.management.availability_zones.exanio.cidr
  }

  node_size = "medium"

  agents = [
{{#agents}}
  {
    offset          = {{agent.offset}}
    customer_offset = {{agent.customer_offset}}
    host_version    = 1
    host     = {{agent.setup.value}}
    ilo_name = "{{agent.sn.value}}"
    ilo_ip   = "{{agent.redfish.value}}"
  },
{{/agents}}
  ]


  opsanio_agents = {
    for idx, agent in local.agents :
    "agent-\${idx + 1}" => {
      index : idx,
      offset : agent.offset,
      customer_offset : agent.customer_offset
      host_version : agent.host_version
      host : agent.host
      ilo_name : agent.ilo_name
      ilo_ip : agent.ilo_ip

      # WireGuard Settings
      endpoint : aws_route53_record.mekan.fqdn
      port : local.common_tags.network_slot + 2000
      interface : "wg0"
      allowed_ips : "\${local.zones.management.availability_zones.exanio.cidr}, \${cidrhost(local.zones.management.availability_zones.transfer.cidr, 1)}/32"

      # WireGuard network
      transfer_cidr : local.zones.management.availability_zones.transfer.cidr
      transfer_gw : cidrhost(local.zones.management.availability_zones.transfer.cidr, 1)
      transfer_ip : cidrhost(local.zones.management.availability_zones.transfer.cidr, idx + 2)
      transfer_vlan : local.common_tags.network_slot

      # Network Data in exanio / rz side
      exanio_cidr : local.cpe_network.exanio_cidr
      exanio_ip : cidrhost(
        local.zones.management.availability_zones.cpe.cidr,
        agent.offset + (local.two_room_setup ? 4 : (agent.offset % 2 != 0 ? 4 : 36))
      )
      exanio_vlan : local.common_tags.network_slot

      # CPE Network
      cpe_shared_ip : local.cpe_network.cpe_shared_internal_ip
      cpe_cidr : local.cpe_cidr
      cpe_vlan : local.zones.management.availability_zones.cpe_a.vlan
      cpe_availability_zone : local.two_room_setup ? agent.offset % 2 != 0 ? local.zones.management.availability_zones.cpe_a.az_short : local.zones.management.availability_zones.cpe_b.az_short : "a0"

      # Customer Network
      customer_cidr : local.customer_network.customer_cidr
      customer_gateway : local.customer_network.customer_gateway
      customer_vlan : local.customer_network.customer_vlan
      customer_ip : cidrhost(local.customer_network.customer_cidr, agent.customer_offset)
      customer_shared_ip : local.customer_network.customer_shared_ip

      #K8s Network
      k8s_cidr : cidrsubnet("10.42.0.0/16", 8, idx + 3)
    }
  }

  retantion = {
    elasti_flow : {{retentions.retentions_flows}}
    wazuh : {{retentions.retentions_wazuh}}
    codex : {{retentions.retentions_codex}}
    flows : {{retentions.retentions_elasti_flow}}
  }
}
`;
