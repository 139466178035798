import { type EvaluatorValue } from '../base/evaluator'
import { Fact, type FactValue } from '../base/fact'
import { type I18nContext } from '../i18n/message_context'
import { type ExecutionContext } from '../runtime'
import { Severity } from './diagnostic'
import { Diagnostics } from './diagnostics'

export class ValidationContext {
  diagnostics: Diagnostics
  history: any[] = new Array<any>()
  private _fact = new Fact<EvaluatorValue>('')
  ctx: ExecutionContext | undefined

  constructor (fact: Fact<EvaluatorValue>) {
    this.diagnostics = new Diagnostics()
    this._fact = fact
  }

  public get value (): FactValue {
    return this._fact.raw
  }

  public get hasError (): boolean {
    for (const diag of this.diagnostics) {
      if (diag.severity === Severity.Error) {
        return true
      }
    }
    return false
  }

  public get hasProblems (): boolean {
    for (const diag of this.diagnostics) {
      if (diag.severity > Severity.Info) {
        return true
      }
    }
    return false
  }

  public get valid (): boolean {
    for (const diag of this.diagnostics) {
      if (diag.severity > Severity.Info) {
        return false
      }
    }
    return true
  }

  public get fact (): Fact<EvaluatorValue> {
    return this._fact
  }

  public set fact (fact: Fact<EvaluatorValue>) {
    this._fact = fact
  }

  public setFactValue (val?: EvaluatorValue, i18nContext?: I18nContext): ValidationContext {
    if (this.fact) {
      this.fact.setValue(val, i18nContext)
    } else {
      this.fact = new Fact<EvaluatorValue>(val)
    }
    this.diagnostics = new Diagnostics()
    return this
  }

  setCurrent (current: any): this {
    if ((this.history.length === 0) || (this.history[this.history.length - 1] !== current)) {
      this.history.push(current)
    }
    return this
  }

  get current (): any | undefined {
    if (this.history.length > 0) {
      return this.history[this.history.length - 1]
    }
    return undefined
  }
}
