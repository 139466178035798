import { type Item } from '../base'
import { ComplexType } from '../base/complex'
import { Form } from '../complex/form'
import { Translation } from '../i18n'

export class Generic extends ComplexType {
  constructor (init?: any) {
    super('Generic', init)
  }

  static getForm (): Item {
    const c = new Form('Generic', [new Translation('en', 'Generic'), new Translation('de', 'Generic')])
    return c
  }
}
