import moment, { type Moment } from 'moment'
import Mustache from 'mustache'
import { type FactValue } from '../base'
import { PATH_SEPARATOR } from '../base/const'
import { validPath } from '../base/identifiable'
import { type Serializable } from '../base/serializable'
import { type Identifiable } from '../base/types'
import { DEFAULT_LANGUAGE } from './const'
import { Translation, type Translations } from './translation'
import { type Value } from './value'

// see https://github.com/moment/moment/tree/develop/locale
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/ru'

import 'moment/locale/de-at'
import 'moment/locale/de-ch'
import 'moment/locale/en-gb'
import { type OptionMap } from './options_map'

export class I18nContext {
  _locale!: Intl.Locale
  numberFormat!: Intl.NumberFormat
  numberGroupSeparator: string = ','
  numberGroupSeparatorRe: RegExp = /,/g
  numberFractionSeparator: string = '.'
  numberFractionSeparatorRe: RegExp = /\./g
  listSeparator: string = ','
  dateFormat!: moment.Locale

  view: any = {}
  size: number = 0
  static fallbackLocale: Intl.Locale = new Intl.Locale('en-US')
  static _global: I18nContext

  constructor (locale?: Intl.Locale | string | undefined) {
    if (locale === undefined) {
      locale = I18nContext.fallbackLocale
    }
    if (typeof locale === 'string') {
      locale = new Intl.Locale(locale)
    }
    this.locale = locale
  }

  public static get Global (): I18nContext {
    if (!I18nContext._global) {
      I18nContext._global = new I18nContext(new Intl.Locale('en-US'))
    }
    return I18nContext._global
  }

  set locale (locale: Intl.Locale) {
    this._locale = locale
    this.numberFormat = new Intl.NumberFormat(locale.baseName)
    const parts = this.numberFormat.formatToParts(10000.12)
    parts.forEach((part: Intl.NumberFormatPart) => {
      if (part.type === 'group') {
        this.numberGroupSeparator = part.value
        this.numberGroupSeparatorRe = this.sepRegex(part.value)
      } else if (part.type === 'decimal') {
        this.numberFractionSeparator = part.value
        this.numberFractionSeparatorRe = this.sepRegex(part.value)
      }
    })

    const list = ['a', 'b']; let str
    if (list.toLocaleString !== undefined) {
      str = list.toLocaleString()
      if (str.indexOf(';') > 0 && !str.includes(',')) {
        this.listSeparator = ';'
      }
    }
    this.dateFormat = moment.localeData([this.locale.baseName, this.language] as string[])
  }

  get locale (): Intl.Locale {
    return this._locale
  }

  private sepRegex (val: string): RegExp {
    if ('[\\^$.|?*+()'.includes(val)) {
      val = '\\' + val
    }
    return new RegExp(val, 'g')
  }

  // checks if the argument matches the current language
  matchesLanguage (t: TextMessage | FactValue | undefined): boolean {
    if (t === undefined) {
      return false
    }
    if ((t instanceof Translation) || (t instanceof Message)) {
      return matchesLanguage(t, this.locale.language ?? 'unknown', true)
    }
    return false
  }

  render (t: TextMessage | FactValue | undefined): string {
    if (t === undefined) {
      return ''
    }
    if (typeof (t) === 'string') {
      const msg = MessageCollection.Global.get(t)
      if (msg !== undefined) {
        t = msg
      } else {
        return t
      }
    }

    if (t === undefined) {
      return ''
    }

    if (typeof (t) === 'number') {
      if (Number.isNaN(t)) {
        return 'NaN'
      }
      if (Number.isInteger(t)) {
        return (Math.trunc(t).toLocaleString(this.locale.baseName))
      }
      return (t.toLocaleString(this.locale.baseName))
    }

    if (t instanceof Date) {
      return moment(t).format(this.dateFormat.longDateFormat('L'))
    }

    if (t instanceof Message) {
      return t.render(this)
    } else if (t instanceof Translation) {
      return t.message
    } else if (Array.isArray(t)) {
      if (t.length === 0) {
        return ''
      }
      const result: string[] = new Array<string>()
      let translation: Translation | undefined
      for (let i = 0; i < t.length; i++) {
        const item = t[i]
        if (item instanceof Translation) {
          if (translation === undefined) {
            translation = item
          }
          if (item.language === this.language) {
            return item.message
          } else if ((item.language === DEFAULT_LANGUAGE)) {
            translation = item
          }
        }
        result.push(this.render(item))
      }
      if (translation !== undefined) {
        return translation.message
      }
      return result.join(this.listSeparator + ' ')
    }

    if (I18nContext.Global === this) {
      if (typeof t.toString === 'function') {
        return t.toString()
      }
      return String(t)
    }
    return `${I18nContext.Global.render(t)}`
  }

  [Symbol.toStringTag]: string = 'AnketaI18nContext'

  clear (): void {
    this.view = {}
    this.size = 0
  }

  delete (key: string): boolean {
    if (Object.prototype.hasOwnProperty.call(this.view, key)) {
      /* eslint-disable @typescript-eslint/no-dynamic-delete */
      delete this.view[key]
      /* eslint-enable @typescript-eslint/no-dynamic-delete */
      this.size--
      return true
    }
    return false
  }

  forEach (notifyfn: (value: Value, key: string, map: Map<string, Value>) => void, thisArg?: any): void {
    const map = new Map<string, Value>()
    for (const key in this.view) {
      map.set(key, this.view[key])
    }
    map.forEach(notifyfn)
  }

  get (key: string): Value | undefined {
    if (Object.prototype.hasOwnProperty.call(this.view, key)) {
      return this.view[key]
    }
    return undefined
  }

  has (key: string): boolean {
    return Object.prototype.hasOwnProperty.call(this.view, key)
  }

  set (key: string, value: Value): this {
    if (!Object.prototype.hasOwnProperty.call(this.view, key)) {
      this.size++
    }
    this.view[key] = value
    return this
  }

  normalizeNumberString (val: string): string {
    if ((val === undefined) || (val === 'NaN')) {
      return val
    }
    if (this.numberGroupSeparator !== '') {
      val = val.replace(this.numberGroupSeparatorRe, '')
    }
    if (this.numberFractionSeparator !== '.') {
      val = val.replace(this.numberFractionSeparatorRe, '.')
    }
    return val
  }

  parseInt (val: string): number {
    val = this.normalizeNumberString(val)
    return parseInt(val)
  }

  parseFloat (val: string): number {
    val = this.normalizeNumberString(val)
    return parseFloat(val)
  }

  parseDate (val: string): Date {
    return this.parseMoment(val).toDate()
  }

  parseMoment (val: string): Moment {
    return moment(val, 'L', this.locale.baseName)
  }

  setNumber (key: string, value: number | bigint): this {
    if (!Object.prototype.hasOwnProperty.call(this.view, key)) {
      this.size++
    }
    this.view[key] = (raw?: boolean) => {
      return raw === true ? value : this.formatDecimal(value)
    }
    return this
  }

  setPercent (key: string, value: number | bigint): this {
    if (!Object.prototype.hasOwnProperty.call(this.view, key)) {
      this.size++
    }
    this.view[key] = (raw?: boolean) => {
      return raw === true ? value : this.formatPercent(value)
    }
    return this
  }

  setCurrency (key: string, value: number | bigint, currency: string): this {
    if (!Object.prototype.hasOwnProperty.call(this.view, key)) {
      this.size++
    }
    this.view[key] = (raw?: boolean) => {
      return raw === true ? value : this.formatCurrency(value, currency)
    }
    return this
  }

  get language (): string {
    const lng = typeof (this.locale.language) === 'string' ? this.locale.language : DEFAULT_LANGUAGE
    return lng.split('-')[0].toLowerCase()
  }

  // see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
  formatNumber (value: number | bigint, options?: Intl.NumberFormatOptions): string {
    if (options === undefined) {
      options = {
        useGrouping: true,
        style: 'decimal'
      }
    }
    if (typeof (options.style) !== 'string') {
      options.useGrouping = true
      options.style = 'decimal'
    }
    return Intl.NumberFormat([this.locale.baseName + '', I18nContext.fallbackLocale.baseName + ''], options).format(value)
  }

  formatDecimal (value: number | bigint): string {
    const options: Intl.NumberFormatOptions = {
      useGrouping: true,
      style: 'decimal'
    }
    if (!Number.isInteger(value)) {
      options.minimumFractionDigits = 2
    }
    return this.formatNumber(value, options)
  }

  formatPercent (value: number | bigint): string {
    const options: Intl.NumberFormatOptions = {
      useGrouping: true,
      style: 'percent'
    }
    return this.formatNumber(value, options)
  }

  formatCurrency (value: number | bigint, currency: string): string {
    const options: Intl.NumberFormatOptions = {
      useGrouping: true,
      style: 'currency',
      currency
    }
    return Intl.NumberFormat([this.locale.baseName + '', I18nContext.fallbackLocale.baseName + ''], options).format(value)
  }

  // unimplemented map functions

  entries (): IterableIterator<[string, Value]> {
    throw new Error('Method not implemented.')
  }

  keys (): IterableIterator<string> {
    throw new Error('Method not implemented.')
  }

  values (): IterableIterator<Value> {
    throw new Error('Method not implemented.')
  }

  [Symbol.iterator] (): IterableIterator<[string, Value]> {
    throw new Error('Method not implemented.')
  }
}

export type TextMessage = Translation | Translations | Message | string
export type Options = string | Map<string, TextMessage>

export class Message implements Identifiable, Serializable {
  private readonly translations: Map<string, string>
  id: string
  public constructor (id: string, message: string, language?: string)
  public constructor (id: string)
  public constructor (id: string, translations: Translations)

  public constructor (...args: any[]) {
    this.id = args[0]

    this.translations = new Map<string, string>()
    if (args.length === 1) {
      return
    }
    let language
    let message = 'N/A'
    if (args.length >= 1) {
      message = args[1]
    }

    if (args.length === 2 && Array.isArray(args[1])) {
      const translations = args[1] as Translations
      translations.forEach((v: Translation) => {
        this.setTranslation(v.language, v.message)
      })
      return
    }

    if (args.length >= 2) {
      language = args[2]
    }
    if (language === undefined) {
      language = DEFAULT_LANGUAGE
    }
    this.setTranslation(language.toLowerCase(), message)
  }

  static load (o: any): Message {
    if (typeof o === 'string') {
      o = JSON.parse(o)
    }
    const m = new Message('t_m_p')
    m.read(o)
    return m
  }

  public read (o: any): this {
    for (const lng in o) {
      this.setTranslation(lng, o[lng])
    }
    return this
  }

  save (): any {
    return Object.fromEntries(this.translations)
  }

  public setTranslation (language: string, message: string): void {
    message = message.trim()
    if (message === '') {
      throw new Error('message must not be empty')
    }
    this.translations.set(language.toLowerCase(), message)
  }

  public getTranslation (language?: string, strict?: boolean): string {
    let val: string | undefined
    if (typeof (language) === 'string') {
      language = language.toLowerCase()
      // return requested language
      val = this.translations.get(language)
      if (val !== undefined) {
        return val
      }
    }

    if (strict === true) {
      throw new Error('could not get translation')
    }

    // rerturn default if given
    if (language !== DEFAULT_LANGUAGE && (this.translations.has(DEFAULT_LANGUAGE))) {
      val = this.translations.get(DEFAULT_LANGUAGE)
      if (val !== undefined) {
        return val
      }
    }
    // return first available
    if (this.translations.size === 1) {
      /* eslint-disable no-unreachable-loop */
      for (val in this.translations) {
        return val
      }
      /* eslint-enable no-unreachable-loop */
    }
    throw new Error('could not get translation')
  }

  public toString (): string {
    return this.render(I18nContext.Global)
  }

  public render (context?: I18nContext | any): string {
    let view = {}
    if (context instanceof I18nContext) {
      view = context.view
    } else {
      if (context !== undefined) {
        view = context
      }
    }
    if (context === undefined) {
      context = I18nContext.Global
    }

    return Mustache.render(this.getTranslation(context.language), view, undefined, ['{', '}'])
  }
}

export function validText (text: TextMessage): boolean {
  if (typeof (text) === 'string') {
    return validPath.test(text)
  } else if (text instanceof Message) {
    return validPath.test(text.id)
  } else if (text instanceof Translation) {
    return true
  } else if (Array.isArray(text)) {
    return text.length > 0
  }
  return false
}

export function matchesLanguage (msg: TextMessage, language: string, strict?: boolean): boolean {
  if (msg instanceof Message) {
    try {
      msg.getTranslation(language, true)
      return true
    } catch {
      return false
    }
  }
  if (msg instanceof Translation) {
    return msg.language.toLowerCase() === language.toLowerCase()
  }
  return false
}

export function saveTextMessage (msg: TextMessage): any {
  if (typeof msg === 'string') {
    return msg
  } else if (msg instanceof Message) {
    return msg.save()
  } else if (msg instanceof Translation) {
    return msg.save()
  } else if (Array.isArray(msg)) {
    return msg
  }
  throw new Error('Unsupport message')
}

export function readTextMessage (o: any, path: string): TextMessage {
  if (typeof o === 'string') {
    return o
  }
  if (!o) {
    throw new Error('Can not read text message from undefined')
  }

  if (Array.isArray(o)) {
    const t = new Array<Translation>()
    o.forEach((trans: Translation) => {
      t.push(new Translation(trans.language, trans.message))
    })
    return t
  }
  if (Object.prototype.hasOwnProperty.call(o, 'id')) {
    const msg = Message.load(o)
    msg.id = path
    return msg
  }

  const t = new Translation('_t_m_p_', '')
  t.read(o)
  return t
}

export class MessageCollection implements Serializable {
  items: Map<string, Message>
  static readonly lib_name = 'default.library'
  static readonly edit = 'default.edit'
  static readonly cancel = 'default.cancel'
  static readonly save = 'default.save'

  constructor () {
    this.items = new Map<string, Message>()
    this.setTranslations(MessageCollection.lib_name, [new Translation('en', 'anketa'), new Translation('de', 'anketa')])
    this.setTranslations(MessageCollection.edit, [new Translation('en', 'edit'), new Translation('de', 'bearbeiten')])
    this.setTranslations(MessageCollection.cancel, [new Translation('en', 'cancel'), new Translation('de', 'Abbrechen')])
    this.setTranslations(MessageCollection.save, [new Translation('en', 'save'), new Translation('de', 'Speichern')])
  }

  getOptions (options: Options): OptionMap {
    const result = new Map<string, TextMessage>()
    if (typeof (options) === 'string') {
      const messages = this.messagesWithPrefix(options)
      messages.forEach((m, k) => {
        if (m !== undefined) {
          const path = k.split(PATH_SEPARATOR)
          result.set(path[path.length - 1], m)
        }
      })
    }
    return result
  }

  get (path: string): Message | undefined {
    const result = this.items.get(path)
    if ((result === undefined) && (this !== MessageCollection.Global)) {
      return MessageCollection.Global.get(path)
    }
    return result
  }

  add (path: string, item: Message): Map<string, Message> {
    return this.items.set(path, item)
  }

  has (path: string): boolean {
    return this.items.has(path)
  }

  set (path: string, value: Message): this {
    this.items.set(path, value)
    return this
  }

  setTranslations (path: string, translations: Translations): this {
    const message = new Message(path, translations)
    return this.set(path, message)
  }

  get size (): number {
    return this.items.size
  }

  entries (): IterableIterator<[string, Message]> {
    return this.items.entries()
  }

  messagesWithPrefix (prefix: string): Map<string, Message> {
    if ((prefix !== '') && (!prefix.endsWith(PATH_SEPARATOR))) {
      prefix = prefix + PATH_SEPARATOR
    }
    const result = new Map<string, Message>()
    this.items.forEach((item, k) => {
      if (k.startsWith(prefix)) {
        if (item !== undefined) {
          result.set(k, item)
        }
      }
    })
    return result
  }

  keys (): IterableIterator<string> {
    return this.items.keys()
  }

  values (): IterableIterator<Message> {
    return this.items.values()
  }

  readObject (path: string, o: any): void {
    if (path !== '') {
      path = path + PATH_SEPARATOR
    }
    let done = false
    for (const key in o) {
      const sub = o[key]
      for (const subKey in sub) {
        if (typeof (sub[subKey]) === 'string') {
          const msg = new Message(path + key)
          msg.read(sub)
          this.set(path + key, msg)
          done = true
        }
      }
      if (done) {
        done = false
        continue
      }
      this.readObject(path + key, o[key])
    };
  }

  read (o: any): this {
    this.items.clear()
    if (typeof (o) === 'string') {
      o = JSON.parse(o)
    }

    this.readObject('', o)

    return this
  }

  save (): any {
    const result = {} as any
    this.items.forEach((item: Message, path: string) => {
      let parent = result
      const parts = path.split(PATH_SEPARATOR)
      for (let i = 0; i < parts.length - 1; i++) {
        if (!Object.prototype.hasOwnProperty.call(parent, parts[i])) {
          parent[parts[i]] = {}
        }
        parent = parent[parts[i]]
      }
      parent[parts[parts.length - 1]] = item.save()
    })
    return result
  }

  private static _instance: MessageCollection
  public static get Global (): MessageCollection {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this())
  }
}
