import {
  Group,
  MultiSelectOptionsQuestion,
  OptionsQuestion,
  Section,
  Translation,
} from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';
import { generateTool } from './tool';

export const generateBackup = () => {
  const sBackup = new Section('backup', [
    new Translation('en', 'Backup and restore'),
    new Translation('de', 'Backup und Wiederherstellung'),
  ]);

  const solution = new MultiSelectOptionsQuestion(
    `backup_solution`,
    [new Translation('en', 'Software'), new Translation('de', 'Software')],
    MedoOptionMap.backup_software
  );
  solution.help = [
    new Translation('en', 'Which backup software do you use to back up and restore your VMs?'),
    new Translation(
      'de',
      'Welche Backupsoftware verwenden Sie für das Backup und die Wiederherstellung Ihrer VMs?'
    ),
  ];
  sBackup.addChild(solution);

  const optimization = new Group('optimization_bck', [
    new Translation('en', 'Backup and Monitoring optimization'),
    new Translation('de', 'Backup und Monitoring optimierung'),
  ]);

  const backTools = new OptionsQuestion(
    `bck_tools`,
    [new Translation('en', 'Other Tools'), new Translation('de', 'Andere Tools')],
    MedoOptionMap.yes_no
  );
  backTools.help = [
    new Translation(
      'en',
      'Do you use open source or low-cost third-party tools for backup and monitoring instead of the more expensive VMware solutions?'
    ),
    new Translation(
      'de',
      'Verwenden Sie Open-Source- oder kostengünstige Drittanbieter-Tools für Backup und Monitoring anstelle der teureren VMware-Lösungen?'
    ),
  ];
  optimization.addChild(backTools);
  optimization.addChild(generateTool('backup'));

  sBackup.addChild(optimization);
  return sBackup;
};
