export const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const deduplicateArray = (array: any[], key: string) => {
    const seen = new Set();
    return array.filter(item => {
        const keyValue = item[key];
        if (seen.has(keyValue)) {
            return false;
        } else {
            seen.add(keyValue);
            return true;
        }
    });
};

export const getSubscriptionColor = (percent: number) => {
    if (percent >= 75) {
        return 'success';
    } else if (percent >= 50) {
        return 'warning';
    } else {
        return 'danger';
    }
};