import type jsep from 'jsep'
import { VM as VM2, type VMScript } from 'vm2'
import { Evaluator, type EvaluatorValue } from '../base/evaluator'
import { type Hierarchical, type Item } from '../base/types'
import { CatalogObject } from '../catalog_object'
import { type Options } from '../i18n'
import { I18nContext, MessageCollection } from '../i18n/message_context'
import { type OptionMap } from '../i18n/options_map'
import { QuestionObject } from '../question_object'
import { evaluate } from './parser'

export class ExecutionContext {
  i18nContext: I18nContext
  catalog: Item
  readonly messages: MessageCollection
  cacheToken: number = 0
  evaluationId: number = 0
  readonly vm?: VM2

  // hold what every you additionaly need here
  custom: any

  constructor (catalog?: Item, i18nContext?: I18nContext, messages?: MessageCollection) {
    if (catalog === undefined) {
      catalog = new CatalogObject('empty', 'Empty')
    }
    this.catalog = catalog
    if (i18nContext !== undefined) {
      this.i18nContext = i18nContext
    } else {
      this.i18nContext = I18nContext.Global
    }
    if (messages !== undefined) {
      this.messages = messages
    } else {
      this.messages = MessageCollection.Global
    }
    if (typeof process === 'object') {
      // see: https://github.com/patriksimek/vm2#nodevm
      this.vm = new VM2({
        timeout: 1000,
        allowAsync: false,
        sandbox: {}
      })
    }
    this.evaluate()
  }

  getOptions (options: Options): OptionMap {
    return this.messages.getOptions(options)
  }

  public getNextCacheToken (): number {
    return this.cacheToken++
  }

  private deepen (obj: any): any {
    const result = {} as any

    // For each object path (property key) in the object
    for (const objectPath in obj) {
      // Split path into component parts
      const parts = objectPath.split('.')

      // Create sub-objects along path as needed
      let target = result
      while (parts.length > 1) {
        const part = parts.shift()
        if (typeof part === 'string') {
          target = target[part] = target[part] || {}
        }
      }
      // Set value at end of path
      try {
        target[parts[0]] = obj[objectPath]
      } catch (e) {
        console.warn('deepen', objectPath, obj, parts, e)
      }
    }
    // some additional veriables
    return result
  }

  public evaluate (): void {
    const resolved = {} as any
    function getOpenResolves (dependencies: string[]): string[] {
      const result = [] as string[]
      dependencies.forEach((d: string) => {
        if (!(d in resolved)) {
          result.push(d)
        }
      })
      return result
    }

    const items = this.catalog.children.getFlatMap()
    const toResolveEvaluators = {} as any
    resolved.now = new Date()

    items.forEach((item: Hierarchical) => {
      const id = item.path.substring(this.catalog.id.length + 1)
      if (item instanceof QuestionObject) {
        if (item.factEvaluator instanceof Evaluator) {
          toResolveEvaluators[id + '.value'] = item.factEvaluator
          resolved[id + '.value'] = undefined
          resolved[id + '.old'] = item.fact.raw
          // debug('EVAL', id, item.factEvaluator.formula)
        } else {
          resolved[id + '.value'] = item.fact.raw
          resolved[id + '.old'] = item.fact.raw
          // console.debug('FACT', id + '.value', item.fact)
        }
      }
      for (const [key, value] of Object.entries(item)) {
        if ((key === '_fact') || (key === 'fact') || (key === 'factEvaluator')) {
          continue
        }
        if (value instanceof Evaluator) {
          let evalId = id
          if (key.endsWith('Evaluator')) {
            evalId = id + '.' + key.substring(0, key.length - 9)
            resolved[evalId] = value.getter()
          } else {
            evalId = '$' + id + '.' + key
          }
          toResolveEvaluators[evalId] = value
          // console.debug('EVAL', evalId, value)
        }
      }
      // iterate attributes...
    })

    let nResolved = 0
    do {
      nResolved = 0
      const deepened = this.deepen(resolved)
      for (const k in toResolveEvaluators) {
        const evaluator = toResolveEvaluators[k] as Evaluator<EvaluatorValue>
        const openResolves = getOpenResolves(evaluator.dependencies)

        if ((!openResolves) || (openResolves.length === 0)) {
          let result
          try {
            if (typeof process === 'object') {
              for (const k in deepened) {
                (this.vm as VM2).setGlobal(k, deepened[k])
              }
              result = (this.vm as VM2).run(evaluator.script as VMScript)
            } else {
              result = evaluate(evaluator.script as jsep.Expression, deepened)
            }
          } catch (e) {
            console.debug('eval', e)
            result = undefined
          }
          const path = k.split('.')
          let target = deepened
          for (let i = 0; i < path.length; i++) {
            target = target[path[i]]
          }
          target = result
          resolved[k] = result
          // console.trace('RESULT', k, result)
          if (toResolveEvaluators[k] instanceof Evaluator) {
            toResolveEvaluators[k].setter(resolved[k])
          }
          nResolved++
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete toResolveEvaluators[k]
        }
      }
    } while ((toResolveEvaluators.length > 0) && (nResolved > 0))

    // console.trace('toResolve', toResolveEvaluators, resolved)
    this.evaluationId++
    items.forEach((item: Hierarchical) => {
      if (item instanceof QuestionObject) {
        item.evaluationId = this.evaluationId
      }
    })
  }
}
