import {
  DateQuestion,
  Group,
  OpenQuestion,
  OptionsQuestion,
  Row,
  Section,
  Translation,
} from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';

export const generateTechEnv = (parentId: string) => {
  const gEnvReq = new Group(`${parentId}_tech_req`, [
    new Translation('en', 'Site Requirements'),
    new Translation('de', 'Anforderung an den Standort'),
  ]);

  gEnvReq.help = [
    new Translation('en', 'Does the site meet all the requirements for the appliance?'),
    new Translation('de', 'Erfüllt der Standort alle Anforderungen für die Appliance?'),
  ];

  const rOwnDevices = new Row(`hw_${parentId}`, [
    new Translation('en', 'Acces to the location'),
    new Translation('de', 'Zugang zu dem Standort'),
  ]);

  const appontment = new DateQuestion('end_support', [
    new Translation('en', 'Appointment'),
    new Translation('de', 'Termin'),
  ]);

  rOwnDevices.addChild(appontment);

  const announced = new OptionsQuestion(
    `announced_${parentId}`,
    [new Translation('en', 'Announced'), new Translation('de', 'Angekündigt')],
    'default.options.yes_no'
  );
  announced.help = [
    new Translation(
      'eng',
      'Have all persons concerned been informed of the appointment and have all accesses been approved?'
    ),
    new Translation(
      'de',
      'Sind alle betroffenen Person über den Termin Informiert und alle zugänge wurden genehmigt?'
    ),
  ];
  rOwnDevices.addChild(announced);

  const ownDevices = new OptionsQuestion(
    `devices_${parentId}`,
    [new Translation('en', 'Own Devices'), new Translation('de', 'Mitnahme eigener Geräte')],
    'default.options.yes_no'
  );
  ownDevices.help = [
    new Translation(
      'eng',
      'Do the devices of autonubil employees have to be registered in advance?'
    ),
    new Translation('de', 'Müssen die Geräte der autonubil Angestellten vorab angemeldet werden?'),
  ];
  rOwnDevices.addChild(ownDevices);

  gEnvReq.addChild(rOwnDevices);

  const gTechEnv = new Section(`${parentId}_tech_env`, [
    new Translation('en', 'Hardware'),
    new Translation('de', 'Hardware'),
  ]);
  const rTechEnv = new Row(`${parentId}_tech_env_0`, [
    new Translation('en', 'Hardware'),
    new Translation('de', 'Hardware'),
  ]);

  const generateTechRow = () => {
    const oldTrigger: OpenQuestion = gTechEnv.children
      .get(`${parentId}_tech_env_${gTechEnv.children.size - 1}`)
      ?.children.get(`${parentId}_components_${gTechEnv.children.size - 1}`) as OpenQuestion;
    oldTrigger.removeChangeListener(generateTechRow);

    const item = new Row(`${parentId}_tech_env_${gTechEnv.children.size}`, [
      new Translation('en', `${gTechEnv.children.size + 1}. Tech env`),
      new Translation('de', `${gTechEnv.children.size + 1}. Technische Landschaft`),
    ]);
    item.addChild(
      new OpenQuestion(`${parentId}_manufactory_name_${gTechEnv.children.size}`, [
        new Translation('en', 'Manufactory'),
        new Translation('de', 'Hersteller'),
      ])
    );

    const components = new OptionsQuestion(
      `${parentId}_components_${gTechEnv.children.size}`,
      [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
      MedoOptionMap.tech_components
    );
    components.addChangeListener(generateTechRow);
    item.addChild(components);

    gTechEnv.addChild(item);
  };

  gTechEnv.addChild(rTechEnv);

  const internalContactName = new OpenQuestion(`${parentId}_manufactory_name_0`, [
    new Translation('en', 'Manufactory'),
    new Translation('de', 'Hersteller'),
  ]);
  rTechEnv.addChild(internalContactName);

  const components = new OptionsQuestion(
    `${parentId}_components_0`,
    [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
    MedoOptionMap.tech_components
  );
  components.addChangeListener(generateTechRow);
  rTechEnv.addChild(components);

  return { gTechEnv, gEnvReq };
};
