import React, { ReactNode } from "react";
import { KeycloakInstance } from "keycloak-js";
import { WarningPage } from "../../components/pages/warning";
import { AuthorizeItemType } from "../../_types";



export const AuthorizeItem = ({ ctx, roleName, children }: AuthorizeItemType): JSX.Element => {
    if (ctx && ctx.resourceAccess && ctx.resourceAccess["anketa-demo"]) {
        const roles = ctx.resourceAccess["anketa-demo"].roles;
        if (roles.includes(roleName)) {
            return <>{children}</>
        } else {
            if (roles.includes("anketa-admin")) {
                // console.log(`${ctx.profile?.username} has full acces to all ressources, he is a admin user!`)
                return <>{children}</>
            } else {
                // console.log(`${ctx.profile?.username} has no acces to ${roleName}!`, roles)
                return <></> || <WarningPage error="403" />
            }
        }
    } else {
        return <></> || <WarningPage error="403" />
    }
}

export const AuthorizeComponent = (ctx: KeycloakInstance | undefined, roleName: string): boolean => {
    if (ctx && ctx.resourceAccess && ctx.resourceAccess["anketa-demo"]) {
        const roles = ctx.resourceAccess["anketa-demo"].roles;
        if (roles.includes(roleName)) {
            // console.log(`[Auth] ${ctx.profile?.username} has acces to ${roleName}.`)
            return true
        } else {
            if (roles.includes("anketa-admin") && roleName !== "anketa-development") {
                // console.log(`[Auth] ${ctx.profile?.username} has full acces to all ressources, he is a admin user!`, roles)
                return true
            } else {
                // console.log(`[Auth] ${ctx.profile?.username} has no acces to ${roleName}!`, roles)
                return false
            }
        }
    } else {
        return false
    }
}