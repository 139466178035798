import React, { useState } from 'react';
import {
  EuiBadge,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { useSupport } from '../../../_functions/support/support';
import { UserCell } from './usercell';
import { UpdateOnboardUser } from '../create/editing/edit';
import {
  OnboardingRequest,
  OnboardingRequestStatus,
  OnboardingRequestStatusState,
  OnboardingRequestStatusType,
  UserValues,
} from '../create/types';
import { useKeycloak } from '../../../_functions';
import { UserChange } from './change';

export const getStatusColor = (status: OnboardingRequestStatusState): string => {
  switch (status) {
    case 'approved':
      return 'success';
    case 'pending':
      return 'warning';
    case 'rejected':
      return 'danger';
    default:
      return 'default';
  }
};
export const CreatedUsers = (): JSX.Element => {
  const { createdUsers, deleteUser, createUser, updateUser } = useSupport();
  const { profile } = useKeycloak();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<OnboardingRequest | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const changeModal = (user: OnboardingRequest | null) => {
    setShowModal(!showModal);
    setSelectedItem(user);
    setIsEditing(false);
  };

  const getTypeColor = (status: OnboardingRequestStatusType): string => {
    switch (status) {
      case 'create':
        return 'success';
      case 'update':
        return 'warning';
      case 'delete':
        return 'danger';
      default:
        return 'default';
    }
  };

  const getActionButtons = (row: OnboardingRequest): JSX.Element[] => {
    const state = row.status.state;
    const type = row.status.type;
    const result: JSX.Element[] = [];
    if (state === 'pending') {
      result.push(
        <>
          {row.approver === profile?.email && (
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={'check'}
                aria-label="accept"
                color="success"
                onClick={() => {
                  switch (type) {
                    case 'create':
                      createUser(row.user.id, 'approved');
                      break;
                    case 'delete':
                      deleteUser(row.user.id, 'approved');
                      break;
                    case 'update':
                      updateUser(row.user.id, 'approved');
                      break;
                  }
                }}
              />
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              iconType={'cross'}
              aria-label="reject"
              color="danger"
              onClick={() => {
                if (row.approver === profile?.email) {
                  switch (type) {
                    case 'create':
                      createUser(row.user.id, 'rejected');
                      break;
                    case 'delete':
                      deleteUser(row.user.id, 'rejected');
                      break;
                    case 'update':
                      updateUser(row.user.id, 'rejected');
                      break;
                  }
                } else {
                  switch (type) {
                    case 'create':
                      createUser(row.user.id, 'cancel');
                      break;
                    case 'delete':
                      deleteUser(row.user.id, 'cancel');
                      break;
                    case 'update':
                      updateUser(row.user.id, 'cancel');
                      break;
                  }
                }
              }}
            />
          </EuiFlexItem>
        </>
      );
    } else {
      if (type === 'delete') {
        if (state === 'cancel') {
          result.push(
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={'pencil'}
                aria-label="Edit"
                color="primary"
                onClick={() => {
                  changeModal(row);
                  setIsEditing(true);
                }}
              />
            </EuiFlexItem>
          );
        }
        result.push(
          <>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={'eye'}
                aria-label="Show"
                color="primary"
                onClick={() => {
                  changeModal(row);
                  setIsEditing(false);
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={'trash'}
                aria-label="Delete"
                color="primary"
                onClick={() => {
                  changeModal(row);
                  setIsEditing(false);
                  setIsDeleting(true);
                }}
              />
            </EuiFlexItem>
          </>
        );
      } else {
        result.push(
          <>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={'pencil'}
                aria-label="Edit"
                color="primary"
                onClick={() => {
                  changeModal(row);
                  setIsEditing(true);
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={'eye'}
                aria-label="Show"
                color="primary"
                onClick={() => {
                  changeModal(row);
                  setIsEditing(false);
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={'trash'}
                aria-label="Delete"
                color="primary"
                onClick={() => {
                  changeModal(row);
                  setIsEditing(false);
                  setIsDeleting(true);
                }}
              />
            </EuiFlexItem>
          </>
        );
      }
    }
    return result;
  };

  const columns = [
    {
      field: 'user',
      name: 'User',
      sortable: true,
      truncateText: true,
      render: (user: UserValues, row: OnboardingRequest) => <UserCell user={row} />,
    },
    {
      field: 'agent',
      name: 'Agent',
      truncateText: true,
      render: (agent: string, row: OnboardingRequest) => {
        return (
          <EuiBadge iconType="user" color={agent === profile?.email ? 'primary' : 'hollow'}>
            <EuiText>
              <p>{agent}</p>
            </EuiText>
          </EuiBadge>
        );
      },
    },
    {
      field: 'approver',
      name: 'Approver',
      truncateText: true,
      render: (approver: string, row: OnboardingRequest) => {
        return (
          <EuiBadge iconType="reporter" color={approver === profile?.email ? 'primary' : 'hollow'}>
            <EuiText>
              <p>{approver}</p>
            </EuiText>
          </EuiBadge>
        );
      },
    },
    {
      field: 'status',
      name: 'Status',
      truncateText: true,
      render: (status: OnboardingRequestStatus, row: OnboardingRequest) => {
        return (
          <EuiBadge color={getStatusColor(status.state)}>
            <EuiText>
              <p>{status.type}</p>
            </EuiText>
          </EuiBadge>
        );
      },
    },
    {
      field: 'changes',
      name: 'Changes',
      truncateText: true,
      render: (changes: UserValues, row: OnboardingRequest) => <UserChange user={row} />,
    },
    {
      field: 'actions',
      name: 'Actions',
      render: (user_name: any, row: OnboardingRequest) => {
        return getActionButtons(row);
      },
    },
  ];

  return (
    <EuiFlexItem>
      <EuiPanel>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiBadge color={'primary'}>
              <EuiText>
                <p>person: Me</p>
              </EuiText>
            </EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiBadge color={'hollow'}>
              <EuiText>
                <p>person: Not me</p>
              </EuiText>
            </EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiBadge color={getStatusColor('approved')}>
              <EuiText>
                <p>status: approved</p>
              </EuiText>
            </EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiBadge color={getStatusColor('pending')}>
              <EuiText>
                <p>status: pending</p>
              </EuiText>
            </EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiBadge color={getStatusColor('rejected')}>
              <EuiText>
                <p>status: rejected</p>
              </EuiText>
            </EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiBadge color={getStatusColor('cancel')}>
              <EuiText>
                <p>status: cancel</p>
              </EuiText>
            </EuiBadge>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />
        <hr />
        <EuiSpacer size="s" />
        <EuiInMemoryTable columns={columns} items={createdUsers} />
        {showModal && selectedItem !== null && (
          <UpdateOnboardUser
            user={selectedItem}
            changeModal={changeModal}
            editing={isEditing}
            deleting={isDeleting}
            getTypeColor={getTypeColor}
          />
        )}
      </EuiPanel>
    </EuiFlexItem>
  );
};
