import { Campaign, Companie, UserDetail, UserNewsletter } from "../../_types";
import { OpsTable } from "../../components/tabel"
import { EuiBadge, EuiBasicTableColumn } from "@elastic/eui";
import { useState } from "react";
import { useNewsletter } from "../../_functions";
import { DeleteUserModal } from "../modal";

export const UserTable = (): JSX.Element => {
    const { customers, importUsers } = useNewsletter()
    const [selectedUser, setSelectedUser] = useState<UserDetail>()
    const [modal, setModal] = useState<"delete" | "none">("none")

    const handleClose = () => {
        setModal("none")
    }

    const columnsUser: EuiBasicTableColumn<UserDetail>[] = [
        {
            field: 'title',
            name: 'Title',
            sortable: true,
            truncateText: true,
            render: (title: string, user: UserDetail) => {
                return <div key={`${user.id}:title`} style={{ minWidth: '100px' }}>{title}</div>;
            },
        },
        {
            field: 'firstname',
            name: 'Vorname',
            sortable: true,
            truncateText: true,
            render: (firstname: string, user: UserDetail) => {
                return <div key={`${user.id}:name`} style={{ minWidth: '100px' }}>{firstname}</div>;
            },
        },
        {
            field: 'lastname',
            name: 'Nachname',
            sortable: true,
            truncateText: true,
            render: (lastname: string, user: UserDetail) => {
                return <div key={`${user.id}:lastname`} style={{ minWidth: '100px' }}>{lastname}</div>;
            },
        },
        {
            field: 'email',
            name: 'E-Mail',
            sortable: true,
            truncateText: true,
            render: (value: string, user: UserDetail) => {
                return <div key={`${user.id}:email`} style={{ minWidth: '100px' }}>{value}</div>;
            },
        },
        {
            field: 'company',
            name: 'Unternehmen',
            sortable: true,
            truncateText: true,
            render: (company: Companie, user: UserDetail) => {
                return <div key={`${user.id}:company`} style={{ minWidth: '100px' }}>{company.name}</div>;
            },
        },
        {
            field: 'campaign',
            name: 'Kampange',
            sortable: true,
            truncateText: true,
            render: (campaign: Campaign, user: UserDetail) => {
                if (campaign !== undefined && campaign.name !== null) {
                    return <div key={`${user.id}:campaign`} style={{ minWidth: '100px' }}>{campaign.name}</div>;
                }
            },
        },
        {
            field: 'newsletters',
            name: 'aboniert',
            render: (newsletters: UserNewsletter[], user: UserDetail) => {
                if (newsletters === null || newsletters.length === 0) {
                    return (
                        <p key={`${user.id}:newsletter`}>Keine Abos</p>
                    )
                } else {
                    return (
                        <div>
                            {newsletters.map((news, idx) => {
                                return <EuiBadge key={`${user.id}:newsletter:${idx}`} color={news.subscriber ? "green" : "red"}>{news.name}</EuiBadge>
                            })}
                        </div>
                    )
                }
            },
        },
        {
            field: 'action',
            name: '',
            actions: [
                {
                    name: 'Löschen',
                    description: 'Element löschen',
                    type: 'icon',
                    icon: 'trash',
                    onClick: (item: UserDetail) => {
                        setSelectedUser(item)
                        setModal("delete")
                    },
                },
            ],
            width: '100px',
        }
    ];

    return (
        <>
            <OpsTable
                tableLayout="auto"
                responsive={true}
                items={customers}
                compressed={true}
                columns={columnsUser}
                sorting={true}
                pagination={{
                    pageSizeOptions: [10, 25, 50, 100, 250],
                    initialPageSize: 10,
                }}
                opsSearch={true}
                opsFilterColumns={["campaign", "company", "email", "firstname", "lastname", "newsletters", "title"]}
                opsImportAction={importUsers}
            />
            {modal === "delete" && selectedUser && <DeleteUserModal handleClose={handleClose} selectedUser={selectedUser} />}
        </>
    )
}