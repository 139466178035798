import React, { useState } from 'react';
import { EuiCard, EuiFlexGrid, EuiFlexItem, EuiIcon } from '@elastic/eui';
import { OnboardingTemplate } from '../create/types';
import { useSupport } from '../../../_functions/support/support';

export const SelectTemplate = (): JSX.Element => {
  const { templates } = useSupport();

  const [selectedTemplate, setSelectedTemplate] = useState<OnboardingTemplate | undefined>(
    undefined
  );

  return (
    <EuiFlexGrid>
      {templates.length > 0 &&
        templates.map((t) => {
          return (
            <EuiFlexItem key={`support:onboarding:card:${t.id}`}>
              <EuiCard
                icon={<EuiIcon size="xxl" type="logoSketch" />}
                title={t.name}
                description="Example of a short card description."
                selectable={{
                  onClick: () => {
                    if (selectedTemplate?.name === t.name) {
                      setSelectedTemplate(undefined);
                    } else {
                      setSelectedTemplate(t);
                    }
                  },
                  isSelected: selectedTemplate?.id === t.id,
                }}
              />
            </EuiFlexItem>
          );
        })}
    </EuiFlexGrid>
  );
};
