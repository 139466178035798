import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiPageTemplate, EuiPanel, EuiSpacer } from "@elastic/eui"
import { WarningImage } from "../../asstes/images/warning"
import { CancelImage } from "../../asstes/images/cancle"
import { AuthImage } from "../../asstes/images/auth"
import { ErrMsg } from "../../_types"

export const WarningPage = ({ error }: { error?: string }): JSX.Element => {

    const generateErrorMsg = (): ErrMsg => {
        switch (error) {
            case "403":
                return {
                    msg: <>
                        <h3>404</h3>
                        <p>
                            This is not the web page you are looking for.
                        </p>
                    </>,
                    color: "warning",
                    image: <CancelImage />

                }
            case "511":
                return {
                    msg: <>
                        <h3>511</h3>
                        <p>
                            Authetication Required, redirect to Keycloak...
                        </p>
                    </>,
                    color: "primary",
                    image: <AuthImage />

                }
            default:
                return {
                    msg: <>
                        <p>
                            There was an error loading the Questionnaire application. Please contact your
                            administrator for assistance.
                        </p>
                        <p>{error && error}</p>
                    </>,
                    color: "danger",
                    image: <WarningImage />

                }

        }
    }
    return (
        <EuiPageTemplate template="empty" style={{
            height: "fit-content",
            margin: "20vh auto"
        }}>
            <EuiFlexGroup
                gutterSize="none"
                direction="column"
                responsive={false}>
                <EuiSpacer size="l" />
                <EuiFlexItem style={{ height: "fit-content" }} >
                    <EuiFlexGroup gutterSize="l">
                        <EuiFlexItem grow={3}>
                            <EuiPanel style={{ display: "flex", alignItems: "center", justifyContent: "center" }} tabIndex={0} hasShadow={true}>
                                {generateErrorMsg().image}
                            </EuiPanel>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiPanel color={generateErrorMsg().color}>
                                <EuiEmptyPrompt
                                    iconType="alert"
                                    iconColor={generateErrorMsg().color}
                                    title={<h2>Error Loading Questionnaire</h2>}
                                    body={generateErrorMsg().msg}
                                />
                            </EuiPanel>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPageTemplate>
    )
}