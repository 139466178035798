import { ItemType } from '../base/types'
import { MessageCollection, readTextMessage, saveTextMessage, validText, type TextMessage } from '../i18n'
import { type Storage } from '../storage'
import { PATH_SEPARATOR } from './const'
import { type Fact } from './fact'
import { isQuestion } from './guards'
import { HierarchyObject } from './hierarchy'
import { type Serializable } from './serializable'
import { type Hierarchical, type Identifiable, type Item } from './types'

export abstract class ItemObject extends HierarchyObject implements Hierarchical, Item, Identifiable, Serializable {
  private _type: ItemType
  private _title: TextMessage
  readonly attributes: Map<string, any>
  referenceId?: string

  constructor (id: string, type: ItemType, title: TextMessage) {
    super(id)
    if (!validText(title)) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`invalid title path '${title}'`)
    }
    this._type = type
    this._title = title
    this.attributes = new Map<string, any>()
  }

  public item<T extends Item>(key: string): T | undefined {
    let parts = key.split(PATH_SEPARATOR)
    if ((parts.length > 0) && this.children.has(parts[0])) {
      const child = this.children.get(parts[0]) as T
      if (parts.length === 1) {
        return child
      }
      parts = parts.slice(1)
      return child.item(parts.join(PATH_SEPARATOR))
    }
    return undefined
  }

  public get title (): TextMessage {
    let t: TextMessage = this._title
    if (typeof (t) === 'string') {
      const msg = MessageCollection.Global.get(t)
      if (msg != null) {
        t = msg
      }
    }
    return t
  }

  public set title (title: TextMessage) {
    this._title = title
  }

  public read (o: any): this {
    super.read(o)
    this.attributes.clear()

    this.children.addChangeListener(this.boundOnChildrenChanged)

    if (Object.prototype.hasOwnProperty.call(o, 'attributes')) {
      Object.entries<Map<string, any>>(o.attributes).forEach((entry: [string, any]) => {
        this.attributes.set(entry[0], entry[1])
      })
    }

    ['type', 'title'].forEach((required: string) => {
      if (!Object.prototype.hasOwnProperty.call(o, required)) {
        throw new Error("missing '" + required + "' attribute")
      }
    })
    if (typeof (o.type) === 'string') {
      this._type = (ItemType[o.type] as any) as ItemType
    } else {
      this._type = o.type as ItemType
    }

    this._title = readTextMessage(o.title, this.path)
    return this
  }

  public save (): any {
    let result = super.save()
    result = {
      ...result,
      ...{
        type: ItemType[this.type],
        title: saveTextMessage(this._title)
      }
    }
    if (this.attributes.size > 0) {
      result.attributes = Object.fromEntries(this.attributes)
    }

    return result
  }

  /**
   * Get the type of ths item. After construction the type is readonly.
   *
   * @readonly
   * @type {ItemType}
   * @memberof Item
   */
  get type (): ItemType {
    return this._type
  }

  /**
   * create a deep copy of this item that can be used and altered in another context
   *
   * @return {*}  {this}
   * @memberof Item
   */
  public referenceCopy (): Item {
    const ser = this.save()
    const result = Object.getPrototypeOf(this).constructor()
    result.read(ser)
    return result
  }

  public async loadFacts (store: Storage): Promise<void> {
    if (!store) {
      return
    }

    await new Promise<void>((resolve, reject) => {
      let abort = false
      this.children.forAny((item: Hierarchical) => {
        if (abort) {
          return
        }
        let fact: Fact<any> | undefined
        if (isQuestion(item)) {
          fact = item.fact as Fact<any> | undefined
          /*
        }
        if ((item instanceof ItemObject) && (item.type === ItemType.Question)) {
          const question = (item as any)
          fact = question.fact as Fact<any> | undefined
        } else if (Object.prototype.hasOwnProperty.call(item, 'fact')) {
          fact = ((item as any).fact) as Fact<any> | undefined
        } else if (Object.prototype.hasOwnProperty.call(item, '_fact')) {
          fact = ((item as any)._fact) as Fact<any> | undefined
          */
          if (fact) {
            store.get(item.path).then((readFact) => {
              if (readFact !== null) {
                item.fact.assign(readFact)
              }
            }).catch((reason) => {
              abort = true
              reject(reason)
            })
          }
        }
      })
      resolve()
    })
  }

  public async storeFacts (store: Storage): Promise<void> {
    if (!store) {
      return
    }

    let abort = false
    await new Promise<void>((resolve, reject) => {
      this.children.forAny((item: Hierarchical) => {
        if (abort) {
          return
        }
        let fact: Fact<any> | undefined
        if (isQuestion(item)) {
          fact = item.fact
          if (fact) {
            store.set(item.path, fact).catch((reason) => {
              abort = true
              reject(reason)
            })
          }
        }
      })
      resolve()
    })
  }

  public factsToObject (root?: Item, deepen?: boolean): any {
    if (!root) {
      console.debug('[anketa-core//factsToObject] No root provided, setting root to this')
      root = this as Item
    }

    const convert = (obj: any): any => {
      console.debug('[anketa-core//factsToObject] Converting object:', obj)
      const result = {} as any

      const eachKeyValue = (obj: any, fun: (key: string, value: any) => void): void => {
        for (const i in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, i)) {
            fun(i, obj[i])
          }
        }
      }

      eachKeyValue(obj, (namespace: string, value: any) => {
        const parts = namespace.split('.')
        console.debug('[anketa-core//factsToObject] Parts from splitting:', parts)
        const last = parts.pop()
        if (last) {
          let node = result
          parts.forEach((key: string) => {
            node = node[key] = node[key] || {}
          })
          node[last] = value
        }
      })

      console.debug('[anketa-core//factsToObject] Converted object:', result)
      return result
    }

    const result = {} as any

    root.children.forAny((item: Hierarchical) => {
      let fact: Fact<any> | undefined

      if (item instanceof ItemObject && item.type === ItemType.Question) {
        console.debug('[anketa-core//factsToObject] Item is an ItemObject with type Question:', item)
        fact = (item as any).fact as Fact<any>
      } else if (Object.prototype.hasOwnProperty.call(item, 'fact')) {
        console.debug('[anketa-core//factsToObject] Item has a fact property:', item)
        fact = (item as any).fact as Fact<any>
      } else if (Object.prototype.hasOwnProperty.call(item, '_fact')) {
        console.debug('[anketa-core//factsToObject] Item has a _fact property:', item)
        fact = (item as any)._fact as Fact<any>
      }

      if (fact) {
        const id = item.path.split('.')[0]
        const correctPath = item.path.replace(`${id}.`, '')
        console.debug('[anketa-core//factsToObject] Fact found for item:', { path: correctPath, substring: item.path.substring(this.id.length + 1) })
        result[correctPath] = fact.save()
      }
    })

    if (deepen) {
      console.debug('[anketa-core//factsToObject] Deepening result')
      return convert(result)
    } else {
      console.debug('[anketa-core//factsToObject] Result without deepening:', result)
      return result
    }
  }

  public factsFromObject (facts: any, flatten?: boolean): Item {
    if (!facts) {
      return this
    }
    if (typeof facts === 'string') {
      facts = JSON.parse(facts)
    }
    function flattenObject (obj: any, parentKey?: string): any {
      let result = {} as any
      if (obj) {
        Object.keys(obj).forEach((key) => {
          const value = obj[key]
          if (!value) {
            return result
          }
          const _key = parentKey ? parentKey + '.' + key : key
          if (value && typeof value === 'object') {
            if (Object.prototype.hasOwnProperty.call(value, 'timestamp')) {
              result[_key] = value
            } else {
              result = { ...result, ...flattenObject(value, _key) }
            }
          } else {
            result[_key] = value
          }
        })
        return result
      }
    }
    if (flatten === true) {
      facts = flattenObject(facts)
    }
    if (facts) {
      Object.keys(facts).forEach((id: string) => {
        const item = this.item(id)
        if (!item) {
          // console.warn('Item with id "' + id + '" not found in catalog')
          return
        }
        if (item.type === ItemType.Question) {
          const question = (item as any)
          const loadetFact = facts[id]
          if ((loadetFact?.value !== undefined) && (!question.fact?.timestamp || (question.fact.timestamp < loadetFact.timestamp))) {
            if (question.fact) {
              question.setFactValue(loadetFact.value, undefined, loadetFact.timestamp)
            }
            console.debug('Item with id "' + id + '" set to new loaded value', question.fact)
          }
        }
      })
    }
    return this
  }
}
