import { type Serializable } from '../serializable'

export abstract class ComplexType implements Serializable {
  _complexType: string

  constructor (type: string, init?: any) {
    this._complexType = type
    this.apply(init)
  }

  public apply (init?: any): void {
    if (init) {
      for (const prop in init) {
        if (prop === '_type') {
          continue
        }
        (this as any)[prop] = init[prop]
      }
    }
  }

  public toString (): string {
    return '[ComplexValue(' + this._complexType + ')]'
  }

  public read (o: any): this {
    return this
  }

  public save (): any {
    const result = {} as any
    for (const prop in this) {
      result[prop] = this[prop]
    }
    return result
  }
}
