import { Group, OptionsQuestion, Row, Section, Translation } from "anketa-core";
import { MedoOptionMap } from "../helpers/medoOptionsMap";

export const generateAutomationOrchestration = () => {
    const sAutoOrch = new Section('auto_orch', [new Translation('en', 'Automation and orchestration'), new Translation('de', 'Automatisierung und Orchestrierung')]);
    const rAutoOrch = new Row('r_auto_orch', [new Translation('en', 'Automation and orchestration'), new Translation('de', 'Automatisierung und Orchestrierung')]);

    const autoDep = new OptionsQuestion(`auto_deploy`, [new Translation('en', 'vSphere Auto Deploy'), new Translation('de', 'vSphere Auto Deploy')], MedoOptionMap.yes_no);
    autoDep.help = [
        new Translation('en', 'Do you use vSphere Auto Deploy to provision and manage your ESXi hosts?'),
        new Translation('de', 'Nutzen Sie vSphere Auto Deploy zur Bereitstellung und Verwaltung Ihrer ESXi-Hosts?')
    ];
    rAutoOrch.addChild(autoDep)

    const vRealize = new OptionsQuestion(`vrealize`, [new Translation('en', 'VMware vRealize'), new Translation('de', 'VMware vRealize')], MedoOptionMap.yes_no);
    vRealize.help = [
        new Translation('en', 'Do you use VMware vRealize Suite or other automation tools to manage your vSphere environment?'),
        new Translation('de', 'Setzen Sie VMware vRealize Suite oder andere Automatisierungstools zur Verwaltung Ihrer vSphere-Umgebung ein?')
    ];
    rAutoOrch.addChild(vRealize)

    const tanzu = new OptionsQuestion(`tanzu`, [new Translation('en', 'VMware Tanzu'), new Translation('de', 'VMware Tanzu')], MedoOptionMap.yes_no);
    tanzu.help = [
        new Translation('en', 'Do you use VMware Tanzu?'),
        new Translation('de', 'Nutzen Sie VMware Tanzu?')
    ];
    rAutoOrch.addChild(tanzu)

    sAutoOrch.addChild(rAutoOrch)


    const optimization = new Group('optimization_auto', [new Translation('en', 'Automation and orchestration optimization'), new Translation('de', 'Automatisierung und Orchestrierung optimierung')])
    const autokTools = new OptionsQuestion(`auto_tools`, [new Translation('en', 'Other Tools'), new Translation('de', 'Andere Tools')], MedoOptionMap.yes_no);
    autokTools.help = [
        new Translation('en', 'Do you use open source tools such as Ansible or Terraform to automate and orchestrate your vSphere environment?'),
        new Translation('de', 'Nutzen Sie Open-Source-Tools wie Ansible oder Terraform für die Automatisierung und Orchestrierung Ihrer vSphere-Umgebung?')
    ];
    optimization.addChild(autokTools)
    sAutoOrch.addChild(optimization)

    return sAutoOrch;
};
