import { CatalogObject, Group, OpenQuestion, Row, Translation } from 'anketa-core';
import { KeycloakInstance } from 'keycloak-js';
import { generateActions } from '../opsanio/actions';
import { generateBasicUse } from './basic_use';
import { generateResourceManagement } from './resource_management';
import { generateStorageManagement } from './memory_management';
import { generateHaFault } from './ha_fault_tolerance';
import { generateSecurityFunctions } from './security_functions';
import { generateNetworkManagement } from './network_managment';
import { generateBackup } from './backup';
import { generateAutomationOrchestration } from './automation_orchestration';
import { generateMonitoring } from './monitoring';
import { generateUpgrades } from './upgrades';
import { generateSpezial } from './spezials';
import { generateOptimazation } from './optimization';
import { genearteBasicEnv } from './basicEnv';

export class VmwareCatalog extends CatalogObject {
  private static _instance: VmwareCatalog;

  public static get instance(): VmwareCatalog {
    if (!VmwareCatalog._instance) {
      throw new Error(
        'VmwareCatalog instance has not been created. Use createInstance(name) method first.'
      );
    }
    return VmwareCatalog._instance;
  }

  public static createInstance(
    name: string,
    changeListener: () => void,
    authCtx: KeycloakInstance
  ): VmwareCatalog {
    if (!VmwareCatalog._instance) {
      VmwareCatalog._instance = new VmwareCatalog(name, changeListener, authCtx);
    }
    return VmwareCatalog._instance;
  }

  constructor(name: string, changeListener: () => void, authCtx: KeycloakInstance) {
    const id: string = name.toLowerCase().replace(/ /g, '-');
    super(id, [new Translation('en', name), new Translation('de', name)]);

    // CUSTOMER CONTACT
    const gGeneralInternalContact = new Group('internal_contact', [
      new Translation('en', 'Customer Contact'),
      new Translation('de', 'Kunden Kontakt'),
    ]);
    const generalInternalContactRow = new Row('internal_contact_0', [
      new Translation('en', 'Internal Contact 1'),
      new Translation('de', 'Interner Kontakt 1'),
    ]);

    const generateInternalContact = () => {
      const oldTrigger: OpenQuestion = gGeneralInternalContact.children
        .get(`internal_contact_${gGeneralInternalContact.children.size - 1}`)
        ?.children.get(
          `internal_contact_tel_${gGeneralInternalContact.children.size - 1}`
        ) as OpenQuestion;
      oldTrigger.removeChangeListener(generateInternalContact);

      const item = new Row(`internal_contact_${gGeneralInternalContact.children.size}`, [
        new Translation('en', `${gGeneralInternalContact.children.size + 1}. Internal Contact`),
        new Translation('de', `${gGeneralInternalContact.children.size + 1}. Interner Kontakt`),
      ]);
      item.addChild(
        new OpenQuestion(`internal_contact_name_${gGeneralInternalContact.children.size}`, [
          new Translation('en', 'Name'),
          new Translation('de', 'Name'),
        ])
      );

      const itemName = new OpenQuestion(
        `internal_contact_email_${gGeneralInternalContact.children.size}`,
        [new Translation('en', 'E-Mail'), new Translation('de', 'E-Mail')]
      );
      item.addChild(itemName);

      const itemTel = new OpenQuestion(
        `internal_contact_tel_${gGeneralInternalContact.children.size}`,
        [new Translation('en', 'Telephone'), new Translation('de', 'Telefon')]
      );
      itemTel.addChangeListener(generateInternalContact);
      item.addChild(itemTel);

      gGeneralInternalContact.addChild(item);
    };

    gGeneralInternalContact.addChild(generalInternalContactRow);

    const internalContactName = new OpenQuestion('internal_contact_name_0', [
      new Translation('en', 'Name'),
      new Translation('de', 'Name'),
    ]);
    generalInternalContactRow.addChild(internalContactName);

    const internalContactEmail = new OpenQuestion('internal_contact_email_0', [
      new Translation('en', 'E-Mail'),
      new Translation('de', 'E-Mail'),
    ]);
    generalInternalContactRow.addChild(internalContactEmail);

    const internalContactTel = new OpenQuestion('internal_contact_tel_0', [
      new Translation('en', 'Telephone'),
      new Translation('de', 'Telefon'),
    ]);
    internalContactTel.addChangeListener(generateInternalContact);
    generalInternalContactRow.addChild(internalContactTel);

    this.addChild(gGeneralInternalContact);

    this.addChild(generateBasicUse());

    this.addChild(generateResourceManagement());

    this.addChild(generateStorageManagement());

    this.addChild(generateNetworkManagement());

    this.addChild(generateHaFault());

    this.addChild(generateSecurityFunctions());

    this.addChild(generateBackup());

    this.addChild(generateAutomationOrchestration());

    this.addChild(generateMonitoring());

    this.addChild(generateUpgrades());

    this.addChild(generateSpezial());

    this.addChild(generateOptimazation());

    this.addChild(genearteBasicEnv('vm'));
    
    this.addChild(generateActions(`vmware_${id}`, changeListener, authCtx));
  }
}
