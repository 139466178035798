import React, { useEffect, useState } from 'react';
import {
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiText,
    EuiPageTemplate,
    EuiPanel,
    EuiEmptyPrompt,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiButton,
    EuiDatePicker,
    EuiFieldNumber,
} from '@elastic/eui';
import { useParams } from 'react-router-dom';
import { useApi } from '../_functions/api/api';
import { CalendarImage } from '../asstes/images/calender';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/de';
import { Appointment, User } from '../_types';
import { useToast } from '../_functions/toast';
import { deduplicateArray } from '../_functions';


export const AppointmentComponent = (): JSX.Element => {
    const { addToast } = useToast();
    const { Get, Post } = useApi();

    const [user, setUser] = useState<User | null>(null);
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [selectedContact, setSelectedContact] = useState<Appointment | null>(null);
    const [modal, setModal] = useState<boolean>(false)
    const [appointment, setAppointment] = useState<{ date: string, slot: string }>()

    const [startDate, setStartDate] = useState<Moment>(moment());
    const [timeslot, setTimeslot] = useState<number>(30)

    moment.locale('de');

    const { newsletter, userid } = useParams<{ newsletter: string; userid: string }>();


    const handleContactChange = (selectedOptions: any) => {
        if (selectedOptions.length > 0) {
            const selectedEmail = selectedOptions[0].value;
            console.log(selectedEmail)
            setSelectedContact(selectedEmail);
        } else {
            setSelectedContact(null);
        }
    };

    const closeModal = () => {
        setModal(false)
    }

    const handleOpenModal = (date: string, slot: string) => {
        setAppointment({ date, slot });
        setModal(true);
    };

    const handleChangeDate = (date: Moment | null) => {
        if (date) {
            setStartDate(date);
        }
    };

    const handleChangeTimeslot = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value) && value >= 15 && value <= 60) {
            setTimeslot(value);
        }
    };

    const generateTimeSlots = (contact: Appointment, start: Moment): string[] => {
        const slots: string[] = [];

        contact.availability.forEach((ava) => {
            if (ava.date === moment(start).format("YYYY-MM-DD")) {
                ava.slots.forEach((time) => {
                    slots.push(time);
                });
            }
        });
        return slots;
    };

    const mergeTimeRanges = (timeRanges: string[]): string[] => {
        timeRanges.sort((a, b) => {
            const startA = a.split(' - ')[0];
            const startB = b.split(' - ')[0];
            return startA.localeCompare(startB);
        });

        const mergedRanges: string[] = [];

        let currentRange = timeRanges[0];

        for (let i = 1; i < timeRanges.length; i++) {
            const nextRange = timeRanges[i];
            const currentEnd = currentRange.split(' - ')[1];
            const nextStart = nextRange.split(' - ')[0];
            const nextEnd = nextRange.split(' - ')[1];

            if (currentEnd === nextStart) {
                currentRange = currentRange.split(' - ')[0] + ' - ' + nextEnd;
            } else {
                mergedRanges.push(currentRange);
                currentRange = nextRange;
            }
        }

        mergedRanges.push(currentRange);

        return mergedRanges;
    }

    const splitTimeRange = (timeRange: string, timeslot: number): string[] => {
        const [start, end] = timeRange.split(' - ');
        const startTime = convertTimeToMinutes(start);
        const endTime = convertTimeToMinutes(end);

        const slots: string[] = [];
        let currentTime = startTime;

        while (currentTime + timeslot <= endTime) {
            const slotEndTime = currentTime + timeslot;
            slots.push(`${convertMinutesToTime(currentTime)} - ${convertMinutesToTime(slotEndTime)}`);
            currentTime = slotEndTime;
        }

        return slots;
    }

    const convertTimeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }

    const convertMinutesToTime = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    }

    const calcTimeSlots = (slots: string[], timeslot: number): string[] => {
        const maxTime: number = 60;
        const minTime: number = 15;
        const defaultTime: number = 30;

        if (timeslot >= minTime && timeslot <= maxTime && timeslot !== defaultTime) {
            console.log(`${timeslot} is in the valid range! Have ${slots.length} in the default of ${defaultTime} minutes`);
            const validSpaces = mergeTimeRanges(slots);
            console.log("Valid Spaces: ", validSpaces);

            let finalSlots: string[] = [];
            for (const range of validSpaces) {
                finalSlots = finalSlots.concat(splitTimeRange(range, timeslot));
            }
            return finalSlots;
        }

        return slots;
    }


    const createISOString = () => {
        const startMoment = moment.tz(`${appointment?.date} ${appointment?.slot}`, 'YYYY-MM-DD HH:mm', 'Europe/Berlin');

        const endMoment = startMoment.clone().add(timeslot, 'minutes');

        const start = startMoment.toISOString();
        const end = endMoment.toISOString();

        return { start, end };
    };

    const createAppointment = async () => {


        const appointmentValues = {
            internalContact: selectedContact?.user,
            customer: user,
            appointment: createISOString()
        }
        console.log(appointmentValues)
        await Post({ path: `public/newsletter/${newsletter}/appointment/${userid}`, value: appointmentValues })
            .then((response) => {
                if (response.status === 500) {
                    addToast({
                        title: 'Ein fehler ist aufgetreten!',
                        text: <EuiText>
                            <p>{response.data}</p>
                        </EuiText>,
                        color: "danger",
                        iconType: "alert"
                    });
                } else {
                    addToast({
                        title: 'Termin wurde vereinbart',
                        text: <EuiText>
                            <p>{user?.firstname} {user?.lastname},</p>
                            <p>dein Termin mit {selectedContact?.user.displayName} wurde erfolgreich erstellt!</p>
                            <p>Sie müssten von uns eine E-Mail mit den Details erhalten haben.</p>
                        </EuiText>,
                        color: "success",
                        iconType: "check"
                    });
                    setModal(false)
                }
            }).catch((err) => {
                addToast({
                    title: 'Ein fehler ist aufgetreten!',
                    text: <EuiText>
                        <p>{err.data}</p>
                    </EuiText>,
                    color: "danger",
                    iconType: "alert"
                });
            });

    }


    useEffect(() => {
        const getAppointments = async () => {
            try {
                const response = await Get({ path: `public/newsletter/${newsletter}/appointment/${userid}` });
                const { user, appointments } = response.data;

                console.warn(`http://localhost:3011/api/public/newsletter/${newsletter}/appointment/${userid}`, user, response)
                setUser(user);

                if (appointments) {
                    const formattedAppointments: any[] = Object.entries(appointments).map(([email, data]: [string, any]) => ({
                        user: {
                            email: email,
                            displayName: data?.displayName,
                        },
                        availability: deduplicateArray(data.availability.map((avail: any) => ({
                            date: avail.date,
                            slots: avail.slots,
                        })), "date"),
                    }));

                    setAppointments(formattedAppointments);
                } else {
                    console.error('No appointments found for the user');
                }
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        };

        getAppointments();
    }, [newsletter, userid]);

    const Body = (): JSX.Element => {
        return (
            <>
                <EuiText>
                    <h3>Freut uns, dass Sie sich umentschieden haben!</h3>
                    {user && <p>Hallo {user.firstname} {user.lastname}</p>}
                </EuiText>
                <EuiSpacer size="m" />
                <EuiForm>
                    <EuiFormRow label="Wählen Sie einen internen Kontakt aus">
                        <EuiComboBox
                            placeholder="Wählen Sie einen internen Kontakt aus"
                            options={appointments && appointments.map((app: Appointment) => ({
                                label: `${app.user.displayName}`,
                                value: app,
                            }))}
                            onChange={handleContactChange}
                            selectedOptions={selectedContact ? [{ label: `${selectedContact.user?.displayName}`, value: selectedContact }] : []}
                            singleSelection={{ asPlainText: true }}
                        />
                    </EuiFormRow>
                    <EuiFormRow label="Wann soll der Termin sein?">
                        <EuiDatePicker
                            selected={startDate}
                            onChange={handleChangeDate}
                            startDate={moment()}
                            endDate={moment().add(30, 'days')}
                            dateFormat="DD.MM.YYYY"
                            locale="de"
                        />
                    </EuiFormRow>
                    <EuiFormRow label={`Wie lange soll der Termin sein? (15-60 Min)`}>
                        <EuiFieldNumber
                            placeholder="Timeslot"
                            defaultValue={timeslot.toString()}
                            onChange={handleChangeTimeslot}
                            aria-label="Timeslot"
                            min={15}
                            max={60}
                        />
                    </EuiFormRow>

                    <EuiSpacer />

                </EuiForm>
                {selectedContact && (
                    <EuiText>
                        <h4>Verfügbare Zeitslots am {moment(startDate).format('dddd, MMMM Do YYYY')}</h4>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", flexWrap: "wrap", alignContent: "center" }}>
                            {calcTimeSlots(generateTimeSlots(selectedContact, startDate), timeslot).map((slot, index) => (
                                <EuiButton
                                    key={`slot-${index}`}
                                    style={{ margin: "5px", cursor: "pointer" }}
                                    color="primary"
                                    onClick={() => handleOpenModal(moment(startDate).format('YYYY-MM-DD'), slot)}
                                >
                                    {slot}
                                </EuiButton>
                            ))}
                        </div>
                    </EuiText>
                )}
            </>
        );
    };

    return (
        <EuiPageTemplate template="empty" style={{ height: 'fit-content', margin: 'auto' }}>
            <EuiFlexGroup gutterSize="l">
                <EuiFlexItem>
                    <EuiPanel hasShadow={true} style={{ justifyContent: "center", alignItems: "center" }}>
                        <CalendarImage style={{ position: "sticky", top: "15px" }} />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel color="success" hasShadow={true}>
                        <EuiEmptyPrompt iconType="" iconColor="success" title={<></>} body={<Body />} />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
            {modal &&
                <EuiModal onClose={closeModal} initialFocus="[name=vorname]">
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1>Termin Vereinbaren!</h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiText>
                            <p>Moin {user?.firstname} {user?.lastname},</p>
                            <p>Wenn du einen verbindlichen Termin mit {selectedContact?.user.displayName} vereinbaren möchtest, klicke auf "Termin".</p>
                            <p>Die Termin-Einladung wird an {user?.email} gesendet.</p>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                <strong>Datum:</strong>
                                <>{moment(appointment?.date).format('dddd, MMMM Do YYYY')}</>
                                <strong>Start:</strong>
                                <>{appointment?.slot} Uhr</>
                                <strong>Dauer</strong>
                                <>{timeslot} Minuten</>
                            </div>

                        </EuiText>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
                        <EuiButton onClick={() => createAppointment()}>Termin</EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            }
        </EuiPageTemplate>
    );
};