import React, { useContext, useEffect } from 'react';
import Mustache from 'mustache';
import { AnketaContext } from '../context_provider';
import { Catalog, type Action, type Hierarchical } from 'anketa-core';
import { KeycloakContext } from '../../_functions/auth';
import moment from 'moment';
import { sendMail } from './mail';
import { useApi } from '../../_functions/api/api';
import { questionarieParams } from '../../_types';
import { template } from './templates/opsanio';
import { networksTemplate } from './templates/images/networks';
import { OpsanioCatalog } from '../../catalogs/opsanio';
import { calculateHostRange } from '../../catalogs/opsanio/2Exanio';
import { bootstrapCustomerSettingsTemplate } from './templates/bootstrap_customer_settings';

const removeNumbersFromKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNumbersFromKeys(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {};

    Object.keys(obj).forEach((key) => {
      const newKey = key.replace(/[0-9]/g, '').replaceAll('a_', '').replaceAll('_', '');
      newObj[newKey] = removeNumbersFromKeys(obj[key]);
    });

    return newObj;
  } else {
    return obj;
  }
};

export const ActionConsumer = (): JSX.Element => {
  const ctx = useContext(AnketaContext);
  const authCtx = useContext(KeycloakContext);

  const { Post } = useApi();

  const downloadFile = (content: string, fileName: string) => {
    const blob = new Blob([content], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const getSection = (id: string): Hierarchical | undefined => {
    const splitArray = id.split('.');
    const catalogItem = splitArray[1];
    const item = ctx.catalog.children.get(catalogItem);
    return item;
  };

  const actionObserver = (action: Action): void => {
    const item = getSection(action.path);
    const regex = /^save_\w+_[\w-]+$/;
    const regexOnBoarding = /^onboarding_\w+_[\w-]+$/;
    if (action.id === 'sendEmail') {
      console.log('want to send E-Mail', item);
      sendMail(item, authCtx.profile)
        .then((response) => {
          console.log(response);
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else if (regex.test(action.id)) {
      if (item) {
        const parent = item.parent?.id;
        const params: questionarieParams = {
          id: `${action.id.split('_')[1]}:${action.id.split('_')[2]}`,
          name: ctx.i18nContext.render((item as Catalog).title),
          content: ctx.catalog.factsToObject(ctx.catalog),
          catalog: `${parent}`,
          last_updated: moment().toISOString(),
          updated_by: authCtx.profile?.username || '',
        };
        Post({
          path: `questionaries/save/${action.id.split('_')[1]}/${action.id.split('_')[2]}`,
          value: params,
          token: authCtx.keycloak?.token,
        });
      }
    } else if (regexOnBoarding.test(action.id)) {
      if (item) {
        if (ctx.catalog instanceof OpsanioCatalog) {
          let context: any = ctx.catalog.factsToObject(ctx.catalog, true);
          // GENERATE AGENT ARRAY
          const agents: any[] = [];
          Object.entries(context.autonubil.agents).forEach((agent: any) => {
            const cleanedObject = removeNumbersFromKeys(agent[1]);
            agents.push(cleanedObject);
          });
          context.agents = agents;
          // GENERATE CUSTOMER NETWORK ARRAY
          const regex = /^c_ne_/;
          const matchingKeys = Object.keys(context.c_network_env).filter((key) => regex.test(key));
          const matchingEntries = matchingKeys.map((key) => ({
            [key]: context.c_network_env[key],
          }));
          context.customer_networks = removeNumbersFromKeys(matchingEntries);
          // GENERATE DRAWIO CONTENT
          const drawiIoExanio = {
            kula: {
              exanio: calculateHostRange(context.autonubil.exanio_net.network_exanio.value, 9)
                .specificHost,
              wg: calculateHostRange(context.autonubil.transfer_net.network_transfer.value, 0)
                .specificHost,
            },
            proxy: calculateHostRange(context.autonubil.exanio_net.network_exanio.value, 19)
              .specificHost,
            k3s: {
              one: calculateHostRange(context.autonubil.exanio_net.network_exanio.value, 20)
                .specificHost,
              two: calculateHostRange(context.autonubil.exanio_net.network_exanio.value, 21)
                .specificHost,
              three: calculateHostRange(context.autonubil.exanio_net.network_exanio.value, 22)
                .specificHost,
            },
            agents: {
              one: {
                customer: calculateHostRange(
                  context.c_network_env.c_gp_setup.network_setup.value,
                  2
                ).specificHost,
                exanio: calculateHostRange(
                  context.autonubil.cpe_net.cpe_a_net.network_cpe_a.value,
                  4
                ).specificHost,
                wireguard: calculateHostRange(
                  context.autonubil.transfer_net.network_transfer.value,
                  1
                ).specificHost,
              },
              two: {
                customer: calculateHostRange(
                  context.c_network_env.c_gp_setup.network_setup.value,
                  3
                ).specificHost,
                exanio: calculateHostRange(
                  context.autonubil.cpe_net.cpe_b_net.network_cpe_b.value,
                  4
                ).specificHost,
                wireguard: calculateHostRange(
                  context.autonubil.transfer_net.network_transfer.value,
                  2
                ).specificHost,
              },
            },
            customer: context.c_network_env.c_gp_setup.gateway_setup.value,
            autonubil: context.autonubil.wg.wireguard_ip.value,
            cpe: {
              cpe: context.autonubil.cpe_net.network_cpe.value,
              a: context.autonubil.cpe_net.cpe_a_net.network_cpe_a.value,
              b: context.autonubil.cpe_net.cpe_b_net.network_cpe_b.value,
            },
          };
          const networkIo = Mustache.render(networksTemplate, drawiIoExanio);
          context.images = {
            networkIo: networkIo,
          };
          context.wazuh = {
            version: '4.8.1',
          };
          // GENERATE MARKDOWN
          const documantation = Mustache.render(template, context);
          downloadFile(documantation, 'opsanio.md');
        }
      }
    } else if (action.id === 'bootstrap') {
      if (item) {
        if (ctx.catalog instanceof OpsanioCatalog) {
          let context: any = ctx.catalog.factsToObject(ctx.catalog, true);
          // GENERATE AGENT ARRAY
          const agents: any[] = [];
          Object.entries(context.autonubil.agents).forEach((agent: any) => {
            const cleanedObject = removeNumbersFromKeys(agent[1]);
            agents.push(cleanedObject);
          });
          context.agents = agents;
          context.agents.forEach((agent: any) => {
            const agentId = parseInt(agent.agent.agentname.value.split('00')[1]);
            agent.agent.offset = agentId;
            agent.agent.customer_offset = agentId + 2;
          });
          // GENERATE CUSTOMER NETWORK ARRAY
          const regex = /^c_ne_/;
          const matchingKeys = Object.keys(context.c_network_env).filter((key) => regex.test(key));
          const matchingEntries = matchingKeys.map((key) => ({
            [key]: context.c_network_env[key],
          }));
          context.customer_networks = removeNumbersFromKeys(matchingEntries);

          // REMOVE TIME ATTRIBUTES
          context.retentions.retentions_flows = context.retentions.retentions_flows.value.replace(
            'Tage',
            ''
          );
          context.retentions.retentions_wazuh = context.retentions.retentions_wazuh.value.replace(
            'Tage',
            ''
          );
          context.retentions.retentions_codex = context.retentions.retentions_codex.value.replace(
            'Tage',
            ''
          );
          context.retentions.retentions_elasti_flow =
            context.retentions.retentions_elasti_flow.value.replace('Tage', '');

          // GENERATE MARKDOWN
          const documantation = Mustache.render(bootstrapCustomerSettingsTemplate, context);
          downloadFile(documantation.replaceAll('&#x2F;', '/'), 'customer_settings.tf');
        }
      }
    } else if (action.id === 'new_env_btn') {
      action.enabled = true;
    } else {
      alert('No Action found!');
    }
  };

  useEffect(() => {
    ctx.attachActions(actionObserver);
    return () => {
      ctx.detachActions(actionObserver);
    };
  }, [ctx, authCtx]);

  return <></>;
};
