import { v4 as uuidv4 } from 'uuid';
import { CreateUserProps, OnboardingRequest, OnboardingRequestStatus, UserValues } from '../types';
import moment from 'moment';

const generateInitPassword = (length = 12): string => {
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';

  const allChars = upperCase + lowerCase + numbers + specialChars;
  const passwordArray = [
    upperCase[Math.floor(Math.random() * upperCase.length)],
    lowerCase[Math.floor(Math.random() * lowerCase.length)],
    numbers[Math.floor(Math.random() * numbers.length)],
    specialChars[Math.floor(Math.random() * specialChars.length)],
  ];

  for (let i = passwordArray.length; i < length; i++) {
    passwordArray.push(allChars[Math.floor(Math.random() * allChars.length)]);
  }

  return passwordArray.sort(() => Math.random() - 0.5).join('');
};

export const createOfficeUser = async (
  user: CreateUserProps,
  agent: string,
  approver: string
): Promise<any> => {
  try {
    const mailName = `${user.user.firstname.toLowerCase().replaceAll(' ', '-')}.${user.user.lastname
      .toLowerCase()
      .replaceAll(' ', '-')}`;
    const mailAddr = `${mailName}@${user.templateValues.domain[0].value}`;
    const userTmpId = uuidv4();
    const validValues: UserValues = {
      //POST USER
      accountEnabled: true,
      displayName: `${user.user.firstname} ${user.user.lastname}`,
      mailNickname: mailName,
      userPrincipalName: mailAddr,
      passwordProfile: {
        forceChangePasswordNextSignIn: true,
        password: generateInitPassword(),
      },
      givenName: user.user.firstname,
      surname: user.user.lastname,
      mail: mailAddr,
      companyName: user.templateValues.companies,
      department: user.templateValues.department,
      city: user.templateValues.city,
      jobTitle: user.user.jobT,
      usageLocation: 'DE',
      preferredLanguage: 'en-US',
      // Assign Groups
      groups: user.templateValues.groups,
      verteilerlisten: user.templateValues.verteilerlisten,
      licenseDetails: user.templateValues.licenses,
      // Domain
      domain: user.templateValues.domain,
      id: userTmpId,
    };

    const newChange: OnboardingRequestStatus = {
      type: 'create',
      state: 'pending',
      id: uuidv4(),
      timestamp: moment(),
      user: userTmpId,
      userEmail: agent,
      userRole: agent === approver ? 'approver' : 'agent',
    };
    const onboardingRequest: OnboardingRequest = {
      id: uuidv4(),
      user: validValues,
      agent,
      approver,
      status: newChange,
      changes: [newChange],
    };
    console.log('New User:', onboardingRequest);

    return onboardingRequest;
  } catch (error) {
    console.log({ 'Error creating user and assigning groups': error }, 'GraphAPI', 'error');
    throw error;
  }
};
