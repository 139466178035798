import { visit } from 'unist-util-visit';
// eslint-disable-next-line  @typescript-eslint/no-var-requires
import mermaid from 'mermaid';
import { Options } from '../../_types';

const DEFAULT_OPTIONS = {
  theme: 'default',
  key: 'mermaid_',
};

/**
 * Plugin for remark-js
 *
 * See details about plugin API:
 * https://github.com/unifiedjs/unified#plugin
 *
 * You can specify the endpoint of PlantUML with the option 'baseUrl'
 *
 * @param {Object} pluginOptions Remark plugin options.
 */
export function remarkMermaidPlugin(pluginOptions: any): any {
  const options: Options = { ...DEFAULT_OPTIONS, ...pluginOptions };

  function transformer(syntaxTree: any): any {
    let idx = 0;
    visit(syntaxTree, 'code', (node, index, parent) => {
      const { lang, value } = node;
      if (!lang || !value || lang !== 'mermaid') {
        return;
      }
      const theme = options.theme ?? 'default';
      mermaid.initialize({
        theme,
        startOnLoad: true,
        flowchart: { useMaxWidth: false, htmlLabels: true },
      });
      const id = `${options.key}_${idx++}`;

      let div = document.getElementById(id);
      if (!div) {
        div = document.createElement('div');
        div.setAttribute('id', id);
        div.setAttribute('className', 'mermaid');
      }

      node.type = 'div';
      node.id = id + '_t';
      node.value = '';
      // node.innerHtml = '<pre><code class="hljs language-mermaid">' + (value as string) + '</code></pre>'

      mermaid
        .render(id, value)
        .then((renderResult: any) => {
          const target = document.getElementById(id + '_t');
          if (target && renderResult) {
            target.innerHTML = renderResult.svg;
          }
        })
        .catch((e) => {
          console.warn('Mermaid Render', e);
        });
    });
    return syntaxTree;
  }

  return transformer;
}
