import { MessageCollection, PATH_SEPARATOR, TextMessage, Translation } from 'anketa-core';

export class MedoOptionMap extends Map<string, TextMessage> {
  static readonly titles_medo = 'default.medocino_titles';
  static readonly job_titles_medo = `default.medocino_job_titles`;
  static readonly tech_components = `default.tech_components`;
  static readonly tech_category = `default.tech_category`;
  static readonly vmware_lizenz = `default.vmware_lizenz`;
  static readonly vmware_lizenz_modell = `default.vmware_lizenz_modell`;
  static readonly yes_no = `default.yes_no`;
  static readonly manufactores = `default.manifactores`;
  static readonly scanners = `default.scanners`;
  static readonly storage_solutions = `default.storage_solutions`;
  static readonly storage_protocols = `default.storage_protocols`;
  static readonly vsphere_security_features = `default.vsphere_security_features`;
  static readonly vsphere_access_roles = `default.vsphere_access_roles`;
  static readonly backup_software = `default.backup_software`;
}

// Add Titles
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'prof', [
  new Translation('en', 'Prof.'), // Professor
  new Translation('de', 'Prof.'), // Professor
]);
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'dr', [
  new Translation('en', 'Dr.'), // Doctor
  new Translation('de', 'Dr.'), // Doktor
]);
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'mr', [
  new Translation('en', 'Mr.'), // Mister
  new Translation('de', 'Herr'), // Herr
]);
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'ms', [
  new Translation('en', 'Ms.'), // Miss
  new Translation('de', 'Frau'), // Frau
]);
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'eng', [
  new Translation('en', 'Eng.'), // Engineer
  new Translation('de', 'Ing.'), // Ingenieur
]);
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'phd', [
  new Translation('en', 'Ph.D.'), // Doctor of Philosophy
  new Translation('de', 'Dr.'), // Doktor
]);
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'mstr', [
  new Translation('en', 'Mstr.'), // Master
  new Translation('de', 'Meister'), // Meister
]);
MessageCollection.Global.setTranslations(MedoOptionMap.titles_medo + PATH_SEPARATOR + 'pres', [
  new Translation('en', 'Pres.'), // President
  new Translation('de', 'Präsident'), // Präsident
]);

// Add Job Titles
MessageCollection.Global.setTranslations(MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'ceo', [
  new Translation('en', 'CEO'),
  new Translation('de', 'CEO'),
]);
MessageCollection.Global.setTranslations(MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'cto', [
  new Translation('en', 'CTO'),
  new Translation('de', 'CTO'),
]);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'software_engineer',
  [new Translation('en', 'Software Engineer'), new Translation('de', 'Softwareentwickler')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'data_scientist',
  [new Translation('en', 'Data Scientist'), new Translation('de', 'Datenwissenschaftler')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'product_manager',
  [new Translation('en', 'Product Manager'), new Translation('de', 'Produktmanager')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'project_manager',
  [new Translation('en', 'Project Manager'), new Translation('de', 'Projektmanager')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'ux_ui_designer',
  [new Translation('en', 'UX/UI Designer'), new Translation('de', 'UX/UI Designer')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'devops_engineer',
  [new Translation('en', 'DevOps Engineer'), new Translation('de', 'DevOps-Entwickler')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'quality_assurance_engineer',
  [
    new Translation('en', 'Quality Assurance Engineer'),
    new Translation('de', 'Qualitätskontroll- oder Qualitätssicherungsingenieur'),
  ]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'systems_administrator',
  [new Translation('en', 'Systems Administrator'), new Translation('de', 'Systemadministrator')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'network_engineer',
  [new Translation('en', 'Network Engineer'), new Translation('de', 'Netzwerktechniker')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.job_titles_medo + PATH_SEPARATOR + 'business_analyst',
  [new Translation('en', 'Business Analyst'), new Translation('de', 'Geschäftsanalytiker')]
);

// Tech Category
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_category + PATH_SEPARATOR + 'hardware',
  [new Translation('en', 'Hardware'), new Translation('de', 'Hardware')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_category + PATH_SEPARATOR + 'security',
  [new Translation('en', 'Security'), new Translation('de', 'Sicherheit')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_category + PATH_SEPARATOR + 'services',
  [new Translation('en', 'Services'), new Translation('de', 'Dienstleistungen')]
);
MessageCollection.Global.setTranslations(MedoOptionMap.tech_category + PATH_SEPARATOR + 'network', [
  new Translation('en', 'Network'),
  new Translation('de', 'Netzwerk'),
]);

// Tech Components
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'server',
  [new Translation('en', 'Server'), new Translation('de', 'Server')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'database',
  [new Translation('en', 'Database'), new Translation('de', 'Datenbank')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'cloud_computing',
  [new Translation('en', 'Cloud Computing'), new Translation('de', 'Cloud-Computing')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'programming_language',
  [new Translation('en', 'Programming Language'), new Translation('de', 'Programmiersprache')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'framework',
  [new Translation('en', 'Framework'), new Translation('de', 'Framework')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'storage',
  [new Translation('en', 'Storage'), new Translation('de', 'Storage')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'access_point',
  [new Translation('en', 'Access Point'), new Translation('de', 'Access Point')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'firewall',
  [new Translation('en', 'Firewall'), new Translation('de', 'Firewall')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'backup',
  [new Translation('en', 'Backup'), new Translation('de', 'Backup')]
);
MessageCollection.Global.setTranslations(
  MedoOptionMap.tech_components + PATH_SEPARATOR + 'archiv',
  [new Translation('en', 'Archive'), new Translation('de', 'Archiv')]
);

// Yes NO
MessageCollection.Global.setTranslations(MedoOptionMap.yes_no + PATH_SEPARATOR + 'yes', [
  new Translation('en', 'Yes'),
  new Translation('de', 'Ja'),
]);
MessageCollection.Global.setTranslations(MedoOptionMap.yes_no + PATH_SEPARATOR + 'no', [
  new Translation('en', 'No'),
  new Translation('de', 'Nein'),
]);

// SCANNERS
MessageCollection.Global.setTranslations(MedoOptionMap.scanners + PATH_SEPARATOR + 'netbox', [
  new Translation('en', 'Netbox'),
  new Translation('de', 'Netbox'),
]);

// vmWare Lizenz
MessageCollection.Global.setTranslations(
  MedoOptionMap.vmware_lizenz + PATH_SEPARATOR + 'essentials',
  [new Translation('en', 'Essentials'), new Translation('de', 'Essentials')]
);

MessageCollection.Global.setTranslations(MedoOptionMap.vmware_lizenz + PATH_SEPARATOR + 'plus', [
  new Translation('en', 'Essentials Plus'),
  new Translation('de', 'Essentials Plus'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vmware_lizenz + PATH_SEPARATOR + 'standard',
  [new Translation('en', 'Standard'), new Translation('de', 'Standard')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vmware_lizenz + PATH_SEPARATOR + 'enterprise_plus',
  [new Translation('en', 'Enterprise Plus'), new Translation('de', 'Enterprise Plus')]
);

// vmWare Lizenz Modell
MessageCollection.Global.setTranslations(
  MedoOptionMap.vmware_lizenz_modell + PATH_SEPARATOR + 'socket',
  [
    new Translation('en', 'Perpetual pro CPU-Socket'),
    new Translation('de', 'Perpetual pro CPU-Socket'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vmware_lizenz_modell + PATH_SEPARATOR + 'cpu_core',
  [new Translation('en', 'Perpetual pro CPU-Core'), new Translation('de', 'Perpetual pro CPU-Core')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vmware_lizenz_modell + PATH_SEPARATOR + 'core',
  [new Translation('en', 'Subscription per Core'), new Translation('de', 'Subscription per Core')]
);

// Storage solutions
MessageCollection.Global.setTranslations(MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'san', [
  new Translation('en', 'SAN (Storage Area Network)'),
  new Translation('de', 'SAN (Speichernetzwerk)'),
]);

MessageCollection.Global.setTranslations(MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'nas', [
  new Translation('en', 'NAS (Network Attached Storage)'),
  new Translation('de', 'NAS (Netzgebundener Speicher)'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'local',
  [new Translation('en', 'Local Storage'), new Translation('de', 'Lokaler Datenspeicher')]
);

MessageCollection.Global.setTranslations(MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'hci', [
  new Translation('en', 'HCI (Hyper-Converged Infrastructure)'),
  new Translation('de', 'HCI (Hyperkonvergente Infrastruktur)'),
]);
MessageCollection.Global.setTranslations(MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'dms', [
  new Translation('en', 'DMS (Document Management System)'),
  new Translation('de', 'DMS (Dokumentenmanagement-System)'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'object',
  [new Translation('en', 'Object Storage'), new Translation('de', 'Objektspeicher')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'tape',
  [new Translation('en', 'Tape Storage'), new Translation('de', 'Bandlaufwerke')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'cloud',
  [new Translation('en', 'Cloud Storage'), new Translation('de', 'Cloud-Speicher')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_solutions + PATH_SEPARATOR + 'backup',
  [new Translation('en', 'Backup Storage'), new Translation('de', 'Backup-Speicher')]
);

// storage protocols

MessageCollection.Global.setTranslations(MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'nfs', [
  new Translation('en', 'NFS (Network File System)'),
  new Translation('de', 'NFS (Netzwerkdateisystem)'),
]);

MessageCollection.Global.setTranslations(MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'smb', [
  new Translation('en', 'SMB (Server Message Block)'),
  new Translation('de', 'SMB (Server Message Block)'),
]);

MessageCollection.Global.setTranslations(MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'fc', [
  new Translation('en', 'FC (Fibre Channel)'),
  new Translation('de', 'FC (Fibre Channel)'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'iscsi',
  [
    new Translation('en', 'iSCSI (Internet Small Computer System Interface)'),
    new Translation('de', 'iSCSI (Internet Small Computer System Interface)'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'nvmf',
  [new Translation('en', 'NVMe over Fabrics'), new Translation('de', 'NVMe über Fabrics')]
);

MessageCollection.Global.setTranslations(MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'sas', [
  new Translation('en', 'SAS (Serial Attached SCSI)'),
  new Translation('de', 'SAS (Serial Attached SCSI)'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'fcoe',
  [
    new Translation('en', 'FCoE (Fibre Channel over Ethernet)'),
    new Translation('de', 'FCoE (Fibre Channel über Ethernet)'),
  ]
);

MessageCollection.Global.setTranslations(MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'ftp', [
  new Translation('en', 'FTP (File Transfer Protocol)'),
  new Translation('de', 'FTP (Dateiübertragungsprotokoll)'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'http',
  [new Translation('en', 'HTTP/HTTPS'), new Translation('de', 'HTTP/HTTPS')]
);

MessageCollection.Global.setTranslations(MedoOptionMap.storage_protocols + PATH_SEPARATOR + 's3', [
  new Translation('en', 'S3 (Amazon Simple Storage Service)'),
  new Translation('de', 'S3 (Amazon Simple Storage Service)'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'gluster',
  [new Translation('en', 'GlusterFS'), new Translation('de', 'GlusterFS')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.storage_protocols + PATH_SEPARATOR + 'ceph',
  [new Translation('en', 'Ceph'), new Translation('de', 'Ceph')]
);

// vSphere Security features
MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_security_features + PATH_SEPARATOR + 'vtpm',
  [
    new Translation('en', 'vSphere Trust Authority (vTPMs)'),
    new Translation('de', 'vSphere Trust Authority (vTPMs)'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_security_features + PATH_SEPARATOR + 'encrypted_vms',
  [new Translation('en', 'Encrypted VMs'), new Translation('de', 'Verschlüsselte VMs')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_security_features + PATH_SEPARATOR + 'vmotion_encryption',
  [new Translation('en', 'vMotion Encryption'), new Translation('de', 'vMotion-Verschlüsselung')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_security_features + PATH_SEPARATOR + 'vm_security',
  [
    new Translation('en', 'VM Security Policies'),
    new Translation('de', 'VM-Sicherheitsrichtlinien'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_security_features + PATH_SEPARATOR + 'network_encryption',
  [new Translation('en', 'Network Encryption'), new Translation('de', 'Netzwerkverschlüsselung')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_security_features + PATH_SEPARATOR + 'identity_management',
  [new Translation('en', 'Identity Management'), new Translation('de', 'Identitätsmanagement')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_security_features + PATH_SEPARATOR + 'auditing_compliance',
  [
    new Translation('en', 'Auditing and Compliance'),
    new Translation('de', 'Überwachung und Compliance'),
  ]
);

// vSphere Security features

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'local_sso_domain',
  [
    new Translation('en', 'Local vSphere SSO Domain'),
    new Translation('de', 'Lokale vSphere SSO-Domäne'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'active_directory',
  [
    new Translation('en', 'Active Directory Integration'),
    new Translation('de', 'Active Directory Integration'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'ldap',
  [
    new Translation('en', 'LDAP (Lightweight Directory Access Protocol)'),
    new Translation('de', 'LDAP (Lightweight Directory Access Protocol)'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'sso',
  [new Translation('en', 'SSO (Single Sign-On)'), new Translation('de', 'SSO (Single Sign-On)')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'adfs',
  [
    new Translation('en', 'ADFS (Active Directory Federation Services)'),
    new Translation('de', 'ADFS (Active Directory Federation Services)'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'vsphere_roles',
  [new Translation('en', 'vSphere Roles'), new Translation('de', 'vSphere Rollen')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'permissions',
  [new Translation('en', 'Permissions'), new Translation('de', 'Berechtigungen')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.vsphere_access_roles + PATH_SEPARATOR + 'auditing',
  [new Translation('en', 'Auditing'), new Translation('de', 'Überwachung')]
);

// Backup
MessageCollection.Global.setTranslations(MedoOptionMap.backup_software + PATH_SEPARATOR + 'veeam', [
  new Translation('en', 'Veeam Backup & Replication'),
  new Translation('de', 'Veeam Backup & Replication'),
]);

MessageCollection.Global.setTranslations(
  MedoOptionMap.backup_software + PATH_SEPARATOR + 'commvault',
  [
    new Translation('en', 'Commvault Complete Backup & Recovery'),
    new Translation('de', 'Commvault Complete Backup & Recovery'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.backup_software + PATH_SEPARATOR + 'rubrik',
  [
    new Translation('en', 'Rubrik Cloud Data Management'),
    new Translation('de', 'Rubrik Cloud Data Management'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.backup_software + PATH_SEPARATOR + 'netbackup',
  [new Translation('en', 'Veritas NetBackup'), new Translation('de', 'Veritas NetBackup')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.backup_software + PATH_SEPARATOR + 'backupexec',
  [new Translation('en', 'Veritas Backup Exec'), new Translation('de', 'Veritas Backup Exec')]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.backup_software + PATH_SEPARATOR + 'arcserve',
  [
    new Translation('en', 'Arcserve Unified Data Protection'),
    new Translation('de', 'Arcserve Unified Data Protection'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.backup_software + PATH_SEPARATOR + 'dataprotector',
  [
    new Translation('en', 'Micro Focus Data Protector'),
    new Translation('de', 'Micro Focus Data Protector'),
  ]
);

MessageCollection.Global.setTranslations(
  MedoOptionMap.backup_software + PATH_SEPARATOR + 'acronis',
  [new Translation('en', 'Acronis Cyber Backup'), new Translation('de', 'Acronis Cyber Backup')]
);

// MANUFACTORYS
// Übersetzungen für verschiedene Hersteller von IT-Hardware

// CPU-Hersteller
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'intel', [
  new Translation('en', 'Intel'),
  new Translation('de', 'Intel'),
]);
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'amd', [
  new Translation('en', 'AMD'),
  new Translation('de', 'AMD'),
]);

// Grafikkarten-Hersteller
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'nvidia', [
  new Translation('en', 'NVIDIA'),
  new Translation('de', 'NVIDIA'),
]);

// Apple
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'apple', [
  new Translation('en', 'Apple'),
  new Translation('de', 'Apple'),
]);

// Samsung
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'samsung', [
  new Translation('en', 'Samsung'),
  new Translation('de', 'Samsung'),
]);

// Dell
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'dell', [
  new Translation('en', 'Dell'),
  new Translation('de', 'Dell'),
]);

// HP
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'hp', [
  new Translation('en', 'HP'),
  new Translation('de', 'HP'),
]);

// Lenovo
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'lenovo', [
  new Translation('en', 'Lenovo'),
  new Translation('de', 'Lenovo'),
]);

// Veritas
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'veritas', [
  new Translation('en', 'Veritas'),
  new Translation('de', 'Veritas'),
]);

// Veeam
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'veeam', [
  new Translation('en', 'Veeam'),
  new Translation('de', 'Veeam'),
]);

// Cisco
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'cisco', [
  new Translation('en', 'Cisco'),
  new Translation('de', 'Cisco'),
]);

// Juniper
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'juniper', [
  new Translation('en', 'Juniper'),
  new Translation('de', 'Juniper'),
]);

// NetApp
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'netapp', [
  new Translation('en', 'NetApp'),
  new Translation('de', 'NetApp'),
]);

// EMC (Dell EMC)
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'emc', [
  new Translation('en', 'EMC'),
  new Translation('de', 'EMC'),
]);

// Aruba Networks
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'aruba', [
  new Translation('en', 'Aruba Networks'),
  new Translation('de', 'Aruba Networks'),
]);

// Nimbel Storage
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'nimbel', [
  new Translation('en', 'HPE Nimbel'),
  new Translation('de', 'HPE Nimbel'),
]);

// HPE (Hewlett Packard Enterprise)
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'hpe', [
  new Translation('en', 'HPE'),
  new Translation('de', 'HPE'),
]);

// Extreme Networks
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'extreme', [
  new Translation('en', 'Extreme Networks'),
  new Translation('de', 'Extreme Networks'),
]);

// Western Digital
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'wd', [
  new Translation('en', 'Western Digital'),
  new Translation('de', 'Western Digital'),
]);

// Seagate
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'seagate', [
  new Translation('en', 'Seagate'),
  new Translation('de', 'Seagate'),
]);

// Synology
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'synology', [
  new Translation('en', 'Synology'),
  new Translation('de', 'Synology'),
]);

// QNAP
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'qnap', [
  new Translation('en', 'QNAP'),
  new Translation('de', 'QNAP'),
]);

// Fortinet
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'fortinet', [
  new Translation('en', 'Fortinet'),
  new Translation('de', 'Fortinet'),
]);

// Palo Alto Networks
MessageCollection.Global.setTranslations(
  MedoOptionMap.manufactores + PATH_SEPARATOR + 'paloaltonetworks',
  [new Translation('en', 'Palo Alto Networks'), new Translation('de', 'Palo Alto Networks')]
);

// Check Point Software Technologies
MessageCollection.Global.setTranslations(
  MedoOptionMap.manufactores + PATH_SEPARATOR + 'checkpoint',
  [new Translation('en', 'Check Point'), new Translation('de', 'Check Point')]
);

// Hitachi Vantara
MessageCollection.Global.setTranslations(MedoOptionMap.manufactores + PATH_SEPARATOR + 'hitachi', [
  new Translation('en', 'Hitachi Vantara'),
  new Translation('de', 'Hitachi Vantara'),
]);
