import {
  ComplexFactory,
  ComplexQuestion,
  Group,
  OpenQuestion,
  OptionsQuestion,
  Row,
  Section,
  Translation,
} from 'anketa-core';
import { Address, MedoUser } from '../helpers/medocino_user';

export const generateHeader = (name: string, opsanioAdmin: boolean) => {
  ComplexFactory.registerClass({
    name: 'MedoUser',
    Constructor: MedoUser as new (init?: any) => MedoUser,
    form: MedoUser.getForm,
  });

  ComplexFactory.registerClass({
    name: 'Address',
    Constructor: Address as new (init?: any) => Address,
    form: Address.getForm,
  });


  const gHeader = new Section('basic', [
    new Translation('en', 'General data'),
    new Translation('de', 'Allgemeine Daten'),
  ]);
  const headerRow = new Row('header', [
    new Translation('en', 'Customer Profile'),
    new Translation('de', 'Kundensteckbrief'),
  ]);
  const companyName = new OpenQuestion('name', [
    new Translation('en', 'Company Name'),
    new Translation('de', 'Firmenname'),
  ]);
  companyName.setFactValue(name);
  companyName.visible = false
  headerRow.addChild(companyName);

  gHeader.addChild(headerRow);

  const customerHeaderRow = new Row('chr', [
    new Translation('en', 'Customer Profile'),
    new Translation('de', 'Kundensteckbrief'),
  ]);

  const rLocation = new Row(`location`, [
    new Translation('en', 'Location where the appliance will be installed.'),
    new Translation('de', 'Standort wo die Appliance installiert wird.'),
  ]);

  rLocation.help = [new Translation('en', 'Location'), new Translation('de', 'Standort')];

  const address = new ComplexQuestion(`address`, [
    new Translation('en', 'Address'),
    new Translation('de', 'Addresse'),
  ], "Address");
  address.description = [
    new Translation('en', 'Where are the opsanio servers physically installed?'),
    new Translation('de', 'Wo werden die opsanio Server physisch eingebaut?'),
  ]
  rLocation.addChild(address);

  const customerContactRow = new Row('customer_contact_0', [
    new Translation('en', 'Contact Person'),
    new Translation('de', 'Ansprechpartner'),
  ]);
  const generateExternal = () => {
    const oldTrigger: ComplexQuestion = customerContactRow.children.get(
      `customer_${customerContactRow.children.size - 1}`
    ) as ComplexQuestion;
    oldTrigger.removeChangeListener(generateExternal);

    const item = new ComplexQuestion(
      `customer_${customerContactRow.children.size}`,
      [
        new Translation('en', `Contact Person ${customerContactRow.children.size}`),
        new Translation('de', `Ansprechpartner ${customerContactRow.children.size}`),
      ],
      'User'
    );
    item.addChangeListener(generateExternal);
    customerContactRow.addChild(item);
  };

  const customer = new ComplexQuestion(
    `customer_0`,
    [new Translation('en', 'Contact Person'), new Translation('de', 'Ansprechpartner')],
    'User'
  );
  customer.addChangeListener(generateExternal);
  customerContactRow.addChild(customer);

  rLocation.addChild(customerContactRow);

  const kritis = new OptionsQuestion(
    'kritis',
    [new Translation('en', 'Dark Side'), new Translation('de', 'Dark Side')],
    'default.options.yes_no'
  );
  kritis.help = [
    new Translation('en', 'Will the opsanio instance be set up in the dark side?'),
    new Translation(
      'de',
      'Wird die opsanio Instance in der Dark Side aufgebaut?'
    ),
  ];
  kritis.readonly = !opsanioAdmin

  const twoRoom = new OptionsQuestion(
    `two_room`,
    [new Translation('en', 'Two Room Setup'), new Translation('de', 'Zwei Raum Setup')],
    'default.options.yes_no'
  );

  twoRoom.help = [
    new Translation('en', 'Specifies whether a two-room setup is required.'),
    new Translation('de', 'Gibt an, ob ein Zwei-Raum-Setup erforderlich ist.'),
  ];

  customerHeaderRow.addChild(twoRoom);
  customerHeaderRow.addChild(kritis);

  const gEnvReq = new Group(`tech_req`, [
    new Translation('en', 'Site Requirements'),
    new Translation('de', 'Anforderung an den Standort'),
  ]);

  gEnvReq.help = [
    new Translation('en', 'Does the site meet all the requirements for the appliance?'),
    new Translation('de', 'Erfüllt der Standort alle Anforderungen für die Appliance?'),
  ];



  const rEnvReq = new Row(`r_tech_req`, [
    new Translation('en', 'Site Requirements'),
    new Translation('de', 'Anforderung an den Standort'),
  ]);


  const rInterfaces = new Row(`interfaces`, [
    new Translation('en', 'Switch Interfaces'),
    new Translation('de', 'Switch Interfaces'),
  ]);

  const tenGbe = new OptionsQuestion(
    `4_ten_gbe_port`,
    [
      new Translation('en', '4x 10 GB Interfaces avaible'),
      new Translation('de', '4x 10 GB Interfaces verfügbar'),
    ],
    'default.options.yes_no'
  );

  tenGbe.help = [
    new Translation('en', 'Indicates the number of free 10 GB interfaces available on the switch.'),
    new Translation('de', 'Gibt die Anzahl der freien 10-GB-Interfaces am Switch an.'),
  ];

  rInterfaces.addChild(tenGbe);

  const oneGbe = new OptionsQuestion(
    `2_gbe_port`,
    [
      new Translation('en', '2x 1 GB Interfaces avaible'),
      new Translation('de', '2x 1 GB Interfaces verfügbar'),
    ],
    'default.options.yes_no'
  );

  oneGbe.help = [
    new Translation('en', 'Indicates the number of free 1 GB interfaces available on the switch.'),
    new Translation('de', 'Gibt die Anzahl der freien 1-GB-Interfaces am Switch an.'),
  ];

  rInterfaces.addChild(oneGbe);

  const twoHu = new OptionsQuestion(
    `two_hu`,
    [
      new Translation('en', 'Two Rack Units'),
      new Translation('de', 'Zwei Höheneinheiten Frei im Rack'),
    ],
    'default.options.yes_no'
  );

  twoHu.help = [
    new Translation(
      'en',
      'Specifies if there are two free rack units available for the appliance.'
    ),
    new Translation('de', 'Gibt an, ob im Rack zwei Höheneinheiten für die Appliance frei sind.'),
  ];
  rEnvReq.addChild(twoHu);

  const pdu = new OptionsQuestion(
    `pdu`,
    [new Translation('en', 'PDU'), new Translation('de', 'PDU')],
    'default.options.yes_no'
  );

  pdu.help = [
    new Translation(
      'en',
      'Specifies if there are four free PDU units available for the appliance.'
    ),
    new Translation('de', 'Gibt an, ob vier PDU Slots für die Appliance frei sind.'),
  ];
  rEnvReq.addChild(pdu);

  gHeader.addChild(rLocation)
  gHeader.addChild(customerHeaderRow);
  gHeader.addChild(rEnvReq)
  return gHeader;
};
