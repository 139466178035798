import { type EvaluatorValue } from '../base/evaluator'
import { type Fact } from '../base/fact'
import { type Serializable } from '../base/serializable'
import { ValidatorType } from '../base/types'
import { type ExecutionContext } from '../runtime/execution_context'
import { type ValidationContext } from './context'

export type validatorFunction<T> = (val: T) => boolean

export function pathSegmentValidator (val: string): boolean {
  return false
}

export abstract class Validator implements Serializable {
  _type: ValidatorType

  constructor (type: ValidatorType) {
    this._type = type
  }

  public read (o: any): this {
    if (!Object.prototype.hasOwnProperty.call(o, 'type')) {
      throw new Error("attribute 'type' is missing")
    }
    if (typeof (o.type) === 'string') {
      this._type = (ValidatorType[o.type] as any) as ValidatorType
    } else {
      this._type = o.type as ValidatorType
    }
    return this
  }

  public save (): any {
    return {
      type: ValidatorType[this._type]
    }
  }

  public validate (ctx: ExecutionContext, fact: Fact<EvaluatorValue>): ValidationContext {
    throw new Error('not implemented')
  }

  public get type (): ValidatorType {
    return this._type
  }
}
