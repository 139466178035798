import React from 'react';
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModalBody,
} from '@elastic/eui';
import { DepartmentOption, UserValues } from '../types';
import { useSupport } from '../../../../_functions/support/support';

export const UserEditingModalUserDetails = ({
  editing,
  oldUserData,
  preferredLanguage,
  setPreferredLanguage,
  domain,
  setNewDepartment,
  setDomain,
  setCompanie,
  setCity,
  setJobTitel,
  newDepartment,
  companie,
  city,
  jobTitel,
}: {
  editing: boolean;
  oldUserData: UserValues;
  preferredLanguage: string;
  setPreferredLanguage: (language: string) => void;
  setNewDepartment: (department: Array<DepartmentOption>) => void;
  setDomain: (domain: Array<DepartmentOption>) => void;
  setCompanie: (company: Array<DepartmentOption>) => void;
  setCity: (city: Array<DepartmentOption>) => void;
  setJobTitel: (city: Array<DepartmentOption>) => void;
  newDepartment: Array<DepartmentOption>;
  domain: Array<DepartmentOption>;
  companie: Array<DepartmentOption>;
  city: Array<DepartmentOption>;
  jobTitel: Array<DepartmentOption>;
}) => {
  const {
    departments,
    setDepartments,
    domains,
    companies,
    setCompanies,
    citys,
    setCitys,
    jobTitlet,
    setJobTitlet,
  } = useSupport();

  const onDepartmentsChange = (options: Array<DepartmentOption>) => {
    setNewDepartment(options);
  };
  const onDepartmentCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setDepartments(newOption);
    }

    setNewDepartment([newOption]);
  };

  const onDomainChange = (options: Array<DepartmentOption>) => {
    setDomain(options);
  };

  const onCompanieChange = (options: Array<DepartmentOption>) => {
    setCompanie(options);
  };
  const onCompanieCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setCompanies(newOption);
    }

    setCompanie([newOption]);
  };

  const onCityChange = (options: Array<DepartmentOption>) => {
    setCity(options);
  };
  const onCityCreateOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setCitys(newOption);
    }

    setCity([newOption]);
  };

  const onJobTitelChange = (options: Array<DepartmentOption>) => {
    setJobTitel(options);
  };
  const onJobTitelOption = (
    searchValue: string,
    flattenedOptions: Array<EuiComboBoxOptionOption<string>> = []
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue) return;

    const newOption = { label: searchValue, value: searchValue };

    const isDuplicate = flattenedOptions.some(
      (option) => option.label.trim().toLowerCase() === normalizedSearchValue
    );

    if (!isDuplicate) {
      setJobTitlet(newOption);
    }

    setJobTitel([newOption]);
  };

  return (
    <EuiModalBody>
      <EuiForm>
        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Preferred Language">
              <EuiFieldText disabled value={oldUserData.preferredLanguage} />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Preferred Language">
                <EuiFieldText
                  value={preferredLanguage}
                  placeholder={oldUserData.preferredLanguage}
                  onChange={(e) => {
                    setPreferredLanguage(e.target.value);
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Department">
              <EuiComboBox
                isDisabled
                selectedOptions={oldUserData.department}
                singleSelection={{ asPlainText: true }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Department">
                <EuiComboBox
                  options={departments}
                  selectedOptions={newDepartment}
                  onChange={onDepartmentsChange}
                  onCreateOption={onDepartmentCreateOption}
                  isClearable={true}
                  singleSelection={{ asPlainText: true }}
                  data-test-subj="departmentComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Domain">
              <EuiComboBox
                isDisabled
                selectedOptions={oldUserData.domain}
                singleSelection={{ asPlainText: true }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue Domain">
                <EuiComboBox
                  options={domains}
                  selectedOptions={domain}
                  onChange={onDomainChange}
                  isClearable={true}
                  singleSelection={{ asPlainText: true }}
                  data-test-subj="domainComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Company">
              <EuiComboBox
                isDisabled
                selectedOptions={oldUserData.companyName}
                singleSelection={{ asPlainText: true }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue Company">
                <EuiComboBox
                  options={companies}
                  selectedOptions={companie}
                  onChange={onCompanieChange}
                  isClearable={true}
                  singleSelection={{ asPlainText: true }}
                  onCreateOption={onCompanieCreateOption}
                  data-test-subj="companyComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="City">
              <EuiComboBox
                isDisabled
                selectedOptions={oldUserData.companyName}
                singleSelection={{ asPlainText: true }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neue City">
                <EuiComboBox
                  options={citys}
                  selectedOptions={city}
                  onChange={onCityChange}
                  isClearable={true}
                  singleSelection={{ asPlainText: true }}
                  onCreateOption={onCityCreateOption}
                  data-test-subj="cityComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGrid direction="row">
          <EuiFlexItem
            style={{
              width: editing ? '40%' : '100%',
            }}
          >
            <EuiFormRow label="Job Titel">
              <EuiComboBox
                isDisabled
                selectedOptions={oldUserData.jobTitle}
                singleSelection={{ asPlainText: true }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {editing && (
            <EuiFlexItem
              style={{
                width: '40%',
              }}
            >
              <EuiFormRow label="Neuer Job Titel">
                <EuiComboBox
                  options={jobTitlet}
                  selectedOptions={jobTitel}
                  onChange={onJobTitelChange}
                  isClearable={true}
                  singleSelection={{ asPlainText: true }}
                  onCreateOption={onJobTitelOption}
                  data-test-subj="jobTitelComboBox"
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>
      </EuiForm>
    </EuiModalBody>
  );
};
