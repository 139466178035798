import { type Item } from './base/types'
import { type Catalog } from './types'

export type InitFunction = new (init?: any) => Catalog
export type GetFormFunction = () => Item

export interface CatalogTypeRegistrationInfo {
  name: string
  instance: Catalog
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CatalogFactory {
  private static readonly registeredCatalogs = new Map<string, CatalogTypeRegistrationInfo>()

  public static registerCatalog (info: CatalogTypeRegistrationInfo): void {
    CatalogFactory.registeredCatalogs.set(info.name, info)
  }

  public static isCatalogRegistered (name: string): boolean {
    return CatalogFactory.registeredCatalogs.has(name)
  }

  public static instance (name: string): any {
    if (!CatalogFactory.registeredCatalogs.has(name)) {
      throw new Error('Unknown Class ' + name)
    }
    const registrationInfo = CatalogFactory.registeredCatalogs.get(name) as CatalogTypeRegistrationInfo
    if (!registrationInfo) {
      throw new Error('Unknown Class ' + name)
    }
    return registrationInfo.instance
  }
}
